import {Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation, OnInit} from '@angular/core';
import {FlagService} from '@material/ie-flag-notification/service/flag-service';
import {OrderProductTile} from './order-product-tile';
import {IOrderProduct} from '@bast/domain/order/order';
import {Bast} from '@bast';

@Component({
  selector: `ie-order-product-tile`,
  templateUrl: `./order-product-tile.component.html`,
  styleUrls: [`./order-product-tile.component.scss`],
  encapsulation: ViewEncapsulation.None
})
export class OrderProductTileComponent extends OrderProductTile implements OnDestroy, OnInit {

  @Input() index = 0;
  @Input() disabled = false;
  @Input() product: IOrderProduct;
  @Input() locked: boolean;
  @Output() quantityChanged: EventEmitter<IOrderProduct> = new EventEmitter();
  @Output() productDelete: EventEmitter<void> = new EventEmitter();

  constructor(
    protected bast: Bast,
    protected flagService: FlagService,
  ) {
    super(flagService, bast);
    
    
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this._quantitySubscription) { this._quantitySubscription.unsubscribe(); }
  }

}
