import {environment} from '../../../environments/environment';

export class TemplateUtils {
  public static replaceShortCode(shortCode = '', replaceWith = '', content = ''): string {
    const regex = new RegExp(shortCode, 'gi');
    const parsedContent = content.replace(regex, replaceWith);

    return parsedContent;
  }

  public static replaceNewsletterShortcode(content: string, isPublic = false, hash = ''): string {
    const regex = /<!--NEWSLETTER-->(.+)?<!--\/NEWSLETTER-->/;
    const code = isPublic ? `<a href="${environment.newsletterUrl(hash||'')}">wyświetl newsletter w przeglądarce</a>` : '';
    const replace = `<!--NEWSLETTER-->${code}<!--/NEWSLETTER-->`;
    return content.replace(regex, replace);
  }
}
