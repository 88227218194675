<div
  class='ie-flag-selector-container'
  (click)="openFlagSelector()"
>
  <div
    class="flag"
    [style.background-color]="getCustomFlag()"
  ></div>
  <button class="icon-button">
    <ie-icon
      [icon]="'expand'"
      [size]="12"
    ></ie-icon>
  </button>
</div>

<ng-template #flagSelectorContent>
  <div class='flag-selector-content'>
    <div
      *ngFor="let flag of flags;let i=index;"
      class='flag'
      (click)="selectFlag(i)"
      [style.background-color]="flag"
    ></div>
  </div>
</ng-template>
