<div class='content-container order-success-container'>
    <div>
        <div class="banner-container">
            <img class="banner"
                 src="./assets/banners/order-success-banner.png"
                 alt="Success page baner"/>
        </div>

        <h1 class="h1">
            <p>Dziękujemy!</p>
            <p>Twoje zamówienie zostało wysłane.</p>
        </h1>
        
        <h3 class="h3">
            <p>Na twój adres e-mail wysłaliśmy wiadomość, w której znajdziesz szczegóły zamówienia.</p>
            <p>W razie jakichkolwiek pytań zapraszamy do kontaktu</p>
        </h3>
    </div>
</div>
