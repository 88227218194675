import {HttpErrorResponse} from '@angular/common/http';

export class ErrorStringifier {
  public static errorToString(error: HttpErrorResponse): string {
    if (!error || !error.hasOwnProperty('error')) { return `Nieznany błąd`; }
    const {errors} = error.error;
    const errorValues = Object.values(errors||{});

    if (error.status===403) { return error.error.hasOwnProperty('message') ? error.error.message : 'Nie posiadasz wystarczających uprawnień do wykonania tej akcji'; }
    if (!errors) { return `Nieznany błąd serwera`; }
    if (errorValues && errorValues instanceof Array && errorValues.length) { return errorValues[0]; }
  }
}
