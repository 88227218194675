import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IEWarnDialogComponent} from './ie-warn-dialog.component';
import {IESpinnerModule} from '../ie-spinner/ie-spinner.module';
import { SharedModule } from '@shared/shared.module';



@NgModule({
  declarations: [IEWarnDialogComponent],
  exports: [IEWarnDialogComponent],
  imports: [
    CommonModule,
    IESpinnerModule,
    SharedModule
  ]
})
export class IeWarnDialogModule { }
