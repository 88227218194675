import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEExtensionPanelComponent } from './ieextensionpanel.component';
import {IEIconModule} from '../ie-icon/ie-icon.module';

@NgModule({
  imports: [
    CommonModule,
    IEIconModule
  ],
  declarations: [IEExtensionPanelComponent],
  exports: [IEExtensionPanelComponent]
})
export class IEExtensionPanelModule { }
