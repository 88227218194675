import {Component, OnInit, forwardRef, Input, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter, AfterViewInit, Renderer2} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IEInputOutputAccessor } from '@shared/input-output-accessor/ie-input-output-accessor';
import { Validator, ErrorResponse } from '@shared/input-output-accessor/Validator';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'ie-input',
  templateUrl: './ie-input.component.html',
  styleUrls: ['./ie-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IEInputComponent),
      multi: true
    }
  ]
})
export class IEInputComponent extends IEInputOutputAccessor implements OnInit, AfterViewInit {

  @Input() placeholder: string;
  @Input() label: string;
  @Input() error: string;
  @Input() name: string;
  @Input() type: string;
  @Input() disabled: boolean;
  @Input() autofocus: boolean;
  @Input() floatingPlaceholder = true;
  @Input() hidePlaceholder = false;
  @Input() multiline: boolean;
  @Input() height: number;
  @Input() unit: string;
  @Input() defaultValue: string;
  @Input() required: boolean;
  @Input() float: boolean;
  @Output() lostFocus: EventEmitter<boolean> = new EventEmitter();
  @Output() onchange: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('inputContainer', { static: true }) container: ElementRef;
  @ViewChild('placeholderEl') set content(placeholderEl: ElementRef) {
    if(placeholderEl) {
        this._placeholderEl = placeholderEl;
    }
  }
  public focused = false;
  private _placeholderEl: ElementRef;
  private validator: Validator = new Validator();

  public validate(errors: ErrorResponse | HttpErrorResponse) {
    if (!this.name) { throw new Error('Validation failed! Define name input'); }
    this.validator.validate(errors, this);
    this.cd.markForCheck();
  }

  public showPlaceholder(): boolean {
    if (this.error) { return true; }
    if (this.hidePlaceholder) { return false; }
    if (typeof this.fieldValue === 'undefined' || this.fieldValue === null) { return true; }
    if (this.floatingPlaceholder) { return true; }

    return false;
  }


  public blur() {
    this.focused = false;
    this.lostFocus.emit();
  }

  public focus() {
    this.error = null;
    this.focused = true;
  }

  public setFocus() {
    setTimeout(() => {
      try { this.el.nativeElement.querySelector('input').focus(); } catch (e) {}
    }, 100);
  }

  constructor(
    private cd: ChangeDetectorRef,
    private el: ElementRef,
    private renderer: Renderer2
  ) { super(); }

  ngOnInit() {
    setTimeout(() => {
      if (!this.fieldValue && this.defaultValue) { this.fieldValue = this.defaultValue; }
      if (this.autofocus) { this.setFocus(); }
    }, 200);
  }

  ngAfterViewInit() {
    if (this.float) {
      this.renderer.addClass(this._placeholderEl.nativeElement, 'no-wrap');
      const width = this._placeholderEl.nativeElement.offsetWidth + 50 + 'px';
      this.renderer.setStyle(this.container.nativeElement, 'width', window.innerWidth > 1024 ? width : '100%')
    }
  }

}
