import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {APIService} from '../../../app/api/apiservice.service';

export class ProducerLocation {
  id?: number;
  producer_id?: number;
  country?: number;
  country_name?: string;
  name?: string;
  city?: string;
  post_code?: string;
  street?: string;
  house_number?: number;
  flat_number?: number;
  phone?: string;
  email?: string;

  public static toDto(obj: ProducerLocation) {
    const output = { location: {} };
    const {name, ...address} = obj;
    Object.assign(output.location, {name: obj.name, address });

    return output;
  }

  constructor(props?: any) {
    if (props) { Object.assign(this, props); }
    else {
      // Set Poland as a default country
      this.country = 168;
    }
  }
}

export class ProducerLocationDomain {

  public get(producerId: number): Promise<ProducerLocation[]> {
    return this.api.request(ENDPOINTS.getProducerLocations(producerId), 'GET').then(({data}) => data);
  }

  public getById(locationId: number): Promise<ProducerLocation> {
    return this.api.request(ENDPOINTS.getProducerLocation(locationId), 'GET');
  }

  public create(location: ProducerLocation): Promise<any> {
    return this.api.request(ENDPOINTS.createProducerLocation(location.producer_id), 'POST', ProducerLocation.toDto(location));
  }

  public update(location: ProducerLocation): Promise<any> {
    return this.api.request(ENDPOINTS.updateProducerLocation(location.id), 'POST', ProducerLocation.toDto(location));
  }

  public delete(location: ProducerLocation): Promise<void> {
    return this.api.request(ENDPOINTS.deleteProducerLocation(location.id), 'DELETE');
  }

  constructor(private api: APIService) {}
}
