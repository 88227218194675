import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Directive({
  selector: `[overlayFocusTrap]`
})
export class IEOverlayFocusTrapDirective implements OnInit {
  protected active: boolean;
  constructor(private _elementRef: ElementRef) {}

  @Input() ingoreElements: Array<string> = [];
  @Input() mode: 'NAMED' | 'CONTAINS' = 'CONTAINS';

  @Output()
  public overlayFocusTrap = new EventEmitter<MouseEvent>();

  @HostListener('document:mousedown', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
    if (!targetElement) { return; }
    if (this.ingoreElements.includes(targetElement.id)) { return; }

    if (this.mode === 'CONTAINS') {
      const firstChild = this._elementRef.nativeElement.firstElementChild;
      const clickedInside = firstChild.contains(targetElement);
      if (!clickedInside) {
        setTimeout(() => this.active = true, 500);
        if (this.active) this.overlayFocusTrap.emit(event);
      }
    } else {
      if (targetElement.id === this._elementRef.nativeElement.id) {
        this.overlayFocusTrap.emit(event);
      }
    }
  }

  ngOnInit() {
    if (!this._elementRef.nativeElement.id) {
      this._elementRef.nativeElement.id = `OVERLAY-${Date.now()}`;
    }
  }

}
