import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import {from, Observable, of} from 'rxjs';
import { APIService } from '../../api/apiservice.service';
import {concatMap, catchError, retry, map, filter, take} from 'rxjs/operators';
import { defaultIfEmpty } from 'rxjs/operators';
import { ENDPOINTS } from '../../configuration/ENDPOINTS';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/take';
import 'rxjs/add/operator/takeUntil';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/first';
import 'rxjs/operators/catchError';

export const STORAGE_KEYS = {
  SUPPLIERS : { key: 'suppliers', endpoint: ENDPOINTS.getSuppliers },
  PRODUCERS : { key: 'producers', endpoint: ENDPOINTS.getProducers },
  USERS : { key: 'users', endpoint: ENDPOINTS.getUsers },
  TAGS : { key: 'tags', endpoint: ENDPOINTS.getTags },
  COUNTRIES : { key: 'countries', endpoint: ENDPOINTS.getCountries },
  REFERER: { key: 'referer' }
};

@Injectable()
export class StorageService {

  private _collection$: { key: any, data$: Observable<any> } = null;

  /**
   * Retrieves collection from local storage
   * and serves colleciton as Observable
   * If storage is empty - data is fetched from remote
   * @param {object} storageKey
   * @returns {Observable} Local storage collection as observable
   */
  public getCollection<T>(storageKey: { key, endpoint }): Observable<any> {
    if (!this._collection$ || this._collection$.key !== storageKey.key) {

      this._collection$ = {} as any;
      this._collection$.key = storageKey.key;

      this._collection$.data$ =
        from(this.apiService.request<T>(storageKey.endpoint, 'GET'))
          .pipe( catchError( () => of({ data: null })))
          .pipe(retry(1))
          .pipe(map( response => {
            return response['data'];
          }))
          .pipe( concatMap( output => {
            if (output) {
              this.localStorage.store(storageKey.key, output);
              return output;
            } else {
              return of([]);
            }
          }));

      if (this.localStorage.retrieve(storageKey.key)) {
        this._collection$.data$ = from(this.localStorage.retrieve(storageKey.key));
      }

    } else {
      this._collection$.key = storageKey.key;
    }

    return this._collection$.data$;
  }

  /**
   * Retrives one object from local storage
   * @param {object} storageKey
   * @param {string} key
   * @param {any} value
   */
  public getObjectByProperty<T>(storageKey: { key, endpoint }, key: string, value: any): Observable<T> {
    return this.getCollection<T>(storageKey).pipe(filter( obj => obj[key] == value )).pipe(take(1)).pipe( defaultIfEmpty(null));
  }

  public getObjectByPredicat<T>(storageKey: { key, endpoint }, predicat: (val) => boolean): Observable<T> {
    return this.getCollection<T>(storageKey).pipe(filter(predicat)).pipe(take(1)).pipe( defaultIfEmpty(null) );
  }

  /**
   * Clears storage at given key
   * @param storageKey
   */
  public forceUpdate(storageKey: { key, endpoint }) {
    this.localStorage.clear(storageKey.key);
    this._collection$ = null;
  }

  /**
   * Appends element to storage collection
   * @param { key, endpoint } storage Storage key
   * @param { any } obj Object to append
   */
  public appendToCollection(storage: { key, endpoint }, obj: any): void {
    const update = [...this.localStorage.retrieve(storage.key), obj];
    this.localStorage.store( storage.key, update);
    this.updateCacheObservable$(storage.key, from(update));
  }

  /**
   * Removes object from local storage collection
   * @param {key, endpoint} storage Local storage collection
   * @param {any} obj Object to remove
   */
  public removeFromCollection(storage: { key, endpoint }, obj: any): void {
    const collection = this.localStorage.retrieve(storage.key);
    collection.splice( collection.indexOf(obj), 1 );
    this.localStorage.store(storage.key, collection);
  }

  public removeFromCollectionByProperty(storage: { key, endpoint }, obj: any, property: string): void {
    let collection = this.localStorage.retrieve(storage.key);
    collection = collection.filter( c => c[property] !== obj[property]);
    this.localStorage.store(storage.key, collection);
  }

  /**
   * Drops all collection stored within local storage
   */
  public purgeCollections() {
    for (const key in STORAGE_KEYS) { if (STORAGE_KEYS[key]) { this.localStorage.clear(STORAGE_KEYS[key].key); } }
  }

  /**
   * Loads collection into local storage
   * @param {key, endpoint} storageKey Storage key
   */
  public loadCollection(storageKey: { key, endpoint }) {
    this.apiService.request(storageKey.endpoint, 'GET')
      .then( collection => {
        this.localStorage.store(storageKey.key, collection[storageKey.key]);
      });
  }

  public revokeCollection(storageKey: { key, endpoint }) {
    this.forceUpdate(storageKey);
    this.getCollection(storageKey);
  }

  private updateCacheObservable$(key: string , data: Observable<any>) {
    this._collection$ = {
      key,
      data$: data
    };
  }

  constructor(
    private localStorage: LocalStorageService,
    private apiService: APIService
  ) {}

}
