import {TagType} from '@material/ie-tag/Tag';

export const ENDPOINTS = {
    // Authorization
    login: '/v2/login',
    loginConfirm: '/v2/login/confirm',
    restorePassword: '/v2/user/restore-password',
    generatePassword: '/v2/user/generate-password',
    logout: '/v2/logout',
    checkIfUserIsActive: '/v2/user/active/check',
    // Account
    account: '/v2/account',
    updateMyAccount: '/v2/account/update',
    updateMyAvatar: '/v2/account/image/update',
    // Suppliers
    getSuppliers: '/v2/suppliers',
    getSupplierProducts: (productId: number) => `/v2/product/${productId}/supplier/products`,
    getProductsBySupplier: '/v2/supplier/products',
    createSupplier: '/v2/supplier/create',
    updateSupplier: (supplierId: number) => `/v2/supplier/${supplierId}/update`,
    deleteSupplier: (supplierId: number) => `/v2/supplier/${supplierId}/delete`,
    deleteSupplierImage: (supplierId: number) => `/v2/supplier/${supplierId}/image/delete`,
    getSupplier: (supplierId?: number) => `/v2/supplier/${supplierId}`,
    getSupplierUsers: (id: number) => `/v2/supplier/${id}/users`,
    uploadSupplierImage: (supplierId: number) => `/v2/supplier/${supplierId}/image/update`,
    getSupplierContacts: (supplierId: number) => `/v2/supplier/${supplierId}/contacts`,
    createSupplierContact: (supplierId: number) => `/v2/supplier/${supplierId}/contact/create`,
    updateSupplierContact: (contactId: number) => `/v2/supplier/contact/${contactId}/update`,
    deleteSupplierContact: (contactId: number) => `/v2/supplier/contact/${contactId}/delete`,
    // Countries
    getCountries: '/v2/countries',
    // Locations
    getLocation: (locationId: number) => `/v2/supplier/location/${locationId}`,
    getSupplierLocations: (supplierId: number) => `/v2/supplier/${supplierId}/locations`,
    getLocationContacts: (locationId: number) => `/v2/supplier/location/${locationId}/contacts`,
    createLocation: (supplierId: number) => `/v2/supplier/${supplierId}/location/create`,
    updateLocation: (locationId: number) => `/v2/supplier/location/${locationId}/update`,
    deleteLocation: (locationId: number) => `/v2/supplier/location/${locationId}/delete`,
    // Producer locations
    getProducerLocation: (locationId: number) => `/v2/producer/location/${locationId}`,
    getProducerLocations: (producerId: number) => `/v2/producer/${producerId}/locations`,
    createProducerLocation: (producerId) => `/v2/producer/${producerId}/location/create`,
    updateProducerLocation: (locationId: number) => `/v2/producer/location/${locationId}/update`,
    deleteProducerLocation: (locationId: number) => `/v2/producer/location/${locationId}/delete`,
    // Warehouses
    getWarehouses: '/v2/supplier/warehouses',
    getWarehousesById: (locationId: number) => `/v2/supplier/location/${locationId}/warehouses`,
    getWarehouseDetails: (warehouseId: number)  => `/v2/supplier/location/warehouse/${warehouseId}`,
    updateWarehouse: (warehouseId: number) => `/v2/supplier/location/warehouse/${warehouseId}/update`,
    createWarehouse: (locationId: number) => `/v2/supplier/location/${locationId}/warehouse/create`,
    deleteWarehouse: (warehouseId: number) => `/v2/supplier/location/warehouse/${warehouseId}/delete`,
    // Producers
    getProducer: (producerId: number) => `/v2/producer/${producerId}`,
    getProducers: '/v2/producers',
    createProducer: '/v2/producer/create',
    updateProducer: (producerId: number) => `/v2/producer/${producerId}/update`,
    deleteProducer: (producerId: number) => `/v2/producer/${producerId}/delete`,
    uploadProducerImage: (producerId: number) => `/v2/producer/${producerId}/image/update`,
    getProducerContacts: (producerId: number) => `/v2/producer/${producerId}/contacts`,
    createProducerContact: (producerId: number) => `/v2/producer/${producerId}/contact/create`,
    updateProducerContact: (contactId: number) => `/v2/producer/contact/${contactId}/update`,
    deleteProducerContact: (contactId: number) => `/v2/producer/contact/${contactId}/delete`,
    downloadProducerVCard: (contactId: number) => `v2/producer/contact/${contactId}/vcard`,
    deleteProducerImage: (producerId: number) => `/v2/producer/${producerId}/image/delete`,
    // Producer Series
    getProducerSeries: (producerId: number) => `/v2/producer/${producerId}/series`,
    getProducerSerie: (serieId: number) => `/v2/producer/serie/${serieId}`,
    createProducerSerie: (producerId: number) => `/v2/producer/${producerId}/serie/create`,
    updateProducerSerie: (serieId: number) => `/v2/producer/serie/${serieId}/update`,
    deleteProducerSerie: (serieId: number) => `/v2/producer/serie/${serieId}/delete`,
    // Producer access logs
    getProducerArticleAccessLog: `/v2/producer/article/access/log`,
    // Emails
    getEmails: '/v2/emails',
    getEmail: (templateId: number) => `/v2/email/${templateId}`,
    sendEmail: '/v2/email/send',
    updateEmail: (templateId: number) => `/v2/email/${templateId}/update`,
    // Email messages
    getEmailMessages: (sent: 0|1) => `/v2/email-messages?sent=${sent}`,
    getEmailMessage: (messageId: number) => `/v2/email-message/${messageId}`,
    getEmailMessageByHash: (hash: string) => `/v2/email-message/public/${hash}`,
    updateEmailMessage: (messageId: number) => `/v2/email-message/${messageId}/update`,
    deleteEmailMessage: (messageId: number) => `/v2/email-message/${messageId}/delete`,
    sendEmailMessage: (messageId: number) => `/v2/email-message/${messageId}/send`,
    createEmailMessage: `/v2/email-message/create`,
    // IPS
    getIPS: '/v2/ips',
    getSupplierIPS: (supplierId) => `/v2/supplier/${supplierId}/ips`,
    getUserIPS: '/v2/ips/user',
    createIP: '/v2/ip/create',
    updateIP: (ipId: number) => `/v2/ip/${ipId}/update`,
    deleteUserIp: (ipId: number) => `/v2/ip/${ipId}/delete`,
    // Tags
    getTags: '/v2/tags',
    createTag: '/v2/tag/create',
    deleteTag: (type: TagType, tagId: number) => `/v2/tag/${tagId}/remove`,
    assignTag: (type: TagType, tagId: number) => `/v2/tag/${tagId}/assign`,
    getProductsByTag: (tagId: number) => `/v2/tag/${tagId}/products`,
    getSuppliersByTag: (tagId: number) => `/v2/tag/${tagId}/suppliers`,
    getProducersByTag: (tagId: number) => `/v2/tag/${tagId}/producers`,
    // Roles
    getRoles: '/v2/roles',
    getSingleRole: (roleId: number) => `/v2/role/${roleId}`,
    getChildrenRoles: '/v2/roles/children',
    createRole: '/v2/role/create',
    updateRole: (roleId: number) => `/v2/role/${roleId}/update`,
    deleteRole: (roleId: number) => `/v2/role/${roleId}/delete`,
    // Product
    updateProduct: (productId: number) => `/v2/product/${productId}/update`,
    getProduct: (productId: number) => `/v2/product/${productId}`,
    getProductETIMFeatures: (productId: number) => `/v2/product/${productId}/features`,
    updateProductEtimFeatures: (productId: number) => `/v2/product/${productId}/etim/update`,
    getProductDownloadFiles: (productId: number) => `/v2/product/${productId}/downloads`,
    getProductChangelog: (productId: number) => `/v2/product/${productId}/changelog`,
    deleteProductImage: (productId: number, imageId: number) => `/v2/product/${productId}/image/${imageId}/delete`,
    // Search
    getProducts: '/v2/search/products',
    getFilters: '/v2/search/filters',
    getSupplierProductsSearch: '/v2/search/supplier-products',
    searchTags: '/v2/search/tags',
    // Privileges
    updatePrivilege: (privilegeId) => `/v2/privilege/${privilegeId}/update`,
    getPrivileges: '/v2/user/privileges',
    getAllPrivileges: '/v2/privileges/all',
    getRolePrivileges: (roleId: number) => `/v2/role/${roleId}/privileges`,
    populatePrivileges: (roleId) => `/v2/role/${roleId}/children/populate/with/privileges`,
    copyPrivileges: (roleId) => `/v2/role/${roleId}/copy/privileges`,
    // Users
    getUsers: '/v2/users',
    getUser: (userId) => `/v2/user/${userId}`,
    createUser: '/v2/user/create',
    activateUser: '/v2/user/activate',
    activateUserOfId: (userId) => `/v2/user/${userId}/activation/email/send`,
    updateUser: (userId) => `/v2/user/${userId}/update`,
    uploadUserImage: (userId) => `/v2/user/${userId}/image/update`,
    deleteUser: (userId) => `/v2/user/${userId}/delete`,
    deleteUserAvatar: (userId: number) => `/v2/user/${userId}/image/delete`,
    // Reports and statistics
    getReport: '/v2/report',
    getStatistics: '/v2/stats',
    getProductsStatistics: '/v2/stats/sales/products',
    getProductStatisticsForSupplier: '/stats/sales/products',
    getStatisticsReport: `/v2/stats/sales`,
    // Orders
    createOrder: '/v2/order/create',
    deleteSupplierOrder: (orderId) => `/v2/order/${orderId}/delete`,
    deleteOrder: (orderId) => `/v2/order/${orderId}/delete`,
    addProductToOrder: (orderId) => `/v2/order/${orderId}/product/create`,
    deleteProductFromOrder: (productId) => `/v2/order/product/${productId}/delete`,
    updateProductInCart: (productId) => `/v2/order/product/${productId}/update`,
    getOrderProducts: (orderId) => `/v2/order/${orderId}/products`,
    sendUserOrder: (orderId) => `/v2/order/${orderId}/send`,
    updateOrder: (orderId) => `/v2/order/${orderId}/update`,
    getOrder: (orderId) => `/v2/order/${orderId}`,
    getOrders: '/v2/orders',
    updateOrderStatus: (orderId) => `/v2/supplier/order/${orderId}/update`,
    getReceivedOrders: '/v2/supplier/orders',
    getReceivedOrderDetails: (orderId) => `/v2/supplier/order/${orderId}`,
    getReceivedOrderProducts: (orderId) => `/v2/supplier/order/${orderId}/products`,
    // Materials
    removeMaterial: (materialId) => `/v2/material/${materialId}/delete`,
    createMaterial: '/v2/material/create',
    updateMaterial: (materialId) => `/v2/material/${materialId}/update`,
    // Help
    getHelpList: '/v2/helps',
    getHelp: (helpId) => `/v2/help/${helpId}`,
    createHelp: '/v2/help/create',
    updateHelp: (helpId) => `/v2/help/${helpId}/update`,
    deleteHelp: (helpId) => `/v2/help/${helpId}/delete`,
    getPublicHelpList: '/v2/helps/public',
    getPublicHelp: (helpId) => `/v2/help/public/${helpId}`,
    // Banners
    getBannerList: '/v2/banners',
    getActiveBannerList: '/v2/banners/active',
    getBanner: (bannerId) => `/v2/banner/${bannerId}`,
    createBanner: '/v2/banner/create',
    updateBanner: (bannerId) => `/v2/banner/${bannerId}/update`,
    deleteBanner: (bannerId) => `/v2/banner/${bannerId}/delete`,
    bannerClick: (bannerId) => `/v2/banner/${bannerId}/click`,
    // Units
    getUnits: '/v2/units',
    getUnit: (unitId: number) => `/v2/unit/${unitId}`,
    createUnit: '/v2/unit/create',
    updateUnit: (unitId: number) => `/v2/unit/${unitId}/update`,
    deleteUnit: (unitId: number) => `/v2/unit/${unitId}/delete`,
    // Price list
    createPriceList: '/v2/price-list/create',
    updatePriceList: (priceListId) => `/v2/price-list/${priceListId}/update`,
    deletePriceList: (priceListId) => `/v2/price-list/${priceListId}/delete`,
    getPriceList: '/v2/price-lists',
    getProducerPriceList: (producerId) => `/v2/producer/${producerId}/price-lists`,
    // Stock list
    createStockList: '/v2/stock-list/create',
    updateStockList: (stockListId) => `/v2/stock-list/${stockListId}/update`,
    deleteStockList: (stockListId) => `/v2/stock-list/${stockListId}/delete`,
    getSupplierStockList: (supplierId) => `/v2/supplier/${supplierId}/stock-lists`,
    getStockList: '/v2/stock-lists',
    // ETIM
    getEtimCategories: '/v2/etim/categories',
    getEtimFeatures: (etimProduct) => `/v2/etim/product/${etimProduct}/features`,
    getEtimProducts: (etimCategory) => `/v2/etim/category/${etimCategory}/products`,
    getEtimUnits: '/v2/etim/units',
    getEtimValues: '/v2/etim/values',
    getEtimVersions: '/v2/etim/versions',
    // Integrations
    getIntegrations: (producerId) => `/v2/producer/${producerId}/integrations`,
    getIntegration: (integrationId) => `/v2/producer/integration/${integrationId}`,
    createIntegration: (producerId) => `/v2/producer/${producerId}/integration/create`,
    updateIntegration: (integrationId) => `/v2/producer/integration/${integrationId}/update`,
    deleteIntegration: (integrationId) => `/v2/producer/integration/${integrationId}/delete`,
    getSupplierIntegrations: (supplierId) => `/v2/supplier/${supplierId}/integrations`,
    getSupplierIntegration: (integrationId) => `/v2/supplier/integration/${integrationId}`,
    createSupplierIntegration: (supplierId) => `/v2/supplier/${supplierId}/integration/create`,
    updateSupplierIntegration: (integrationId) => `/v2/supplier/integration/${integrationId}/update`,
    deleteSupplierIntegration: (integrationId) => `/v2/supplier/integration/${integrationId}/delete`,
    // Brands
    createBrand: '/v2/brand/create',
    updateBrand: (brandId) => `/v2/brand/${brandId}/update`,
    deleteBrand: (brandId) => `/v2/brand/${brandId}/delete`,
    getBrands: (producerId?) => `/v2/producer/${producerId}/brands`,
    getBrand: (brandId) => `/v2/brand/${brandId}`,
    // Task events
    getTaskEventsForPriceList: (priceListId) => `/v2/producer/price-list/${priceListId}/task/events`,
    // Task
    createManualImportTask: (priceListId?) => `/producer/manual/import/task/create/${priceListId}`,
    deleteImportTask: (taskId) => `/v2/producer/integration/task/${taskId}/delete`,
    deleteSupplierImportTask: (taskId) => `/v2/supplier/integration/task/${taskId}/delete`,
    runIntegrationTask: (integrationId?) => `/v2/producer/integration/${integrationId}/task/create`,
    runSupplierIntegrationTask: (integrationId?) => `/v2/supplier/integration/${integrationId}/task/create`,
    updateProducerTaskIntegration: (taskId) => `/v2/producer/integration/task/${taskId}/update`,
    updateProducerManualTask: (taskId) => `/v2/producer/manual/import/task/${taskId}/update`,
    createStockListManualImportTask: (stockListId?) => `/v2/supplier/manual/import/${stockListId}/task/create`,
    updateSupplierManualTask: (taskId) => `/v2/supplier/manual/import/task/${taskId}/update`,
    updateSupplierTaskIntegration: (taskId) => `/v2/supplier/integration/task/${taskId}/update`,
    // Articles
    getAgreementArticle: (producerId) => `/v2/producer/${producerId}/article/agreement`,
    getBonusArticle: (producerId) => `/v2/producer/${producerId}/article/bonus`,
    getDiscountArticle: (producerId) => `/v2/producer/${producerId}/article/discount`,
    getPromotionArticle: (producerId) => `/v2/producer/article/${producerId}/promotion`,
    getSaleArticle: (producerId) => `/v2/producer/${producerId}/article/sale`,
    updateAgreementArticle: (producerId) => `/v2/producer/${producerId}/article/agreement/update`,
    updateBonusArticle: (producerId) => `/v2/producer/${producerId}/article/bonus/update`,
    updateDiscountArticle: (producerId) => `/v2/producer/${producerId}/article/discount/update`,
    updatePromotionArticle: (promotionId) => `/v2/producer/article/promotion/update/${promotionId}`,
    updateSaleArticle: (producerId) => `/v2/producer/${producerId}/article/sale/update`,
    getProducerPromotions: (producerId: number) => `/v2/producer/${producerId}/articles/promotion`,
    getProducerPromotion: (promotionArticleId: number) => `/v2/producer/article/promotion/${promotionArticleId}`,
    createProducerPromotion: (producerId: number) => `/v2/producer/${producerId}/article/promotion/create`,
    updateProducerPromotion: (articleId: number) => `/v2/producer/article/promotion/${articleId}/update`,
    deleteProducerPromotion: (articleId: number) => `/v2/producer/article/promotion/${articleId}/delete`,
    getAttachmentsForPromotionArticle: (articleId: number) => `/v2/promotion/attachments/${articleId}`,
    // Attachments
    createAttachment: '/v2/attachment/create',
    updateAttachment: (attachmentId) => `/v2/attachment/${attachmentId}/update`,
    deleteAttachment: (attachmentId) => `/v2/attachment/${attachmentId}/delete`,
    getAttachments: '/v2/attachments',
    getAttachmentsForArticle: (articleId) => `/v2/article/${articleId}/attachments`,
    // Contact form
    sendContactForm: '/v2/email/contact',
    // Directories
    getDirectories: '/v2/directories',
    createDirectory: '/v2/directory/create',
    getDirectory: (directoryId) => `/v2/directory/${directoryId}`,
    deleteDirectory: (directoryId => `/v2/directory/${directoryId}/delete`),
    updateDirectory: (directoryId => `/v2/directory/${directoryId}/update`),
};
