<div class="order-product-tile">
  <div class="product-index">{{index+1}}</div>

  <div class="product-photo-wrapper">
    <div
      class="product-photo"
      [attr.inaccessibile]="!product.product_id?true:null"
      [style.background-image]="'url(' + product.product_image_url + ')'"
    ></div>
  </div>

  <div class="product-details-wrapper">
    <div class="product-tile-col">
      <span class="product-name">
        <a *ngIf="product.product_id;else simpleProductName" class="--link" routerLink="/app/product/{{product.product_id}}">{{product.product_name}}</a>
        <ng-template #simpleProductName>
          <span [attr.inaccessibile]="!product.product_id?true:null">{{product.product_name}}</span>
        </ng-template>
      </span>
      <span class="producer-name">Producent: <b>{{product.producer_alias || product.producer_name}}</b></span>
      <span class="product-code">Kod produktu: <b>{{product.product_code}}</b></span>
    </div>

    <div class="product-tile-col col-shifted">
      <span class="lot-number">Nr partii: <b>{{product.lot||'-'}}</b></span>
      <span class="product-unit-netto-price">Netto: <b>{{product.price|price}} zł</b></span>
      <span class="unit">Jednostka: <b>{{product.unit_name||'szt'}}</b></span>
    </div>

    <!--<div class="product-tile-col col-shifted">
      <span>Producent: <b>{{product.producer_name}}</b></span>
    </div>-->

    <div class="product-tile-col col-shifted">
      <span class="quantity">Ilość <b>{{product.quantity}}</b></span>
    </div>

    <div class="product-tile-col col-shifted col-aligned-right">
      <span class="order-product-netto-price">{{product.price_total|price}} zł</span>
      <span class="order-product-gross-price">({{product.gross_price_total|price}} zł brutto)</span>
      <span class="tax-value">(VAT {{product.tax}} %)</span>
    </div>

  </div>

</div>
