import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of, Subscription} from 'rxjs';
import {FlagService} from '@material/ie-flag-notification/service/flag-service';
import {catchError, flatMap, switchMap} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {PAGE_TITLES} from '../../../../configuration/PAGE_TITLES';
import {IEWarnDialogComponent} from '@material/ie-warn-dialog/ie-warn-dialog.component';
import {IE_EMPTY_PLACEHOLDER} from '@material/ie-empty-placeholder/ie-empty-placeholder.component';
import {ErrorStringifier} from '@shared/utils/error-stringifier';
import {Bast} from '@bast';
import {OrderSort, OrderStatus, SupplierOrder} from '@bast/domain/order/order';
import {User} from '@bast/domain/user';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpErrorHandler} from '@shared/utils/error-handler';
import { OrderStateSelector } from '@bast/domain/order';

@Component({
  selector: 'ie-order-preview',
  templateUrl: './order-preview.component.html',
  styleUrls: ['./order-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderPreviewComponent implements OnInit, OnDestroy {

  @ViewChild(IEWarnDialogComponent) warnDialog: IEWarnDialogComponent;
  public window = window;
  public pending = true;
  public sendingPending: boolean;
  public account: User;
  public order: SupplierOrder;
  public comment: string;
  public newOwner: number;
  public warehouse: number;
  public warehouses: any = [];
  public users: Array<User> = [];
  public breadcrumb = [{ href: '/orders', label: 'Zamówienia zlecone'}];
  public NO_PRODUCTS_PLACEHOLDER = {
    image: IE_EMPTY_PLACEHOLDER.NO_PRODUCTS,
    action: { label: 'Filtruj produkty', handler: () => this.router.navigateByUrl('/app') },
  };
  public orderNameEditable = false;
  public sortOptions = [
    { key: OrderSort.Supplier, value: 'Po sprzedawcy' },
    { key: OrderSort.Product, value: 'Po produkcie' },
  ]

  private _orderSubscription: Subscription;
  private _activeOrderSubscription: Subscription;

  constructor(
    private bast: Bast,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private flagService: FlagService,
    private title: Title,
  ) {}

  ngOnInit() {
    this.activeRoute.params.subscribe(params => {
      const { id } = params;
      if (id) {
        this.initialize(id);
      } else {
        // Navigate elsewhere
        this.router.navigateByUrl('/app');
      }
    });
  }

  ngOnDestroy() {
    if (this._orderSubscription) { this._orderSubscription.unsubscribe(); }
  }

  private fetchSupplierOrder(orderId: number): Observable<any> {
    return this.bast.order.getById(orderId, true)
      .pipe(flatMap( order => {
        this.order = order;
        this.title.setTitle(PAGE_TITLES.orderPreview(this.order.code));
        this.setBreadcrumb(order);
        return of(null);
      }));
  }

  private fetchSupplierWarehouses(): Observable<any> {
    return this.bast.warehouse.get()
      .pipe(catchError(() => {
        if (this.order.status !== OrderStatus.Sent) {
          this.flagService.publishErrorFlag(
            'Brak magazynów',
            'Nie posiadasz zdefiniowanych magazynów',
            false,
            'Dodaj nowy',
            'Anuluj'
          )
            .subscribe(
              () => {
                const {supplier_id} = this.account;
                this.router.navigateByUrl(`/app/suppliers/preview/${supplier_id}?tab=1`);
              });
        }

        return of([]);
      }))
      .pipe(flatMap(res => {
          this.warehouses = res;
          this.warehouse = this.warehouses[0].id;
          return of(null);
      }));
  }

  private fetchUserAccount(): void {
    this.bast.user.myself()
      .then(account => {
        this.account = account;
      });
  }

  private setBreadcrumb(order: SupplierOrder): void {
    if (!order) { return; }
    if (this.breadcrumb.length === 2) { return; }

    this.breadcrumb = [
      ...this.breadcrumb,
      { href: `/orders/received/${order.id}`, label: order.code }
    ];
  }

  private initialize(orderId: number): void {
    this.fetchUserAccount();
    this._orderSubscription = this.fetchSupplierOrder(orderId)
      .pipe(switchMap(_ => this.fetchSupplierWarehouses()))
      .subscribe(
        () => {
          this.pending = false;
          if(this.order.active) {
            this._activeOrderSubscription = this.bast.order.state(OrderStateSelector.Active, true)
            .subscribe(({ active }) => {
              this.order = active;
            });
          }
        },
        (e: HttpErrorResponse) => {
          this.pending = false;
          new HttpErrorHandler().handleHttpError(e, '/orders');
        }
      );
  }

  makeOrderNameEditable(el): void {
    this.orderNameEditable = true;
    el.innerText = this.order.code;
    setTimeout(() => el.focus(), 200);
  }

  sortBy(event) {
    this.order.changeSortMethod(event);
  }

  deleteProduct(product): void {
    this.warnDialog.open(
      `warn`,
      `Usuwanie z koszyka`,
      `Czy na pewno usunąć produkt ${product.product_name} z koszyka?`,
      'usuń'
    )
      .onAccept()
      .subscribe(() => {
        this.warnDialog.setPending(true);
        this.bast.order.deleteProductFromOrder(product, this.order.id)
          .subscribe( () => {
            this.order.deleteProduct(product);
            this.warnDialog.close();
          });
      });
  }

  sendOrder(): void {
    this.sendingPending = true;

    if(this?.order && this?.order?.id && this?.order?.code) {
      const orderCode = this.order.code;

      this.bast.order.send(this?.order?.id, this.warehouse, this.comment)
      .subscribe(
        () => {
          this.sendingPending = false;

          this.flagService.publishSuccessFlag(
            'Wysłano',
            `Zamówienie ${orderCode} zostało wysłane`
          );

          setTimeout(() => this.router.navigateByUrl('/app/orders/order-success'), 1000);
        },
        err => {
          const getErrorMessage = () => {
            if (err.error.hasOwnProperty('errors')) { return Object.values(err.error.errors).join(', '); }
            if (err.error.hasOwnProperty('message')) { return err.error.message; }
            return '';
          };

          this.sendingPending = false;
          this.flagService.publishErrorFlag(
            'Błąd',
            `Nie udało się wysłać zamówienia ${getErrorMessage()}`,
            false
          );
        }
      );
    }
  }

  changeOrderOwner(): void {
    this.bast.order.update(this.order.id, { user: this.newOwner })
      .subscribe(
        () => {
          this.flagService.publishSuccessFlag(
            'Nowy opiekun zamówienia!',
            'To zamówienie zmieniło opiekuna'
          );

          this.initialize(this.order.id);
        },
        err => {
          console.warn('Cannot change order owner: ', err);
          this.flagService.publishErrorFlag(
            'Nie udało się zmienić opiekuna zamówienia',
            `${ErrorStringifier.errorToString(err)}`
          );
        }
      );
  }

  changeOrderName(event): void {
    const newOrderName = event.target.innerText;
    if (newOrderName===this.order.code) {
      event.target.innerText = `Zamowienie: ${this.order.code}`; this.orderNameEditable = false; return;
    }

    this.bast.order.update(this.order.id, { code: newOrderName })
      .subscribe(({code}) => {
        event.target.innerText = `Zamowienie: ${code}`;
        this.order.code = code;
        this.orderNameEditable = false;
      });
  }

  deleteOrder(): void {
    this.warnDialog.open(
      `warn`,
      'Usuwanie zamówienia',
      'Czy na pewno usunąć zamówienie? Tej operacji nie można cofnąć',
      'usuń'
    )
      .onAccept()
      .subscribe(() => {
        this.warnDialog.setPending(true);
        this.bast.order.delete(this.order.id)
          .then(() => {
            this.router.navigate(['app', 'orders']);
            this.flagService.publishInfoFlag(
              'Usunięto',
              `Zamówienie ${this.order.code} zostało usunięte`
            );
          })
          .catch(e => this.flagService.publishErrorFlag('Błąd usuwania', e.error.message));
      });
  }

  fetchUsers(): void {
    this.bast.supplier.users(this.account.supplier_id)
      .then(res => {
        this.users = res;
      })
      .catch(() => {
        this.users = [];
      })
  }

  createFirstWarehouse() {
    this.bast.user.myself()
      .then(account => {
        this.router.navigateByUrl(`/app/suppliers/preview/${account.supplier_id}?tab=1`);
      });
  }

  focusCommentArea(el): void {
    setTimeout(() => el.focus(), 50);
  }

  appendOrderComment(el) {
    const comment = el.innerText;
    if (!comment.length) { return; }
    const date = Date.now()/1000;
    this.bast.order.update(this.order.id, { comment })
      .subscribe(() => {
          this.order.comments = [...this.order.comments, { user: this.account.id, date, content: comment }];
          el.innerText = '';
        },
        () => {
          this.flagService.publishWarningFlag(
            'Błąd',
            'Nie udało się dodać komentarza do zamówienia'
          );
        });
  }

  activateOrder(): void {
    this.bast.order.activate(this.order)
      .subscribe(() => {
        this.order.active = true;
        setTimeout(() => this.router.navigateByUrl('/app/orders'), 1500);
      });
  }

  recalculatePrices(): void {
    this.bast.order.getById(this.order.id)
      .subscribe((order) => this.order.recalculatePrices(order));
  }
}
