import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {from, Observable} from 'rxjs';
import {APIService} from '../../../app/api/apiservice.service';
import {Tag} from '../../../app/ie-material/ie-tag/Tag';
import {SupplierLocation, SupplierWarehouse} from '../supplier-location';
import {User} from '@bast/domain/user';

export interface SupplierContact {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  position?: string;
  description?: string;
}

export interface SupplierCount {
  count? :string;
  supplier_id?: string;
  supplier_name?: string
}

export class Supplier {

  id?: number;
  tax_number?: number;
  supplier_id?: number;
  name?: string;
  full_name?: string;
  alias?: string;
  city?: string;
  post_code?: string;
  street?: string;
  house_number?: string;
  flat_number?: string;
  country?: number;
  country_name?: string;
  phone?: string;
  email?: string;
  url?: string;
  tags?: Tag[];
  image?: number;
  image_id?: number;
  image_url?: string;
  is_member?: boolean;
  order_phone?: string;
  order_email?: string;
  erp?: string;
  gln?: string;
  developers_emails?: string[];
  supplier_order_default_patron?: number;
  supplier_order_default_patron_id?: number;

  public static toDto(supplier: Supplier) {
    const {
      name,
      full_name,
      alias,
      tax_number,
      erp,
      gln,
      developers_emails,
      is_member,
      street,
      house_number,
      flat_number,
      city,
      post_code,
      phone,
      email,
      country,
      order_phone,
      order_email,
      supplier_order_default_patron,
      url
    } = supplier;

    const address = {
      name,
      street,
      house_number,
      flat_number,
      city,
      post_code,
      phone,
      email,
      country
    };

    return {
      supplier: {
        name,
        tax_number,
        erp,
        gln,
        full_name,
        alias,
        developers_emails,
        is_member,
        supplier_order_default_patron,
        url,
        location: {
          address,
          order_phone,
          order_email,
        }
      }
    };
  }

  constructor(props?) {
    if (props) {
      Object.assign(this, props);
      this.supplier_order_default_patron = props.supplier_order_default_patron_id;
    } else {

      // Set Poland as a default country
      this.country = 168;
    }
  }
}

export class SupplierDomain {

  public create(supplier: Supplier): Promise<Supplier> {
    return this.api.request(ENDPOINTS.createSupplier, 'POST', Supplier.toDto(supplier));
  }

  public get(): Promise<Supplier[]> {
    return this.api.request(ENDPOINTS.getSuppliers, 'GET').then(({data}) => data);
  }

  public getById(supplierId: number): Promise<Supplier> {
    return this.api.request(ENDPOINTS.getSupplier(supplierId), 'GET');
  }

  public update(supplier: Supplier): Promise<Supplier> {
    return this.api.request(ENDPOINTS.updateSupplier(supplier.id), 'POST', Supplier.toDto(supplier));
  }

  public delete(supplierId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteSupplier(supplierId), 'DELETE')
  }

  public deleteImage(supplierId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteSupplierImage(supplierId), 'DELETE')
  }

  public users(supplierId: number): Promise<User[]> {
    return this.api.request(ENDPOINTS.getSupplierUsers(supplierId), 'GET')
      .then(({ data }) => data);
  }

  public getLocation(locationId: number): Promise<Location> {
    return this.api.request(ENDPOINTS.getLocation(locationId), 'GET');
  }

  public getLocations(supplierId: number): Promise<SupplierLocation[]> {
    return this.api.request(ENDPOINTS.getSupplierLocations(supplierId), 'GET').then(({ data }) => Promise.resolve(data));
  }

  public deleteLocation(locationId): Observable<void> {
    return from(this.api.request(
      ENDPOINTS.deleteLocation(locationId),
      'DELETE'
    )) as Observable<void>;
  }

  public createLocation(location: SupplierLocation, supplier: Supplier): Promise<SupplierLocation> {
    return this.api.request(ENDPOINTS.createLocation(supplier.id), 'POST', SupplierLocation.toDto(location));
  }

  public updateLocation(location: SupplierLocation): Promise<SupplierLocation> {
    return this.api.request(ENDPOINTS.updateLocation(location.id), 'POST', SupplierLocation.toDto(location));
  }

  public getWarehouses(locationId: number): Promise<any> {
    return this.api.request(ENDPOINTS.getWarehousesById(locationId), 'GET').then(({data}) => data);
  }

  public createWarehouse(location: any, warehouse: any): Promise<SupplierWarehouse> {
    return this.api.request(
      ENDPOINTS.createWarehouse(location.id),
      'POST',
      { warehouse }
    );
  }

  public updateWarehouse(warehouse: any): Promise<SupplierWarehouse> {
    return this.api.request(
      ENDPOINTS.updateWarehouse(warehouse.id),
      'POST',
      { warehouse });
  }

  public deleteWarehouse(warehouseId: number): Promise<void> {
    return this.api.request(
      ENDPOINTS.deleteWarehouse(warehouseId),
      'DELETE'
    );
  }

  public getSuppliersListWithFilters(filter: any): Promise<any> {
    const filtersQuery = this.buildFilters(filter);
    const query = `${ENDPOINTS.getSuppliers}?order_by=featured&order=desc&${filtersQuery}`;
    return this.api.request(query, 'GET').then(({data}) => data);
  }

  public getSupplierProducts(
    query: string,
    page?: number,
    supplier?: number,
    order?: string,
    orderBy?: string
  ): Promise<any> {
    const searchQuery = ENDPOINTS.getProductsBySupplier + '?' + this.buildQuery(query) +
      '&page=' + (page || 1) + `&suppliers[]=${supplier}&` + this.buildOrder(order, orderBy);
    return this.api.request(searchQuery, 'GET');
  }

  public getSupplierProductsSearch(
    query: string,
    page?: number,
    supplier?: number,
    order?: string,
    orderBy?: string,
    inStock?: boolean
  ): Promise<any> {
    const _inStock = inStock ? `&filters[in_stock]=1` : '';
    
    const searchQuery = ENDPOINTS.getSupplierProductsSearch + '?' + this.buildQuery(query) +
      '&page=' + (page || 1) + `&filters[suppliers][]=${supplier}&` + this.buildOrder(order, orderBy) + _inStock;
    return this.api.request(searchQuery, 'GET');
  }

  private buildFilters(filter) {
    if (!filter) return '';

    let f = '';
    // tslint:disable-next-line:forin
    for (const prop in filter) {
      if (!filter[prop]) { continue; }

      if (filter[prop] instanceof Array) {
        const arrayFilter = filter[prop]
          .reduce((acc, next) => {
            return acc += `&filters[${prop}][]=${next}&`;
          }, '');

        f += arrayFilter;
      } else {
        f += `&filters[${prop}]=${filter[prop]}&`;
      }
    }

    return f;
  }

  private buildOrder(order: string, orderBy: string): string {
    if (!order) { return 'order=desc&order_by=stock'; }
    if (order && !orderBy) { return ''; }
    return `order=${order}&order_by=${orderBy}`;
  }

  private buildQuery(query: string) {
    if (!query) return '';
    return `q=${query}`;
  }

  public contact(supplierId: number): Promise<SupplierContact[]> {
    return this.api.request(ENDPOINTS.getSupplierContacts(supplierId), 'GET').then(({ data }) => Promise.resolve(data));
  }

  public createContact(supplierId: number, contact: SupplierContact): Promise<SupplierContact> {
    return this.api.request(ENDPOINTS.createSupplierContact(supplierId), 'POST', { supplier_contact: { contact } });
  }

  public updateContact(contact: SupplierContact): Promise<SupplierContact> {
    return this.api.request(ENDPOINTS.updateSupplierContact(contact.id), 'POST', { supplier_contact: { contact } });
  }

  public deleteContact(contactId: number): Promise<SupplierContact> {
    return this.api.request(ENDPOINTS.deleteSupplierContact(contactId), 'DELETE');
  }

  constructor(private api: APIService) {}
}
