import {orderBy} from 'lodash-es';
import {deburr} from 'lodash-es';
import {BulkList} from '@shared/utils/bulk-operation';
import { element } from 'protractor';

export class Sorter {
  property = '';
  order = 0;

  public sort(property: string) {
    if (!this.property || this.property !== property){ 
      this.order = 1;
      this.property = property;
    } else {
      this.order *= -1;
      this.property = '';
    }
  }

  private lookForSecondSortProperty(property: string, list: Array<any>): string {
    if (property.includes('_to')) {
      return property.replace('_to', '_from');
    } else {
      return '';
    }
  }

  private handleNull(property: string, list: Array<any>, secondProperty?: string) {
    const _helper = list;
    let _values = [];
    let _nulls = [];
    let _sorted = [];
    if (!secondProperty) {
      _values = _helper.filter(element => element[property] !== '' && element[property] !== null)
      _nulls = _helper.filter(element => element[property] === '' || element[property] === null)
    } else {
      _values = _helper.filter(element => (element[property] !== '' && element[property] !== null) || (element[secondProperty] !== '' && element[secondProperty] !== null))
      _nulls = _helper.filter(element => element[secondProperty] === '' || element[secondProperty] === null)
    }
    _sorted = [..._values, ..._nulls];
    return _sorted
  }

  public sortArray(property: string, list: Array<any>, secondProperty?: string): any {
    // this.sort(property);
    // const transformProperty = (value) => { 
    //   if (typeof value === 'string') { 
    //     return value.toLowerCase(); 
    //   }
    //   return value; }
    // const propertyComparator = this.order === 1 ? 
    //   [(i) => (i[property] === null || i[property]) === '' ? null : transformProperty(deburr(i[property])) ] : 
    //   [(i) => (i[property] === null || i[property]) === '' ? '' : transformProperty(deburr(i[property])) ];
    // const sortedArray = orderBy(list, propertyComparator, [this.order === 1 ? 'asc' : 'desc']);
    this.sort(property);
    let sortedArray;
    const _list = list;
    let _boolean = false;
    let _number = false;
    let _second_property = secondProperty?secondProperty:this.lookForSecondSortProperty(property, list);

    _list.forEach((element) => {

      if (typeof element[property] === 'number') {
        _number = true;
      }
      if (element[property] === false) {
        _boolean = true;
      }
      if (element[property] === true) {
        _boolean = true;
      }
      if (element[property] === null && !_number) {
        element[property] = '';
      }
    })
    if (this.order === 1) {
      if (_boolean) {
        if (_second_property) {
          sortedArray = _list.sort((a,b) => b[property]-a[property] || b[_second_property]-a[_second_property]);
        } else {
          sortedArray = _list.sort((a,b) => b[property]-a[property]);
        }
      }
      if (_number) {
        if (_second_property) {
          sortedArray = _list.sort((a,b) => a[property]-b[property] || a[_second_property]-b[_second_property]);
        } else {
          sortedArray = _list.sort((a,b) => a[property]-b[property]);
        }
        sortedArray.reverse();
      }
      if (!_number && !_boolean) {
        sortedArray = _list.sort((a,b) => a[property].localeCompare(b[property]) || a['id'] - b['id']);
      }
    } else {
      if (_boolean) {
        sortedArray = _list.sort((a,b) => a[property]-b[property]);
      } 
      if (_number) {
        if (_second_property) {
          sortedArray = _list.sort((a,b) => a[property]-b[property] || a[_second_property]-b[_second_property]);
        } else {
          sortedArray = _list.sort((a,b) => a[property]-b[property]);
        }
      }
      if (!_number && !_boolean) {
        sortedArray = _list.sort((a,b) => a[property].localeCompare(b[property]) || a['id'] - b['id']); 
      }
      if (!_boolean && !_number) {
        sortedArray.reverse();
      }
    }
    return list instanceof BulkList ? new BulkList(this.handleNull(property, sortedArray, _second_property)) : this.handleNull(property, sortedArray, _second_property);
  }
}

