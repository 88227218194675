/**
 * Transformers are used to transform cdktable input data to match Durszlak export interface.
 * In CDKTable single column SHOULD represent single property of row object
 * but in some cases columns merge several properties.
 * Transformers split those merged properties back to it's original form before requesting Durszlak.
 * @author Mateusz Pytel
 */

const __DEFAULT_TRANSFORMER__ = (columns: string[]) => columns;
const __PRODUCERS_LIST_TRANSFORM__ = (columns: string[]) => {
  return columns.reduce((acc, next) => {
    if (next === 'bonus_valid_from_valid_to') {
      return [
        ...acc,
        'bonus_article_valid_from',
        'bonus_article_valid_to',
      ];
    }
    if (next === 'discount_valid_from_valid_to') {
      return [
        ...acc,
        'discount_article_valid_from',
        'discount_article_valid_to'
      ];
    }
    if (next === 'sale_valid_from') {
      return [
        ...acc,
        'sale_article_valid_from'
      ];
    }

    return [...acc, next];
  }, []);
};
const __USERS_LIST_TRANSFORM__ = (columns: string[]) => {
  return columns.reduce((acc, next) => {
    if (next === 'name') {
      return [
        ...acc,
        'last_name',
        'first_name',
      ];
    }
    return [...acc, next];
  }, []);
};



const CdkTableColumnsTransfomer = {
  DEFAULT: __DEFAULT_TRANSFORMER__,
  PRODUCERS: __PRODUCERS_LIST_TRANSFORM__,
  USERS: __USERS_LIST_TRANSFORM__,
};

export default CdkTableColumnsTransfomer;
