import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, SimpleChanges, OnChanges} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators' 

@Component({
  selector: 'ie-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IESearchBarComponent implements OnInit, OnChanges {

  @Input() isMainSearchInput: boolean = false;
  @Input() placeholder: string;
  @Input() mobileplaceholder: string = null;
  @Input() phrase = '';
  @Input() keepOpen: boolean;
  @Output() onsearch: EventEmitter<string> = new EventEmitter();
  @Output() onlivesearch: EventEmitter<string> = new EventEmitter();
  @Output() onenter: EventEmitter<string> = new EventEmitter();
  @Output() onfocus: EventEmitter<boolean> = new EventEmitter();
  @Output() onblur: EventEmitter<string> = new EventEmitter();
  @Output() onclear: EventEmitter<void> = new EventEmitter();
  @ViewChild('input', { static: true }) el: ElementRef;

  searchBoxOpened = false;
  error: string;
  private phrase$: Subject<string> = new Subject();

  search(): void {
    if (!this.searchBoxOpened) {
      this.searchBoxOpened = true;
      this.onfocus.emit(true);
    } else {
      this.onenter.emit(this.phrase);
    }
  }

  closeSearchbox(): void { this.searchBoxOpened = false; this.onfocus.emit(false); }

  onKeyUp(event) {
    if (event.target.value.length > 0) {
      this.error = null;
    }
    
    if(this.phrase.length > 2) {
      this.onsearch.emit(event);
      this.phrase$.next(event.target.value.length?event.target.value:'');

      if(!this.isMainSearchInput) {
        this.search();
      }
    }
  }

  /**
   * Set timeout is required to fill the field
   */
  onPaste(event): void {
    setTimeout(() => {
      if (event.target.value.length > 0) {
        this.error = null;
      }
      
      if(this.phrase.length > 2) {
        this.onsearch.emit(event);
        this.phrase$.next(event.target.value.length?event.target.value:'');
        if(!this.isMainSearchInput) {
          this.search();
        }
      }
    }, 100)
  }

  blur(): void {
    if(!this.phrase) {
      this.phrase = '';
    }
      this.error = null;
      this.onblur.emit(this.phrase);
      this.onenter.emit(this.phrase);
  }

  printError(error: string) {
    this.phrase = '';
    this.error = error;
  }

  clear(): void {
    this.phrase = '';
    this.search();
    this.onclear.emit();
  }

  ngOnInit() {
    this.phrase$
    .pipe(debounceTime(500))
    .subscribe(val => {
      this.onlivesearch.emit(val);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.phrase?.currentValue) {
      this.phrase$.next(this.phrase);
    }
  }

}
