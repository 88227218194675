import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APIService } from './apiservice.service';
import { IELoaderService } from '@material/ie-loader/ieloader.service';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    APIService,
    IELoaderService
  ]
})
export class APIModule { }
