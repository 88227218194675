<div class='ie-paginator'>
  <ie-icon 
    [icon]="'dropdown_big'"
    [attr.active]="isLeftButtonActive() ? true : null"
    (click)="prevPage()"
  ></ie-icon>
  <input type="number" [(ngModel)]="currentPage" (change)="changePage($event.target.value)">
  <span>z {{pages ? pages : '-'}}</span>
  <ie-icon 
    [icon]="'dropdown_big'"
    [attr.active]="isRightButtonActive() ? true : null"
    (click)="nextPage()"
    [attr.next]="true"
  ></ie-icon>
</div>
