import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEDialogComponent } from '../component/ie-dialog.component';
import { DynamicAnchorModule } from '../../ie-dynamic-anchor/ie-dynamic-anchor-module';
import { IEIconModule } from '../../ie-icon/ie-icon.module';
import { IEOverlayFocusTrapModule } from '../../ie-overlay-focus-trap/ie-overlay-focus-trap.module';
import {IEDialogContentComponent} from '@material/ie-dialog/component/ie-dialog-content.component';

@NgModule({
  imports: [
    CommonModule,
    DynamicAnchorModule,
    IEIconModule,
    IEOverlayFocusTrapModule,
  ],
  declarations: [
    IEDialogComponent,
    IEDialogContentComponent,
  ],
  exports: [
    IEDialogComponent
  ]
})
export class IEDialogModule { }
