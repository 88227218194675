<div
  class='ie-inline-dropdown-container'
  tabindex="0"
  (keydown.enter)="openDropdown()"
  (click)="openDropdown()"
>
  <div
    class="label"
    [attr.selected]="fieldValue?true:null"
    [title]="getPlaceholder()"
  >{{getPlaceholder()}}</div>
  <button class="icon-button">
    <ie-icon
      [icon]="'dropdown_small'"
      [size]="12"
    ></ie-icon>
  </button>
</div>
<ng-template #dropdownContent>
  <div class="ie-inline-dropdown-content">
    <div
      *ngFor="let option of options"
      class='dropdown-option'
      (click)="selectValue(option)"
    >{{getOptionLabel(option)}}</div>
    <div
      *ngIf="!options || options.length===0"
      class='no-options'
    >Brak opcji</div>
  </div>
</ng-template>
