import {Component, EventEmitter, Input, Output, ViewChild, ViewContainerRef} from '@angular/core';
import {DynamicAnchor} from '@material/ie-dynamic-anchor/ie-dynamic-anchor';

@Component({
  template: `
      <div class='overlay dialog-overlay'></div>
      <div
          class="dialog-container-overlay-wrapper"
          [attr.opened]="opened"
          [attr.overflow]="overflow"
      >
          <div
              class='dialog-container'
              [attr.overflow]="overflow"
          >
              <ie-icon
                [icon]="'clear'"
                [pointer]="true"
                (click)="closeDialog()"
              ></ie-icon>
              <ng-template dynamicanchor></ng-template>
          </div>
      </div>

  `,
  styleUrls: [`./ie-dialog.component.scss`]
})
export class IEDialogContentComponent {

  @ViewChild(DynamicAnchor, { static: true }) anchor: DynamicAnchor;
  @Input() destroy = () => {};
  @Input() close = () => {};
  @Output() onclose: EventEmitter<void> = new EventEmitter();

  public opened = true;
  public overflow: boolean;

  public closeDialog(): void {
    this.opened = false;
    setTimeout(() => this.close(), 400);
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
  ) {}
}
