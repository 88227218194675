<ng-container *ngIf="!sent;else sentMessage">
  <div class="dialog-form-container">
    <h2>Wyślij zgłoszenie</h2>
    <br />
    <div class='contact-form-content'>
      <ie-multiselect
        style="margin-bottom: 2rem"
        [placeholder]="'Co jest tematem zgłoszenia?'"
        [name]="'subject'"
        [(ngModel)]="contactForm.subject"
      >
        <ie-multiselect-option
          *ngFor="let subject of SUBJECTS"
          [option]="{ key: subject.value, value: subject.value }"
        >{{subject.value}}</ie-multiselect-option>
      </ie-multiselect>

      <ie-input
        [multiline]="true"
        [disabled]="!contactForm.subject"
        [placeholder]="'Opisz zgłoszenie'"
        [name]="'content'"
        [(ngModel)]="contactForm.content"
      ></ie-input>

      <div class='form-input-wrapper'>
        <span>Załącz pliki <span class="error" *ngIf="attachmentError">Nieprawidłowy plik. Rozszerzenie nieobsługiwane lub plik za duży</span></span>
        <div class='files-list'>
          <ie-icon-button
            *ngIf="contactForm.files.length===0"
            style="display: inline-block"
            [icon]="'add'"
            [label]="'Dodaj plik'"
          >
          <input
            type="file"
            id="contact-form-file"
            class='file-input'
            (change)="handleFileInput($event.target.files)"
          />
          </ie-icon-button>
          <div class='file' *ngFor="let file of contactForm.files;let i=index">
            <span>{{file.name}}</span>
            <ie-icon
              matTooltip="usuń plik"
              [icon]="'clear'"
              [size]="13"
              (click)="deleteFile(i)"
            ></ie-icon>
          </div>
        </div>
      </div>

      <div class='form-input-wrapper'>
        <span>Czy możemy się z Tobą telefonicznie skontaktować?</span>
        <div class='radiobuttons-container'>
          <ie-radiobutton
            [label]="'tak'"
            [value]="true"
            [(ngModel)]="contactForm.contactAggreement"
          ></ie-radiobutton>
          <ie-radiobutton
            [label]="'nie'"
            [value]="false"
            [(ngModel)]="contactForm.contactAggreement"
          ></ie-radiobutton>
        </div>
      </div>

      <div class='submit-container'>
        <ie-button
          [active]="contactForm.subject && contactForm.content"
          [text]="'Wyślij'"
          (click)="sendContactForm()"
        ></ie-button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #sentMessage>
  <div class='sent-message-container'>
    <label>Dziękujemy za zgłoszenie!</label>
    <p>Zgłoszenie zapisane pod kluczem: <b>{{taskKey}}</b>.</p>
    <p>Jeżeli wyraziłeś zgodę na kontakt telefoniczny, wkrótce <br /> skontaktujemy się z Tobą. <p>
  </div>
</ng-template>
