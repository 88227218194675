import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import {CDKSortableColumn, IECdkTableComponent} from './ie-cdk-table.component';
import {TableSortButtonComponent} from './table-sort-button/table-sort-button.component';
import {IESearchbarModule} from '../ie-searchbar/ie-searchbar.module';
import {TableChangeColumnOrderComponent} from './table-change-column-order/table-change-column-order.component';
import {TableExportDataComponent} from './table-export-data/table-export-data.component';
import {IEIconModule} from '../ie-icon/ie-icon.module';
import {IECheckboxModule} from '../ie-checkbox/ie-checkbox.module';
import {FormsModule} from '@angular/forms';
import {IESpinnerModule} from '../ie-spinner/ie-spinner.module';
import {CdkTableModule} from '@angular/cdk/table';
import {IEDialogModule} from '../ie-dialog/module/ie-dialog.module';
import {IEPaginatorModule} from '../ie-paginator/ie-paginator.module';
import {IEEmptyPlaceholderModule} from '../ie-empty-placeholder/ie-empty-placeholder.module';
import {IEIconButtonModule} from '../ie-icon-button/ie-icon-button.module';
import {DragScrollerDirective} from './drag-scroller/drag-scroller.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    IECdkTableComponent,
    CDKSortableColumn,
    TableSortButtonComponent,
    TableChangeColumnOrderComponent,
    TableExportDataComponent,
    DragScrollerDirective
  ],
  exports: [
    IECdkTableComponent,
    CDKSortableColumn,
    TableSortButtonComponent,
    CdkTableModule,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IESearchbarModule,
    IEIconModule,
    IECheckboxModule,
    IESpinnerModule,
    IEDialogModule,
    IEPaginatorModule,
    IEEmptyPlaceholderModule,
    IEIconButtonModule,
    DragDropModule
  ],
  entryComponents: [
    TableSortButtonComponent,
    TableChangeColumnOrderComponent,
    TableExportDataComponent
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class IECdkTableModule { }
