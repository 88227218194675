<div
  class="ie-quantity-input"
  [attr.pending]="pending?true:null"
  [attr.disabled]="disabled?true:null"
>
  <ie-icon
    class='decrease-btn'
    [icon]="'minus'"
    [pointer]="true"
    [size]="14"
    [attr.disabled]="lockedFloor"
    (click)="decrease()"
  ></ie-icon>
  <input
    id="orderInput"
    class='order-input'
    placeholder="1"
    name="{{name}}"
    (focus)="error=null"
    (blur)="blur($event)"
    (keypress)="validateKeypress($event)"
    (keyup)="validateKeyup($event)"
    [attr.disabled]="this.max===0||disabled?true:null"
    [ngStyle]="{'color':'#3333cc'}"
    [value]="fieldValue"
  />
  <ie-icon
    class="increase-btn"
    [icon]="'plus'"
    [size]="14"
    [pointer]="true"
    [attr.disabled]="lockedCeil"
    (click)="increase()"
  ></ie-icon>
</div>
