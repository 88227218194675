import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEMultiselectComponent } from './ie-multiselect.component';
import { IEAutofocusModule } from '../ie-autofocus/ie-autofocus.module';
import { FormsModule } from '@angular/forms';
import { ClickOutsideModule } from '../ie-click-outside/click-outside-module';
import {IECheckboxModule} from '../ie-checkbox/ie-checkbox.module';
import {IEIconModule} from '../ie-icon/ie-icon.module';
import {MultiselectContentComponent} from './multiselect-content/multiselect-content.component';
import {IeMultiselectOptionComponent} from './ie-multiselect-option.component';
import {DynamicAnchorModule} from '../ie-dynamic-anchor/ie-dynamic-anchor-module';
import {MultiselectBindService} from './multiselect-bind.service';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule,
    IEAutofocusModule,
    IECheckboxModule,
    IEIconModule,
    DynamicAnchorModule
  ],
  declarations: [
    IEMultiselectComponent,
    IeMultiselectOptionComponent,
    MultiselectContentComponent
  ],
  exports: [
    IEMultiselectComponent,
    IeMultiselectOptionComponent
  ],
  entryComponents: [MultiselectContentComponent],
  providers: [MultiselectBindService]
})
export class IEMultiselectModule { }
