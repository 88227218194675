import {from, Observable, of, ReplaySubject} from 'rxjs';
import {catchError, map, retry, switchMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {some, intersection, keys, flattenDeep} from 'lodash-es';
import {UserDomain} from '../user';
import { APIService } from 'src/app/api/apiservice.service';


interface BastPrivilege {
  id: number;
  name: string;
  description: string;
}

/**
 * Service used to check if user has access to particular API action.
 * It stores internally dictionary of privileges previously parsed
 * from /privileges response to check with O(1) complexity user permission.
 */
export class AuthorizationDomain {

  private _privilegesObs$: ReplaySubject<any>;

  /**
   * Checks if user has a permission to one or multiple action.
   * Returns true if user has any permission listed in params list
   * @param privileges List of permissions
   */
  public check(privileges: string | string[] | [string[]] | string[][] | object): Observable<boolean> {
    return this.fetchPrivileges()
      .pipe(map(_ => {
        return some(intersection(privileges instanceof Array ? flattenDeep(privileges) : [privileges], keys(_)));
      } ))
      .pipe(take(1));
  }

  /**
   * Checks if user has permission to one or multiple action, but within user account context.
   * If user does not have a root permission to a API endpoint, but might have a SELF permission additional check is performed.
   * Any property from /account response can be checked against context e.g. if we want to check if user
   * can access /supplier we can pass { supplier_id: ${SUPPLIER_ID}  } object as a context parameter.
   * @param privileges
   * @param context
   */
  public checkWithinContext(privileges: string[], context: object): Observable<boolean> {
    if (!privileges || !(privileges instanceof Array) || privileges.length < 2 || Object.keys(context).length !== 1) { return of(false); }
    return this.fetchPrivileges()
      .pipe(switchMap(_ => {
        const flattenPrivileges = flattenDeep(privileges);
        const prop = Object.keys(context)[0];
        const value = context[prop];
        const checkPrivileges = (forRoot) => flattenPrivileges
          .filter((p, i) => forRoot ? !(i % 2) : (i % 2))
          .every((p) => _.hasOwnProperty(p as any));

        return checkPrivileges(true) ?
          of(true) :
          from(
            this.userDomain.myself()
              .then(acc => (acc[prop] ===  value && checkPrivileges(false)))
              .catch(() => false)
          );
      }))
      .pipe(take(1));
  }

  /**
   * Preflight method used early in APP_MODULE to fetch
   * and parse privilege map from /privileges on app startup
   */
  public cache(): void {
    this.fetchPrivileges();
  }

  protected fetchPrivileges(): Observable<any> {
    if (!this._privilegesObs$) {
      this._privilegesObs$ = new ReplaySubject();
      this.httpClient
        .get<{ data: BastPrivilege[] }>(this.apiService.API_URL + ENDPOINTS.getPrivileges)
        .pipe( catchError( () => { this._privilegesObs$ = null; return of({ data: null }); } ))
        .pipe( retry(2) )
        .pipe(map(this.mapPrivilegeResponse))
        .subscribe(privileges => this._privilegesObs$ ? this._privilegesObs$.next(privileges) : Object.call(null  ));
    }

    return this._privilegesObs$;
  }

  protected mapPrivilegeResponse(response: { data: BastPrivilege[] }) {
    const { data } = response;
    const output = (data||[]).reduce((acc, next) => {
      Object.assign(acc, { [next.name]: true });
      return acc;
    }, {});

    return output;
  }


  constructor(private apiService: APIService, private httpClient: HttpClient, private userDomain: UserDomain) {}
}
