import {FlagService} from '@material/ie-flag-notification/service/flag-service';
import {ErrorStringifier} from '@shared/utils/error-stringifier';
import {Subject, Subscription} from 'rxjs';
import {EventEmitter} from '@angular/core';
import {Bast} from '@bast';
import {IOrderProduct} from '@bast/domain/order/order';

export class OrderProductTile {

  public disabled: boolean;
  public locked: boolean;
  public product: IOrderProduct;
  public quantityChange$: Subject<any> = new Subject<any>();
  public quantityChanged: EventEmitter<any> = new EventEmitter();
  public INFINITY = Infinity;

  protected _quantitySubscription: Subscription;

  public quantityChange(evt): void {
    this.locked = true;
    this.bast.order.updateProductQuantity(this.product.id, evt, false)
      .subscribe(
        () => {
          this.locked = false;
          this.product.quantity = evt;
          this.quantityChanged.emit();
        },
        err => {
          this.flagService.publishErrorFlag(
            'Błąd',
            `Nie udało się zmienić ilości produktu w zamówieniu <br/> ${ErrorStringifier.errorToString(err)}`
          );
          this.locked = false;
        }
      );
  }

  constructor(
    protected flagService: FlagService,
    protected bast: Bast,
  ) {}
}
