import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ie-spinner',
  templateUrl: './ie-spinner.component.html',
  styleUrls: ['./ie-spinner.component.scss']
})
export class IESpinnerComponent implements OnInit {

  @Input() size = 1;

  constructor() { }

  ngOnInit() {
  }

}
