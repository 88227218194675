import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'ie-table-sort-button',
  templateUrl: './table-sort-button.component.html',
  styleUrls: ['./table-sort-button.component.scss']
})
export class TableSortButtonComponent implements OnInit {

  @Input() order: number;

  constructor() { }

  ngOnInit() {
  }

}
