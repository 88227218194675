<div class='ie-breadcrumb'>
  <ng-container *ngIf="!disabled; else disabledBreadcrumb">
    <a 
      *ngFor="let link of breadcrumb; let i=index;"
      [routerLink]="[absolute ? (link.href) : ('/app' + link.href)]"
      [queryParams]="link.queryParams"
      [attr.disabled]="disabled"
      (click)="changeRepositoryDirectory.emit(0)"
    >
      {{link.label}}
      <span *ngIf="i<breadcrumb.length - 1 || (i<breadcrumb.length && directory)">></span>
    </a>
  </ng-container>
  <ng-template #disabledBreadcrumb>
    <a 
      *ngFor="let link of breadcrumb; let i=index;"
      [attr.disabled]="disabled"
    >
      {{link.label}}
      <span *ngIf="i<breadcrumb.length - 1 || (i<breadcrumb.length && directory)">></span>
    </a>
  </ng-template>

  <ng-container *ngIf="directory">
    <a
      (click)="changeRepositoryDirectory.emit(directory.parent_id?directory.parent_id:directory.id)"
    >
      {{directory.parent_name?directory.parent_name:directory.name}}
      <span *ngIf="directory.parent_id">></span>
    </a>
    <a
      *ngIf="directory.parent_id"
      (click)="changeRepositoryDirectory.emit(directory.id)"
    > 
      {{directory.name}}
    </a>
  </ng-container>
</div>

<!-- [routerLink]="[absolute ? (link.href) : ('/app' + link.href)]" -->