<ie-breadcrumb [breadcrumb]="breadcrumb"></ie-breadcrumb>
<div class='content-container'>

  <ng-container *loaderTemplate="pending">
    <ie-cdk-table
      *ngIf="orders$.value&&orders$.value.length;else noOrders"
      [searchable]="true"
      [interface]="'orders-list'"
      [manageableColumns]="manageableColumns"
      [searchableFields]="['code']"
    >
      <table cdk-table [dataSource]="orders$">
        <ng-container cdkColumnDef="id">
          <th cdk-header-cell *cdkHeaderCellDef>#</th>
          <td cdk-cell *cdkCellDef="let row" style="width: 50px;min-width: 50px;">{{row.id}}</td>
        </ng-container>
        <ng-container cdkColumnDef="active">
          <th cdk-header-cell *cdkHeaderCellDef>aktywne zam.</th>
          <td cdk-cell *cdkCellDef="let row" style="width:50px;min-width: 50px;">
            <ie-radiobutton
              [disabled]="row.status!=='open' || disableRadioButtons"
              (onchange)="activateOrder(row)"
              [value]="true"
              [(ngModel)]="row.active"
            ></ie-radiobutton>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="status">
          <th cdk-header-cell cdk-sortable-column="status" *cdkHeaderCellDef>status</th>
          <td cdk-cell *cdkCellDef="let row" style="width: 100px;min-width: 100px;">
            <div *ngIf="row.active" class="active">
              <div class="dot"></div>
              aktywne
            </div>
            <div *ngIf="!row.active">
              {{row.status | orderContext}}
            </div>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="code">
          <th cdk-header-cell cdk-sortable-column="code" *cdkHeaderCellDef>nazwa</th>
          <td cdk-cell *cdkCellDef="let row" style="width: 180px;min-width: 180px;">
            <a
              class='--link'
              [ngStyle]="{'font-weight' : row.active ? '600' : '400'}"
              routerLink="/app/orders/preview/{{row.id}}"
            >{{row.code}}</a>
          </td>
        </ng-container>
        <ng-container cdkColumnDef="user">
          <th cdk-header-cell cdk-sortable-column="user_first_name" *cdkHeaderCellDef>opiekun</th>
          <td cdk-cell *cdkCellDef="let row" style="min-width: 250px; white-space: nowrap;">{{row.user_first_name}} {{row.user_last_name}}</td>
        </ng-container>
        <ng-container cdkColumnDef="price">
          <th cdk-header-cell cdk-sortable-column="price" *cdkHeaderCellDef>netto</th>
          <td cdk-cell *cdkCellDef="let row">{{row.price|price}}</td>
        </ng-container>
        <ng-container cdkColumnDef="manage">
          <th cdk-header-cell *cdkHeaderCellDef></th>
          <td cdk-cell align="right" *cdkCellDef="let row">
            <div class="icons-row">
              <ng-container *vaultlocal="'DELETE_ORDER'|vaultprivilege">
                <ie-icon
                  *ngIf="row.status==='open'&&row.is_manageable"
                  style="margin-right: 10px;"
                  matTooltip="Usuń zamówienie"
                  [matTooltipShowDelay]="500"
                  [icon]="'delete'"
                  [hover]="true"
                  (click)="deleteOrder(row)"
                ></ie-icon>
              </ng-container>
              <ie-icon
                style="margin-right: 10px;"
                matTooltip="Podgląd zamówienia"
                [matTooltipShowDelay]="500"
                [icon]="'preview'"
                [hover]="true"
                (click)="previewOrder(row)"
              ></ie-icon>
            </div>
          </td>
        </ng-container>

        <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
        <tr cdk-row *cdkRowDef="let row;columns:displayedColumns"></tr>
      </table>
    </ie-cdk-table>

    <ng-template #noOrders>
      <ie-empty-placeholder
        [header]="'Lista zamówień jest pusta'"
        [placeholder]="'Na tej liście pojawią się wszystkie zamówienia Twojego sprzedawcy'"
        [image]="NO_ORDERS"
        [height]="'calc(100vh - 8rem)'"
      ></ie-empty-placeholder>
    </ng-template>
  </ng-container>

</div>
<ie-warn-dialog></ie-warn-dialog>
