<div class='ie-icon-button-container' [attr.disabled]="disabled ? true : null" [attr.rotated]="rotated ? true : null">
  <ng-content></ng-content>
  <ie-icon
    [icon]="icon"
    [size]="size"
    [disabled]="disabled"
  >
  </ie-icon>
  <label>{{label}}</label>
</div>
