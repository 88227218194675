export class ProducerArticleAttachment {

  public id: number;
  public auxId: number;
  public file: File;
  public name: string;
  public type: string;
  public temporary: boolean;
  public selected: boolean;
  public url: string;
  public custom_fields: {
    valid_from?: number | string;
    valid_to?: number | string;
    valid?: number[];
    is_valid?: boolean;
    date_of_signature?: [];
    order?: number;
  } = { valid: [] };

  public static toDto(obj): ProducerArticleAttachment {
    const output = {...obj};
    try {
      const [from, to] = obj.custom_fields.valid || [null, null];
      const [signature] = obj.custom_fields.date_of_signature || [null];
      output.custom_fields = {
        ...obj.custom_fields,
        valid_from: from,
        valid_to: to,
        ...(signature) && { date_of_signature: signature },
      };

      delete output.custom_fields['valid'];
    } catch (e) {}

    return output;
  }

  public static getTemporaryAttachments(obj: ProducerArticleAttachment[]): ProducerArticleAttachment[] {
    if (!obj || !obj.length) { return []; }
    return obj.filter(o => o.temporary);
  }

  public hasOrder(): boolean {
    return typeof this.custom_fields.order !== 'undefined';
  }

  public hasValidDate(): boolean {
    return this.custom_fields.hasOwnProperty('valid_from') && this.custom_fields.valid_from !== 'null';
  }

  constructor(props?: any) {
    Object.assign(this, props);
    try {
      const isNull = (val) => !val || (typeof val === 'string' && val === 'null');
      if (props.custom_fields) {
        this.custom_fields.valid = !isNull(props.custom_fields.valid_from) && !isNull(props.custom_fields.valid_to) ?
          [props.custom_fields.valid_from, props.custom_fields.valid_to] :
          null;
        this.custom_fields.order = props.custom_fields.hasOwnProperty('order') ? parseInt(this.custom_fields.order as any, 10) : undefined;
        this.custom_fields.is_valid = props.custom_fields.hasOwnProperty('is_valid') && props.custom_fields.is_valid === 'true' ? true : false;
      }
    } catch (e) {}
  }
}
