import {Component, Input, OnInit} from '@angular/core';

export enum IE_EMPTY_PLACEHOLDER {
  NO_ORDERS = './assets/illustrations/stats.svg',
  NO_PRODUCTS = './assets/illustrations/stats.svg',
  NO_SEARCH_RESULTS = './assets/illustrations/no_search_results.svg',
  NO_STATS = './assets/illustrations/stats.svg',
  NO_ARTICLES = './assets/illustrations/stats.svg',
  NO_DATA = './assets/illustrations/stats.svg',
  NO_INTEGRATION = './assets/illustrations/stats.svg',
  NO_BANNERS = './assets/illustrations/stats.svg',
}

@Component({
  selector: 'ie-empty-placeholder',
  templateUrl: './ie-empty-placeholder.component.html',
  styleUrls: ['./ie-empty-placeholder.component.scss']
})
export class IEEmptyPlaceholderComponent implements OnInit {

  @Input() image: IE_EMPTY_PLACEHOLDER;
  @Input() header: string;
  @Input() placeholder: string;
  @Input() height: number;
  @Input() action: { label: string, handler: () => {}, privilege?: string | string[], context: object };

  public evaluate(): void {
    this.action.handler();
  }

  constructor() { }

  ngOnInit() {}

}
