import {Observable} from 'rxjs';
import {STORAGE_KEYS, StorageService} from '../../../app/shared/storage-service/storage.service';
import {toArray} from 'rxjs/operators';
import {Injector} from '@angular/core';

export class Country {
  id: number;
  name: string;
  code: string;
}

export class CountryDomain {

  public get(): Observable<Country[]> {
    return (window['angularInjector'] as Injector).get(StorageService).getCollection(STORAGE_KEYS.COUNTRIES).pipe(toArray());
  }

  constructor() {}

}
