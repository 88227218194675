import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'ie-extension-panel',
  templateUrl: './ieextensionpanel.component.html',
  styleUrls: ['./ieextensionpanel.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class IEExtensionPanelComponent implements OnInit, OnChanges {

  @Input() opened: boolean;
  @Input() disabled: boolean;
  @Input() locked: boolean;
  @Input() ignoredNodes: Array<HTMLElement>;
  @Input() icon: string = 'dropdown_big';
  @Output() onopen: EventEmitter<void> = new EventEmitter();

  toggleExtensionPanel(e): void {
    if (this.ignoredNodes && this.contains(e.target) ) { return; }
    if (!this.disabled) {
      this.opened = !this.opened;
      if (this.opened) { this.onopen.emit(); }
    }
  }

  contains(target) {
    for (let i = 0; i < this.ignoredNodes.length; i++) {
      if (this.ignoredNodes[i].contains(target)) return true;
    }
    return false;
  }

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.disabled &&
      changes.disabled.previousValue === null &&
      changes.disabled.currentValue === true
    ) {
      this.opened = false;
    }
  }

}
