import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IEReportBugComponent } from "./ie-report-bug.component";
import { IEIconModule } from '@material/ie-icon/ie-icon.module';
import { ContactModule } from 'src/app/core/contact/module/contact.module';
import { VaultSharedModule } from 'src/app/authorization/module/vault.module';
import { IETooltipModule } from '@material/ie-tooltip/ie-tooltip.module';

@NgModule({
    imports: [
        CommonModule,
        IETooltipModule,
        VaultSharedModule,
        IEIconModule,
        ContactModule,
    ],
    declarations: [
        IEReportBugComponent
    ],
    exports: [
        IEReportBugComponent
    ]
})
export class IEReportBugModule {}
