import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {Injector} from '@angular/core';
import {FlagService} from '@material/ie-flag-notification/service/flag-service';

export enum HttpErrorStatus {
  OK = 200,
  CREATED,
  ACCEPTED,
  NO_CONTENT = 204,
  MOVED_PERMANENTLY = 301,
  NOT_MODIFIED = 304,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  SERVER_ERROR = 500,
  BAD_GATEWAY = 502,
  GATEWAY_TIMEOUT = 504,
}

export class HttpErrorHandler {

  protected router: Router;
  protected flag: FlagService;

  public handleHttpError(error: HttpErrorResponse, redirectGetaway?: string): void {
    if (error.status === HttpErrorStatus.FORBIDDEN) {
      this.flag.publishWarningFlag('Błąd', 'Nie posiadasz wystarczających uprawnień do podglądu tej sekcji. Skontaktuj się z administratorem');
      this.router.navigateByUrl(redirectGetaway||'/app');
    }

    if (error.status === HttpErrorStatus.NOT_FOUND) {
      this.flag.publishWarningFlag('BAST', 'Nie znaleziono zasobu');
      this.router.navigateByUrl(redirectGetaway||'/app');
    }
  }

  constructor() {
    this.router = (window['angularInjector'] as Injector).get(Router);
    this.flag = (window['angularInjector'] as Injector).get(FlagService);
  }
}
