type QueueCallback<T> = (el: T, resolve?: (arg?) => void, reject?: (arg?) => void, index?: number) => any;

export class NonBlockingQueue<T> {

    public busy: boolean;
    private queuedElements = [];
    private retryCount: number;
    private currentIndex: number;
    private currentRetryIndex: number;
    private resolvedQueue: Array<T> = [];

    constructor(elements?: Array<T> | FileList, retryCount?: number) {
        try {
          if (elements instanceof Array) { this.queuedElements = elements; } else { this.queuedElements = Array.from(elements); }
        } catch (e) {}
        this.busy = false;
        this.currentIndex = 0;
        this.currentRetryIndex = 0;
        this.retryCount = retryCount || 0;
        this.processQueueElement = this.processQueueElement.bind(this);
        this.incrementIndex = this.incrementIndex.bind(this);
        this.onFinish = this.onFinish.bind(this);
    }

    public pop(callback: QueueCallback<T>, finished?: (args?) => void): void {
        this.queuedElements.forEach(async (file, index) => {
            this.busy = true;
            try {
                await this.processQueueElement(callback, index, finished);
            } catch (e) {
                if (this.currentRetryIndex < this.retryCount) {
                    this.queuedElements.push(file);
                    this.processQueueElement(callback, index, finished);
                }
            }
        });
    }

    private onFinish(callback) {
        if (this.currentIndex === this.queuedElements.length && callback) { callback(this.resolvedQueue); }
    }

    private incrementIndex(): void {
        this.currentIndex = this.currentIndex + 1;
    }

    private processQueueElement(callback: QueueCallback<T>, index: number, finished?: (args?) => void): Promise<void> {
        return new Promise((resolve, reject) => {
            let resolved = (data) => {
                this.busy = false;
                this.currentRetryIndex = 0;
                this.resolvedQueue.push(data);
                this.currentIndex = this.currentIndex + 1;
                this.onFinish(finished);
                resolve(data);
            };
            let rejected = () => {
                this.busy = false;
                this.currentRetryIndex = this.currentRetryIndex + 1;
                this.onFinish(finished);
                reject();
            };
            resolved = resolved.bind(this);
            rejected = rejected.bind(this);
            callback = callback.bind(this);

            const processResult = callback(
                this.queuedElements[index],
                resolved,
                rejected,
                this.currentIndex
            );

            if (processResult && typeof processResult === 'function') {
                return processResult();
            }

            if (processResult && Object.prototype.toString.call(processResult) === '[object Promise]') {
            }
        });
    }
}
