import {Component, OnDestroy} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {ENDPOINTS} from '../../configuration/ENDPOINTS';
import {ActivatedRoute, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {IE_EMPTY_PLACEHOLDER} from '@material/ie-empty-placeholder/ie-empty-placeholder.component';
import {environment} from '../../../environments/environment';
import {TemplateUtils} from '@shared/utils/template-utils';
import {EmailMessage} from '@bast/domain/email';
import { APIService } from 'src/app/api/apiservice.service';
import { Bast } from '@bast';

@Component({
  template: `
      <header class='public-header'>
          <a routerLink="/" class='logo'>
              <ie-icon
                      [icon]="'logo_bast'"
                      [size]="60"
              ></ie-icon>
          </a>
      </header>
      <div class="newsletter-content-body">
          <ng-container *loaderTemplate="pending">
              <div
                      *ngIf="!error;else noNewsletter"
                      class="newsletter-content"
                      [innerHTML]="message?.content|safecontent"
              ></div>
              <ng-template #noNewsletter>
                  <ie-empty-placeholder
                          [image]="NO_NEWSLETTER"
                          [header]="'Nie znaleziono'"
                          [placeholder]="'Ten newsletter nie jest już dostępny'"
                          [action]="action"
                  ></ie-empty-placeholder>
              </ng-template>
          </ng-container>
      </div>
  `,
  styles: [`
      .public-header {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 1000;
          height: 4rem;
          background: #FFF;
          box-shadow: 0 0 10px rgba(0,0,0,0.1);
          display: flex;
          align-items: center;
      }
      .logo {
          cursor: pointer;
          margin-left: 1rem;
      }
      .newsletter-content-body {
          margin: 6rem auto;
          max-width: 800px;
          background-color: #FFF;
      }
      .newsletter-content {
          width: 600px;
          background-color: #FFF;
          margin: 0 auto;
      }
      @media screen and (max-width: 768px) {
          .newsletter-content { width: 100%;}
      }
  `],
})
export class PublicNewsletterComponent implements OnDestroy {

  public message: EmailMessage;
  public error: boolean;
  public pending: boolean;
  public NO_NEWSLETTER = IE_EMPTY_PLACEHOLDER.NO_DATA;
  public action = {
    label: 'Przejdź do strony głównej',
    handler: this.gotoHomepage.bind(this),
  }
  private _routerSubscription: Subscription;

  private gotoHomepage(): void {
    this.router.navigateByUrl('/app');
  }

  private fetchPublicMessage(): void {
    this.pending = true;
    this._routerSubscription = this.activeRouter.params
      .pipe(switchMap(params => this.httpClient.get(this.api.API_URL + ENDPOINTS.getEmailMessageByHash(params.hash) )))
      .subscribe((message: EmailMessage) => {
          this.message = message;
          this.message.content = TemplateUtils.replaceNewsletterShortcode(message.content, false);
          this.pending = false;
        },
        (error: HttpErrorResponse) => {
          this.error = true;
          this.pending = false;
        }
      );
  }

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private api: APIService,
    private bast: Bast
  ) {
    this.bast.getConfig().subscribe(() => this.fetchPublicMessage() );
  }

  ngOnDestroy(): void {
    if (this._routerSubscription) { this._routerSubscription.unsubscribe(); }
  }
}
