import {DomainComparator} from '../../../app/shared/utils/domain-comparator';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {TemplateUtils} from '../../../app/shared/utils/template-utils';
import {NonBlockingQueue} from '../../../app/shared/utils/non-blocking-queue';
import {APIService} from '../../../app/api/apiservice.service';
import {Material} from '@bast/domain/material';

export class EmailMessage {
  id?: number;
  to?: string[];
  reply_to?: string;
  subject?: string;
  content?: string;
  public?: boolean;
  created_at?: number;
  updated_at?: number;
  sent_at?: number;
  hash?: string;
  attachments?: Material[];
  variables?: any[];

  public static toDto(obj: EmailMessage, attachments?: Material[]) {
    const attachmentsIds = (attachments||obj.attachments||[]).map(({id}) => id)
    const to = (obj.to instanceof Array ? obj.to : [obj.to]).filter(r => r);
    return {
      email_message: {
        ...obj,
        to,
        attachments: attachmentsIds
      }
    };
  }

  constructor(props?) {
    if (props) { Object.assign(this, props); }
  }

}

export interface EmailTemplate {
  id?: number;
  name?: string,
  subject?: string,
  content?: string,
  variables?: string[],
  files?: any[];
  to?: string;
  reply_to?: string;
}


export class EmailDomain {

  public send(comparator: DomainComparator<EmailMessage>): Promise<any> {
    if (comparator.getCurrent().id) { return this.api.request(ENDPOINTS.sendEmailMessage(comparator.getCurrent().id), 'POST'); }

    return this.api.request(ENDPOINTS.createEmailMessage, 'POST', EmailMessage.toDto(comparator.getCurrent()))
      .then((message: EmailMessage) => {
        const content = TemplateUtils.replaceNewsletterShortcode(comparator.getCurrent().content, comparator.getCurrent().public, message.hash);
        return { ...message, content };
      })
      .then((message: EmailMessage) => this.api.request(ENDPOINTS.updateEmailMessage(message.id), 'POST', EmailMessage.toDto({...message, attachments: comparator.getCurrent().attachments })) )
      .then((message: EmailMessage) => this.api.request(ENDPOINTS.sendEmailMessage(message.id), 'POST'));
  }

  public drafts(): Promise<EmailMessage[]> {
    return this.api.request(ENDPOINTS.getEmailMessages(0), 'GET')
      .then(({ data }) => data);
  }

  public getById(messageId: number): Promise<EmailMessage> {
    return this.api.request(ENDPOINTS.getEmailMessage(messageId), 'GET');
  }

  public getSent(): Promise<EmailMessage[]> {
    return this.api.request(ENDPOINTS.getEmailMessages(1), 'GET')
      .then(({ data }) => data);
  }

  public templates(): Promise<EmailTemplate[]> {
    return this.api.request(ENDPOINTS.getEmails, 'GET').then(({ data }) => data);
  }

  public templateById(templateId: number): Promise<EmailTemplate> {
    return this.api.request(ENDPOINTS.getEmail(templateId), 'GET');
  }

  public saveOrUpdate(message: EmailMessage, attachments?): Promise<EmailMessage> {
    return message.id? this.update(message, attachments) : this.create(message, attachments);
  }

  public delete(messageId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteEmailMessage(messageId), 'DELETE');
  }

  public deleteBulk(emailMessages?): Promise<number[]> {
    return new Promise((resolve) => {
      new NonBlockingQueue(emailMessages)
        .pop((message: EmailMessage, queueResolve, queueReject) => {
            const {id} = message;
            this.delete(id).then(() => queueResolve(id)).catch(() => queueReject());
          },
          (deleted: number[]) => {
            resolve(deleted);
          });
    });
  }

  public updateTemplate(template: EmailTemplate): Promise<any> {
    return this.api.request(ENDPOINTS.updateEmail(template.id), 'POST', { email: template });
  }

  private create(message?: EmailMessage, attachments?): Promise<EmailMessage> {
    return this.api.request(ENDPOINTS.createEmailMessage, 'POST', EmailMessage.toDto(message, attachments));
  }

  private update(message?: EmailMessage, attachments?): Promise<EmailMessage> {
    return this.api.request(ENDPOINTS.updateEmailMessage(message.id), 'POST', EmailMessage.toDto(message, attachments));
  }

  constructor(private api: APIService) {}
}
