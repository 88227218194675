<ie-breadcrumb [breadcrumb]="breadcrumb"></ie-breadcrumb>

<ng-container *loaderTemplate="pending">
    <div class='content-container fly-in' *ngIf="order">

        <div class='received-order-header'>
            <ie-icon
              routerLink="/app/orders/received"
              style="margin-right: 15px"
              [icon]="'input_arrow'" style="transform: rotate(180deg)"
              [hover]="true"
              [title]="'Wróć'"
            ></ie-icon>
            <h1>Zamówienie: {{order.code}}</h1>
            <ie-flag-selector
                [(ngModel)]="order.custom_flag"
                (onchange)="changeOrderCustomFlag($event)"
            ></ie-flag-selector>
            <div class="order-created-at">Data utworzenia: {{order.created_at|date}}</div>
        </div>

        <ng-container>
          <div class="order-received-wrapper">

            <!-- Products list -->
            <div style="flex-grow: 2">
              <div class='supplier-order-container'>
                  <div class="supplier-order-name">
                      Zamówienie od kupującego: {{order.delivery_supplier_alias || order.delivery_supplier_name}}
                  </div>
                  <ul>
                      <li *ngFor="let product of order.products;let i=index">
                        <ie-order-received-product-tile
                          [index]="i"
                          [product]="product"
                        ></ie-order-received-product-tile>
                        <div class="supplier-order-summary">
                          <span>Razem (brutto): {{product.gross_price_total|price}} zł</span>
                          <span>Razem (netto): {{product.price_total|price}} zł</span>
                        </div>
                      </li>
                  </ul>
              </div>

              <div
                class="comments-section"
                [attr.active]="order.isActive()"
              >
                <div *ngIf="order.comments.length > 0" class="comments-list">
                  <label>Komentarze ({{order.comments.length}})</label>
                  <div *ngFor="let comment of order.comments" class="comment-entry">
                    <div class="comment-entry-header"><b>{{comment.user | user | async}}</b><span>{{comment.date|date}}</span></div>
                    <p>{{comment.content}}</p>
                  </div>
                </div>

                <div class='add-new-comment-container' *ngIf="order.isActive()">
                  <label>Komentarz (opcjonalnie)</label>
                  <div
                    class="comment-editable-wrapper"
                    [attr.comment-input-collapsed]="!commentInputFocused?true:null"
                    (overlayFocusTrap)="commentInputFocused=false"
                  >
                    <div
                      #commentInput
                      contentEditable
                      class="order-editable"
                      (click)="focusCommentArea(commentInput)"
                      (blur)="appendOrderComment(commentInput)"
                    ></div>
                  </div>
                </div>
              </div>

              <!-- Events -->
              <div class="events-section">
                <div *ngIf="order.events && order.events.length > 0" class="events-list">
                  <label>Szczegóły zdarzenia ({{order.events.length}})</label>
                  <table *ngFor="let event of order.events" class="event">
                      <tbody>
                        <tr>
                          <td>data zdarzenia</td>
                          <td><b>{{event.date|date}}</b></td>
                        </tr>
                        <tr>
                          <td>godzina zdarzenia</td>
                          <td><b>{{event.date|hour}}</b></td>
                        </tr>
                        <tr>
                          <td>typ zdarzenia</td>
                          <td><b>{{event.type}}</b></td>
                        </tr>
                        <tr>
                          <td>wiadomość</td>
                          <td>{{event.message}}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>

            </div>

            <!-- Summary wrapper -->
            <div class="summary-wrapper">

              <!-- Price -->
              <div class="summary-value-wrapper">
                <h2>Podsumowanie</h2>
                <div class="order-price-gross">
                  <label>Razem (brutto) </label>
                  <label>{{order.gross_price|price}} zł</label>
                </div>

                <div class="order-price-netto">
                  <label>Razem (netto) </label>
                  <label>{{order.price|price}} zł</label>
                </div>

                <div class="order-delivery">
                  <label>Adres do dostawy</label>
                  <label>Lokalizacja {{order.delivery_location_name}}</label>
                  <label>{{order.delivery_location_street}} {{order.delivery_location_house_number||''}}{{order.delivery_location_flat_number||''}}, {{order.delivery_location_post_code}} {{order.delivery_location_city}}</label>
                </div>
              </div>

              <!-- Order status -->
              <div class="summary-status-wrapper">
                <div class='manage-option'>
                  <label class='option-label'>Status zamówienia</label>
                  <ie-multiselect
                    style="margin: 5px 0; display: block"
                    [placeholder]="'Wybierz status'"
                    [hidePlaceholder]="true"
                    [(ngModel)]="comparator.getCurrent().status"
                  >
                    <ie-multiselect-option
                      *ngFor="let status of availableOrderStatus"
                      [option]="{ key: status.status, value: status.label }"
                    >{{status.label}}</ie-multiselect-option>
                  </ie-multiselect>
                </div>

                <div class="manage-option" style="margin-top: 10px;">
                  <label class='option-label'>Opiekun</label>
                  <label class='option-label'>Akualny opiekun: <span style="font-weight: bold">{{order.user | user | async}}</span></label>
                  <button
                    *ngIf="order.user !== myself.id"
                    class="bar-outline-button"
                    style="width: fit-content; margin: 10px auto auto; padding: 0.8rem 1.8rem;"
                    (click)="takeOrder()"
                  >przejmij</button>
                </div>

              </div>

              <div
                *ngIf="comparator.hasChanged()"
                class="summary-wrapper-button"
              >
                <ie-button
                  [text]="'Aktualizuj informacje'"
                  (click)="updateOrder()"
                ></ie-button>
              </div>

            </div>

          </div>
        </ng-container>

    </div>
</ng-container>
