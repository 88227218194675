import { Pipe, PipeTransform } from '@angular/core';
import {DatepickerUtils} from '@material/ie-datepicker/utils/DatepickerUtils';

@Pipe({
  name: 'hour',
  pure: true
})
export class HourPipe implements PipeTransform {

  transform(value: number, args?: any): any {
    return value ? DatepickerUtils.toBastHour(value, args) : '-';
  }

}
