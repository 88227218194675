import {
  ChangeDetectorRef,
  Component, ComponentFactoryResolver, ComponentRef, ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output, QueryList,
  Renderer2,
  ViewChild, ViewContainerRef, AfterViewInit
} from '@angular/core';
import {IEInputOutputAccessor} from '@shared/input-output-accessor/ie-input-output-accessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ErrorResponse} from '@shared/input-output-accessor/Validator';
import {HttpErrorResponse} from '@angular/common/http';
import {MultiselectContentComponent} from './multiselect-content/multiselect-content.component';
import {IeMultiselectOptionComponent} from './ie-multiselect-option.component';
import {MultiselectBindService} from './multiselect-bind.service';

@Component({
  selector: 'ie-multiselect',
  templateUrl: './ie-multiselect.component.html',
  styleUrls: ['./ie-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IEMultiselectComponent),
      multi: true
    }
  ],
})
export class IEMultiselectComponent extends IEInputOutputAccessor implements OnInit, AfterViewInit {

  @Input() placeholder = 'wybierz';
  @Input() label = '';
  @Input() hidePlaceholder: boolean;
  @Input() multiple: boolean;
  @Input() showSelectAll: boolean = true;
  @Input() position = 'left';
  @Input() disabled: boolean;
  @Input() limitWidth = false;
  @Input() name: string;
  @Input() error: string;
  @Input() height: number;
  @Input() float: boolean;
  @Output() changed: EventEmitter<any> = new EventEmitter();
  @Output() reset: EventEmitter<void> = new EventEmitter();
  @ViewChild('ieselectlabel', { static: true }) ieselectlabel: ElementRef;
  @ViewChild('ieselect', { static: true }) ieselect: ElementRef;
  @ViewChild('multiselectContainer', { static: true }) container: ElementRef;
  @ViewChild('multiselectAnchor', { static: true }) anchor: ElementRef;
  @ViewChild('placeholderEl', { static: true }) placeholderEl: ElementRef;
  @ViewChild('placeholderEl') set content(placeholderEl: ElementRef) {
    if(placeholderEl) {
        this._placeholderEl = placeholderEl;
    }
 }
  @ContentChildren(IeMultiselectOptionComponent) options: QueryList<IeMultiselectOptionComponent>;

  public filter = '';
  public _onclick: EventEmitter<any> = new EventEmitter();
  public _focused: boolean;
  public _placeholder: string;
  private _ref: ComponentRef<MultiselectContentComponent>;
  private _placeholderEl: ElementRef;

  public show(): void {
    if (this._ref) { return; }
    if (this.disabled) { return; }
    const factory = this.resolver.resolveComponentFactory(MultiselectContentComponent);
    const viewContainerRef = this.injector.get(ViewContainerRef);
    const ngContent = this.resolveNgContent();
    this._ref = viewContainerRef.createComponent(factory, 0, this.injector, ngContent);
    this._ref.hostView.detectChanges();
    this._ref.instance.height = this.height;
    this._ref.instance.host = this.container.nativeElement;
    this._ref.instance.onclose.subscribe(() => this.hide());
    this.error = null;

    const node = window.innerWidth < 768 ? document.body : this.anchor.nativeElement;
    node.appendChild(this._ref.location.nativeElement);
    this.toggleBodyScroll();
    this.injector.get(MultiselectBindService).setMultiselect(this);
  }

  public resolveNgContent() {
    const viewRefs = Array.from(this.options).map(o => o.viewRef.element.nativeElement);
    return [viewRefs];
  }

  public hide(): void {
    if (this._ref) {
      this._ref.destroy();
      this._ref = null;
      this.toggleBodyScroll();
    }
  }

  public clear(): void {
    this.writeValue(null);
    this._placeholder = null;
    this.changed.emit(null);
    this.filter = '';
    if (this.multiple) { this.options.forEach( v => v.option.selected = false ); }
    this.hide();
  }

  public setFocus(): void {
    this.ieselectlabel.nativeElement.focus();
  }

  public isResetable(): boolean {
    if (this.disabled) { return false; }
    if (!this.multiple && this._fieldValue) { return true; }
    return this._fieldValue && this._fieldValue.length > 0;
  }

  public getPlaceholder(): string {
    // if (!this._fieldValue) { return this.placeholder; }
    // if (this._fieldValue.length === 0) { return this.placeholder; }
    if (!this._fieldValue) { return ''; }
    if (this._fieldValue.length === 0) { return ''; }
    if (this.multiple) { return `Wybrane (${this._fieldValue.length})`; }
    const selectedValue = Array.from(this.options).find(({option}) => option.key === this._fieldValue);

    // return selectedValue ? selectedValue.option.value : this.placeholder;
    return selectedValue ? selectedValue.option.value : '';
  }

  public validate(res: ErrorResponse | HttpErrorResponse) {
    if (res instanceof HttpErrorResponse) { res = res.error; }
    if ((res as any).errors.hasOwnProperty(this.name)) {
      this.error = (res as any).errors[this.name];
      this.changeDet.markForCheck();
    }
  }

  private toggleBodyScroll(): void {
    this._ref ?
      document.body.classList.add('--prevent-scroll') :
      document.body.classList.remove('--prevent-scroll');
  }

  constructor(
    public changeDet: ChangeDetectorRef,
    private renderer: Renderer2,
    private resolver: ComponentFactoryResolver,
    private injector: Injector
  ) { super(); }

  ngOnInit() {this.injector.get(MultiselectBindService).setMultiselect(this);}

  ngAfterViewInit() {
    if (this.float) {
      this.renderer.addClass(this._placeholderEl.nativeElement, 'no-wrap');
      const width = this._placeholderEl.nativeElement.offsetWidth + 50 + 'px';
      this.renderer.setStyle(this.container.nativeElement, 'width', window.innerWidth > 1024 ? width : '100%')
      this.renderer.setStyle(this.container.nativeElement, 'min-width', '170px')
    }
  }
}

