import {from, Observable} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {map, switchMap} from 'rxjs/operators';
import {APIService} from '../../../app/api/apiservice.service';
import {PRICE_LIST_TYPE} from '../price-list';
import {STOCK_LIST_TYPE} from '../stock-list';

export interface IIntegration {
  id?: number;
  producer_id?: number;
  brand_id?: number;
  brand?: number;
  price_list_id?: number;
  type?: string;
  host?: string;
  port?: number;
  user?: string;
  password?: string;
  headers?: string|string[];
  column_mapping?: Array<any>;
  custom_fields?: any;
  excluded_columns?: string[]|string;
  filename?: string;
  interval?: number;
  active?: boolean;
  status?: string;
  task_id?: number;
  execute_at?: number;
}

export class Integration {
  id?: number;
  producer_id?: number;
  brand_id?: number;
  brand?: number;
  price_list_id?: number;
  type?: string;
  host?: string;
  port?: number;
  user?: string;
  password?: string;
  headers?: string;
  column_mapping?: Array<any>;
  filename?: string;
  interval?: number;
  active?: boolean;
  status?: string;
  execute_at?: number;
  task_id?: number;
  custom_fields?: any;
  excluded_columns?: string|string[];

  public static toDto(integration: IIntegration): IIntegration {
    const output = Object.assign({}, integration);

    try {
      output.custom_fields = JSON.parse(integration.custom_fields);
    } catch (e) {
      output.custom_fields = [];
    }

    if (integration.excluded_columns && typeof integration.excluded_columns === 'string') {
      output.excluded_columns = integration.excluded_columns.replace(/\s+/, '').split(',');
    } else {
      output.excluded_columns = [];
    }

    output.brand = output.brand || output.brand_id;
    delete output['_custom_fields'];
    delete output['_excluded_columns'];

    return output;
  }

  constructor(props) {
    Object.assign(this, props);
    this.brand = this.brand_id;

    if (props.custom_fields) {
      if (props.custom_fields instanceof Array && props.custom_fields.length === 0) { this.custom_fields = null; }
      else { this.custom_fields = JSON.stringify(props.custom_fields, null, 2); }
    }
    if (props.excluded_columns) {
      if (props.excluded_columns instanceof Array) { this.excluded_columns = props.excluded_columns.join(','); }
      else { this.excluded_columns = null; }
    }
  }
}


export class IntegrationDomain {

  public get(producerId: number): Observable<any> {
    return from(this.api.request(
      ENDPOINTS.getIntegrations(producerId),
      'GET'
    )).pipe(map(({ data }) => data));
  }

  public getSupplierIntegration(supplierId: number): Observable<any> {
    return from(this.api.request(
      ENDPOINTS.getSupplierIntegrations(supplierId),
      'GET'
    )).pipe(map(({ data }) => data));
  }

  public getById(integrationId: number): Observable<any> {
    return from(this.api.request(ENDPOINTS.getIntegration(integrationId), 'GET'));
  }

  public getSupplierIntegrationById(integrationId: number): Observable<any> {
    return from(this.api.request(ENDPOINTS.getSupplierIntegration(integrationId), 'GET'));
  }

  public create(producerId: number, brandId: number, integration: IIntegration): Observable<any> {
    return from(this.api.request(
      ENDPOINTS.createIntegration(producerId),
      'POST',
      { producer_integration: { producer: producerId, brand: brandId, integration: Integration.toDto(integration) }}
    ));
  }

  public update(integration: IIntegration): Observable<any> {
    const { id, brand } = integration;
    return from(this.api.request(
      ENDPOINTS.updateIntegration(id),
      'POST',
      { producer_integration: { integration: Integration.toDto(integration), brand }}
    ));
  }

  public createSupplierIntegration(supplierId: number, integration: IIntegration): Observable<any> {
    return from(this.api.request(
      ENDPOINTS.createSupplierIntegration(supplierId),
      'POST',
      { supplier_integration: { supplier: supplierId, integration: Integration.toDto(integration) }}
    ));
  }


  public updateSupplierIntegration(integration: IIntegration): Observable<any> {
    const { id } = integration;
    return from(this.api.request(
      ENDPOINTS.updateSupplierIntegration(id),
      'POST',
      { supplier_integration: { integration: Integration.toDto(integration) } }
    ));
  }

  public delete(integrationId: number): Observable<any> {
    return from(this.api.request(
      ENDPOINTS.deleteIntegration(integrationId),
      'DELETE'
    ));
  }

  public deleteSupplierIntegration(integrationId: number): Observable<any> {
    return from(this.api.request(
      ENDPOINTS.deleteSupplierIntegration(integrationId),
      'DELETE'
    ));
  }

  public run(integration: IIntegration): Observable<any> {
    return from(integration.status === PRICE_LIST_TYPE.WAITING ?
      this.api.request(ENDPOINTS.deleteImportTask(integration.task_id), 'DELETE') :
      Promise.resolve()
    ).pipe(switchMap(() => this.api.request(
      ENDPOINTS.runIntegrationTask(integration.id),
      'POST',
      {
        producer_integration_task: {
          producer_integration: integration.id,
          task: {
            execute_at: Math.floor(Date.now() / 1000),
          }
        }
      }
    )));
  }

  public runSupplierIntegration(integration: IIntegration): Observable<any> {
    return from(integration.status === STOCK_LIST_TYPE.WAITING ?
      this.api.request(ENDPOINTS.deleteSupplierImportTask(integration.task_id), 'DELETE') :
      Promise.resolve()
    ).pipe(switchMap(() => this.api.request(
      ENDPOINTS.runSupplierIntegrationTask(integration.id),
      'POST',
      {
        supplier_integration_task: {
          supplier_integration: integration.id,
          task: {
            execute_at: Math.floor(Date.now() / 1000),
          }
        }
      }
    )));
  }

  constructor(private api: APIService) {}
}
