import {environment} from '../environments/environment';
import {UserDomain} from './domain/user';
import {APIService} from '../app/api/apiservice.service';
import {Injectable, Injector} from '@angular/core';
import {SupplierDomain} from './domain/supplier';
import {ProducerDomain} from './domain/producer';
import {RoleDomain} from './domain/roles';
import {MaterialDomain} from './domain/material';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {IPDomain} from './domain/ip';
import {CountryDomain} from './domain/country';
import {AccessLogDomain} from './domain/access';
import {CalendarDomain} from './domain/calendar';
import {ContactDomain} from './domain/contact';
import {HelpDomain} from './domain/help';
import {BannerDomain} from './domain/banner';
import {PriceListDomain} from './domain/price-list';
import {StockListDomain} from './domain/stock-list';
import {OrderDomain} from './domain/order';
import {WarehouseDomain} from './domain/warehouse';
import {SeriesDomain} from './domain/series';
import {SearchDomain} from './domain/search';
import {IntegrationDomain} from './domain/integration';
import {BrandDomain} from './domain/brand';
import {ProducerLocationDomain} from './domain/producer-location';
import {ProductDomain} from './domain/product';
import {StatisticsDomain} from './domain/reports';
import {SupplierLocationDomain} from './domain/supplier-location';
import {EtimDomain} from './domain/etim';
import {ProducerArticleDomain} from './domain/producer-article';
import {UnitsDomain} from './domain/units';
import {EmailDomain} from './domain/email';
import {AuthorizationDomain} from './domain/authorization';
import {FileRepositoryDomain} from './domain/file-repository';
import {Router} from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Config {
  API_URL?: string;
  VAULT_URL?: string;
}

@Injectable()
export class Bast {

  protected __defaultConfig = {
    icalProxyLink: `${environment.icsCalendarPrivateLink}`,
  };
  protected _bast;

  private readonly _userDomain: UserDomain;
  private readonly _supplierDomain: SupplierDomain;
  private readonly _producerDomain: ProducerDomain;
  private readonly _rolesDomain: RoleDomain;
  private readonly _materialDomain: MaterialDomain;
  private readonly _ipDomain: IPDomain;
  private readonly _countryDomain: CountryDomain;
  private readonly _accessDomain: AccessLogDomain;
  private readonly _calendarDomain: CalendarDomain;
  private readonly _contactDomain: ContactDomain;
  private readonly _helpDomain: HelpDomain;
  private readonly _bannerDomain: BannerDomain;
  private readonly _priceListDomain: PriceListDomain;
  private readonly _stockListDomain: StockListDomain;
  private readonly _orderDomain: OrderDomain;
  private readonly _warehouseDomain: WarehouseDomain;
  private readonly _seriesDomain: SeriesDomain;
  private readonly _searchDomain: SearchDomain;
  private readonly _integrationDomain: IntegrationDomain;
  private readonly _brandDomain: BrandDomain;
  private readonly _producerLocationDomain: ProducerLocationDomain;
  private readonly _productDomain: ProductDomain;
  private readonly _statisticsDomain: StatisticsDomain;
  private readonly _supplierLocationDomain: SupplierLocationDomain;
  private readonly _etimDomain: EtimDomain;
  private readonly _producerArticleDomain: ProducerArticleDomain;
  private readonly _unitsDomain: UnitsDomain;
  private readonly _emailDomain: EmailDomain;
  private readonly _authorizationDomain: AuthorizationDomain;
  private readonly _fileRepositoryDomain: FileRepositoryDomain;
  protected _api: APIService;

  public get user(): UserDomain { return this._userDomain; }
  public get supplier(): SupplierDomain { return this._supplierDomain; }
  public get producer(): ProducerDomain { return this._producerDomain; }
  public get roles(): RoleDomain { return this._rolesDomain; }
  public get material(): MaterialDomain { return this._materialDomain; }
  public get ip(): IPDomain { return this._ipDomain; }
  public get country(): CountryDomain { return this._countryDomain; }
  public get access(): AccessLogDomain { return this._accessDomain; }
  public get calendar(): CalendarDomain { return this._calendarDomain; }
  public get contact(): ContactDomain { return this._contactDomain; }
  public get help(): HelpDomain { return this._helpDomain; }
  public get banner(): BannerDomain { return this._bannerDomain; }
  public get priceList(): PriceListDomain { return this._priceListDomain; }
  public get stockList(): StockListDomain { return this._stockListDomain; }
  public get order(): OrderDomain { return this._orderDomain; }
  public get warehouse(): WarehouseDomain { return this._warehouseDomain; }
  public get series(): SeriesDomain { return this._seriesDomain; }
  public get search(): SearchDomain { return this._searchDomain; }
  public get integration(): IntegrationDomain { return this._integrationDomain; }
  public get brand(): BrandDomain { return this._brandDomain; }
  public get producerLocation(): ProducerLocationDomain { return this._producerLocationDomain; }
  public get product(): ProductDomain { return this._productDomain; }
  public get statistics(): StatisticsDomain { return this._statisticsDomain; }
  public get supplierLocation(): SupplierLocationDomain { return this._supplierLocationDomain; }
  public get etim(): EtimDomain { return this._etimDomain; }
  public get producerArticle(): ProducerArticleDomain { return this._producerArticleDomain; }
  public get unit(): UnitsDomain { return this._unitsDomain; }
  public get email(): EmailDomain { return this._emailDomain; }
  public get authorization(): AuthorizationDomain { return this._authorizationDomain; }
  public get fileRepository(): FileRepositoryDomain { return this._fileRepositoryDomain; }

  public async initialize() {
    try {
      const user = await this.user.myself();
      this._bast = {
        user,
      };
    } catch (e) { console.error(`Error while initializing bast object. Authorization failed`); }
  }

  public getConfig(): Observable<Config> {
    return this.http.get<Config>('/assets/config/config.json');
  }

  protected setGlobalInjector(): void {
    if (!window['angularInjector']) { window['angularInjector'] = this.injector; }
  }


  constructor(private api: APIService, private local: LocalStorageService, private session: SessionStorageService, private router: Router, private injector: Injector, private http: HttpClient) {
    this.setGlobalInjector();
    this._api = api;

    // Domain initialization
    this._userDomain = new UserDomain(this._api);
    this._supplierDomain = new SupplierDomain(this._api);
    this._producerDomain = new ProducerDomain(this._api);
    this._rolesDomain = new RoleDomain(this._api);
    this._materialDomain = new MaterialDomain(this._api, local);
    this._ipDomain = new IPDomain(this._api, this._userDomain);
    this._countryDomain = new CountryDomain();
    this._accessDomain = new AccessLogDomain(this._api);
    this._calendarDomain = new CalendarDomain(this.__defaultConfig);
    this._contactDomain = new ContactDomain(this._api);
    this._helpDomain = new HelpDomain(this._api);
    this._bannerDomain = new BannerDomain(this._api, local);
    this._priceListDomain = new PriceListDomain(this._api);
    this._stockListDomain = new StockListDomain(this._api);
    this._orderDomain = new OrderDomain(this._api, session, this.user);
    this._warehouseDomain = new WarehouseDomain(this._api);
    this._seriesDomain = new SeriesDomain(this._api);
    this._searchDomain = new SearchDomain(this._api);
    this._integrationDomain = new IntegrationDomain(this._api);
    this._brandDomain = new BrandDomain(this._api);
    this._producerLocationDomain = new ProducerLocationDomain(this._api);
    this._productDomain = new ProductDomain(this._api);
    this._statisticsDomain = new StatisticsDomain(this._api);
    this._supplierLocationDomain = new SupplierLocationDomain(this._api);
    this._etimDomain = new EtimDomain(this._api);
    this._producerArticleDomain = new ProducerArticleDomain(this._api, local);
    this._unitsDomain = new UnitsDomain(this._api);
    this._emailDomain = new EmailDomain(this._api);
    this._authorizationDomain = new AuthorizationDomain(this._api, this._api.http, this._userDomain);
    this._fileRepositoryDomain = new FileRepositoryDomain(this._api);
  }
}
