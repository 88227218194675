import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IESearchBarComponent } from './searchbar.component';
import { FormsModule } from '@angular/forms';
import { ClickOutsideModule } from '../ie-click-outside/click-outside-module';
import {IEIconModule} from '../ie-icon/ie-icon.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ClickOutsideModule,
    IEIconModule,
  ],
  declarations: [IESearchBarComponent],
  exports: [IESearchBarComponent]
})
export class IESearchbarModule { }
