import {Injector} from '@angular/core';
import {PricePipe} from '../../../app/shared/pipes/price.pipe';
import {SessionStorageService} from 'ngx-webstorage';
import {ACTIVE_ORDER_KEY} from './order-state';
import {APIService} from '../../../app/api/apiservice.service';
import {SupplierDomain} from '../supplier';
import {UserDomain} from '@bast/domain/user';

export enum OrderStatus {
  Sent = 'sent',
  Opened = 'open',
  New = 'new',
  Inprogress = 'in_progress',
  Cancelled = 'canceled',
  Closed = 'closed'
}

export enum OrderSort {
  Supplier = 1,
  Product,
}

export interface IOrderProduct {
  id?: number;
  supplier_id?: number;
  supplier_product_id?: number;
  supplier_name?: string;
  supplier_alias?: string;
  producer_name?: string;
  producer_alias?: string;
  product_id?: number;
  product_name?: string;
  product_code?: string;
  product_image?: string;
  product_image_url?: string;
  lot?: string;
  price?: number;
  price_total?: number;
  gross_price_total?: number;
  formated_price?: string;
  formated_price_total?: any;
  formated_gross_price_total?: any;
  infinity_stock?: boolean;
  quantity?: number;
  stock?: number;
  sale?: number;
  bonus?: string;
  package_info?: string;
  package_quantity?: number;
  unit_id?: number;
  unit?: string;
  unit_name?: string;
  min_order_quantity?: number;
  order_quantity_margin?: number;
  warehouse?: string;
  warehouse_id?: number;
  updated_at?: number;
  created_at?: number;
  tax?: number;
}

export interface OrderSupplierProduct extends IOrderProduct {
  supplier_tax_number?: string;
  supplier_phone?: string;
  supplier_image?: string;
  supplier_email?: string;
  supplier_alias?: string;
}

export class OrderSupplierGroup {
  supplier_id?: number;
  supplier_name?: string;
  supplier_alias?: string;
  supplier_products?: Array<IOrderProduct>;

  public recalculateSupplierGroupPrices(): void {
    if (this.supplier_products && this.supplier_products.length) {
      this.supplier_products.map((sp: IOrderProduct) => {
        sp.price_total = sp.quantity * sp.price;
        sp.formated_price = new PricePipe().transform(sp.price_total);
        sp.gross_price_total = Math.round(((sp.price_total * (1 + (sp.tax / 100))) + Number.EPSILON) * 100) / 100;
        sp.formated_gross_price_total = new PricePipe().transform(sp.gross_price_total);

        return sp;
      });
    }
  }

  constructor(props?) {
    if (props) { Object.assign(this, props); }
  }
}

export class OrderProductGroup {
  product_id?: number;
  product_name?: string;
  suppliers?: OrderSupplierProduct[] = [];

  constructor(props?) {
    if (props) { Object.assign(this, props); }
  }
}

export class Order {
  id?: number;
  code?: string;
  status?: OrderStatus;
  user?: number | string = '-';
  user_id?: number;
  price?: number;
  formated_price?: string;
  created_at?: number;
  comments?: Array<{ user: any, content: string, date: number }>;
  events?: Array<{type: string, message: string, date: number}>;
  comment?: { content?: string } = {};
  gross_price?: number;
  formated_gross_price?: string;
  delivery_supplier_name?: string;
  delivery_supplier_alias?: string;
  custom_flag?: any;
  order_comment?: string;
  delivery_location_name?: string;
  delivery_location_street?: string;
  delivery_location_house_number?: string;
  delivery_location_flat_number?: string;
  delivery_location_post_code?: string;
  delivery_location_city?: string;
  products?: Array<IOrderProduct> = [];
  is_manageable? = false;

  public isActive(): boolean {
    return this.status !== OrderStatus.Cancelled && this.status !== OrderStatus.Closed;
  }

  protected setManageable(): void {
    try {
      (this[Symbol.for('__userdomain')] as UserDomain)
        .myself().then(u => {
          this.is_manageable = u.id === this.user;
        });
    } catch (e) {
      this.is_manageable = false;
    }
  }

  constructor(public params?, public active?: boolean, public userDomain?: UserDomain) {
    if (params) {
      Object.assign(this, params);
    }

    // Hide user domain to prevent JSON circular dependency problem
    this[Symbol.for('__userdomain')] = userDomain;
    delete this['userDomain'];
    delete this['params'];

    this.setManageable();
  }
}

export class SupplierOrder extends Order {
  active?: boolean;
  code?: string;
  calculatedTotalCost?: number;
  calculatedTotalFormattedCost?: string;
  comment?: { content: string };
  comments?: Array<{ user: any, content: string, date: number }>;
  formated_price?: string;
  formated_gross_price?: string;
  gross_price?: number;
  id?: number;
  is_manageable?: boolean;
  price?: number;
  products?: Array<OrderProductGroup> = [];
  sortBy?: OrderSort;
  status?: OrderStatus;
  suppliers?: Array<OrderSupplierGroup> = [];
  user?: number|string;
  user_first_name?: string;
  user_last_name?: string;
  _totalCost?: number;

  get totalCost() {
    if (this.suppliers.length === 0) { return 0; }
    return this.getTotalCost(this.params);
  }

  public recalculateTotalCost(): void {
    let totalCost = 0;
    if (this.sortBy === OrderSort.Supplier) {
      totalCost = this.suppliers.reduce((acc, supplier) => {
        const sp = supplier.supplier_products.reduce((totalOfSupplier, product) => totalOfSupplier + product.quantity * product.price, 0);
        return acc + sp;
      }, 0);
    } else {
      totalCost = this.products.reduce((acc, product) => {
        const sp = product.suppliers.reduce((totalOfProduct, supplier) => totalOfProduct + supplier.quantity * supplier.price, 0);
        return acc + sp;
      }, 0);
    }

    const pricePipe = new PricePipe();
    this.calculatedTotalCost = totalCost;
    this.calculatedTotalFormattedCost = pricePipe.transform(totalCost);
    this.formated_gross_price = pricePipe.transform(1.23 * totalCost);
    this.formated_price = pricePipe.transform(totalCost);
  }

  public addProduct(product: IOrderProduct): void {
    if (this.sortBy === OrderSort.Product) { this.addProductGroupedByProduct(product); }
    else { this.addProductGroupedBySupplier(product); }

    this.recalculateTotalCost();
  }

  public deleteProduct(product: IOrderProduct): void {
    try {
      const supplierIndex = this.suppliers.findIndex(s => s.supplier_id === product.supplier_id);
      this.suppliers[supplierIndex].supplier_products = this.suppliers[supplierIndex].supplier_products
        .filter(prod => prod.id !== product.id);

      // If deleted product was the only one from this supplier - delete whole supplier
      if (this.suppliers[supplierIndex].supplier_products.length === 0) { this.suppliers.splice(supplierIndex, 1); }

      this.params.products = (this.params.products || []).filter(p => p.id !== product.id);
      this.products = this.products.filter(p => p.product_id !== product.id);
      this.changeSortMethod(this.sortBy);
    } catch (e) {}
  }

  public getTotalSupplierCost(supplier_id: number) {
    if (!this.suppliers||!this.suppliers.length) { return 0; }
    const supplierIndex = this.suppliers.findIndex( s => s.supplier_id === supplier_id );
    let total_cost = 0;
    for (let i = 0; i < this.suppliers[supplierIndex].supplier_products.length; i++) {
      total_cost += this.suppliers[supplierIndex].supplier_products[i].quantity * this.suppliers[supplierIndex].supplier_products[i].price;
    }

    return total_cost;
  }

  public getTotalProductCost(product_id: number) {
    if (!this.products||!this.products.length) { return 0; }
    const productIndex = this.products.findIndex( p => p.product_id === product_id );
    let total_cost = 0;
    for (let i = 0; i < this.products[productIndex].suppliers.length; i++) {
      total_cost += this.products[productIndex].suppliers[i].quantity * this.products[productIndex].suppliers[i].price;
    }

    return total_cost;
  }

  public recalculatePrices(order: Order): void {
    const { price, gross_price, formated_price, formated_gross_price } = order;
    this.price = price;
    this.gross_price = gross_price;
    this.formated_price = formated_price;
    this.formated_gross_price = formated_gross_price;
    this.suppliers.forEach(supplier => supplier.recalculateSupplierGroupPrices());
  }

  public changeSortMethod(sort: OrderSort): void {
    switch (sort) {
      case OrderSort.Product: {
        if (!this.products.length) { this.groupByProduct(this.params); }
        this.sortBy = OrderSort.Product;
        return;
      }
      case OrderSort.Supplier: {
        if (!this.suppliers.length) { this.groupBySupplier(this.params); }
        this.sortBy = OrderSort.Supplier;
        return;
      }
      default: {
        if (!this.suppliers.length) { this.groupBySupplier(this.params); }
        this.sortBy = OrderSort.Supplier;
        return;
      }
    }
  }

  public clone(params): SupplierOrder {
    const clone = Object.assign( Object.create( Object.getPrototypeOf(this)), this);
    Object.assign(clone, params);
    return clone;
  }

  public findProduct(product_id): IOrderProduct {
    let product = null;
    this.suppliers.forEach(s => {
      const match = s.supplier_products.find(p => p.supplier_product_id === product_id);
      if (match) { product = match; }
    });

    return product;
  }

  private setActive(): void {
    if (this.params.active) { return; }
    try {
      if (this.id === (window['angularInjector'] as Injector).get(SessionStorageService).retrieve(ACTIVE_ORDER_KEY)) { this.active = true; }
      else { this.active = false; }
    } catch (e) { this.active = false; }
  }

  private groupBySupplier(order: Order): void {
    for (let i = 0; i < order.products.length; i++) {
      this.addProductGroupedBySupplier(order.products[i]);
    }
  }

  private async groupByProduct(order: Order) {
    for (let i = 0; i < order.products.length; i++) {
      await this.addProductGroupedByProduct(order.products[i]);
    }
  }

  private getTotalCost(order: Order): number {
    if (!order) { return 0; }
    let total = 0;
    for ( let i = 0; i < order.products.length; i++) {
      total += order.products[i].price * order.products[i].quantity;
    }
    return total;
  }

  protected addProductGroupedBySupplier(product: IOrderProduct): void {
    const supplierIndex = this.suppliers.findIndex( s => s.supplier_id === product.supplier_id );

    if (!product.product_image) {
      product.product_image = './assets/product_icon.svg';
    }

    // If supplier is in order - check if supplier's order has this product
    if (supplierIndex > -1 ) {
      const productIndex = this.suppliers[supplierIndex].supplier_products
        .findIndex( sp => sp.id === product.id );

      // If supplier has this product - increase quantity
      if (productIndex > -1) {
        this.suppliers[supplierIndex].supplier_products[productIndex].quantity = product.quantity;
      } else {
        // If supplier DOES NOT have this product append it to suppliers products list
        this.suppliers[supplierIndex].supplier_products.push(product);
      }
    } else {
      const supplierGroup = {
        supplier_id: product.supplier_id,
        supplier_name: product.supplier_name,
        supplier_alias: product.supplier_alias,
        supplier_products: [product]
      };

      this.suppliers.push(new OrderSupplierGroup(supplierGroup));
    }
  }

  protected async addProductGroupedByProduct(product: IOrderProduct) {
    const productIndex = this.products.findIndex(p => p.product_id === product.id);

    if (productIndex > -1) {
      const supplierIndex = this.products[productIndex].suppliers
        .findIndex(s => s.supplier_id === product.supplier_id);
      if (supplierIndex > -1) {
        this.products[productIndex].suppliers[supplierIndex].quantity += product.quantity;
      } else {
        const supplier = await new SupplierDomain((window['angularInjector'] as Injector).get(APIService)).getById(product.supplier_id);
        const supplierGroup = {
          ...product,
          supplier_tax_number: supplier.tax_number,
          supplier_phone: supplier.phone,
          supplier_image: supplier.image_url,
          supplier_email: supplier.email,
        };

        this.products[productIndex].suppliers.push(supplierGroup as any);
      }
    } else {
      const supplier = await new SupplierDomain((window['angularInjector'] as Injector).get(APIService)).getById(product.supplier_id);
      const productGroup = {
        product_id: product.id,
        product_name: product.product_name,
        suppliers: [{
          ...product,
          supplier_tax_number: supplier.tax_number,
          supplier_phone: supplier.phone,
          supplier_image: supplier.image_url,
          supplier_email: supplier.email,
        }],
      };

      this.products.push(new OrderProductGroup(productGroup));
    }
  }

  constructor(public params, public userDomain) {
    super(null, false, userDomain);
    this.id = params.id;
    this.code = params.code;
    this.status = params.status;
    this.user = params.user;
    this.gross_price = params.gross_price;
    this.formated_gross_price = params.formated_gross_price;
    this.price = params.price;
    this.formated_price = params.formated_price;
    this.comment = { content: null };
    this.comments = params.comments;
    this._totalCost = this.getTotalCost(params);
    this.setActive();
    this.setManageable();
    this.changeSortMethod(OrderSort.Supplier);
  }
}
