import {Injectable, Injector} from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { IELoaderService } from '@material/ie-loader/ieloader.service';
import {catchError, map} from 'rxjs/operators';
import {FlagService} from '@material/ie-flag-notification/service/flag-service';
import {IEDownloadsDialogService} from '@material/ie-downloads-dialog/ie-downloads-dialog.service';
import {ObjectUtils} from '@shared/utils/object-utils';

@Injectable()
export class APIService {

  constructor(public http: HttpClient, private loader: IELoaderService, private injector: Injector) {
  }

  API_URL: string;

  /**
   * Remote API request
   * @param {string} url  Remote URL address
   * @param {string} method  HTTP method: GET/POST/PUT/DELETE
   * @param {object} params Body parameters
   */
  request<T>(url: string, method: string, params?: any, headers?: HttpHeaders, form?: boolean): Promise<T> {
    let parameters = { reportProgress: true };
    if (params && !form) {
      parameters = { ...parameters, ...params };
    } else { parameters = params; }

    const req = headers ?
                new HttpRequest(method, this.API_URL + url, parameters, { headers: headers })
              : new HttpRequest(method, this.API_URL + url, parameters);

    return new Promise((resolve, reject) => {
      this.http.request(req).subscribe(
        (event: HttpEvent<any>) => {
          switch (event.type) {
            case HttpEventType.Sent:

                // Show loading bar
                this.loader.loading();
              break;
              case HttpEventType.ResponseHeader:

                // Fulfill loading bar and hide it
                this.loader.finishLoading();
              break;
              case HttpEventType.Response: {

                // Fulfill loading bar and hide it
                this.loader.finishLoading();
                const obj: (T) = event.body;
                resolve(obj);
              }
            }
        },
        (error) => {
          this.loader.finishLoading();
          reject(error);
        });
    });
  }

  /**
   * Requests file to download
   * Retieves filename from Content-Disposition header
   * and replaces unallowed characters such as `:` from filename
   * @param {string} url API url
   */
  downloadFile(url: string): Observable<any> {
    const fileUrl = url.indexOf('http') > -1 ? url : this.API_URL + '/' + url;
    const downloadId = this.injector.get(IEDownloadsDialogService).publish({ name: ObjectUtils.readFilenameFromUrl(url), url });
    return this.http.get(fileUrl, { observe : 'response', responseType: 'blob' })
      .pipe(catchError(e => {
        this.injector.get<FlagService>(FlagService).publishWarningFlag(
          'Błąd',
          'Nie posiadasz wystarczających uprawnień do wykonania tej akcji',
          false
        );
        // const downloadId = this.injector.get(IEDownloadsDialogService).publish({ name: ObjectUtils.readFilenameFromUrl(url), url });
        this.injector.get(IEDownloadsDialogService).changeState(downloadId,'failed');

        return Observable.of(null);
      }))
      .pipe(map( res => this.blob(res) ))
      .pipe(map( blob => {
        if (blob) {
          // const downloadId = this.injector.get(IEDownloadsDialogService).publish({ name: blob.filename, url });
          saveAs(blob.blob, blob.filename, false);
          this.injector.get(IEDownloadsDialogService).changeState(downloadId,'success');
        }
      }));
  }

  /**
   * Returns blob object with proper filename
   * @param {HttpResponse} res Remote response
   */
  private blob(res) {
    if (!res) { return null; }
    if (!res.headers.get('Content-Disposition')) {
      throw new Error('Content-Disposition header was not returned in response');
    }

    return {
      blob: new Blob([res.body]),
      filename: (res.headers.get('Content-Disposition').split(';')[1].split('=')[1]).replace(/[\"\:]/g, '')
    };
  }
}
