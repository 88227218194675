import {Injectable} from '@angular/core';
import {APIService} from '../../../api/apiservice.service';
import {InterfaceElement} from '../model/InterfaceElement';
import {DURSZLAK_ENDPOINTS} from '../../../configuration/DURSZLAK_ENDPOINTS';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Bast} from '@bast';

export enum DataExportFormat {
  'CSV'= 'CSV',
  'XML' = 'XML',
};

@Injectable()
export class UiPersonalizationService {

  VAULT_URL: string;

  public getInterfaceElement(elementName: string): Observable<InterfaceElement> {
    return from(this.bast.user.myself())
      .pipe(switchMap(user => {
        const {id} = user;
        return this.apiService.http.get(DURSZLAK_ENDPOINTS.getInterfaceElementForUser(this.VAULT_URL, id, elementName));
      }));
  }

  public createInterfaceElement(elementName: string, options: any): Observable<InterfaceElement> {
    return from(this.bast.user.myself())
      .pipe(switchMap(user => {
        const {id} = user;
        const payload = { name: elementName, interfaceUser: id, options };
        return this.apiService.http.post(DURSZLAK_ENDPOINTS.createInterfaceElement(this.VAULT_URL), payload);
      }));
  }

  public updateInterfaceElement(elementId: number, elementName: string, options: any): Observable<InterfaceElement> {
    return this.apiService.http.put(DURSZLAK_ENDPOINTS.updateInterfaceElement(this.VAULT_URL, elementId), { options });
  }

  public saveInterfaceElement(elementId: number, elementName: string, options: any): Observable<InterfaceElement> {
    if (!elementId) {
      return this.createInterfaceElement(elementName, options);
    } else {
      return this.updateInterfaceElement(elementId, elementName, options);
    }
  }

  public exportInterfaceElementToFormat(elementId: number, format: DataExportFormat, columns: string[] = []): Observable<any> {
    return this.apiService.downloadFile(DURSZLAK_ENDPOINTS.exportInterfaceElementBoundData(this.VAULT_URL, elementId, format, columns));
  }

  constructor(
    private apiService: APIService,
    private bast: Bast,
  ) {}
}
