<div id="cdk-table-wrapper">
<div
  *ngIf="!dataPending;"
  class="ie-cdk-table-container"
  [ngClass]="sticky?'ie-cdk-table-sticky-header':''"
>
  <div class="cdk-table-header">
    <ie-searchbar
      *ngIf="searchable"
      [keepOpen]="true"
      [placeholder]="'wyszukaj w tabeli'"
      (onsearch)="filter.next$.next($event)"
      (onclear)="clearFilter()"
    ></ie-searchbar>

    <div class="--empty-grow"></div>

    <div class="table-personalization-container">
      <ng-content select="[cdk-table-header]"></ng-content>
      <ng-container *ngIf="interface">
        <ie-icon-button
          style="margin-right: 10px"
          [icon]="'export'"
          [label]="'eksportuj'"
          [size]="15"
          (click)="openExportDataDialog()"
          *ngIf="showExportButton"
        >
        </ie-icon-button>
        <ie-icon-button
          *ngIf="manageableColumns.length"
          style="margin-right: 10px"
          [icon]="'menage_columns'"
          [label]="'zarządzaj'"
          [size]="15"
          (click)="openColumnManageDialog()"
        >
        </ie-icon-button>
      </ng-container>

      <ie-paginator
        *ngIf="paginationLimit>0"
        [max]="maxResults"
        [count]="paginationLimit"
        [currentPage]="currentPage + 1"
        [startIndex]="1"
        [disabled]="filter?.phrase?.length&&maxResults>paginationLimit"
        (change)="changePagination($event)"
      ></ie-paginator>
    </div>
  </div>

  <div
    id="cdk-content-container"
    class="cdk-content-container"
    [attr.drag]="drag"
    [attr.pending]="pending"
    [attr.disable]="disablePointerEvents"
    #tableContainer
  >
    <div
      class="sticky-header-container"
      ieDragScroller
      [sticky]="sticky"
    >
      <ng-container><ng-content></ng-content></ng-container>
    </div>

    <div
      *ngIf="maxResults>20"
      class="cdk-content-container-footer"
    >
      <ie-paginator
        *ngIf="paginationLimit>0"
        [max]="maxResults"
        [count]="paginationLimit"
        [currentPage]="currentPage + 1"
        [startIndex]="1"
        [disabled]="filter?.phrase?.length&&maxResults>paginationLimit"
        (change)="changePagination($event)"
      ></ie-paginator>
    </div>
  </div>

</div>
<ie-dialog></ie-dialog>

</div>
