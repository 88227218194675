import {from, Observable} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {map, switchMap} from 'rxjs/operators';
import {STORAGE_KEYS, StorageService} from '../../../app/shared/storage-service/storage.service';
import {Producer} from '../../../app/core/producers/model/producer';
import {APIService} from '../../../app/api/apiservice.service';
import {Injector} from '@angular/core';
import {ProductStatisticsResponse} from './product-report';
import {SalesStatisticsResponse} from './sales-report';
import {IntegrationStatisticsResponse} from './integration-report';
import {ActivityStatisticsResponse} from './activity-report';
import {PriceUpdateStatisticsResponse} from './price-update-report';
import {Supplier} from '../supplier';

export default class {

  /**
   * Retrieves sales data from remote API to supply sales table
   * based on user privileges
   * @param {string} request_url URL
   */
  public salesTableData(dateFrom: number, dateTo: number, product_id?: any, suppliers?: Array<number>, producers?: Array<any>) {
    return from(this.apiService.request<ProductStatisticsResponse>(ENDPOINTS.getProductsStatistics + this.getParameterizedURL(dateFrom, dateTo, product_id, suppliers, producers), 'GET'))
      .pipe( map(({data}) => data) );
  }

  /**
   * Retrieves sales report for given product to supply sales chart
   * @param dateFrom Timestamp from
   * @param dateTo  Timestamp to
   * @param product_id Product ID
   * @param suppliers Supplier's ID list
   * @param fullPrivileges Has full
   */
  public sales(dateFrom: number, dateTo: number, product_id?: any,
                    suppliers?: Array<number>, producers?: any): Observable<any> {
    return from(this.apiService.request<SalesStatisticsResponse>(ENDPOINTS.getStatisticsReport + this.getParameterizedURL(dateFrom, dateTo, product_id, suppliers, producers), 'GET'))
      .pipe( switchMap( stats => from(this.mapSalesReportData(stats)) ));
  }

  public integration(): Promise<IntegrationStatisticsResponse> {
    return this.apiService.request<IntegrationStatisticsResponse>(ENDPOINTS.getStatistics + '/integration', 'GET');
  }

  public activity(): Promise<ActivityStatisticsResponse> {
    return this.apiService.request<ActivityStatisticsResponse>(ENDPOINTS.getStatistics + '/activity', 'GET');
  }

  public priceListUpdate(): Promise<PriceUpdateStatisticsResponse> {
    return this.apiService.request<PriceUpdateStatisticsResponse>(ENDPOINTS.getStatistics + '/price-list-update', 'GET');
  }

  /**
   * Prepares integration data for Google Chart
   * @param {IntegrationReportResponse} data Integration Data response from remote API
   */
  public mapIntegrationReportData(input: IntegrationStatisticsResponse): Promise<any> {
    const storage = (window['angularInjector'] as Injector).get(StorageService);

    return new Promise((resolve, reject) => {
      const outputDataTable = [];
      const {data} = input;
      outputDataTable.push(['Sprzedawca', 'Ilość']);

      for (let i = 0; i < data.integration.length; i++) {

        let totalSupplierCount = 0;

        input.data.integration[i].data.forEach( supplier_data => {
          totalSupplierCount += supplier_data.supplier_products_count;
        });

        storage.getObjectByProperty<Supplier>(STORAGE_KEYS.SUPPLIERS, 'tax_number', data.integration[i].supplier_tax_number)
          .subscribe( s => {

            outputDataTable.push( [s.alias, totalSupplierCount]);
          });

      }

      (outputDataTable.length > 1) ? resolve(outputDataTable) : reject('No data error');
    });
  }


  /**
   * Downloads reports data file from remote API
   * @param {string} file_format File format
   * @param {number} dateFrom Initial timestamp range
   * @param {number} dateTo Final timestamp
   * @param {number} product_id Product ID
   * @param {Array<number>} suppliers Supplier's IDS array
   * @param {Array<number>} producers Producer's IDS array
   */
  public downloadFile(file_format: string, dateFrom: number, dateTo: number, product_id?: number,
               suppliers?: Array<number>, producers?: Array<number> ): Observable<any> {
    return this.apiService.downloadFile(
        ENDPOINTS.getProductsStatistics +
        this.getParameterizedURL(dateFrom, dateTo, product_id, suppliers, producers) + '&export_format=' + file_format
    );
  }

  private mapProducerIntegrationData(input: IntegrationStatisticsResponse): Promise<any> {
    const storage = (window['angularInjector'] as Injector).get(StorageService);

    return new Promise((resolve, reject) => {
      const output = [];
      input.data.integration.map( el => {
        storage.getObjectByProperty<Supplier>(STORAGE_KEYS.SUPPLIERS, 'tax_number', el.supplier_tax_number)
          .subscribe( supplier => {
            if (supplier) { el.supplier_tax_number = supplier.alias; }
          });

        el.data.map( sub_el => {
          storage.getObjectByProperty<Producer>(STORAGE_KEYS.PRODUCERS, 'tax_number', sub_el.producer_tax_number)
            .subscribe( producer => {
              if (producer) { sub_el.producer_tax_number = producer.alias; }
              sub_el.supplier_producer_share = (Number(sub_el.supplier_producer_share) * 100).toFixed(2) + '%';
            });
        });

        return el;
      });

      resolve(input.data.integration);
    });
  }

  /**
   * Maps remote response to Google Chart input format
   * @param {SalesStatisticsResponse} data Data supplied from remote API
   */
  protected mapSalesReportData(input: SalesStatisticsResponse): Promise<any> {
    return new Promise((resolve, reject) => {
      const output = [];
      output.push(['Data', 'Sprzedaż [sztuk]', { type: 'string', role: 'tooltip', p: { html: true} }]);
      input.data.forEach( report_data => {
        output.push([ report_data.date, report_data.count,
          this.mapToolTipHTMLContent(report_data.count, report_data.formated_price) ]);
      });
      (output.length > 0) ? resolve(output) : reject(false);
    });
  }

  /**
   * Prepares tooltip HTML content for chart hovered legend
   * @param {number} sales_count Sales count
   * @param {string} sales_price Sales total pricetag
   */
  public mapToolTipHTMLContent(sales_count: number, sales_price: string): string {
    return `
    <div style="padding: 10px; background-color: #3333CC; color: #FFF; box-shadow: 0 0 10px rgba(0,0,0,0.3)">
      <label style="font-weight: 600">Ilość sprzedanych sztuk: </label><label style="padding-left: 10px">` + sales_count + ` sztuk</label>
      <br>
      <br>
      <label style="font-weight: 600">Sprzedaż: </label><label style="padding-left: 10px">`
      + (sales_price).toLocaleString() + ` zł</label></div>`;
  }

  /**
   * Prepares URL query for chart data
   * @param {boolean} fullPrivileges Has full privileges?
   */
  private getParameterizedURL(dateFrom: number, dateTo: number, product_id: any,
                              suppliers?: Array<number>, producers?: number | Array<number>/*, fullPrivileges?: boolean*/) {
    const dF = 'date_from=' + dateFrom;
    const dT = '&date_to=' + dateTo;
    const product =  (product_id) ? '&products[]=' + product_id : '';

    let url = '?' + dF + dT + product;

    if (typeof producers === 'string') { url += '&producers[]=' + producers; }
    else if (producers instanceof Array) {
      let producer = '';
      producers.forEach( p => { producer += ('&producers[]=' + p); });
      url += producer;
    }

    let supplier = '';
    if (suppliers) {
      suppliers.forEach( s => { supplier += ('&suppliers[]=' + s); } );
    }

    url += supplier;

    return url;
  }


  constructor(private apiService: APIService) {}

}
