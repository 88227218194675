import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IEFlagSelectorComponent } from "./ie-flag-selector.component";
import {IEIconModule} from '../ie-icon/ie-icon.module';

@NgModule({
  imports: [CommonModule, IEIconModule],
    declarations: [IEFlagSelectorComponent],
    exports: [IEFlagSelectorComponent]
})
export class IEFlagSelectorModule {}
