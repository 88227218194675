import { ControlValueAccessor } from '@angular/forms';
import {KeyboardAware} from '../keyboard-aware/keyboard-aware';

const noop = () => {};

/**
 * Input IO interface
 */
export interface IEInputOutput {
    _fieldValue: any;
    name: string | string[];
    error: string;
}

/**
 * Control value accessor interface
 * is used in all custom input components which
 * utilize ngModel for two way binding
 */
export class IEInputOutputAccessor extends KeyboardAware implements ControlValueAccessor {

    _fieldValue: any = '';
    private onChangeCallback: (_: any) => void = noop;
    private onTouchCallback: () => void = noop;

    /**
     * Returns input value
     */
    get fieldValue() {
        return this._fieldValue;
    }

    /**
     * Sets input value
     */
    set fieldValue(value: any) {
        if (!this._fieldValue&&value) { this._fieldValue = value; this.onInitCallback(); }
        this._fieldValue = value;
        this.onChangeCallback(value);
    }

    writeValue(value: any): void {
        if (value !== undefined) {
            this.fieldValue = value;
        } else {
            this.fieldValue = null;
        }
    }
    registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouchCallback = fn;
    }

    protected onInitCallback() {}
}
