import {from, Observable} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {ProducerSerie} from '../../../app/core/producers/model/producer-serie';
import {ProducerLocation} from '../../../app/core/producers/model/producer-location';
import {APIService} from '../../../app/api/apiservice.service';
import {Tag} from '../../../app/ie-material/ie-tag/Tag';

export interface ProducerContact {
  id?: number;
  first_name?: string;
  last_name?: string;
  phone?: string;
  email?: string;
  position?: string;
}

export interface ProducerCount {
  count? :string;
  producer_id?: string;
  producer_name?: string
}

export class Producer {
  id?: number;
  alias?: string;
  producer_id?: number;
  tax_number?: number;
  erp?: string;
  name?: string;
  city?: string;
  post_code?: string;
  street?: string;
  house_number?: string;
  flat_number?: string;
  phone?: string;
  email?: string;
  tags?: Array<Tag>;
  contacts?: ProducerContact[];
  image?: string;
  image_id?: number;
  image_url?: string;
  image_file?: any;
  is_supplier?: boolean;
  central_contract?: boolean;
  country?: number;
  country_name?: string;
  products_last_updated_at?: number;
  avatar?: string;
  url?: string;

  public static toDto(producer: Producer) {
    const { name, tax_number, alias, central_contract, street, house_number, flat_number, city, post_code, phone, email, country, url } = producer;
    const address = {
      street,
      house_number,
      flat_number,
      city,
      post_code,
      phone,
      email,
      country,
      url
    };

    return {
      producer: {
        name,
        tax_number,
        alias,
        central_contract,
        location: { address },
        url
      }
    };
  }

  constructor(props?) {
    if (props) {
      Object.assign(this, props);
    } else {
      // Set Poland as a default country
      this.country = 168;
    }
  }
}


export class ProducerDomain {

  public get(): Observable<Producer[]> {
    return from(this.api.request(ENDPOINTS.getProducers, 'GET').then(({data}) => data));
  }

  public getById(producerId): Promise<Producer> {
    return this.api.request<Producer>(ENDPOINTS.getProducer(producerId), 'GET');
  }

  public delete(producerId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteProducer(producerId), 'DELETE');
  }

  public deleteImage(producerId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteProducerImage(producerId), 'DELETE');
  }

  public update(producer: Producer): Promise<Producer> {
    return this.api.request(ENDPOINTS.updateProducer(producer.id), 'POST', Producer.toDto(producer));
  }

  public create(producer: Producer): Promise<Producer> {
    return this.api.request(ENDPOINTS.createProducer, 'POST', Producer.toDto(producer))
  }

  public getProducersListWithFilters(filter: any): Promise<Array<Producer>> {
    const filtersQuery = this.buildFilters(filter);
    const query = `${ENDPOINTS.getProducers}?${filtersQuery}`;
    return this.api.request(query, 'GET');
  }

  public getProducerContact(producerId: number): Promise<ProducerContact[]> {
    return this.api.request(ENDPOINTS.getProducerContacts(producerId), 'GET').then(({ data }) => Promise.resolve(data));
  }

  public createProducerContact(producerId: number, contact: ProducerContact): Promise<ProducerContact> {
    return this.api.request(ENDPOINTS.createProducerContact(producerId), 'POST', { producer_contact: { contact } });
  }

  public updateProducerContact(contact: ProducerContact): Promise<ProducerContact> {
    return this.api.request(ENDPOINTS.updateProducerContact(contact.id), 'POST', { producer_contact: { contact } });
  }

  public deleteProducerContact(contactId: number): Promise<ProducerContact> {
    return this.api.request(ENDPOINTS.deleteProducerContact(contactId), 'DELETE');
  }

  public downloadProducerVCard(contactId: number): Observable<void> {
    return this.api.downloadFile(ENDPOINTS.downloadProducerVCard(contactId));
  }

  public getProducerSeries(producerId: number): Promise<ProducerSerie[]> {
    return this.api.request(ENDPOINTS.getProducerSeries(producerId), 'GET').then(({ data }) => Promise.resolve(data));
  }

  public getProducerSerie(serieId: number): Promise<ProducerSerie> {
    return this.api.request(ENDPOINTS.getProducerSerie(serieId), 'GET');
  }

  public createSerie(producerId: number, serie: ProducerSerie): Promise<ProducerSerie> {
    return this.api.request(ENDPOINTS.createProducerSerie(producerId), 'POST', { serie });
  }

  public updateSerie(serie: ProducerSerie): Promise<ProducerSerie> {
    return this.api.request(ENDPOINTS.updateProducerSerie(serie.id), 'POST', { serie });
  }

  public deleteSerie(serie: ProducerSerie): Promise<void> {
    return this.api.request(ENDPOINTS.deleteProducerSerie(serie.id), 'DELETE');
  }

  public getLocations(producerId: number): Promise<ProducerLocation[]> {
    return this.api.request(ENDPOINTS.getProducerLocations(producerId), 'GET').then(({data}) => data);
  }

  public getLocation(locationId: number): Promise<ProducerLocation> {
    return this.api.request(ENDPOINTS.getProducerLocation(locationId), 'GET');
  }

  public createLocation(location: ProducerLocation): Promise<any> {
    return this.api.request(ENDPOINTS.createProducerLocation(location.producer_id), 'POST', ProducerLocation.toDto(location));
  }

  public updateLocation(location: ProducerLocation): Promise<any> {
    return this.api.request(ENDPOINTS.updateProducerLocation(location.id), 'POST', ProducerLocation.toDto(location));
  }

  public deleteLocation(location: ProducerLocation): Promise<void> {
    return this.api.request(ENDPOINTS.deleteProducerLocation(location.id), 'DELETE');
  }

  public contact(producerId: number): Promise<ProducerContact[]> {
    return this.api.request(ENDPOINTS.getProducerContacts(producerId), 'GET').then(({ data }) => Promise.resolve(data));
  }

  public createContact(producerId: number, contact: ProducerContact): Promise<ProducerContact> {
    return this.api.request(ENDPOINTS.createProducerContact(producerId), 'POST', { producer_contact: { contact } });
  }

  public updateContact(contact: ProducerContact): Promise<ProducerContact> {
    return this.api.request(ENDPOINTS.updateProducerContact(contact.id), 'POST', { producer_contact: { contact } });
  }

  public deleteContact(contactId: number): Promise<ProducerContact> {
    return this.api.request(ENDPOINTS.deleteProducerContact(contactId), 'DELETE');
  }

  public downloadVCard(contactId: number): Observable<void> {
    return this.api.downloadFile(ENDPOINTS.downloadProducerVCard(contactId));
  }

  private buildFilters(filter) {
    if (!filter) return '';

    let f = '';
    // tslint:disable-next-line:forin
    for (const prop in filter) {
      if (!filter[prop]) { continue; }

      if (filter[prop] instanceof Array) {
        const arrayFilter = filter[prop]
          .reduce((acc, next) => {
            return acc += `filters[${prop}][]=${next}&`;
          }, '');

        f += arrayFilter;
      } else {
        f += `filters[${prop}]=${filter[prop]}&`;
      }
    }

    return f;
  }

  constructor(private api: APIService) {}
}
