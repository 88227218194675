<div
  *ngIf="isOpened()"
  class="ie-warn-dialog-overlay overlay overlay-dark"
  (click)="dismiss()"
></div>
<div
  *ngIf="isOpened()"
  class="ie-warn-dialog"
>
  <header>{{header}}</header>
  <img *ngIf="imgUrl" [src]="imgUrl | secure"/>
  <div class="warn-message">{{message}}</div>
  <div class="warn-buttons">
    <button
      *ngIf="!hideAcceptButton"
      class="bar-accept-button warn-button"
      [attr.type]="type"
      (click)="accept()"
    >
      <ng-container *ngIf="!pending;else loading">{{acceptButton || 'ok'}}</ng-container>
      <ng-template #loading><ie-spinner [size]=".5"></ie-spinner></ng-template>
    </button>
    <button
      *ngIf="type==='warn'"
      class="bar-cancel-button"
      (click)="dismiss()"
    >{{cancelButton || 'anuluj'}}</button>
  </div>
</div>
