import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  NgZone,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {IEInputOutputAccessor} from '@shared/input-output-accessor/ie-input-output-accessor';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {skipUntil, takeUntil} from 'rxjs/operators';
import {Subject, timer} from 'rxjs';
import {TemplatePortal} from '@angular/cdk/portal';

@Component({
  selector: 'ie-inline-dropdown',
  templateUrl: './ie-inline-dropdown.component.html',
  styleUrls: ['./ie-inline-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IEInlineDropdownComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None,
})
export class IEInlineDropdownComponent extends IEInputOutputAccessor implements OnInit {

  @Input() options: Array<any>;
  @Input() placeholder = 'Wybierz';
  @Input() bindValue = 'value';
  @Input() bindKey = 'key';
  @ViewChild('dropdownContent') dropdownContent: TemplateRef<any>;
  @Output() onchange: EventEmitter<any> = new EventEmitter();

  public opened: boolean;
  private _visible: boolean;
  private _overlayRef: OverlayRef;
  private readonly _destroyed: Subject<void> = new Subject<void>();

  @HostListener('document:keypress', ['$event'])
  closeOnEsc(e) {
    if (e.keyCode === 27) { this.opened = false; }
  }

  public openDropdown(): void {
    this._createOverlay();
  }

  public hideDropdown(): void {
    this._overlayRef.dispose();
    this._overlayRef = null;
    this._destroyed.next();
    this._visible = false;
  }

  public getPlaceholder(): string {
    if (!this.fieldValue || !this.options || !(this.options instanceof Array)) { return this.placeholder; }
    if (typeof this.options[0] === 'string') { return this.fieldValue; }

    const getValue = () => {
      const option = this.options.find(o => o[this.bindKey] === this.fieldValue);
      return option ? option[this.bindValue] : this.placeholder;
    };

    return getValue();
  }

  public selectValue(option: any) {
    // this.closeDropdown();
    this.hideDropdown();

    if (typeof option === 'string') {
      this.writeValue(option);
      this.onchange.emit(option);
      return;
    }

    if (!this.bindKey) {
      throw new Error('No key to bind');
    }

    this.writeValue(option[this.bindKey]);
    this.onchange.emit(option[this.bindKey]);
  }

  public getOptionLabel(option: any) {
    if (typeof option === 'string') { return option; }
    if (!this.bindValue) { throw new Error(`No value to bind`); }
    return option[this.bindValue];
  }

  public onEscape(): void {
    if (this._visible) { this.hideDropdown(); }
  }

  private _createOverlay(): OverlayRef {
    if (this._overlayRef) { return; }

    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this.overlay.position()
      .flexibleConnectedTo(this.el)
      .withPositions([
        // {
        //   originX: 'center',
        //   originY: 'top',
        //   overlayX: 'center',
        //   overlayY: 'bottom'
        // },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }]
      ).withTransformOriginOn('.ie-inline-dropdown-container')

    strategy.positionChanges
      .pipe(takeUntil(this._destroyed))
      .pipe(skipUntil(timer(200)))
      .subscribe(change => {
        if (this._visible) { this.ngZone.run(() => this.hideDropdown()); }
      });

    this._overlayRef = this.overlay.create({
      positionStrategy: strategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({scrollThrottle: 0}),
      hasBackdrop: true,
      backdropClass: 'overlay-clear'
    });

    const portal = new TemplatePortal(this.dropdownContent, this.viewContainerRef);
    this._overlayRef.attach(portal);
    this._overlayRef.backdropClick().subscribe(() => this.hideDropdown());

    this._visible = true;
  }


  constructor(
    private overlay: Overlay,
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private ngZone: NgZone,
  ) {
    super();
  }

  ngOnInit() {
  }

}
