import {APIService} from '../../../app/api/apiservice.service';
import {from, Observable, of, ReplaySubject} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {catchError, map, retry, shareReplay, take, tap} from 'rxjs/operators';
import * as Sentry from '@sentry/browser';
import {IP} from '@bast/domain/ip';
import { isError } from 'util';

export interface UserTypes {
  [key: string]: {
    name?: string;
    icon?: string;
    tooltip?: string;
  }
}

export class User {
  id?: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  phone?: string;
  supplier_id?: number;
  producer_id?: number;
  producer?;
  supplier?;
  supplier_name?: string;
  supplier_alias?:string;
  producer_name?: string;
  producer_alias?: string;
  role?;
  role_name?: string;
  role_short_description?: string;
  last_activity?: number;
  last_login?: number;
  active?: boolean;
  sms_enabled?: boolean;
  image_url?: string;
  image_id?: number;
  image?: number;
  file?;
  ips?: IP[];
  initials?: string;
  type?: string;
  etim_version?: string;

  public static toDto(user) {
    const ips: Array<number> = user.ips? user.ips.map(ip => typeof ip === 'number' ? ip : ip.id) : [];

    return {
      user: {
        ...user,
        producer: user.producer_id,
        supplier: user.supplier_id,
        file: user.image_id,
        image: user.image_id,
        ips
      }
    };
  }

  public updateProps?(user) {
    Object.assign(this, user);
  }

  constructor(props?) {
    Object.assign(this, props);
    try {
      this.initials = `${props.first_name.charAt(0)}${props.last_name.charAt(0)}`.toUpperCase();
    } catch (e) {}
  }
}

export class UserDomain {

  public hiddenUsers = [190];

  public userTypes = {
    external: {name: 'obsługa informatyczna', icon: 'IT-icon', tooltip: 'obsługa informatyczna'},
    internal: {name: 'brak'}
  }

  public _myself$: ReplaySubject<User>;

  public get(): Observable<User[]> {
    return from(this.api.request(ENDPOINTS.getUsers, 'GET'))
      .pipe(map(({data}) => data.map(u => new User(u))));
  }

  public myself(): Promise<User> {
    if (!this._myself$) {
      this._myself$ = new ReplaySubject<User>();
      this.api.http
        .get<{ user: User }>(this.api.API_URL + ENDPOINTS.account)
        .pipe( catchError( () => { this._myself$ = null; return of(null); } ))
        .pipe( retry(2) )
        .pipe( tap( user => this.setSentryConfigurationForUser(user)) )
        .subscribe(user => {
          this._myself$ ? this._myself$.next(user) : Object.call(null  );
        });
    }

    return new Promise((resolve, reject) => {
      this._myself$.subscribe(user => resolve(user));
    });
  }

  public updateMyself(user: User): Promise<User> {
    const payload = {
      id: user.id,
      email: user.email,
      phone: user.phone,
      first_name: user.first_name,
      last_name: user.last_name,
      etim_version: user.etim_version
    };

    return this.api.request(ENDPOINTS.updateMyAccount, 'POST', { user: payload });
  }

  public getById(userId: number): Observable<User> {
    const obs$ = this.api.http.get<User>(this.api.API_URL + ENDPOINTS.getUser(userId))
      .pipe(map(res => new User(res)))
      .pipe(shareReplay(2));

    if (!this[Symbol.for('__user_cache__')].has(Number(userId))) {
      (this[Symbol.for('__user_cache__')] as Map<number, Observable<User>>).set(Number(userId), obs$);
    }

    return (this[Symbol.for('__user_cache__')] as Map<number, Observable<User>>).get(Number(userId));
  }

  public update(user: User): Observable<User> {
    return from(this.api.request(ENDPOINTS.updateUser(user.id), 'POST', User.toDto(user)).then(res => {
      (this[Symbol.for('__user_cache__')] as Map<number,Observable<User>>).delete(user.id);
      return res;
    }));
  }

  public create(user: User): Observable<User> {
    return from(this.api.request(ENDPOINTS.createUser, 'POST', User.toDto(user))) as Observable<User>;
  }

  public activate(userId: number): Promise<void> {
    return this.api.request(ENDPOINTS.activateUserOfId(userId), 'POST');
  }

  public delete(userId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteUser(userId), 'DELETE');
  }

  public deleteAvatar(userId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteUserAvatar(userId), 'DELETE');
  }

  protected setSentryConfigurationForUser(user): void {
    Sentry.configureScope(scope => {
      scope.setUser({
        id: String(user.id),
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
      });
    });
  }


  constructor(private api: APIService) {

    // Create cache map
    this[Symbol.for('__user_cache__')] = new Map();
  }
}
