import {HttpHeaders} from '@angular/common/http';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {LocalStorageService} from 'ngx-webstorage';
import {APIService} from '../../../app/api/apiservice.service';

export class ContactDomain {

  public send(form): Promise<any> {
    const formData = new FormData();
    const [file] = form.files;
    const localStorage = window['angularInjector'].get(LocalStorageService);

    formData.append('email[subject]', form.subject);
    formData.append('email[content]', form.content);
    if (file) formData.append('email[file]', file, file.name);
    formData.append('email[phone_contact]', form.contactAggreement);
    formData.append('email[referer]', form.referer);

    const header = new HttpHeaders({ Authorization : localStorage.retrieve('APIKEY') });
    return this.api.request(ENDPOINTS.sendContactForm, 'POST', formData, header, true);
  }

  constructor(private api: APIService) {}
}
