import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {PublicNewsletterComponent} from '../component/public-newsletter.component';


const routes: Routes = [
  { path: ':hash', component: PublicNewsletterComponent },
  { path: '', component: PublicNewsletterComponent }
  ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicNewsletterRoutingModule { }
