import {Component, HostBinding, Input, OnInit, ViewContainerRef} from '@angular/core';
import {without} from 'lodash-es';
import {MultiselectBindService} from './multiselect-bind.service';

@Component({
  selector: 'ie-multiselect-option',
  template: `<div
              *ngIf="isVisible()"
              id="multiselect-option"
              tabindex="0"
              class='ie-multiselect-option'
              [attr.disabled]="option.disabled ? true : null"
              (keydown.enter)="click()"
              (click)="click()"
            >
                <ie-checkbox
                        *ngIf="parent.getMultiselect().multiple"
                        style="pointer-events: none"
                        [(ngModel)]="option.selected"
                ></ie-checkbox>
              <ng-content></ng-content>
            </div>`,
  styleUrls: ['./ie-multiselect.component.scss']
})
export class IeMultiselectOptionComponent implements OnInit {

  @HostBinding('attr.data-selected') get selected() { return this.option.selected; }
  @Input() option: { key, value, selected?, disabled?: boolean };

  public isVisible(): boolean {
    return (this.option.value &&
      this.option.value.toString().toLowerCase().indexOf(this.parent.getMultiselect().filter.toLowerCase()) > -1 ||
      this.parent.getMultiselect().filter.length === 0);
  }

  public click(): void {
    if (this.option.disabled) { return; }
    if (!this.parent.getMultiselect().multiple) {
      this.parent.getMultiselect()._onclick.next(this.option.key);
      this.parent.getMultiselect().writeValue(this.option.key);
      this.parent.getMultiselect().changeDet.markForCheck();
      this.parent.getMultiselect().changed.emit(this.parent.getMultiselect()._fieldValue);
      this.parent.getMultiselect().hide();
    } else {
      if (!this.parent.getMultiselect()._fieldValue) { this.parent.getMultiselect()._fieldValue = []; }

      const values = (this.parent.getMultiselect()._fieldValue instanceof Array) ?
        this.parent.getMultiselect()._fieldValue :
        [this.parent.getMultiselect()._fieldValue];
      this.option.selected = !this.option.selected;
      this.parent.getMultiselect().writeValue(this.option.selected ? [...values, this.option.key] : without(values, this.option.key));

      setTimeout(() => {
        this.parent.getMultiselect()._focused = true;
        this.parent.getMultiselect().changed.emit(this.parent.getMultiselect()._fieldValue);
      }, 1);
    }

    this.parent.getMultiselect().changeDet.markForCheck();
    this.parent.getMultiselect().filter = '';
  }

  constructor(
    public viewRef: ViewContainerRef,
    public parent: MultiselectBindService,
  ) {}

  ngOnInit() {}

}
