<div class='ie-multiselect' #multiselectContainer>
  <ng-container *ngIf="!hidePlaceholder">
    <span #placeholderEl class='ie-multiselect-label'>{{label ? label : placeholder}}</span>
  </ng-container>

  <div
      #ieselectlabel
      tabindex="0"
      class='select-input'
      [attr.error]="error ? true : null"
      [attr.selected]="_fieldValue ? (multiple ? _fieldValue.length : _fieldValue) : false"
      [attr.disabled]="disabled ? true : null"
      (keydown.enter)="show()"
  >
    <!-- {{getPlaceholder()}} -->
    <span
      class='placeholder'
      (click)="show()"
    >{{getPlaceholder()}}</span>
    <div class="ie-multiselect-buttons">
      <button
        *ngIf="isResetable()"
        class='icon-button clear-btn'
        ignore-target
        (click)="clear()"
      ><ie-icon [size]="13" [icon]="'clear'"></ie-icon></button>
      <button
        class='icon-button'
        (click)="show()"
      ><ie-icon [icon]="'dropdown_small'" [size]="15"></ie-icon></button>
    </div>
  </div>

  <span
    *ngIf="error"
    class="ie-multiselect-error"
  >{{error}}</span>

  <div #multiselectAnchor></div>
</div>
