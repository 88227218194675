<div
  class="ie-empty-placeholder-container"
  [style.min-height.px]="height"
>
  <img [src]="image" alt="Image icon" />
  <div class="text-button-container">
    <h3 *ngIf="header">{{header}}</h3>
    <p
      *ngIf="placeholder"
      [innerHTML]="placeholder|safecontent"
    ></p>
    <ng-container *ngIf="action">
      <ie-button
        *ngIf="!action?.privilege;else protectedButton"
        [text]="action.label"
        (click)="evaluate()"
      ></ie-button>
      <ng-template #protectedButton>
        <ie-button
          *vaultlocal="action.privilege;context:action.context"
          [text]="action.label"
          (click)="evaluate()"
        ></ie-button>
      </ng-template>
    </ng-container>
  </div>
</div>
