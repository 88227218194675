import {HttpHeaders} from '@angular/common/http';
import {APIService} from '../../../app/api/apiservice.service';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {LocalStorageService} from 'ngx-webstorage';


export class Banner {
  id?: number;
  name?: string;
  image_id?: number;
  image_url?: string;
  tablet_image_id?: number;
  tablet_image_url?: string;
  mobile_image_id?: number;
  mobile_image_url?: string;
  date_from?: number;
  date_to?: number;
  url?: string;
  active?: boolean;
  duration?: number[];
  image?: File;

  public static toDto(obj: Banner): Banner {
    const output = { ...obj, valid_from: undefined, valid_to: undefined };
    if (obj.duration) {
      const [from, to] = obj.duration;
      Object.assign(output, from && { date_from: from });
      Object.assign(output, to && { date_to: to });
    }

    delete output['duration'];

    return output as Banner;
  }

  constructor(props?: any) {
    if (props) {
        Object.assign(this, props);
        this.duration = [props.date_from, props.date_to]
    }
}

}

export class BannerDomain {

  public get(): Promise<Banner[]> {
    return this.api.request(ENDPOINTS.getBannerList, 'GET').then(({data}) => data);
  }

  public getActive(): Promise<Banner[]> {
    return this.api.request(ENDPOINTS.getActiveBannerList, 'GET').then(({data}) => data);
  }

  public getById(id: number): Promise<Banner> {
    return this.api.request(ENDPOINTS.getBanner(id), 'GET');
  }

  public create(file: File, mobileFile: File, banner: Banner): Promise<Banner> {
    const formData = new FormData();
    const obj= Banner.toDto(banner);

    if (file) { formData.append('banner[image][file]', file, file.name); }
    if (mobileFile) { formData.append('banner[mobile_image][file]', mobileFile, mobileFile.name); }

    for (let key in obj) {
      formData.append(`banner[${key}]`, obj[key])
    }
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY')
    });
    const requestUrl = ENDPOINTS.createBanner;
    return this.api.request(requestUrl, 'POST', formData, header, true);
  }

  public update(destktopFile: File, mobileFile: File, banner: Banner) {
    const formData = new FormData();
    const obj= Banner.toDto(banner);

    if (destktopFile) { formData.append('banner[image][file]', destktopFile, destktopFile.name); }
    if (mobileFile) { formData.append('banner[mobile_image][file]', mobileFile, mobileFile.name); }

    for (let key in obj) {
      formData.append(`banner[${key}]`, obj[key]);
    }
    const header = new HttpHeaders({
      Authorization: this.localStorage.retrieve('APIKEY')
    });
    const requestUrl = ENDPOINTS.updateBanner(banner.id);
    return this.api.request(requestUrl, 'POST', formData, header, true);
  }
  public delete(id: number) {
    return this.api.request(ENDPOINTS.deleteBanner(id), 'DELETE' );
  }

  public collectClick(id: number) {
    return this.api.request(ENDPOINTS.bannerClick(id), 'POST');
  }

  constructor(
    private api: APIService,
    private localStorage: LocalStorageService
  ) {}
}
