import {ENDPOINTS} from '../../../configuration/ENDPOINTS';
import {BastPrivilegeMap} from '../../../authorization/model/BastPrivilegeMap';

export enum PRODUCER_ARTICLES_TYPE {
  AGREEMENT = 'agreement',
  AGREEMENT_FILE = 'agreement_file',
  BONUS = 'bonus',
  DISCOUNT = 'discount',
  PROMOTION = 'promotion',
  SALE = 'sale',
};

export const ARTICLE_TYPE_MAP = {
  [PRODUCER_ARTICLES_TYPE.AGREEMENT]: {
    label: 'umowy',
    privilegeUpdate: BastPrivilegeMap.UPDATE_PRODUCER_AGREEMENT_ARTICLE,
    get: ENDPOINTS.getAgreementArticle,
    update: ENDPOINTS.updateAgreementArticle,
  },
  [PRODUCER_ARTICLES_TYPE.BONUS]: {
    label: 'bonusy',
    privilegeUpdate: BastPrivilegeMap.UPDATE_PRODUCER_BONUS_ARTICLE,
    get: ENDPOINTS.getBonusArticle,
    update: ENDPOINTS.updateBonusArticle,
  },
  [PRODUCER_ARTICLES_TYPE.DISCOUNT]: {
    label: 'rabaty',
    privilegeUpdate: BastPrivilegeMap.UPDATE_PRODUCER_DISCOUNT_ARTICLE,
    get: ENDPOINTS.getDiscountArticle,
    update: ENDPOINTS.updateDiscountArticle,
  },
  [PRODUCER_ARTICLES_TYPE.PROMOTION]: {
    label: 'promocje',
    privilegeUpdate: BastPrivilegeMap.UPDATE_PROMOTION,
    get: ENDPOINTS.getPromotionArticle,
    update: ENDPOINTS.updatePromotionArticle
  },
  [PRODUCER_ARTICLES_TYPE.SALE]: {
    label: 'dane ze sprzedaży',
    privilegeUpdate: BastPrivilegeMap.UPDATE_PRODUCER_SALE_ARTICLE,
    get: ENDPOINTS.getSaleArticle,
    update: ENDPOINTS.updateSaleArticle,
  }
};
