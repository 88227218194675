import {omit} from 'lodash-es';

export class DomainComparator<T> {

  private _snapshot: T;
  private _ref: T;

  /**
   * Returns true if underlying domain object had deeply changed
   */
  public hasChanged(): boolean {
    if (Object.keys(this._ref).length < 2) { return true; }
    try {
      return JSON.stringify(this._snapshot) !== JSON.stringify(this._ref);
    } catch (e) {
      return false;
    }
  }

  /**
   * Returns original version of the object before any changes may occurred
   */
  public getSnapshot(): T { return this._snapshot; }

  /**
   * Returns original reference of the object
   */
  public getCurrent(): T { return this._ref; }

  /**
   * Merges original state of the object with passed obj argument
   * @param obj
   * @param omitProps
   */
  public merge(obj, omitProps?: string[]): T {
    const copy = {...obj};
    Object.assign(copy, omit(this._snapshot, omitProps));

    return copy;
  }

  /**
   * Squashes new object into original state
   * @param override
   */
  public squash(override?): void {
    this._snapshot = ((override||this._ref) instanceof Array) ? (override||this._ref).slice(0) as any : {...(override||this._ref)};
    this._ref = override||this._ref;
  }

  /**
   * Resets current state into it's original form
   */
  public reset(): void {
    this.squash(this.getSnapshot());
  }

  constructor(obj: T) {
    this._ref = obj;
    this._snapshot = (obj instanceof Array) ? obj.slice(0) as any : {...obj};
  }
}
