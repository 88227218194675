import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { INofiticationFlag } from '../../model/flag-interface';

@Component({
  selector: 'ie-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss']
})
export class FlagComponent implements OnInit {

  @Input() flagId: number;
  @Input() data: INofiticationFlag;
  @Output() onClose: EventEmitter<number> = new EventEmitter();
  @Output() onAccept: EventEmitter<number> = new EventEmitter();

  public icon: string;
  public state: string;

  public acceptFlag(): void {
    this.state = 'disappear';
    setTimeout(() => this.onAccept.emit(this.flagId), 500);
  }

  public closeFlag(): void {
    this.state = 'disappear';
    setTimeout(() => this.onClose.emit(this.flagId), 500);
  }

  private setIcon(type: string) {
    this.icon = `${type}`;
  }

  private setAutoDismiss() {
    if (this.data.dissmissTime) {
      setTimeout(() => this.closeFlag(), this.data.dissmissTime);
    }
  }

  constructor() {}

  ngOnInit() {
    this.setIcon(this.data.type);
    this.setAutoDismiss();
    setTimeout(() => this.state = 'appear', 300);
  }

}
