import { NgModule } from '@angular/core';
import { BlanketComponent } from '../component/blanket.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {IEButtonModule} from '@material/ie-button/ie-button.module';
import {BlanketContentComponent} from '@shared/token-expiry-blanket/component/blanket-content.component';
import {DynamicAnchorModule} from '@material/ie-dynamic-anchor/ie-dynamic-anchor-module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IEButtonModule,
    DynamicAnchorModule
  ],
  declarations: [
    BlanketComponent,
    BlanketContentComponent
  ],
  exports: [
    BlanketComponent
  ],
  entryComponents: [
    BlanketContentComponent
  ]
})
export class BlanketModule {}
