import {PRODUCER_ARTICLES_TYPE} from '../../../app/core/producers/model/producer-article-type-map';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {URLUtils} from '../../../app/shared/utils/url-utils';
import {APIService} from '../../../app/api/apiservice.service';

export interface ProducerAccessLog {
  user_first_name?: string,
  user_last_name?: string,
  user_supplier_name?: string,
  producer_alias?: string,
  action_type?: string,
  article_type?: string,
  agreement_filename?: string,
  date?: number
}

export interface ProducerAccessLogQueryFilter {
  user?: number;
  user_supplier?: number;
  producer?: number;
  type?: PRODUCER_ARTICLES_TYPE;
}

export class AccessLogDomain {

  public get(filter: ProducerAccessLogQueryFilter): Promise<ProducerAccessLog[]> {
    return this.apiService.request(ENDPOINTS.getProducerArticleAccessLog + URLUtils.buildQueryUrl(filter), 'GET')
      .then(({data}) => data);
  }

  constructor(private apiService: APIService) {}
}
