import {Tag} from '../../../app/ie-material/ie-tag/Tag';
import {STORAGE_KEYS, StorageService} from '../../../app/shared/storage-service/storage.service';
import {Producer} from '../producer';
import uniq from 'lodash-es/uniq';
import uniqBy from 'lodash-es/uniqBy';
import {Material} from '@bast/domain/material';

export interface IProductInterface {
  id?: number;
  producer?: string;
  producer_name?: string;
  producer_id?: number;
  code?: string;
  ean?: string;
  description?: string;
  downloads?: Array<Material>;
  name?: string;
  image?: string;
  image_url?: string;
  image_id?: number;
  mainImage?: any;
  additional_images?: Array<any>;
  min_order_quantity?: number;
  price?: number;
  price_updated_at?: number;
  price_from?: number;
  formated_price?: string;
  created_at?: number;
  updated_at?: number;
  etim_category_name?: string;
  etim_category_code?: string;
  etim_product_name?: string;
  etim_product_code?: string;
  stock?: number;
  recent_names?: string[];
  lowest_price?: any;
  tags?: Array<any>;
  etim_features?: Array<any>;
  supplier_availability?: Array<SupplierAvailability>;
  isAvailable?: boolean;
  unit?: string;
  withdrawn?: boolean;
}

export interface IProductLotInterface {
  id?: number;
  supplier_product_id?: number;
  lot?: string;
  lot_id?: number;
  stock?: number;
  formated_stock?: string;
  infinity_stock?: boolean;
  price?: number;
  formated_price?: number;
  supplier_id?: number;
  supplier_name?: string;
  updated_at?: number;
  up_to_date_margin?: number;
  confirmed_at?: number;
  supplier_last_update?: number;
  sale?: boolean;
  bonus?: string;
  url?: string;
  package_info?: string;
  product_name?: string;
  product_id?: number;
  unit?: number;
  min_order_quantity?: number;
  order_quantity_margin?: number;
  warehouse?: number;
  canOrderProduct?: boolean;
  quantity?: number;
  producer_name?: string;
  error?: string;
  supplier_is_member?: boolean;
  supplier_alias? : string;
  showInTable?: boolean;
}

export class ProductWarehouse {

  warehouse_id: number;
  warehouse_name: string;
  totalStock: number;
  lots: Array<IProductLotInterface>;
  canOrderProduct?: boolean;

  public addLot(lot: IProductLotInterface) {
    const timeDiff = Math.floor((Date.now() / 1000 / 60) - lot.confirmed_at / 60);
    const canOrderProduct = timeDiff < lot.up_to_date_margin;

    this.totalStock += lot.stock;
    this.lots = [...this.lots, { ...lot, canOrderProduct }];
  }

  public getLot(lot_id: number) { return this.lots.find( l => l.lot_id === lot_id); }
  public getLotByName(lot_name: string) { return this.lots.find( l => l.lot === lot_name); }
  public removeLot(lot_id: number) { return this.lots.filter( l => l.lot_id !== lot_id ); }

  constructor(warehouse_id: number, lot?: IProductLotInterface) {
    this.warehouse_id = warehouse_id;
    this.totalStock = 0;
    if (lot) {
      const timeDiff = Math.floor((Date.now() / 1000 / 60 / 60) - lot.confirmed_at / 60 / 60);
      const canOrderProduct = timeDiff <= lot.up_to_date_margin;
      const newLot = { ...lot, canOrderProduct };

      this.totalStock += newLot.stock;
      this.lots = [newLot];
    } else {
      this.lots = [];
    }
  }
}

export class SupplierAvailability {
  warehouses?:  Array<ProductWarehouse> = [];
  supplier_id: number;
  supplier_name: string;
  supplier_alias: string;
  supplier_image: string;
  supplier_last_update: number;
  supplier_is_member: boolean;
  updated_at: number;
  confirmed_at: number;
  product_name: string;
  product_id: number;
  minPrice: number;
  stock: number;
  formated_stock: string;
  totalStock: number;
  isAvailable = false;
  selectedLot: IProductLotInterface;
  error: boolean;
  lots: { [lotKey: string]: IProductLotInterface } = {};

  public addWarehouse(warehouse_id: number) {
    this.warehouses = [...this.warehouses, new ProductWarehouse(warehouse_id) ];
  }

  public addLot(lot: IProductLotInterface) {
    const warehouse = this.warehouses.find( w => w.warehouse_id === lot.warehouse );
    this.lots[lot.id] = { ...lot, product_name: this.product_name, quantity: 1 };

    this.setMinPriceForLot(lot);

    if (lot.stock > 0) { this.isAvailable = true; }
    if (warehouse) {
      warehouse.addLot(lot);
    } else {
      this.warehouses.push(new ProductWarehouse(lot.warehouse, lot));
    }
  }

  public increaseLotQuantity(productLot: IProductLotInterface) {
    const lot = this.lots[productLot.id];
    if (lot && (lot.quantity + 1) > lot.stock && !lot.infinity_stock) { return; }
    if (!lot) {
      const insert = { ...productLot, quantity: 1 };
      Reflect.defineProperty(this.lots, productLot.id, { value: insert, enumerable: true, configurable: true });
      return;
    }

    lot.quantity = this.lots[lot.id].quantity + 1;
    lot.error = null;
  }

  public decreaseLotQuantity(lot: IProductLotInterface) {
    if (!this.lots[lot.id] || this.lots[lot.id].quantity === 0) { return; }

    this.lots[lot.id].quantity = this.lots[lot.id].quantity - 1;
    this.lots[lot.id].error = null;
  }

  public hasWarehouse(warehouse_id) {
    return this.warehouses.find( w => w.warehouse_id === warehouse_id ) ? true : false;
  }

  protected setMinPriceForLot(lot: IProductLotInterface) {
    if (!this.minPrice) {
      this.minPrice = lot.price;
      return;
    }

    if (this.minPrice > lot.price) {
      this.minPrice = lot.price;
    }
  }

  constructor(lot: IProductLotInterface, product_name?: string, product_id?: number) {
    this.addLot(lot);
    this.supplier_id = lot.supplier_id;
    this.supplier_name = lot.supplier_name;
    this.supplier_last_update = lot.supplier_last_update;
    this.updated_at = lot.updated_at;
    this.confirmed_at = lot.confirmed_at;
    this.product_name = product_name;
    this.product_id = product_id;
    this.stock = lot.stock;
    this.formated_stock = lot.formated_stock;
    this.supplier_is_member = lot.supplier_is_member;
    this.supplier_alias = lot.supplier_alias;
  }
}

export class Product implements IProductInterface {

  id?: number;
  stock?: number;
  quantity?: number;
  formated_stock?: string;
  producer?: string;
  brand?: string;
  producer_name?: string;
  producer_alias?: string;
  producer_id?: number;
  code?: string;
  ean?: string;
  description?: string;
  downloads?: Array<Material>;
  name?: string;
  image?: any;
  image_name?: string;
  image_extension?: string;
  image_id?: number;
  image_url?: string;
  mainImage?: number;
  defaultImage?: string;
  additional_images?: Array<any> = [];
  image_thumbnails: Array<any> = [];
  price?: number;
  price_comment?: string;
  price_from?: number;
  price_updated_at?: number;
  formated_price?: string;
  lowest_price?: number;
  created_at?: number;
  updated_at?: number;
  min_order_quantity?: number;
  etim_category_name?: string;
  etim_category_code?: string;
  etim_product_name?: string;
  etim_product_code?: string;
  etim_feature_values_count?: number;
  etim_features_count?: number;
  ecommerce_url?: string;
  recent_names?: string[];
  unit?: string;
  package_quantity?: number;
  withdrawn?: boolean;
  website_name?: string;
  reverse_charge?: any;
  tax?: string;
  waste_management_cost?: any;
  bonus?: boolean;
  bonus_group_name?: string;
  discount_group_name?: string;
  lot?: any;
  supplier_product_id?: number;
  supplier_id?: number;
  product_name?: string;

  // Additional parameters
  tags: Array<Tag> = [];
  etim_features = [];
  supplier_availability: Array<SupplierAvailability> = [];
  _images: Array<any> = [];

  set images(images) {
    this._images = images;
  }

  get images() {
    return this._images;
  }

  public static toDto(p: any): any {
    // Clone existing object
    const output = { ...p };
    try {
      output.additional_images = uniq(
        p.additional_images.map(image => {
          if (image.hasOwnProperty('image_id')) { return image.image_id; }
          return image;
        })
      );
    } catch (e) {}

    output.image = p.image_id;

    if (p.downloads && p.downloads instanceof Array && p.downloads.length > 0) {
      if (typeof p.downloads[0] !== 'number') { output.downloads = p.downloads.map(d => d.id); }
    } else {
      output.downloads = [];
    }

    delete output['supplier_availability'];
    delete output['storage'];
    delete output['image_url'];
    delete output['image_id'];

    return { product: output };
  }

  public addTag(tag: Tag) { this.tags = [...this.tags, tag]; }
  public removeTag(tag: Tag) { this.tags = this.tags.filter( t => t.id !== tag.id ); }

  get isAvailable() { return this.supplier_availability.length > 0 ? true : false; }

  public buildAvailability(availability: any) {
    for (let i = 0; i < availability.length; i++ ) {
      this.appendAvailability(availability[i]);
    }

    this.setLowestPrice();
    this.setAvailability();
    this.mapProducersThumbnails();
  }

  public swapMainImage(additionalImageIndex: number): void {
    if (additionalImageIndex > this.additional_images.length) { return; }
    const prevImage = {
      image_id: this.image_id,
      image_url: this.image_url ? this.image_url.slice() : null,
      image: this.image_url,
      image_name: this.image_name,
      image_extension: this.image_extension,
      image_thumbnails: this.image_thumbnails
    };

    const currentImage = this.additional_images[additionalImageIndex];
    this.image_id = currentImage.image_id;
    this.image_url = currentImage.image_url;
    this.image = currentImage.image_url;
    this.image_name = currentImage.image_name;
    this.image_extension = currentImage.image_extension;
    this.image_thumbnails = currentImage.image_thumbnails

    if (prevImage.image_id) {
      this.additional_images = uniqBy([...this.additional_images, prevImage], 'image_id');
    }
  }

  public deleteMainImage(): void {
    this.image = null;
    this.image_id = null;
    this.image_url = null;
  }

  private appendAvailability(a: IProductLotInterface) {
    if (this.supplier_availability.length === 0) {
      this.supplier_availability.push( new SupplierAvailability(a, this.name) );
    } else {
      const availability = this.supplier_availability.find( av => av.supplier_id ===  a.supplier_id);
      if (availability) {
        availability.addLot(a);
      } else {
        this.supplier_availability.push( new SupplierAvailability(a, this.name, this.id) );
      }
    }
  }

  private setLowestPrice(): void {
    const minPrices = this.supplier_availability.map(sa => sa.minPrice);
    this.lowest_price = Math.min(...minPrices);
  }

  private setAvailability(): void {
    const totalAvailability = this.supplier_availability
      .forEach(availability => {
        const totalStock = availability.warehouses.reduce((acc, warehouse) => {
          return acc + warehouse.totalStock;
        }, 0);
        availability.totalStock = totalStock;
      });
  }

  private mapProducersThumbnails(): void {
    if (!this.storage) { return; }
    this.supplier_availability.map(availability => {
      const { supplier_id } = availability;
      this.storage.getObjectByProperty(STORAGE_KEYS.SUPPLIERS, 'id', supplier_id)
        .subscribe(({ image_url }) => {
          availability.supplier_image = image_url||'./assets/illustrations/supplier_icon.svg';
        } );
    });
  }

  private setProducer(): void {
    try {
      this.storage.getObjectByPredicat(STORAGE_KEYS.PRODUCERS, (producer) => producer.name === this.producer_name)
        .subscribe((producer: Producer) => this.producer_id = producer ? producer.id : null);
    } catch (e) {}
  }

  constructor(params: IProductInterface, private storage?: StorageService) {
    Object.assign(this, params);
    this.setProducer();
  }
}
