import { Component, Output, EventEmitter } from '@angular/core';
import {AuthenticationService} from '../../../authentication/service/authentication.service';

@Component({
  selector: 'ie-blanket-content',
  template: `
    <div class='blanket'>
        <img src="./assets/illustrations/token_expired.svg" alt="token expired"/>
        <div class='text-content'>
            <h2>koniec sesji</h2>
            <span>przykro nam Twój klucz stracił ważność,<br/>aby zalogować się ponownie kliknij w button.</span>
            <ie-button
                (click)="gotoLoginPage()"
                [text]="'przejdź do logowania'"
            ></ie-button>
        </div>
    </div>
  `,
  styleUrls: ['../component/blanket.component.scss']
})
export class BlanketContentComponent {

  @Output() close: EventEmitter<void> = new EventEmitter();

  public gotoLoginPage(): void {
    this.close.emit();
    this.authorizationService.logout();
  }

  constructor(
    private authorizationService: AuthenticationService
  ) {}
}
