import {from, Observable} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {map} from 'rxjs/operators';
import {URLUtils} from '../../../app/shared/utils/url-utils';
import {NonBlockingQueue} from '../../../app/shared/utils/non-blocking-queue';
import {ProducerIntegrationTask} from '../../../app/core/producers/model/producer-integration-task';
import {APIService} from '../../../app/api/apiservice.service';

export enum PRICE_LIST_TYPE {
  FAILED = 'failed',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  CONFIRMED = 'confirmed',
  INPROGRESS = 'in_progress',
  WAITING = 'waiting',
  COMPLETED_WITH_ERRORS = 'completed_with_errors' 
};

export interface PriceList {
  id?: number;
  producer_id?: number;
  producer_name?: number;
  producer_integration_task_id?: number;
  producer_manual_import_task_id?: number;
  name?: string;
  type?: string;
  extension?: string;
  size?: number;
  url?: string;
  execute_at?: number;
  finished_at?: number
  from_integration?: boolean;
  status?: string;
  report_url?: string;
  current?: boolean;
  material_id?: number;
};

export class PriceListDomain {

  /**
   * Returns list of price list for every producer. Note that it's only accessible for
   * API user with ROLE_ROOT privilege
   * @param dateFrom Unix timestamp from which price list is valid
   * @param dateTo Unix timestamp by which price list is valid
   * @param status Price list status
   * @returns Promise<PriceList[]>
   */
  public get(status?: string, producer?: number, supplier?: number, dateFrom?: number, dateTo?: number): Promise<PriceList[]> {
    const filters = {
      status,
      producer,
      supplier,
      date_from: dateFrom,
      date_to: dateTo,
      all: 1
    };

    return this.api.request(ENDPOINTS.getPriceList + URLUtils.buildQueryUrl(filters), 'GET')
      .then(({data}) => data.map(pl => ({...pl, integration: pl.producer_manual_import_task_id ? 'manualna' : (pl.producer_integration_task_id ? 'automatyczna' : 'brak') }) ));
  }

  /**
   * Returns list of price list for producer
   * @param producerId Producer id
   * @returns Observable<PriceList[]>
   */
  public getByProducerId(producerId?: number, showAll?: boolean): Observable<PriceList[]> {
    return from(this.api.request(ENDPOINTS.getProducerPriceList(producerId) + `?all=${showAll}`, 'GET'))
      .pipe(map(({data}) => data))
      .pipe(map(pl => pl.map(p => ({...p, integration: p.producer_manual_import_task_id ? 'manualna' : (p.producer_integration_task_id ? 'automatyczna' : 'brak') }) )));
  }

  /**
   * Returns events list for imported price list
   * @param priceListId Identifier of price list object
   * @returns Observable<any[]>
   */
  public getPriceListStatus(priceListId: number): Observable<any[]> {
    return from(this.api.request<{ data: Array<any> }>(
      ENDPOINTS.getTaskEventsForPriceList(priceListId),
      'GET'
    ))
      .pipe(map(({data}) => data));
  }

  /**
   * Creates price list from selected material
   * @param producerId Producer id
   * @param materialId Material id
   * @param validFrom Unix timestamp from which price list is valid
   * @param brandId Producer brand id
   * @returns Promise<PriceList>
   */
  public create(
    producerId: number,
    materialId: number,
    validFrom: number,
    brandId: number
  ): Observable<any> {
    const price_list = {
      producer: producerId,
      material: materialId,
      valid_from: validFrom,
      brand: brandId
    };

    return from(this.api.request(ENDPOINTS.createPriceList, 'POST', { price_list }));
  }

  /**
   * Updates price list object
   * @param priceListId Producer price list id
   * @param producerId Producer id
   * @param materialId Material id with which price list is updated
   * @param validFrom Unix timestamp that defines start of price list validity
   */
  public update(
    priceListId: number,
    producerId: number,
    materialId: number,
    validFrom: number
  ): Observable<any> {
    const price_list = {
      producer: producerId,
      material: materialId,
      valid_from: validFrom
    };

    return from(this.api.request(ENDPOINTS.updatePriceList(priceListId), 'POST', { price_list }));
  }

  public makeCurrent(priceListId: number, current: boolean): Observable<any> {
    const price_list = {
      current: current
    };

    return from(this.api.request(ENDPOINTS.updatePriceList(priceListId), 'POST', { price_list }));
  }

  /**
   * Removes existing price list
   * @param priceListId Price list id
   */
  public delete(priceListId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deletePriceList(priceListId), 'DELETE');
  }

  /**
   * Deletes multiple price lists. Deletion is guaranteed for passed arguments.
   * If one request fails queue continues to work.
   * @param priceLists Array of PriceList objects
   * @returns Promise with array of deleted price lists ids. Note that price list that failed to be deleted
   * will not be included within that array.
   */
  public deleteBulk(priceLists: PriceList[]): Promise<number[]> {
    return new Promise((resolve) => {
      new NonBlockingQueue(priceLists)
        .pop((priceList, queueResolve, queueReject) => {
          this.delete(priceList.id).then(() => queueResolve(priceList.id)).catch(() => queueReject(priceList));
        }, (ids) => resolve(ids));
    });
  }

  /**
   * Creates manual integration for price list
   * @param priceListId
   * @param executeAt Unix timestamp that defines when to start processing
   */
  public createIntegrationForPriceList(priceListId: number, executeAt: number): Observable<any> {
    const payload = {
      producer_manual_import_task: {
        price_list: priceListId,
        task: { execute_at: executeAt }
      }
    };
    return from(this.api.request(ENDPOINTS.createManualImportTask(priceListId), 'POST', payload));
  }

  /**
   * Sets status for integration both manual or automatic on selected price list
   * @param priceList Producer price list object
   * @returns Observable<any>
   */
  public setTaskStatus(priceList: PriceList, status: PRICE_LIST_TYPE): Promise<ProducerIntegrationTask> {
    const confirmManualIntegration = (taskId: number) => {
      const body = {
        producer_manual_import_task: {
          task: {
            status,
          }
        }
      };
      return this.api.request(ENDPOINTS.updateProducerManualTask(taskId), 'POST', body);
    };

    const confirmIntegration = (taskId: number) => {
      const body = {
        producer_integration_task: {
          task: {
            status
          }
        }
      };
      return this.api.request(ENDPOINTS.updateProducerTaskIntegration(taskId), 'POST', body);
    };

    return priceList.producer_integration_task_id ?
      confirmIntegration(priceList.producer_integration_task_id) :
      confirmManualIntegration(priceList.producer_manual_import_task_id);
  }

  /**
   * Sets task status to 'confirmed' on selected price list
   * @param priceList Producer price list
   */
  public confirm(priceList: PriceList): Promise<ProducerIntegrationTask> {
    return this.setTaskStatus(priceList, PRICE_LIST_TYPE.CONFIRMED);
  }

  /**
   * Sets task status to 'failed' on selected price list
   * @param priceList Producer price list
   */
  public suspendImport(priceList: PriceList): Promise<ProducerIntegrationTask> {
    return this.setTaskStatus(priceList, PRICE_LIST_TYPE.FAILED);
  }

  constructor(
    private api: APIService,
  ) {}

}
