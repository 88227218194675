import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEIconButtonComponent } from './ie-icon-button.component';
import { IEIconModule } from '../ie-icon/ie-icon.module';

@NgModule({
  imports: [
    CommonModule,
    IEIconModule,
  ],
  declarations: [IEIconButtonComponent],
  exports: [IEIconButtonComponent]
})
export class IEIconButtonModule { }
