import {ComponentFactoryResolver, ComponentRef, Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {IESpinnerComponent} from '@material/ie-spinner/ie-spinner.component';

/**
 * Directive used to inject loader container anywhere loading state must be rendered
 */
@Directive({
  selector: '[loaderTemplate]'
})
export class LoaderTemplateDirective {

  @Input() set loaderTemplate(pending: boolean) {
    this.viewContainerRef.clear();
    if (!pending) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      const factory = this.factoryResolver.resolveComponentFactory(IESpinnerComponent);
      const ref: ComponentRef<IESpinnerComponent> = this.viewContainerRef.createComponent(factory);
      ref.instance.size = .6;
      ref.location.nativeElement.style.display = 'flex';
      ref.location.nativeElement.style.justifyContent = 'center';
      ref.location.nativeElement.style.alignItems = 'center';
      ref.location.nativeElement.style.width = '100%';
      ref.location.nativeElement.style.height = '300px';
    }
  }

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }

}
