import {
  Component,
  OnInit,
  OnDestroy,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  ViewContainerRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import {BlanketContentComponent} from '@shared/token-expiry-blanket/component/blanket-content.component';
import {FlagService} from '@material/ie-flag-notification/service/flag-service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'ie-blanket',
  template: `
      <div
        *ngIf="blanketComponentRef"
        class='blanket-overlay'
      ></div>

  `,
  styleUrls: ['./blanket.component.scss']
})
export class BlanketComponent implements OnInit, OnDestroy {

  public  blanketComponentRef: ComponentRef<BlanketContentComponent>;
  private _blanketSubscription: Subscription;

  private mountBlanket(): void {
    if (this.blanketComponentRef) { return; }

    const factory = this.componentFactoryResolver.resolveComponentFactory(BlanketContentComponent);
    const ref = this.viewContainerRef.createComponent(factory);
    this.document.querySelector('body').appendChild(ref.location.nativeElement);
    this.blanketComponentRef = ref;
    ref.instance.close.subscribe(() => {
      this.clearBlanket();
    })
  }

  private clearBlanket(): void {
    this.blanketComponentRef.destroy();
    this.blanketComponentRef = null;
  }

  private subscribeToBlanketEvents(): void {
    this._blanketSubscription = this.flagService.getBlanket()
      .subscribe((res) => {
        if (res) this.mountBlanket();
        else this.clearBlanket();
      });
  }

  constructor(
    @Inject(DOCUMENT) public document,
    private flagService: FlagService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.subscribeToBlanketEvents();
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this._blanketSubscription) { this._blanketSubscription.unsubscribe(); }
  }

}
