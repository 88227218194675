import {fromEvent, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';

/**
 * Base utility class used in all components that reacts on Escape or Enter keyboard key action
 */
export class KeyboardAware {

  private _subscriptions: Subscription = new Subscription();

  public subscribeToKeyboardEvents(): void {
    // Escape
    this._subscriptions.add(fromEvent(window, 'keydown').pipe(map(e => e['keyCode'])).pipe(filter(_ => _ === 27)).subscribe(() => this.onEscape()));
    // Enter
    this._subscriptions.add(fromEvent(window, 'keydown').pipe(map(e => e['keyCode'])).pipe(filter(_ => _ === 13)).subscribe(() => this.onEnter()));
  }

  public onEscape(): void {}
  public onEnter(): void {}

  public unsubscribe(): void {
    this._subscriptions.unsubscribe();
  }

  constructor() {
    this.subscribeToKeyboardEvents();
  }
}
