import {fromEvent, Subscription} from 'rxjs';
import {uniq} from 'lodash';

export class BulkList<T> extends Array<T> {

  private _keyTrackSubscription: Subscription = new Subscription();
  private _selectionStarted: number;
  private _multiSelection: boolean;
  private _dirty: boolean;

  get length(): number {
    return this.length;
  }

  get selectedAll(): boolean {
    if (!this.length) { return false; }
    return this.every(e => e && e['selected'] === true);
  }

  set selectedAll(selected: boolean) {
    if (selected) { this.selectAll(); } else { this.deselectAll(); }
  }

  public select(value, predicat?: (arg) => boolean) {
    this.forEach(e => {
      if (e && predicat(e)) { e['selected'] = value; }
    });
  }

  public pop(): T {
    return this.pop();
  }

  public indexOf(el: T): number | null {
    return this.findIndex(e => e === el);
  }

  public get(index: number): T {
    return this[index];
  }

  public getById(id, property?): T {
    return this.find(e => e[property || 'id'] === id);
  }

  public getOrAllSelected(id, property?): T[] {
    if (!id) { return this.getAllSelected().toArray(); }  else { return [this.getById(id, property)]; }
  }

  public getAllSelected(): BulkList<T> {
    return this.filter(e => e['selected'] === true);
  }

  public getUniq(el: T): BulkList<T> {
    return new BulkList<T>(uniq([...this.getAllSelected().toArray(), el]));
  }

  public selectedCount(): number {
    return this.getAllSelected().length;
  }

  public selectAll(): void {
    this.forEach(e => { if (e) { e['selected'] = true; } });
  }

  public deselectAll(): void {
    this.forEach(e => { if (e) { e['selected'] = false; } });
  }

  public someSelected(): boolean {
    return this.some(e => e && e['selected'] === true);
  }

  public remove(id, property?): BulkList<T> {
    return this.filter(e => e[property || 'id'] !== id);
  }

  public removeBulk<C>(ids: C[], property?): BulkList<T> {
    return this.filter(e => !ids.includes(e[property || 'id']));
  }

  public removeSelected(): BulkList<T> {
    return this.filter(e => !e['selected']);
  }

  public append(collection = []): BulkList<T> {
    return this.concat(collection);
  }

  public add(index, collection = []): BulkList<T> {
    const copy = [...this];
    const input = collection instanceof Array ? collection : [collection];
    copy.splice(index, 0, ...input);
    return new BulkList<T>(copy);
  }

  public toArray(): Array<T> {
    return [...this];
  }

  public move(from, to) {
    if ( to === from ) { return this; }

    const target = this[from];
    const increment = to < from ? -1 : 1;

    for (let k = from; k !== to; k += increment) {
      this[k] = this[k + increment];
    }
    this[to] = target;

    return new BulkList<T>([...this]);
  }

  public finally(callback: (bulkList: BulkList<T>) => void): void { callback(this); }

  /**
   * Override array prototype methods where new Array() is used by internal implementation
   * due to 'found non interable @@iterator' bug
   */
  // @ts-ignore
  map(callback: any): BulkList<T> {
    return new BulkList<T>([...this].map(callback));
  }
  // @ts-ignore
  filter(callback: any): BulkList<T> {
    return new BulkList<T>([...this].filter(callback));
  }
  // @ts-ignore
  slice(start?: number, end?: number): BulkList<T> {
    return new BulkList<T>([...this].slice(start, end));
  }
  // @ts-ignore
  concat(...items): BulkList<T> {
    return new BulkList<T>([...this].concat(...items));
  }
  // @ts-ignore
  reduce(callbackfn: (previousValue: T, currentValue: T, currentIndex: number, array: T[]) => T): BulkList<T> {
    return new BulkList<T>([...this].reduce(callbackfn) as any);
  }
  // @ts-ignore
  splice(start: number, deleteCount?: number, ...items): BulkList<T> {
    Array.prototype.splice.call(this, start, deleteCount, items);
  }

  protected observeChange(value, index) {
    if (this._dirty) { return; }
    document.getSelection().removeAllRanges();
    if (this._multiSelection) { this.selectBetween(this._selectionStarted, index); }
    this._keyTrackSubscription.add(fromEvent(window, 'keydown').subscribe((e) => e['keyCode'] === 16 ? this._multiSelection = true : Object.call(null) ));
    this._keyTrackSubscription.add(fromEvent(window, 'keyup').subscribe((e) => e['keyCode'] === 16 ? this._multiSelection = false : Object.call(null) ));
    this._selectionStarted = index;
  }

  protected unsubscribeFromEvent(): void {
    if (this._keyTrackSubscription) { this._keyTrackSubscription.unsubscribe(); }
  }

  protected selectBetween(from, to): void {
    this._dirty = true;
    this.forEach((el, index) => {
      if (index >= from && index <= to) { el['selected'] = true; }
    });
    this._dirty = false;
  }

  constructor(items?: T[]) {
    super(...(items || []));
    Object.setPrototypeOf(this, Object.create(BulkList.prototype));

    // Observe collection elements for change on property 'selected'
    const that = this;
    this.forEach((el, i) => {
      let val = el && el['selected'];
      Object.defineProperty(el, 'selected', {
        set(v: any): void {
          val = v;
          that.observeChange(v, i);
        },
        get() {
          return val;
        },
        enumerable: true,
        configurable: true
      });
    });
  }
}
