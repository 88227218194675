import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

export class LoaderState {
  loading: boolean;
}

@Injectable()
export class IELoaderService {

  private loaderSubject = new Subject<LoaderState>();

  public loaderState = this.loaderSubject.asObservable();

  getLoaderState(): Observable<LoaderState> {
    return this.loaderSubject.asObservable();
  }

  loading() { this.loaderSubject.next(<LoaderState>{ loading: true }); }

  finishLoading() { this.loaderSubject.next(<LoaderState>{ loading: false }); }

  constructor() {}

}
