import { BrowserModule, HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { RootModule } from './root/root.module';
import { RootComponent } from './root/root.component';
import { RoutingModule } from './root/routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APIModule } from './api/api.module';
import { AuthenticationGuard } from './authentication/service/authentication.guard';
import { AuthenticationService } from './authentication/service/authentication.service';
import { HttpBackendInterceptor } from './api/http.interceptor';
import { RespondService } from './shared/utils/respond.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StorageService } from './shared/storage-service/storage.service';
import { OrdersServiceModule } from './core/orders/module/orders.module';
import { FlagService } from './ie-material/ie-flag-notification/service/flag-service';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { UiPersonalizationService } from './shared/ui-personalization/service/ui-personalization.service';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { IEDownloadsDialogModule } from './ie-material/ie-downloads-dialog/ie-downloads-dialog.module';
import { Bast } from '@bast';

export class CustomHammerConfig extends HammerGestureConfig  {
  overrides = <any>{
      'pinch': { enable: false },
      'rotate': { enable: false }
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    APIModule,
    RoutingModule,
    OrdersServiceModule.forRoot(),
    ScrollToModule.forRoot(),
    RootModule,
    NgxWebstorageModule.forRoot(),
    IEDownloadsDialogModule.forRoot(),
    HammerModule,
  ],
  providers: [
    AuthenticationGuard,
    AuthenticationService,
    StorageService,
    RespondService,
    FlagService,
    UiPersonalizationService,
    Bast,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpBackendInterceptor,
      multi: true,
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: CustomHammerConfig
    }
  ],
  bootstrap: [RootComponent],
})
export class AppModule {
  constructor() {}
}
