import {Component, Host, HostBinding, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {KeyboardAware} from '@shared/keyboard-aware/keyboard-aware';

@Component({
  selector: 'ie-warn-dialog',
  templateUrl: './ie-warn-dialog.component.html',
  styleUrls: ['./ie-warn-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IEWarnDialogComponent extends KeyboardAware implements OnInit, OnDestroy {

  @HostBinding('class.--display-block') clazz = true;

  public type: string;
  public header: string;
  public message: string;
  public acceptButton: string;
  public cancelButton: string;
  public imgUrl: string;
  public hideAcceptButton: boolean = false;
  public pending: boolean;
  private _opened: boolean;
  private _onAccept$: Subject<void> = new Subject();
  private _onDismiss$: Subject<void> = new Subject();

  public open(type?: 'warn' | 'message' | 'announcement', header?: string, message?: string, acceptButtonLabel?: string, cancelButtonLabel?: string, imgUrl?: string, hideAcceptButton?: boolean): IEWarnDialogComponent {
    this.type = type;
    this.header = header;
    this.message = message;
    this.acceptButton = acceptButtonLabel;
    this.cancelButton = cancelButtonLabel;
    this.imgUrl = imgUrl;
    this.hideAcceptButton = hideAcceptButton;
    this._opened = true;
    this._onAccept$ = new Subject();
    this._onDismiss$ = new Subject();
    return this;
  }

  public close(): void {
    this._opened = false;
    this.pending = false;
    this._onDismiss$.unsubscribe();
    this._onAccept$.unsubscribe();
  }

  public accept(): void { this._onAccept$.next(); }

  public dismiss(): void {
    this._onDismiss$.next();
    this._opened = false;
  }

  public isOpened(): boolean {
    return this._opened;
  }

  public onAccept(): Observable<void> {
    return this._onAccept$.asObservable().pipe(take(1));
  }

  public onDismiss(): Observable<void> {
    return this._onDismiss$.asObservable().pipe(take(1));
  }

  public onEscape(): void {
    this.dismiss();
  }

  public setPending(pending?: boolean): IEWarnDialogComponent {
    this.pending = pending;
    return this;
  }

  constructor() {
    super();
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }

}
