import {Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'ie-checkbox',
  template: `
    <div
      [attr.disabled]="disabled?true:null"
      class="ie-checkbox-wrapper"
    >
      <label
        class='ie-checkbox-container'
        [attr.disabled]="disabled ? true : null"
      >
        <input
          type='checkbox'
          name="role"
          [attr.disabled]="disabled ? true : null"
          [checked]="checked"
          (change)="changed()"
        />
        <span class='checkbox'></span>
      </label>
      <div class="ie-checkbox-content" (click)="changed()"><ng-content></ng-content></div>
    </div>
  `,
  styleUrls: ['./ie-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IECheckboxComponent),
      multi: true
    }
  ],
  encapsulation: ViewEncapsulation.None
})
export class IECheckboxComponent implements OnInit, ControlValueAccessor {

  @Input()
    get value() { return this._checked; }
    set value(value: boolean) { this.checked = value; }
  @Input() disabled: boolean;
  @Input()
    get checked(): boolean { return this._checked; }
    set checked(value: boolean) {
      // tslint:disable-next-line:triple-equals
      if (value != this.checked) {
        this._checked = value;
      }
    }
  @Output() onchange: EventEmitter<any> = new EventEmitter();

  private _checked: boolean;
  private onChangeCallback: (_: any) => void = () => {};
  private onTouchCallback: () => void = () => {};

  public changed(): void {
    this._checked = !this._checked;
    this.onChangeCallback(this.checked);
    this.onchange.emit(this.checked);
  }

  public writeValue(value: any): void {
    this._checked = !!value;
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  constructor() {}

  ngOnInit() {}
}
