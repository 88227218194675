import { Component, OnInit, OnDestroy } from '@angular/core';
import {Subscription, fromEvent} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'ie-scroll-to-top',
  templateUrl: './ie-scroll-to-top.component.html',
  styleUrls: ['./ie-scroll-to-top.component.scss']
})
export class IEScrollToTopComponent implements OnInit, OnDestroy {

  public isVisible: boolean;
  private _scrollSubscription: Subscription;

  public scrollToTop(): void {
    if (window.innerWidth <= 1024) {
      window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }
  }

  constructor() {
    this._scrollSubscription = fromEvent(window, 'scroll')
      .pipe(debounceTime(200))
      .subscribe(e => {
        this.isVisible = window.scrollY > 200;
      });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this._scrollSubscription) { this._scrollSubscription.unsubscribe(); }
  }

}
