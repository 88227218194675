// @ts-ignore
export const BastPrivilegeMap = {
    // Account
    UPDATE_ACCOUNT_IMAGE: 'ROLE_ACCOUNT_IMAGE_UPDATE',
    DELETE_ACCOUNT_IMAGE: 'ROLE_ACCOUNT_IMAGE_DELETE',
    // Integration
    HANDLE_INTEGRATION: [
        'ROLE_PRODUCER_INTEGRATION_TASK_UPDATE',
        'ROLE_PRODUCER_INTEGRATION_TASK_UPDATE_SELF',
        'ROLE_PRODUCER_MANUAL_IMPORT_TASK_UPDATE',
        'ROLE_PRODUCER_MANUAL_IMPORT_TASK_UPDATE_SELF'
    ],
    // Orders
    GET_ORDER_LIST: 'ROLE_ORDER_INDEX',
    GET_ORDER: 'ROLE_ORDER_SHOW',
    SEND_ORDER: 'ROLE_ORDER_SEND',
    CREATE_ORDER: 'ROLE_ORDER_CREATE',
    DELETE_ORDER: 'ROLE_ORDER_DELETE',
    GET_ORDER_PRODUCT_LIST: 'ROLE_ORDER_PRODUCT_INDEX',
    CREATE_ORDER_PRODUCT: 'ROLE_ORDER_PRODUCT_CREATE',
    DELETE_ORDER_PRODUCT: 'ROLE_ORDER_PRODUCT_DELETE',
    UPDATE_ORDER_PRODUCT: 'ROLE_ORDER_PRODUCT_UPDATE',
    GET_SUPPLIER_ORDER_LIST: 'ROLE_SUPPLIER_ORDER_INDEX',
    GET_SUPPLIER_ORDER: 'ROLE_SUPPLIER_ORDER_SHOW',
    UPDATE_ORDER:  'ROLE_ORDER_UPDATE',
    // Products
    GET_PRODUCT: ['ROLE_PRODUCT_SHOW', 'ROLE_PRODUCT_SHOW_SELF'],
    UPDATE_PRODUCT: ['ROLE_PRODUCT_UPDATE', 'ROLE_PRODUCT_UPDATE_SELF'],
    GET_PRODUCT_STATS: ['ROLE_STATS_SALES_SHOW_PRODUCTS_ALL', 'ROLE_STATS_SALES_SHOW_PRODUCTS'],
    GET_PRODUCT_AVAILABILITY: 'ROLE_PRODUCT_SUPPLIER_PRODUCT_INDEX',
    GET_PRODUCT_ETIM_FEATURES: ['ROLE_PRODUCT_ETIM_FEATURE_INDEX'],
    GET_PRODUCT_DOWNLOADABLE_FILES: ['ROLE_PRODUCT_DOWNLOAD_INDEX', 'ROLE_PRODUCT_DOWNLOAD_INDEX_SELF'],
    GET_PRODUCT_CHANGELOG: ['ROLE_PRODUCT_CHANGELOG_INDEX'],
    DELETE_PRODUCT_IMAGE: ['ROLE_PRODUCT_IMAGE_DELETE', 'ROLE_PRODUCT_IMAGE_DELETE_SELF'],
    // Suppliers
    GET_SUPPLIERS_LIST: 'ROLE_SUPPLIER_INDEX',
    UPDATE_SUPPLIER: ['ROLE_SUPPLIER_UPDATE', 'ROLE_SUPPLIER_UPDATE_SELF'],
    GET_SUPPLIER: ['ROLE_SUPPLIER_SHOW', 'ROLE_SUPPLIER_SHOW_SELF'],
    CREATE_SUPPLIER: ['ROLE_SUPPLIER_CREATE'],
    DELETE_SUPPLIER: ['ROLE_SUPPLIER_DELETE'],
    UPDATE_SUPPLIER_IMAGE: ['ROLE_SUPPLIER_IMAGE_UPDATE', 'ROLE_SUPPLIER_IMAGE_UPDATE_SELF'],
    GET_SUPPLIER_STOCK_LIST: ['ROLE_SUPPLIER_STOCK_LIST_INDEX', 'ROLE_SUPPLIER_STOCK_LIST_INDEX_SELF'],
    DELETE_SUPPLIER_IMAGE: ['ROLE_SUPPLIER_IMAGE_DELETE', 'ROLE_SUPPLIER_IMAGE_DELETE_SELF'],
    // Supplier contact
    CREATE_SUPPLIER_CONTACT: ['ROLE_SUPPLIER_CONTACT_CREATE', 'ROLE_SUPPLIER_CONTACT_CREATE_SELF'],
    UPDATE_SUPPLIER_CONTACT: ['ROLE_SUPPLIER_CONTACT_UPDATE', 'ROLE_SUPPLIER_CONTACT_UPDATE_SELF'],
    DELETE_SUPPLIER_CONTACT: ['ROLE_SUPPLIER_CONTACT_DELETE', 'ROLE_SUPPLIER_CONTACT_DELETE_SELF'],
    GET_SUPPLIER_CONTACTS: ['ROLE_SUPPLIER_CONTACT_INDEX'],
    // Producers
    GET_PRODUCERS_LIST: ['ROLE_PRODUCER_INDEX'],
    GET_PRODUCER: ['ROLE_PRODUCER_SHOW', 'ROLE_PRODUCER_SHOW_SELF'],
    CREATE_PRODUCER: 'ROLE_PRODUCER_CREATE',
    UPDATE_PRODUCER: ['ROLE_PRODUCER_UPDATE', 'ROLE_PRODUCER_UPDATE_SELF'],
    DELETE_PRODUCER: 'ROLE_PRODUCER_DELETE',
    UPDATE_PRODUCER_IMAGE: ['ROLE_PRODUCER_IMAGE_UPDATE', 'ROLE_PRODUCER_IMAGE_UPDATE_SELF'],
    DELETE_PRODUCER_IMAGE: ['ROLE_PRODUCER_IMAGE_DELETE', 'ROLE_PRODUCER_IMAGE_DELETE_SELF'],
    // Producer contacts
    CREATE_PRODUCER_CONTACT: ['ROLE_PRODUCER_CONTACT_CREATE', 'ROLE_PRODUCER_CONTACT_CREATE_SELF'],
    UPDATE_PRODUCER_CONTACT: ['ROLE_PRODUCER_CONTACT_UPDATE', 'ROLE_PRODUCER_CONTACT_UPDATE_SELF'],
    DELETE_PRODUCER_CONTACT: ['ROLE_PRODUCER_CONTACT_DELETE', 'ROLE_PRODUCER_CONTACT_DELETE_SELF'],
    GET_PRODUCER_CONTACTS: ['ROLE_PRODUCER_CONTACT_INDEX'],
    GET_PRODUCER_VCARD: ['ROLE_PRODUCER_CONTACT_VCARD'],
    // Producer series
    CREATE_SERIES: ['ROLE_PRODUCER_SERIE_CREATE', 'ROLE_PRODUCER_SERIE_CREATE_SELF'],
    UPDATE_SERIE: ['ROLE_PRODUCER_SERIE_UPDATE', 'ROLE_PRODUCER_SERIE_UPDATE_SELF'],
    GET_SERIES_LIST: ['ROLE_PRODUCER_SERIE_INDEX', 'ROLE_PRODUCER_SERIE_INDEX_SELF'],
    GET_SERIE: ['ROLE_PRODUCER_SERIE_SHOW', 'ROLE_PRODUCER_SERIE_SHOW_SELF'],
    DELETE_SERIE: ['ROLE_PRODUCER_SERIE_DELETE', 'ROLE_PRODUCER_SERIE_DELETE_SELF'],
    // Producer access log
    GET_PRODUCER_ARTICLE_ACCESS_LOG: 'ROLE_PRODUCER_ARTICLE_LOG_INDEX',
    // Tags
    GET_TAG_LIST: 'ROLE_TAG_INDEX',
    CREATE_TAG: 'ROLE_TAG_CREATE',
    DELETE_TAG: 'ROLE_TAG_REMOVE',
    ASSIGN_TAG: ['ROLE_TAG_ASSIGN'],
    GET_PRODUCER_TAGS: 'ROLE_TAG_PRODUCER_INDEX',
    GET_PRODUCT_TAGS: 'ROLE_TAG_PRODUCT_INDEX',
    GET_SUPPLIER_TAGS: 'ROLE_TAG_SUPPLIER_INDEX',
    // Locations
    CREATE_LOCATION_CONTACT: 'ROLE_LOCATION_CONTACT_CREATE',
    DELETE_LOCATION_CONTACT: 'ROLE_SUPPLIER_LOCATION_CONTACT_DELETE',
    GET_LOCATION_CONTACTS: 'ROLE_LOCATION_CONTACT_INDEX',
    UPDATE_LOCATION_CONTACT: 'ROLE_SUPPLIER_LOCATION_CONTACT_UPDATE',
    GET_SUPPLIER_LOCATIONS_LIST: 'ROLE_LOCATION_INDEX',
    GET_SUPPLIER_LOCATION: 'ROLE_LOCATION_SHOW',
    UPDATE_SUPPLIER_LOCATION: ['ROLE_LOCATION_UPDATE', 'ROLE_LOCATION_UPDATE_SELF'],
    CREATE_SUPPLIER_LOCATION: ['ROLE_LOCATION_CREATE', 'ROLE_LOCATION_CREATE_SELF'],
    DELETE_SUPPLIER_LOCATION: ['ROLE_LOCATION_DELETE', 'ROLE_LOCATION_DELETE_SELF'],
    UPDATE_WAREHOUSE: ['ROLE_SUPPLIER_LOCATION_WAREHOUSE_UPDATE', 'ROLE_SUPPLIER_LOCATION_WAREHOUSE_UPDATE_SELF'],
    DELETE_WAREHOUSE: ['ROLE_SUPPLIER_LOCATION_WAREHOUSE_DELETE', 'ROLE_SUPPLIER_LOCATION_WAREHOUSE_DELETE_SELF'],
    CREATE_WAREHOUSE: ['ROLE_SUPPLIER_LOCATION_WAREHOUSE_CREATE', 'ROLE_SUPPLIER_LOCATION_WAREHOUSE_CREATE_SELF'],
    GET_SUPPLIER_WAREHOUSES_LIST: 'ROLE_SUPPLIER_WAREHOUSE_INDEX',
    GET_SUPPLIER_LOCATION_WAREHOUSES_LIST: 'ROLE_SUPPLIER_LOCATION_WAREHOUSE_INDEX',
    GET_SUPPLIER_LOCATION_WAREHOUSE: 'ROLE_SUPPLIER_LOCATION_WAREHOUSE_SHOW',
    // Producer locations
    CREATE_PRODUCER_LOCATION: ['ROLE_PRODUCER_LOCATION_CREATE', 'ROLE_PRODUCER_LOCATION_CREATE_SELF'],
    UPDATE_PRODUCER_LOCATION: ['ROLE_PRODUCER_LOCATION_UPDATE', 'ROLE_PRODUCER_LOCATION_UPDATE_SELF'],
    GET_PRODUCER_LOCATION: ['ROLE_PRODUCER_LOCATION_SHOW'],
    GET_PRODUCER_LOCATIONS: ['ROLE_PRODUCER_LOCATION_INDEX'],
    DELETE_PRODUCER_LOCATION: ['ROLE_PRODUCER_LOCATION_DELETE', 'ROLE_PRODUCER_LOCATION_DELETE'],
    // Countries
    GET_CONTRIES: 'ROLE_COUNTRY_INDEX',
    // Units
    CREATE_UNIT: ['ROLE_UNIT_CREATE', 'ROLE_UNIT_CREATE_SELF'],
    UPDATE_UNIT: ['ROLE_UNIT_UPDATE', 'ROLE_UNIT_UPDATE_SELF'],
    DELETE_UNIT: ['ROLE_UNIT_DELETE', 'ROLE_UNIT_DELETE_SELF'],
    GET_UNITS: ['ROLE_UNIT_INDEX', 'ROLE_UNIT_INDEX_SELF'],
    GET_UNIT: ['ROLE_UNIT_SHOW', 'ROLE_UNIT_SHOW_SELF'],
    // Price List
    CREATE_PRICE_LIST: ['ROLE_PRICE_LIST_CREATE', 'ROLE_PRICE_LIST_CREATE_SELF'],
    DELETE_PRICE_LIST: ['ROLE_PRICE_LIST_DELETE', 'ROLE_PRICE_LIST_DELETE_SELF'],
    GET_PRICE_LISTS: ['ROLE_PRICE_LIST_INDEX'],
    GET_PRICE_LIST: ['ROLE_PRICE_LIST_SHOW', 'ROLE_PRICE_LIST_SHOW_SELF'],
    GET_PRODUCER_PRICE_LISTS: ['ROLE_PRODUCER_PRICE_LIST_INDEX', 'ROLE_PRODUCER_PRICE_LIST_INDEX_SELF'],
    UPDATE_PRICE_LIST: ['ROLE_PRICE_LIST_UPDATE', 'ROLE_PRICE_LIST_UPDATE_SELF'],
    // Stock List
    CREATE_STOCK_LIST: ['ROLE_STOCK_LIST_CREATE', 'ROLE_STOCK_LIST_CREATE_SELF'],
    DELETE_STOCK_LIST: ['ROLE_STOCK_LIST_DELETE', 'ROLE_STOCK_LIST_DELETE_SELF'],
    // Users
    CREATE_USER: ['ROLE_USER_CREATE', 'ROLE_USER_CREATE_SELF'],
    UPDATE_USER: ['ROLE_USER_UPDATE', 'ROLE_USER_UPDATE_SELF'],
    DELETE_USER: ['ROLE_USER_DELETE', 'ROLE_USER_DELETE_SELF'],
    DELETE_USER_IMAGE: ['ROLE_USER_IMAGE_DELETE', 'ROLE_USER_IMAGE_DELETE_SELF'],
    CREATE_UPDATE_USER_AS_ROOT: ['ROLE_USER_CREATE', 'ROLE_USER_UPDATE'],
    GET_USERS_LIST: ['ROLE_USER_INDEX', 'ROLE_USER_INDEX_SELF'],
    GET_SUPPLIER_USERS_LIST: ['ROLE_SUPPLIER_USER_INDEX', 'ROLE_SUPPLIER_USER_INDEX_SELF'],
    UPDATE_USER_IMAGE: ['ROLE_USER_IMAGE_UPDATE', 'ROLE_USER_IMAGE_UPDATE_SELF'],
    GET_USER: ['ROLE_USER_SHOW', 'ROLE_USER_SHOW_SELF'],
    SEND_USER_ACTIVATION_EMAIL: ['ROLE_USER_ACTIVATION_EMAIL_SEND', 'ROLE_USER_ACTIVATION_EMAIL_SEND_SELF'],
    // IPS
    CREATE_IP: 'ROLE_IP_CREATE',
    DELETE_IP: 'ROLE_IP_DELETE',
    GET_ALL_IP_LIST: 'ROLE_IP_INDEX',
    GET_USER_IPS: 'ROLE_USER_IP_INDEX',
    GET_SUPPLIER_IPS: ['ROLE_IP_SUPPLIER_INDEX', 'ROLE_IP_SUPPLIER_INDEX_SELF'],
    UPDATE_IP: 'ROLE_IP_UPDATE',
    UPDATE_USER_UP: 'ROLE_USER_IP_INDEX',
    // Integration
    CREATE_INTEGRATION: ['ROLE_PRODUCER_INTEGRATION_CREATE', 'ROLE_PRODUCER_INTEGRATION_CREATE_SELF', 'ROLE_SUPPLIER_INTEGRATION_CREATE', 'ROLE_SUPPLIER_INTEGRATION_CREATE_SELF'],
    DELETE_INTEGRATION: ['ROLE_PRODUCER_INTEGRATION_DELETE', 'ROLE_PRODUCER_INTEGRATION_DELETE_SELF'],
    UPDATE_INTEGRATION: ['ROLE_PRODUCER_INTEGRATION_UPDATE', 'ROLE_PRODUCER_INTEGRATION_UPDATE_SELF'],
    GET_INTEGRATION_LIST: ['ROLE_PRODUCER_INTEGRATION_INDEX', 'ROLE_PRODUCER_INTEGRATION_INDEX_SELF'],
    GET_INTEGRATION: ['ROLE_PRODUCER_INTEGRATION_SHOW', 'ROLE_PRODUCER_INTEGRATION_SHOW_SELF'],
    // Brands
    CREATE_BRAND: ['ROLE_BRAND_CREATE', 'ROLE_BRAND_CREATE_SELF'],
    DELETE_BRAND: ['ROLE_BRAND_DELETE', 'ROLE_BRAND_DELETE_SELF'],
    GET_BRANDS_LIST: ['ROLE_BRAND_INDEX', 'ROLE_BRAND_INDEX_SELF'],
    GET_BRAND: ['ROLE_BRAND_SHOW', 'ROLE_BRAND_SHOW_SELF'],
    UPDATE_BRAND: ['ROLE_BRAND_UPDATE', 'ROLE_BRAND_UPDATE_SELF'],
    // Roles
    CREATE_ROLE: 'ROLE_ROLE_CREATE',
    GET_USER_ROLE: 'ROLE_USER_ROLE_SHOW',
    GET_ROLE: 'ROLE_ROLE_SHOW',
    DELETE_ROLE: 'ROLE_ROLE_DELETE',
    UPDATE_ROLE: 'ROLE_ROLE_UPDATE',
    GET_ROLES_LIST: 'ROLE_ROLE_INDEX',
    GET_ROLE_PRIVILEGES: 'ROLE_ROLE_PRIVILEGE_INDEX',
    // ETIM
    UPDATE_ETIM: ['ROLE_PRODUCT_ETIM_UPDATE', 'ROLE_PRODUCT_ETIM_UPDATE_SELF'],
    GET_ETIM_FEATURES_LIST: 'ROLE_PRODUCT_ETIM_FEATURE_INDEX',
    GET_ETIM_CATEGORY: 'ROLE_ETIM_CATEGORY_INDEX',
    GET_ETIM_PRODUCT: 'ROLE_ETIM_PRODUCT_INDEX',
    GET_ETIM_FEATURE: 'ROLE_ETIM_FEATURE_INDEX',
    GET_ETIM_UNITS_LIST: 'ROLE_ETIM_UNIT_INDEX',
    GET_ETIM_VALUES_LIST: 'ROLE_ETIM_VALUE_INDEX',
    // Search
    SEARCH_PRODUCT: ['ROLE_SEARCH_PRODUCT', 'ROLE_SEARCH_PRODUCT_SELF'],
    SEARCH_FILTERS: ['ROLE_SEARCH_FILTERS', 'ROLE_SEARCH_FILTERS_SELF'],
    SEARCH_SUPPLIER_PRODUCTS: ['ROLE_SEARCH_SUPPLIER_PRODUCT', 'ROLE_SEARCH_SUPPLIER_PRODUCT_SELF'],
    // Statistics
    GET_ACTIVITY_REPORT: 'ROLE_STATS_ACTIVITY_INDEX',
    GET_INTEGRATION_STATS: 'ROLE_STATS_INTEGRATION_INDEX',
    GET_PRICE_LIST_STATS: 'ROLE_STATS_PRICE_LIST_UPDATE_INDEX',
    GET_SALES_STATS: ['ROLE_STATS_SALES_INDEX', 'ROLE_STATS_SALES_INDEX_SELF'],
    GET_SALES_STATS_ROOT: 'ROLE_STATS_SALES_INDEX',
    GET_PRODUCT_SALES_STATS: ['ROLE_STATS_SALES_PRODUCT_INDEX', 'ROLE_STATS_SALES_PRODUCT_INDEX_SELF'],
    // Privileges
    GET_USER_PRIVILEGES_LIST: 'ROLE_USER_PRIVILEGE_INDEX',
    UPDATE_PRIVILEGE: 'ROLE_PRIVILEGE_UPDATE',
    POPULATE_PRIVILEGES: 'ROLE_ROLE_POPULATE_CHILDREN_WITH_PRIVILEGES',
    COPY_PRIVILEGES: 'ROLE_COPY_PRIVILEGES',
    // Emails
    GET_EMAILS: 'ROLE_EMAIL_INDEX',
    GET_EMAIL: 'ROLE_EMAIL_SHOW',
    SEND_EMAIL: 'ROLE_EMAIL_SEND',
    UPDATE_EMAIL: 'ROLE_EMAIL_UPDATE',
    // Email messages
    GET_EMAIL_MESSAGES: 'ROLE_EMAIL_MESSAGE_INDEX',
    GET_EMAIL_MESSAGE: 'ROLE_EMAIL_MESSAGE_SHOW',
    CREATE_EMAIL_MESSAGE: 'ROLE_EMAIL_MESSAGE_CREATE',
    UPDATE_EMAIL_MESSAGE: 'ROLE_EMAIL_MESSAGE_UPDATE',
    SEND_EMAIL_MESSAGE: 'ROLE_EMAIL_MESSAGE_SEND',
    DELETE_EMAIL_MESSAGE: 'ROLE_EMAIL_MESSAGE_DELETE',
    // Help
    GET_HELPS: 'ROLE_HELP_INDEX',
    GET_HELP: 'ROLE_HELP_SHOW',
    CREATE_HELP: 'ROLE_HELP_CREATE',
    UPDATE_HELP: 'ROLE_HELP_UPDATE',
    DELETE_HELP: 'ROLE_HELP_DELETE',
    // Task events
    GET_PRICE_LIST_TASK_EVENTS: ['ROLE_PRODUCER_PRICE_LIST_TASK_EVENT_INDEX', 'ROLE_PRODUCER_PRICE_LIST_TASK_EVENT_INDEX_SELF'],
    CREATE_INTEGRATION_TASK_EVENT: 'ROLE_PRODUCER_INTEGRATION_TASK_EVENT_CREATE_SELF',
    CREATE_MANUAL_INTEGRATION_TASK_EVENT: 'ROLE_PRODUCER_MANUAL_IMPORT_TASK_EVENT_CREATE_SELF',
    // Task
    CREATE_INTEGRATION_TASK: ['ROLE_PRODUCER_INTEGRATION_TASK_CREATE', 'ROLE_PRODUCER_INTEGRATION_TASK_CREATE_SELF'],
    DELETE_INTEGRATION_TASK: ['ROLE_PRODUCER_INTEGRATION_TASK_DELETE', 'ROLE_PRODUCER_INTEGRATION_TASK_DELETE_SELF'],
    CREATE_TASK_EVENT: 'ROLE_PRODUCER_INTEGRATION_TASK_EVENT_CREATE_SELF',
    UPDATE_TASK_EVENT: ['ROLE_PRODUCER_INTEGRATION_TASK_UPDATE', 'ROLE_PRODUCER_INTEGRATION_TASK_UPDATE_SELF'],
    CREATE_MANUAL_INTEGRATION_TASK: ['ROLE_PRODUCER_MANUAL_IMPORT_TASK_CREATE', 'ROLE_PRODUCER_MANUAL_IMPORT_TASK_CREATE_SELF'],
    DELETE_MANUAL_INTEGRATION_TASK: ['ROLE_PRODUCER_MANUAL_IMPORT_TASK_DELETE', 'ROLE_PRODUCER_MANUAL_IMPORT_TASK_DELETE_SELF'],
    UPDATE_MANUAL_INTEGRATION_TASK: ['ROLE_PRODUCER_MANUAL_IMPORT_TASK_UPDATE', 'ROLE_PRODUCER_MANUAL_IMPORT_TASK_UPDATE_SELF'],
    // Articles
    GET_PRODUCER_AGREEMENT_ARTICLE: 'ROLE_PRODUCER_ARTICLE_AGREEMENT_SHOW',
    UPDATE_PRODUCER_AGREEMENT_ARTICLE: 'ROLE_PRODUCER_ARTICLE_AGREEMENT_UPDATE',
    GET_PRODUCER_BONUS_ARTICLE: 'ROLE_PRODUCER_ARTICLE_BONUS_SHOW',
    UPDATE_PRODUCER_BONUS_ARTICLE: 'ROLE_PRODUCER_ARTICLE_BONUS_UPDATE',
    GET_PRODUCER_DISCOUNT_ARTICLE: 'ROLE_PRODUCER_ARTICLE_DISCOUNT_SHOW',
    UPDATE_PRODUCER_DISCOUNT_ARTICLE: 'ROLE_PRODUCER_ARTICLE_DISCOUNT_UPDATE',
    GET_PRODUCER_SALE_ARTICLE: 'ROLE_PRODUCER_ARTICLE_SALE_SHOW',
    UPDATE_PRODUCER_SALE_ARTICLE: 'ROLE_PRODUCER_ARTICLE_SALE_UPDATE',
    CREATE_PROMOTION: 'ROLE_PRODUCER_ARTICLE_PROMOTION_CREATE',
    GET_PROMOTIONS_LIST: 'ROLE_PRODUCER_ARTICLE_PROMOTION_INDEX',
    GET_PROMOTION: 'ROLE_PRODUCER_ARTICLE_PROMOTION_SHOW',
    UPDATE_PROMOTION: 'ROLE_PRODUCER_ARTICLE_PROMOTION_UPDATE',
    DELETE_PROMOTION: 'ROLE_PRODUCER_ARTICLE_PROMOTION_DELETE',
    // Contact form
    SEND_CONTACT_FORM: 'ROLE_EMAIL_CONTACT',
    // Attachments
    CREATE_ATTACHMENT: 'ROLE_ATTACHMENT_CREATE',
    DELETE_ATTACHMENT: 'ROLE_ATTACHMENT_DELETE',
    UPDATE_ATTACHMENT: 'ROLE_ATTACHMENT_UPDATE',
    GET_ATTACHMENTS: 'ROLE_ATTACHMENT_INDEX',
    GET_ATTACHMENTS_FOR_ARTICLE: 'ROLE_ARTICLE_ATTACHMENT_INDEX',
    // Material
    CREATE_MATERIAL: 'ROLE_MATERIAL_CREATE',
    // Banners
    CREATE_BANNER: 'ROLE_BANNER_CREATE',
    GET_BANNER: 'ROLE_BANNER_SHOW',
    DELETE_BANNER: 'ROLE_BANNER_DELETE',
    UPDATE_BANNER:  'ROLE_BANNER_UPDATE',
    GET_BANNERS: 'ROLE_BANNER_INDEX',
    GET_ACTIVE_BANNERS: 'ROLE_BANNER_ACTIVE_INDEX',
    // Directories
    GET_DIRECTORIES: ['ROLE_DIRECTORY_INDEX', 'ROLE_DIRECTORY_INDEX_PUBLIC'],
    CREATE_DIRECTORY: ['ROLE_DIRECTORY_CREATE', 'ROLE_DIRECTORY_CREATE_PUBLIC'],
    UPDATE_DIRECTORY_MATERIAL: ['ROLE_DIRECTORY_UPDATE', 'ROLE_DIRECTORY_UPDATE_PUBLIC'],
    UPDATE_DIRECTORY_MATERIAL_PUBLIC: 'ROLE_DIRECTORY_UPDATE_PUBLIC',
    UPDATE_DIRECTORY_MATERIAL_PRIVATE: 'ROLE_DIRECTORY_UPDATE',
    GET_DIRECTORY: 'ROLE_DIRECTORY_SHOW',
    DELETE_DIRECTORY: 'ROLE_DIRECTORY_DELETE',
    // Calendar
    SHOW_CALENDAR: 'ROLE_CALENDAR',
    // Export
    EXPORT_PRODUCER_TABLE: 'ROLE_PRODUCER_INDEX_EXPORT',
    EXPORT_SUPPLIER_TABLE: 'ROLE_SUPPLIER_INDEX_EXPORT',
    EXPORT_USER_TABLE: 'ROLE_USER_INDEX_EXPORT'
}
