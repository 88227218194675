import {ModuleWithProviders, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {IEDownloadsDialogService} from './ie-downloads-dialog.service';
import {IEDownloadsDialogComponent} from './ie-downloads-dialog.component';
import {IEIconModule} from '../ie-icon/ie-icon.module';



@NgModule({
  declarations: [IEDownloadsDialogComponent],
  exports: [
    IEDownloadsDialogComponent
  ],
  imports: [
    CommonModule,
    IEIconModule
  ]
})
export class IEDownloadsDialogModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: IEDownloadsDialogModule,
      providers: [IEDownloadsDialogService]
    }
  }
}
