import {Component, HostBinding, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'ie-icon',
  template: `
    <div
      class="ie-icon"
      [attr.title]="title"
      [attr.pending]="pending?true:null"
      [attr.hover]="hover?true:null"
      [attr.disabled]="disabled?true:null"
      [style.cursor]="pointer ? 'pointer' : ''"
      [style.width.px]="innerWidth < 500 ? mobileSize : size"
      [style.height.px]="innerWidth < 500 ? mobileSize : size"
      [style.max-width.px]="innerWidth < 500 ? mobileSize : size"
    >
        <ng-content></ng-content>
        <svg>
            <use attr.xlink:href="assets/icons/symbol-defs.svg#icon-{{!pending?icon:'synchronize'}}"></use>
        </svg>
    </div>
  `,
  styles: [`
  :host {
      display: flex;
  }
  .ie-icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: text-top;
      position: relative;
  }

  .ie-icon svg {
      width: inherit;
      height: inherit;
      all: inherit;
  }

  .ie-icon[hover] {
      cursor: pointer;
      z-index: 0;
  }

  .ie-icon[hover]:not([disabled]):hover:before {
      transform-origin: center;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      transform: scale(1.8);
      z-index: -1;
      background-color: #E0E0FF;
  }

  .ie-icon[pending] {
      animation: rotate-infinite 900ms linear infinite;
  }
  .ie-icon[disabled] {
      cursor: not-allowed;
      opacity: .6;
      filter: grayscale(1);
  }

  @keyframes rotate-infinite {
      from {
          transform: rotate(0);
      }
      to {
          transform: rotate(360deg);
      }
  }    `],
  encapsulation: ViewEncapsulation.None
})
export class IEIconComponent implements OnInit {

  @Input() icon: string;
  @Input() title = '';
  @Input() pointer: boolean;
  @Input() disabled: boolean;
  @Input() hover: boolean;
  @Input() size = 16;
  @Input() mobileSize: number;
  @Input() pending: boolean;
  @HostBinding('style.max-height') maxHeight;

  public innerWidth: number;

  constructor() { }

  ngOnInit() { this.maxHeight = `${this.size}px`; this.mobileSize = this.size; this.innerWidth = window.innerWidth; }
}
