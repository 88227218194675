import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from './pipes/date.pipe';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { SafeContentPipe } from './pipes/safecontent.pipe';
import { PricePipe } from './pipes/price.pipe';
import { ReportFilterPipe } from './pipes/filter.pipe';
import { OrderContextPipe } from './pipes/ordercontext.pipe';
import { BooleanPipe } from './pipes/boolean.pipe';
import { UserPipe } from './pipes/user.pipe';
import {DateRangePipe} from './pipes/date-range.pipe';
import {AddressPipe} from './pipes/address.pipe';
import {GrammarPipe} from './pipes/grammar.pipe';
import {SupplierPipe} from './pipes/supplier.pipe';
import {ProducerPipe} from './pipes/producers.pipe';
import {RolePipe} from './pipes/role.pipe';
import {LoaderTemplateDirective} from './loader-template/loader-template.directive';
import { HourPipe } from './pipes/hour.pipe';
import { FlatNumberPipe } from './pipes/flat-number.pipe';
import { SecureImgPipe } from './pipes/secured-img.pipe';
import { SecuredImgService } from './secured-img-service/secured-img.service';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [
    /* Components */
    FileuploadComponent,
    /* Pipes */
    DatePipe,
    HourPipe,
    DateRangePipe,
    SafeContentPipe,
    PricePipe,
    ReportFilterPipe,
    OrderContextPipe,
    BooleanPipe,
    UserPipe,
    AddressPipe,
    GrammarPipe,
    SupplierPipe,
    ProducerPipe,
    RolePipe,
    FlatNumberPipe,
    SecureImgPipe,
    /* Directives */
    LoaderTemplateDirective
  ],
  declarations: [
    /* Components */
    FileuploadComponent,
    /* Pipes */
    DatePipe,
    HourPipe,
    DateRangePipe,
    SafeContentPipe,
    PricePipe,
    ReportFilterPipe,
    OrderContextPipe,
    BooleanPipe,
    UserPipe,
    AddressPipe,
    GrammarPipe,
    SupplierPipe,
    ProducerPipe,
    RolePipe,
    FlatNumberPipe,
    SecureImgPipe,
    /* Directives */
    LoaderTemplateDirective
  ],
  providers: [
    SecuredImgService
  ],
  entryComponents: []
})
export class SharedModule { }
