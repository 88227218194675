import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrdersRoutingModule } from './orders-routing.module';
import { OrdersListComponent } from '../component/orders-list/orders-list.component';
import { IEButtonModule } from '@material/ie-button/ie-button.module';
import { FormsModule } from '@angular/forms';
import { IEMenuModule } from '@material/ie-menu/ie-menu.module';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { OrderPreviewComponent } from '../component/order-preview/order-preview.component';
import { IEExtensionPanelModule } from '@material/ie-extension-panel/ie-extension-panel.module';
import { SharedModule } from '@shared/shared.module';
import { OrdersReceivedListComponent } from '../component/orders-received-list/orders-received-list.component';
import { OrdersReceivedPreviewComponent } from '../component/orders-received-preview/orders-received-preview.component';
import { IEMultiselectModule } from '@material/ie-multiselect/ie-multiselect.module';
import { IEOnMountModule } from '@material/ie-on-mount/ie-on-mount.module';
import { IEBreadCrumbModule } from '@material/ie-breadcrumb/ie-breadcrumb.module';
import { IEInlineDropdownModule } from '@material/ie-inline-dropdown/ie-inline-dropdown.module';
import { IEFlagSelectorModule } from '@material/ie-flag-selector/ie-flag-selector.module';
import { IESpinnerModule } from '@material/ie-spinner/ie-spinner.module';
import { IEIconButtonModule } from '@material/ie-icon-button/ie-icon-button.module';
import { IEEmptyPlaceholderModule } from '@material/ie-empty-placeholder/ie-empty-placeholder.module';
import {VaultSharedModule} from '../../../authorization/module/vault.module';
import {IECdkTableModule} from '@material/ie-cdk-table/ie-cdk-table.module';
import {IEIconModule} from '@material/ie-icon/ie-icon.module';
import {OrderProductTileComponent} from '../component/order-product-tile/order-product-tile.component';
import {IeWarnDialogModule} from '@material/ie-warn-dialog/ie-warn-dialog.module';
import {OrderSupplierTileComponent} from '../component/order-supplier-tile/order-supplier-tile.component';
import {OrderReceivedProductTileComponent} from '../component/order-received-product-tile/order-received-product-tile.component';
import {IEOverlayFocusTrapModule} from '@material/ie-overlay-focus-trap/ie-overlay-focus-trap.module';
import {IETooltipModule} from '@material/ie-tooltip/ie-tooltip.module';
import {IEQuantityInputModule} from '@material/ie-quantity-input/ie-quantity-input.module';
import {IECheckboxModule} from '@material/ie-checkbox/ie-checkbox.module';
import { IERadiobuttonModule } from '@material/ie-radiobutton/ie-radiobutton.module';
import { OrderSuccessComponent } from '../component/order-success/order-success.component';

@NgModule()
export class OrdersServiceModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: OrdersServiceModule,
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: OrdersServiceModule) {
    if (parentModule) {
      throw new Error('OrdersServiceModule was already loaded');
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    OrdersRoutingModule,
    FormsModule,
    IEButtonModule,
    IEMenuModule,
    IEExtensionPanelModule,
    IEMultiselectModule,
    IEOnMountModule,
    IEBreadCrumbModule,
    IEInlineDropdownModule,
    IEFlagSelectorModule,
    IESpinnerModule,
    IEIconModule,
    IEIconButtonModule,
    SharedModule,
    IEEmptyPlaceholderModule,
    VaultSharedModule,
    IECdkTableModule,
    IeWarnDialogModule,
    IEOverlayFocusTrapModule,
    IETooltipModule,
    IEQuantityInputModule,
    IERadiobuttonModule,
    IECheckboxModule
  ],
  declarations: [
    OrdersListComponent,
    OrderPreviewComponent,
    OrdersReceivedListComponent,
    OrdersReceivedPreviewComponent,
    OrderProductTileComponent,
    OrderSupplierTileComponent,
    OrderReceivedProductTileComponent,
    OrderSuccessComponent,
  ],
})
export class OrdersModule { }
