import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ie-icon-button',
  templateUrl: './ie-icon-button.component.html',
  styleUrls: ['./ie-icon-button.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IEIconButtonComponent implements OnInit {

  @Input() icon: string;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() stroke: boolean;
  @Input() size = 16;
  @Input() rotated: boolean;

  constructor() { }

  ngOnInit() {}

}
