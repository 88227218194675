import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ContentChild,
  Directive,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {TableSortButtonComponent} from './table-sort-button/table-sort-button.component';
import {DOCUMENT} from '@angular/common';
import {BehaviorSubject, combineLatest, fromEvent, of, Subscription, Observable} from 'rxjs';
import {CdkHeaderRowDef, CdkRowDef, CdkTable} from '@angular/cdk/table';
import {TableFilter} from './filter/table-filter';
import {IEDialogComponent} from '../ie-dialog/component/ie-dialog.component';
import {TableChangeColumnOrderComponent} from './table-change-column-order/table-change-column-order.component';
import {UiPersonalizationService} from '@shared/ui-personalization/service/ui-personalization.service';
import {CdkManageableTable} from './CdkManageableTable';
import {TableExportDataComponent} from './table-export-data/table-export-data.component';
import {distinctUntilChanged, switchMap} from 'rxjs/operators';
import {IE_EMPTY_PLACEHOLDER} from '../ie-empty-placeholder/ie-empty-placeholder.component';
import {Sorter} from './sorter/cdk-table-sorter';
import CdkTableColumnsTransfomer from './transformer/cdk-table-columns-transformers';
import {pullAt, without, intersection, uniq} from 'lodash-es';
import {BulkList} from '@shared/utils/bulk-operation';
import {ScrollToService} from '@nicky-lenaers/ngx-scroll-to';
import CdkTableDefaultSorter from './sorter/cdk-table-default-sorter';
import { Bast } from '@bast';
import { BastPrivilegeMap } from 'src/app/authorization/model/BastPrivilegeMap';
import { IESearchBarComponent } from '@material/ie-searchbar/searchbar.component';


export interface CDKColumnDefinition {
  property: string;
  name?: string;
  transient?: boolean;
  visible?: boolean;
}

@Directive({
  selector: '[cdk-sortable-column]',
})
export class CDKSortableColumn implements OnInit, OnDestroy {

  public sortButtonComponent: TableSortButtonComponent;
  private _property: string;
  private _eventsSubscription: Subscription

  @Input('cdk-sortable-column')
  get property() {
    return this._property;
  }

  set property(property: string) {
    this._property = property;
  }

  protected injectSorterComponent(): void {
    const factory = this.componentFactoryResolver.resolveComponentFactory(TableSortButtonComponent);
    const ref = this.viewContainerRef.createComponent(factory);
    this.sortButtonComponent = ref.instance;

    const wrapper = this.document.createElement('div');
    wrapper.classList.add('th-flex-wrapper');
    const [thContentElement] = this.el.nativeElement.childNodes;
    this.el.nativeElement.removeChild(thContentElement);
    wrapper.appendChild(thContentElement);
    wrapper.appendChild(ref.location.nativeElement);
    this.el.nativeElement.appendChild(wrapper);
    this.el.nativeElement.classList.add('sortable-header-column');

    this._eventsSubscription = fromEvent(this.el.nativeElement, 'mousedown')
      .subscribe(() => {

        // Clear sort indicators on all sortable columns
        document.querySelectorAll('.sort-button').forEach((e: HTMLElement) => {
          e.classList.remove('sort-button-asc');
          e.classList.remove('sort-button-desc');
        });
        this.table.sort(this._property);
        this.sortButtonComponent.order = this.table.getSorterOrder();
      });
  }

  constructor(
    public el: ElementRef,
    public viewContainerRef: ViewContainerRef,
    public componentFactoryResolver: ComponentFactoryResolver,
    public table: IECdkTableComponent,
    @Inject(DOCUMENT) public document,
  ) {}

  ngOnInit(): void {
    this.injectSorterComponent();
  }

  ngOnDestroy(): void {
    if (this._eventsSubscription) { this._eventsSubscription.unsubscribe(); }
  }
}

// @ts-ignore-start
@Component({
  selector: 'ie-cdk-table',
  templateUrl: './ie-cdk-table.component.html',
  styleUrls: ['./ie-cdk-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IECdkTableComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() sticky: boolean;
  @Input() searchable: boolean;
  @Input() searchableFields: string[] = [];
  @Input() manageableColumns: CDKColumnDefinition[] = [];
  @Input() interface: string;
  @Input() paginationLimit = 50;
  @Input() transformer = CdkTableColumnsTransfomer.DEFAULT;
  @Input() defaultSorter;
  @Input() disablePointerEvents: boolean;
  @Output() onsort: EventEmitter<Sorter> = new EventEmitter<Sorter>();
  @ViewChild(IEDialogComponent) dialog: IEDialogComponent;
  @ViewChild(IESearchBarComponent) searchBar: IESearchBarComponent;
  @ViewChild('tableContainer') table: ElementRef;
  @ContentChild(CdkTable) cdkTable: CdkTable<any>;
  @ContentChild(CdkHeaderRowDef) cdkHeaderRowDef: CdkHeaderRowDef;
  @ContentChild(CdkRowDef) cdkRowDef: CdkRowDef<any>;

  public filter: TableFilter;
  public displayedColumns: string[];
  public order: string[];
  public pending: boolean;
  public dataPending = true;
  public currentPage = 0;
  public maxResults: number;

  public NOT_FOUND = IE_EMPTY_PLACEHOLDER.NO_SEARCH_RESULTS;
  public drag: boolean;
  public reachedEnd: boolean;

  private _deepCopiedData: any[] = [];
  private _deepCopiedDataBackup: any[] = [];
  public _filteredData: any[] = [];
  private _sorter: Sorter = new Sorter();
  private _filterSubscription: Subscription;
  private _persistTableOptionsSubscription: Subscription;
  private _uiFetchSubscription: Subscription;
  private _dialogSubscription: Subscription = new Subscription();
  private _deepCopySubscription: Subscription = new Subscription();
  private currentPage$ = new BehaviorSubject<number>(0);
  private _stable = true;

  protected interfaceId: number;

  public showExportButton: boolean;

  async shouldShowExportButton(): Promise<void> {
    this.showExportButton = true;
    if (this.interface === 'producers-list') {
      this.showExportButton = await this.bast.authorization.check(BastPrivilegeMap.EXPORT_PRODUCER_TABLE).toPromise();
    }
    if (this.interface === 'suppliers-list') {
      this.showExportButton = await this.bast.authorization.check(BastPrivilegeMap.EXPORT_SUPPLIER_TABLE).toPromise();
    }
    if (this.interface === 'users-list') {
      this.showExportButton = await this.bast.authorization.check(BastPrivilegeMap.EXPORT_USER_TABLE).toPromise();
    }
  }

  public getSorterOrder(): number {
    return this._sorter.order;
  }

  public clearFilter(): void {
    this.resetTableData();
  }

  public sort(property: string, secondProperty?: string): void {

    try {
      this._deepCopiedData = this._filteredData.length ? this._sorter.sortArray(property, this._filteredData, secondProperty) : this._sorter.sortArray(property, this._deepCopiedData, secondProperty);
      this.onsort.emit(this._sorter);
      this.filter.resetSearchMap(this._deepCopiedData);
      this.currentPage$.next(0);
      this.currentPage = 0;
    } catch (e) {}
  }

  public resetTableData(): void {
    this._filteredData = [];
    this.filter.clear();
    this.maxResults = this._deepCopiedDataBackup.length;
    this.forceRenderWithData(this._deepCopiedDataBackup);
    this._deepCopiedData = this._deepCopiedDataBackup;
  }

  public openColumnManageDialog(): void {
    this.manageableColumns = this.buildManageableColumnsOrder(false);
    const manage = this.dialog.openDialog<TableChangeColumnOrderComponent>({ columns: this.manageableColumns }, TableChangeColumnOrderComponent);
    this.detect();
    this._dialogSubscription.add(
      manage.onorderchange
        .subscribe(order => {
          this.displayedColumns = order.filter(col => !col.transient && col.visible).map(({ property }) => property);
          this.setColumnsOrder(this.displayedColumns, this.displayedColumns);

          this.persistInterfaceElementPersonalizationSettings(order);
          this.order = order.map(({ property }) => property);
          this.dialog.closeDialog();
      })
    );

    this._dialogSubscription.add(manage.onclose.subscribe(() => this.dialog.closeDialog()));
  }

  // Do zmiany po wywaleniu durszlaka
  public durszlakFixColumns(columns: Array<CDKColumnDefinition>) {
    columns.forEach(column => {
      if(column.property === 'membership') {
        column.property = 'is_member';
      }
    });
    return columns;
  }

  public openExportDataDialog(): void {
    this.manageableColumns = this.buildManageableColumnsOrder(true);
    const dialog = this.dialog.openDialog<TableExportDataComponent>({ columns: this.durszlakFixColumns(this.manageableColumns) }, TableExportDataComponent);
    this.detect();
    this._dialogSubscription.add(dialog.onclose.subscribe(() => this.dialog.closeDialog()));
    this._dialogSubscription.add(dialog.onexport.subscribe((exportMetaInfo) => {
      if (!this.interfaceId) {
        this.ui.createInterfaceElement(this.interface, { columnsVisible: [] })
          .subscribe(element => {
            this.interfaceId = element.id;
            return this.ui
              .exportInterfaceElementToFormat(this.interfaceId, exportMetaInfo.format, this.transformer(exportMetaInfo.exportColumns))
              .subscribe(() => this.dialog.closeDialog());
          });
      } else {
        this.ui.exportInterfaceElementToFormat(this.interfaceId, exportMetaInfo.format, this.transformer(exportMetaInfo.exportColumns))
          .subscribe(() => this.dialog.closeDialog());
      }
    }));
  }

  public changePagination(page: number): void {
    if (typeof page !== 'number') { return }
    this.currentPage = page - 1;
    this.currentPage$.next(page - 1);
    this.table.nativeElement.children[0].scrollTop = 0;
  }

  public detect(): void {
    this.cd.markForCheck();
    this.cd.detectChanges();
  }

  public reindex(data: any[]): void {
    this.filter.reindex(data);
  }

  /**
   * Forces async render on cdk table with supplied data
   * and forces change detection
   * @param data
   */
  protected forceRenderWithData(data): void {
    try {
      this._stable = false;
      if (!data || data.some(el => !el)) { return; }
      this.cdkTable['_dataSource'].next(data);
      this.detect();
      this._stable = true;
    } catch (e) {}
  }

  /**
   * Saves user interface configuration to Durszlak
   * @param payload
   */
  protected persistInterfaceElementPersonalizationSettings(payload: CDKColumnDefinition[]): void {
      this._persistTableOptionsSubscription = this.ui
        .saveInterfaceElement(this.interfaceId, this.interface, CdkManageableTable.toDto(payload))
        .subscribe();
  }

  /**
   * Fetches configuration from Durszlak to determine user defined columns order and visibility
   */
  protected fetchComponentPersonalization(): void {
    if (!this.interface) { this.pending = false; return; }
    this.pending = true;
    this._uiFetchSubscription = this.ui.getInterfaceElement(this.interface)
      .subscribe(
        result => {
          this.interfaceId = result.id;
          try { this.order = result.options.order; this.setColumnsOrder(result.options.order, result.options.columnsVisible); } catch (e) {}
          this.detect();
          this.pending = false;
        },
        () => {
          this.manageableColumns = this.manageableColumns.map(c => ({ ...c, visible: true }));
          this.displayedColumns = this.cdkHeaderRowDef.columns as string[];
          this.detect();
          this.pending = false;
        });
  }

  /**
   * Sorts columns against configuration fetched from Durszlak
   * @param order
   * @param visibleColumns
   */
  protected setColumnsOrder(order: string[], visibleColumns: string[]) {
    const transient = this.manageableColumns.filter(c => c.transient).map(c => c.property);
    const visible = (!visibleColumns || !visibleColumns.length) ?
      this.manageableColumns.map(c => c.property) :
      intersection(this.manageableColumns.map(c => c.property), visibleColumns);
    const ordered = (!order || !order.length) ?
      this.manageableColumns.map(c => c.property) :
      order;

    this.displayedColumns = uniq([...intersection(ordered, visible), ...transient]);
    this.cdkHeaderRowDef.columns = this.displayedColumns;
    this.cdkRowDef.columns = this.displayedColumns;
  }

  /**
   * Returns array of columns definitions based on column predicate:
   * 1. Only persistent columns can be manageable (columns ought not to be transient)
   * 2. Only compatible columns can be manageable (columns names do change over time. Some users
   * may use old ui configuration thus those columns are not viable.)
   * @param excludeTransient
   */
  protected buildManageableColumnsOrder(excludeTransient?: boolean): CDKColumnDefinition[] {
    // If table is exportable, but columns order can't be changed - display all columns as export list
    if (!this.manageableColumns.length) { return this.displayedColumns.map(dc => ({ property: dc, name: dc, visible: true })); }

    // If no order specified display default cdk order
    if (!this.order) { return this.manageableColumns.map(c => ({ ...c, visible: this.displayedColumns.includes(c.property) })); }
    const incompatibleColumns = without(this.manageableColumns.map(c => c.property), ...this.order);

    return [...this.order, ...incompatibleColumns].reduce((acc, next) => {
      const definition = this.manageableColumns.find(c => c.property === next);

      // If column is not manageable (e.g. contains context menu, icons) or is defined as transient do not include it in a order list
      if (!definition || (excludeTransient && definition.transient)) { return acc; }
      return [...acc, { ...definition, visible: this.displayedColumns.includes(next) }];
    }, []);
  }

  protected trackPageChange(): void {
    combineLatest(this.currentPage$)
      .subscribe(([page]) => {

        // Calculate data slice based on pagination range and force table rerender with sliced data
        const startingIndex = (page) * this.paginationLimit;
        const onPage = this._deepCopiedData.slice(startingIndex, startingIndex + this.paginationLimit);
        this.forceRenderWithData(onPage);
        this.scrollToService.scrollTo({ target: 'cdk-content-container', offset: -80 });
      });
  }

  /**
   * Creates a deeply cloned array of input data.
   * It is later used to rerender table with initial data.
   */
  protected setDeepCopiedData(): void {
    this._deepCopySubscription.add(
      this.cdkTable['_dataSource']
        .subscribe((data) => {
          if (!this._stable) { return; }
          this.maxResults = data.length
          this._deepCopiedData = data;
          this._deepCopiedDataBackup = data;
          if (this.defaultSorter) {
            this.sort(this.defaultSorter.column, this.defaultSorter.secondColumn?this.defaultSorter.secondColumn:null);
          }
          this.dataPending = data ? false : true;
        })
    );
  }

  /**
   * Creates a new table filter map. This technique reduces filtering complexity
   * to O(1) thus greatly improving deep searching performance.
   */
  protected initializeFilter(): void {
    this.filter = new TableFilter(this._deepCopiedData, this.searchableFields);
    this._filterSubscription = this.filter.subscribeToTableFilter()
      .subscribe(result => {
        const final = pullAt([...this._deepCopiedData], result);
        this._filteredData = final;
        this.maxResults = final.length;
        this.currentPage = 0;
        this.currentPage$.next(0);
        this.forceRenderWithData(this._deepCopiedData instanceof BulkList ? new BulkList(final) : final);
      });
    this.filter.onclear.subscribe(() => this.resetTableData());
  }

  constructor(
    private cd: ChangeDetectorRef,
    private ui: UiPersonalizationService,
    private scrollToService: ScrollToService,
    private bast: Bast
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.fetchComponentPersonalization();
    this.setDeepCopiedData();
    this.trackPageChange();
    this.initializeFilter();
    this.shouldShowExportButton();
  }

  ngOnDestroy(): void {
    if (this._filterSubscription) { this._filterSubscription.unsubscribe(); }
    if (this._persistTableOptionsSubscription) { this._persistTableOptionsSubscription.unsubscribe(); }
    if (this._uiFetchSubscription) { this._uiFetchSubscription.unsubscribe(); }
    if (this._dialogSubscription) { this._dialogSubscription.unsubscribe(); }
    if (this._deepCopySubscription) { this._deepCopySubscription.unsubscribe(); }
  }

}
// @ts-ignore-end
