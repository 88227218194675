import { Pipe, PipeTransform } from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {Bast} from '@bast';
@Pipe({
  name: 'user'
})
export class UserPipe implements PipeTransform {

  constructor(protected bast: Bast) {}

  transform(value: any, args?: any): Observable<string> {
    if (!value) { return of('-'); }
    return this.bast.user.getById(value)
      .pipe(catchError(e => of(null)))
      .pipe(map(user => {
        return user ? `${user.first_name||'-'} ${user.last_name||'-'}` : '-'
    }));
  }

}
