import {Component} from '@angular/core';
import {Bast} from '@bast/index';
import { APIService } from '../api/apiservice.service';
import { UiPersonalizationService } from '@shared/ui-personalization/service/ui-personalization.service';
import { environment } from 'src/environments/environment';
import { version } from '../../../package.json';
import { release } from '../../build';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})

export class RootComponent {

  constructor(
    private apiService: APIService,
    private ui: UiPersonalizationService,
    private bast: Bast
  ) {
    this.showReleaseInfo();
    this.bast.getConfig()
    .subscribe(
      config => {
        this.apiService.API_URL = config.API_URL ? config.API_URL : environment.API_URL;
        this.ui.VAULT_URL = config.VAULT_URL ? config.VAULT_URL : environment.VAULT_URL;
      },
      err => {
        this.apiService.API_URL = environment.API_URL;
        this.ui.VAULT_URL = environment.VAULT_URL;
      }
    )
  }

  showReleaseInfo() {
    console.log('%c* Version: ' + version, 'font-weight:bold;');
    const r = release as any;
    if (r.timestamp) {
      console.log(
        '%c* Build timestamp: ' + r.timestamp,
        'font-weight:bold;'
      );
    }
    if (r.hash) {
      console.log('%c* Build hash: ' + r.hash, 'font-weight:bold;');
    }
    if (r.branch) {
      console.log('%c* GIT branch: ' + r.branch, 'font-weight:bold;');
    }
  }

}
