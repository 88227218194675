import { Pipe, PipeTransform } from '@angular/core';
import { ORDER_STATUS_MAP } from '../../configuration/translations/ORDER_STATUS_MAP';

@Pipe({
    name: 'orderContext'
})
export class OrderContextPipe implements PipeTransform {

    transform(value: string) {
        return ORDER_STATUS_MAP[value];
    }
}
