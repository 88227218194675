import { Directive, Output, EventEmitter, OnInit } from "@angular/core";

@Directive({
    selector: '[ie-on-mount]'
})
export class IEOnMountDirective implements OnInit {

    @Output() onmount: EventEmitter<void> = new EventEmitter();

    ngOnInit(): void {
        this.onmount.emit();
    }
}