  <svg
    class="sort-button"
    [ngClass]="order===1 ? 'sort-button-asc': (order===-1) ? 'sort-button-desc' : null"
    width="8"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10.93 9.3">
    <defs><style>.cls-1{fill:none;stroke:#33c;}</style></defs>
    <g id="Dalej_mniejsza">
      <g id="Group_5124">
        <path id="Path_40" class="cls-1" d="M20.93,16l4.29,4.3-4.29,4.29" transform="translate(-15 -15.65)"/>
        <line id="Line_31" class="cls-1" x1="10.22" y1="4.65" y2="4.65"/>
      </g>
    </g>
  </svg>
  <!--<svg
    class="sort-button"
    [ngClass]="order === 1 ? 'sort-button-active': null"
    xmlns="http://www.w3.org/2000/svg"
    width="6.286"
    height="4"
    viewBox="0 0 6.286 4"
  >
    <path
      id="Path_8305"
      data-name="Path 8305" d="M5.743,8.6,2.6,5.424,3.415,4.6,5.743,6.953,8.071,4.6l.815.824Z"
      transform="translate(8.886 8.6) rotate(180)"
      fill="inherit"
    />
  </svg>

  <svg
    class="sort-button"
    [ngClass]="order === -1 ? 'sort-button-active': null"
    xmlns="http://www.w3.org/2000/svg"
    width="6.286"
    height="4"
    viewBox="0 0 6.286 4"
  >
    <path
      id="Path_8304"
      data-name="Path 8304"
      d="M5.743,8.6,2.6,5.424,3.415,4.6,5.743,6.953,8.071,4.6l.815.824Z"
      transform="translate(-2.6 -4.6)"
      fill="inherit"
    />
  </svg>-->
