import {APIService} from '../../../app/api/apiservice.service';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';

export interface ProducerSeries {
  id?: number;
  name?: string;
};

export class SeriesDomain {

  public get(producerId: number): Promise<ProducerSeries[]> {
    return this.api.request(ENDPOINTS.getProducerSeries(producerId), 'GET').then(({ data }) => Promise.resolve(data));
  }

  public getById(serieId: number): Promise<ProducerSeries> {
    return this.api.request(ENDPOINTS.getProducerSerie(serieId), 'GET');
  }

  public create(producerId: number, serie: ProducerSeries): Promise<ProducerSeries> {
    return this.api.request(ENDPOINTS.createProducerSerie(producerId), 'POST', { serie });
  }

  public update(serie: ProducerSeries): Promise<ProducerSeries> {
    return this.api.request(ENDPOINTS.updateProducerSerie(serie.id), 'POST', { serie });
  }

  public delete(serie: ProducerSeries): Promise<void> {
    return this.api.request(ENDPOINTS.deleteProducerSerie(serie.id), 'DELETE');
  }

  constructor(private api: APIService) {}
}
