import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ie-button',
  templateUrl: './ie-button.component.html',
  styleUrls: ['./ie-button.component.scss']
})
export class IEButtonComponent {

  // Button inner text
  @Input() text: string;

  // Is button active
  @Input() active = true;

  // Button type 'primary' | 'secondary' | 'clear' | 'link' | 'icon'
  @Input() type: string = 'primary';

  // Is button sticky on mobile
  @Input() sticky: boolean;

  // Emit event when button is clicked
  @Output() onclick = new EventEmitter<void>();

  constructor() {}
}
