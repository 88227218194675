// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  environment: 'dev',
  production: false,
  canonical: 'https://dev.ie24.pl',
  API_URL: 'https://dev-api.ie24.pl',
  // API_URL: 'https://api.ie24.pl',
  VAULT_URL: 'https://dev.durszlak.ie24.pl/api',
  versionStamp: '3.16.0',
  tinyMceApiKey: 'dyki2ht8m5rmnz2vvvnzh0p85sny5a2gyncwfkf11q8qn7jg',
  icsCalendarPrivateLink: 'https://api.ie24.pl/calendar/basic.ics',
  newsletterUrl: (hash: string) => `https://dev.ie24.pl/newsletter/${hash}`,
};

// export const environment = {
//   environment: 'sandbox',
//   production: true,
//   API_URL: 'https://sandbox-api.ie24.pl',
//   VAULT_URL: 'https://sandbox.durszlak.ie24.pl/api',
//   versionStamp: '3.29.0',
//   tinyMceApiKey: 'dyki2ht8m5rmnz2vvvnzh0p85sny5a2gyncwfkf11q8qn7jg',
//   icsCalendarPrivateLink: 'https://api.ie24.pl/calendar/basic.ics',
//   newsletterUrl: (hash: string) => `https://sandbox.ie24.pl/newsletter/${hash}`,
// };

// export const environment = {
//   environment: 'prod',
//   production: true,
//   API_URL: 'https://api.ie24.pl',
//   VAULT_URL: 'https://durszlak.ie24.pl/api',
//   versionStamp: '3.30.0',
//   tinyMceApiKey: 'dyki2ht8m5rmnz2vvvnzh0p85sny5a2gyncwfkf11q8qn7jg',
//   icsCalendarPrivateLink: 'https://api.ie24.pl/calendar/basic.ics',
//   newsletterUrl: (hash: string) => `https://bast.ie24.pl/newsletter/${hash}`,
// };
