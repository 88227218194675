<div class="export-data-container">
  <h3>Eksportuj plik (kodowanie UTF-8)</h3>
  <div class="dialog-section">
    <label>Wybierz format</label>
    <div class="dialog-section-buttons-container">
      <button
        (click)="setFormat('XML')"
        class="dialog-section-button"
        [ngClass]="format==='XML' ? 'selected-button' : null"
      >XML</button>
      <button
        (click)="setFormat('CSV')"
        class="dialog-section-button"
        [ngClass]="format==='CSV' ? 'selected-button' : null"
      >CSV</button>
    </div>
  </div>

  <div class="dialog-section">
    <div class="dialog-section-header">
      <label>Kolumny</label>
      <span
        #selected
        style="margin-left: auto"
        class="--link"
        (click)="selectAll(selected)"
      >{{selected['value']?'Odznacz':'Zaznacz'}} wszystkie</span>
    </div>
    <ul>
      <li *ngFor="let column of columns">
        <ng-container *ngIf="!column.transient">
          <div class="draggable-wrapper">
            <ie-checkbox [(ngModel)]="column.visible"></ie-checkbox>
            <span>{{column.name}}</span>
            <div class="change-order-icon"></div>
          </div>
        </ng-container>
      </li>
    </ul>
  </div>

  <div class="manage-bar">
    <button
      class="bar-cancel-button"
      (click)="onclose.emit()"
    >anuluj</button>
    <button
      class="bar-accept-button"
      [attr.disabled]="!canBeExported()?true:null"
      [ngClass]="pending ? 'pending-button' : null"
      (click)="export()"
    >
      <span *ngIf="!pending;else loader">eksportuj</span>
      <ng-template #loader>
        <ie-spinner [size]=".5"></ie-spinner>
      </ng-template>
    </button>
  </div>
</div>
