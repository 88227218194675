import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {IEInputOutputAccessor} from '@shared/input-output-accessor/ie-input-output-accessor';

@Component({
  selector: 'ie-quantity-input',
  templateUrl: './ie-quantity-input.component.html',
  styleUrls: ['./ie-quantity-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IEQuantityInputComponent),
      multi: true
    }
  ]
})
export class IEQuantityInputComponent extends IEInputOutputAccessor implements OnInit {

  @Input() min = 1;
  @Input() max = 100;
  @Input() pending: boolean;
  @Input() error: string;
  @Input() name = '';
  @Input() disabled: boolean;
  @Output() onblur: EventEmitter<void> = new EventEmitter<void>();
  @Output() onchange: EventEmitter<number> = new EventEmitter<number>();

  public get lockedFloor() {
    return this._fieldValue <= this.min ? true : null;
  }

  public get lockedCeil() {
    return this._fieldValue >= this.max ? true : null;
  }

  public decrease(): void {
    if (this.lockedFloor || this.disabled) { return; }
    this._fieldValue = Number(this._fieldValue||1) - 1;
    this.onchange.emit(this._fieldValue);
    this.writeValue(this._fieldValue);
  }

  public increase(): void {
    if (this.lockedCeil || this.disabled) { return; }
    this._fieldValue = Number(this._fieldValue||1) + 1;
    this.onchange.emit(this._fieldValue);
    this.writeValue(this._fieldValue);
  }

  public blur(evt): void {
    this.error = null;
    const prev = this._fieldValue;
    const value =  evt.target.value || 1;
    if (!value || value === 1) {
      evt.target.value = 1;
    }

    this._fieldValue = Number(evt.target.value);
    this.onblur.emit();

    if (prev !== this._fieldValue) {
      this.onchange.emit(this._fieldValue);
    }
  }

  public validateKeypress(evt) {
    const event = evt || window.event;
    let key;

    if (event.type === 'paste') {
      key = event.clipboardData.getData('text/plain');
    } else {
      const pressed = event.keyCode || event.which;
      key = String.fromCharCode(pressed);
    }

    const regex = /[0-9]|\./;

    if( !regex.test(key) ) {
      event.returnValue = false;
      if(event.preventDefault) event.preventDefault();
    }
  }

  public validateKeyup(evt) {
    const value = evt.target.value;
    if (value > this.max) {
      this._fieldValue = this.max;
      evt.target.value = this.max;
    } else {
      this._fieldValue = Number(value);
    }

    if (this._fieldValue && this._fieldValue.toString().length) {
      this.writeValue(this._fieldValue);
      this.onchange.emit(this._fieldValue);
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

}
