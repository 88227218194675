import {AfterContentInit, Component, ElementRef, EventEmitter, OnInit, Output, QueryList} from '@angular/core';
import {fromEvent} from 'rxjs';
import {MultiselectBindService} from '../multiselect-bind.service';

@Component({
  selector: 'ie-multiselect-content',
  templateUrl: './multiselect-content.component.html',
  styleUrls: ['../ie-multiselect.component.scss']
})
export class MultiselectContentComponent implements OnInit, AfterContentInit {

  @Output() onclose: EventEmitter<void> = new EventEmitter();

  public host: HTMLElement;
  public height: number;

  private _currentFocusedIndex = 0;
  private _initialized: boolean;

  public someSelected(): boolean {
    return Array.from(this.parent.getMultiselect().options).some(({option}) => option.selected);
  }

  public close(): void {
    if (this._initialized) {
      this.onclose.emit();
    }
  }

  public focusMultiselectAutocompleteInput(): void {
    setTimeout(() => {
      try {
        (document.querySelector('#ieselectinput') as HTMLElement).focus();
      } catch (e) {}
    }, 10);
  }

  public focusOnNextOption() {
    const options: any = document.querySelectorAll('#multiselect-option');
    if (this._currentFocusedIndex === options.length) return;
    const option = options[this._currentFocusedIndex];
    if (!options || options.length === 0 || !option) return;
    try {
      options[this._currentFocusedIndex].focus();
      this.scrollToFocused(option);
      this._currentFocusedIndex = this._currentFocusedIndex + 1;
    } catch (e) {}
  }

  public focusOnPreviousOption() {
    if (this._currentFocusedIndex === 0) return;
    this._currentFocusedIndex = this._currentFocusedIndex - 1;
    const options: any = document.querySelectorAll('#multiselect-option');
    const option = options[this._currentFocusedIndex];
    if (!options || options.length === 0 || !option) return;
    option.focus();
    this.scrollToFocused(option);
  }

  public toggleAll(): void {
    const someSelected = this.someSelected();
    this.parent.getMultiselect().options.forEach(({option}) => {
      if (!this.parent.getMultiselect().filter || this.parent.getMultiselect().filter.length === 0) { option.selected = !someSelected; return; }
      const isFiltered = option.value.toString().toLowerCase().indexOf(this.parent.getMultiselect().filter.toLowerCase()) > -1;
      option.selected = isFiltered ? !someSelected : false;
    });

    this.parent.getMultiselect().writeValue(this.parent.getMultiselect().options.map(({option}) => option).filter(o => o.selected).map( o => o.key));
  }

  private sortMultioptions(): void {
    if (!this.parent.getMultiselect().multiple) { return; }

    setTimeout(() => {
      const parent = this.el.nativeElement.querySelector('#options-container');
      const children = this.el.nativeElement.querySelectorAll('ie-multiselect-option');
      this.removeChildrenNodes(parent);
      this.appendSortedChildren(children, parent);
    }, 0);
  }

  private appendSortedChildren(nodeList: QueryList<HTMLElement>, parent): void {
    Array.from(nodeList).forEach((next: HTMLElement) => {
      const index = next.getAttribute('data-selected') === 'true' ? 0 : nodeList.length;
      parent.insertBefore(next, parent.children[index]);
    });
  }

  private removeChildrenNodes(parentNode: any): void {
    let child = parentNode.lastElementChild;
    while (child) {
      parentNode.removeChild(child);
      child = parentNode.lastElementChild;
    }
  }

  private checkOverflow(): void {
    const setContainerPosition = () => {
      if (window.innerWidth < 769) { return; }
      try {
        const hostContainer = this.parent.getMultiselect().container.nativeElement;
        const hostContainerRect = hostContainer.getBoundingClientRect();
        const optionsContainer = document.querySelector('.ie-multiselect-options-container') as HTMLElement;
        if (hostContainerRect.top > window.innerHeight / 2) {
          optionsContainer.style.top = 'unset';
          optionsContainer.style.bottom = '0';
          optionsContainer.style.marginBottom = '0';
        }

        if (hostContainerRect.right > (window.innerWidth - 100)) {
          optionsContainer.style.right = '0';
          optionsContainer.style.left = 'unset';
        }
      } catch (e) {}
    };
    setContainerPosition();
  }

  private scrollToFocused(element: HTMLElement): void {
    const wrapper = document.querySelector('.ie-multiselect-option-wrapper');
    const itemHeight = element.getBoundingClientRect().height;
    const optionOffsetFromScrollTop = itemHeight * (this._currentFocusedIndex + 1);
    const optimalScrollPosition = optionOffsetFromScrollTop - itemHeight;
    wrapper.scrollTop = Math.max(0, optimalScrollPosition);
  }

  private subscribeToKeyboardEvents(): void {
    fromEvent(this.el.nativeElement, 'keydown')
      .subscribe((evt: KeyboardEvent) => {
        switch (evt.keyCode) {
          // Arrow Up
          case 38: { this.focusOnPreviousOption(); evt.preventDefault(); break; }
          // Arrow Down
          case 40: { this.focusOnNextOption(); evt.preventDefault(); break; }
          case 27: { this.onclose.emit(); break; }
          default: { this.focusMultiselectAutocompleteInput(); }
        }
      });
  }

  constructor(
    public parent: MultiselectBindService,
    private el: ElementRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.focusMultiselectAutocompleteInput();
    this.sortMultioptions();
    this.subscribeToKeyboardEvents();
    setTimeout(() => {
      this.checkOverflow();
    }, 100);
    setTimeout(() => {
      this._initialized = true;
    }, 500);
  }

}
