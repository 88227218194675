import { IEInputOutput } from './ie-input-output-accessor';
import {HttpErrorResponse} from '@angular/common/http';
import { IERangeInputOutput } from './ie-range-input-output-accessor';

/** Provide IE and IE11 SUPPORT FOR OBJECT ENTRIES ES6 STANDARD METHOD */
if (!Object.entries) {
    Object.entries = function( obj ) {
        let ownProps =
            Object.keys( obj ),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array
        while (i--) { resArray[i] = [ownProps[i], obj[ownProps[i]]]; }

        return resArray;
    };
}

export interface ErrorResponse {
    message: string;
    errors: any;
}

export class Validator {
    validate(error: ErrorResponse | HttpErrorResponse, input: IEInputOutput | IERangeInputOutput): void {
        if (error instanceof HttpErrorResponse) { error = error.error; }
        if (!error.hasOwnProperty('errors')) { return; }
        Object.entries((error as any).errors).forEach( ([key, value]: [string, string]) => {
          if (input.name instanceof Array) {
            input.name.includes(key.toString()) ? input.error = `${value}` : Object.call(null);
          } else {
            (input.name.toString() === key.toString()) ? input.error = value : Object.call(null);
          }
        });
    }
}
