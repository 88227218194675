<svg 
  version="1.1" 
  id="Warstwa_1" 
  xmlns="http://www.w3.org/2000/svg" 
  xmlns:xlink="http://www.w3.org/1999/xlink" 
  x="0px" 
  y="0px"
  viewBox="0 0 40 40" 
  style="enable-background:new 0 0 40 40;" 
  xml:space="preserve"
  class="scroll-to-top"
  [class.visible]="isVisible"
  (click)="scrollToTop()"
>
<style type="text/css">
	.st0{fill:#242488;}
	.st1{fill:none;stroke:#FFFFFF;}
</style>
<circle id="Ellipse_7" class="st0" cx="20" cy="20" r="20"/>
<g>
	<line id="Line_2" class="st1" x1="20" y1="32.5" x2="20" y2="7.5"/>
	<polyline class="st1" points="12,16.5 20,7.5 28,16.5 	"/>
</g>
</svg>
