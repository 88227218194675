import {Component, Input, ViewEncapsulation, OnInit} from '@angular/core';
import {IOrderProduct} from '@bast/domain/order/order';

@Component({
  selector: `ie-order-received-product-tile`,
  templateUrl: `./order-received-product-tile.component.html`,
  styleUrls: [`./order-received-product-tile.component.scss`],
  encapsulation: ViewEncapsulation.None
})
export class OrderReceivedProductTileComponent implements OnInit {

  @Input() index = 0;
  @Input() disabled = false;
  @Input() product: IOrderProduct;

  constructor() {}

  ngOnInit() {
  }

}
