import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {APIService} from '../../../app/api/apiservice.service';
import {from, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {UserDomain} from '../user';

export class IP {
  id?: number;
  name?: string;
  ip?: string;
}

export class IPDomain {

  public create(name: string, ip: string): Promise<IP> {
    return this.api.request(ENDPOINTS.createIP, 'POST', { ip: { name, ip }});
  }

  public update(userIp: IP): Promise<IP> {
    return this.api.request(ENDPOINTS.updateIP(userIp.id), 'POST', { ip: userIp });
  }

  public deleteIp(id: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteUserIp(id), 'DELETE');
  }

  public getMyIps(): Observable<IP[]> {
    return from(this.user.myself())
      .pipe(switchMap(u => this.api.request(ENDPOINTS.getSupplierIPS(u.supplier_id), 'GET') ))
      .pipe(map(({ data }) => data));
  }

  constructor(private api: APIService, private user: UserDomain) {}
}
