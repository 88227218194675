import {Directive, ElementRef, Output, HostListener, EventEmitter, Input} from '@angular/core';

/**
 * Fires @Output event when user clicks outside HTMLElement
 * e.g. <div (clickOutside)='handleClickOutside($event)'></div>
 */
@Directive({
  selector: '[clickOutside]'
})
export class ClickOutsideDirective {

  @Output()
  public clickOutside = new EventEmitter<MouseEvent>();

  @HostListener('document:click', ['$event', '$event.target'])
  public onClick(event: MouseEvent, targetElement: HTMLElement): void {
      if (!targetElement) { return; }
      const clickedInside = this._elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
          this.clickOutside.emit(event);
      }
  }

  constructor(private _elementRef: ElementRef) {}

}
