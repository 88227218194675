import {Pipe, PipeTransform} from '@angular/core';
import {BastPrivilegeMap} from '../model/BastPrivilegeMap';

@Pipe({
  name: 'vaultprivilege',
})
export class VaultPrivilegePipe implements PipeTransform {

  transform(value: string | string[]): object {
    if (value instanceof Array) { return value.map(p => BastPrivilegeMap[p]); }
    return BastPrivilegeMap[value];
  }
}
