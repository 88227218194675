import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlagComponent } from '../component/flag/flag.component';
import { FlagAnchor } from '../directive/flag-anchor.directive';
import { FlagContainerComponent } from '../component/flag-container/flag-container.component';
import { IEIconModule } from '../../ie-icon/ie-icon.module';

@NgModule({
  imports: [
    CommonModule,
    IEIconModule,
  ],
    declarations: [
        FlagContainerComponent,
        FlagComponent,
        FlagAnchor
    ],
    exports: [
        FlagContainerComponent
    ],
    entryComponents: [
        FlagComponent
    ]
})
export class IEFlagModule {}
