import {DatepickerMode} from './datepicker-mode';
import {DatepickerRange} from './datepicker-range';

const moment = require('moment');

export class DatepickerUtils {

  public static getNavigatorLabel(startDate: Date, endDate?: Date, mode?: DatepickerMode, range?: DatepickerRange) {
    moment.locale('pl');
    if (range === DatepickerRange.DAY) {
      return moment(startDate).format('MMMM YYYY');
    } else if (range === DatepickerRange.MONTH) {
      return moment(startDate).format('YYYY');
    } else if (range === DatepickerRange.YEAR) {
      return `${moment(startDate).format('YYYY')} - ${moment(endDate).format('YYYY')}`;
    } else {
      return moment(startDate).format('MMMM YYYY');
    }
  }

  public static getTimestamp(day: number, cal): number {
    return new Date(cal.getYear(), cal.getMonth(), day).getTime();
  }

  public static getDate(cal, day?: number, month?: number, year?: number): Date {
    return new Date(year || cal.getYear(), month || cal.getMonth(), day || cal.getDate().getDate());
  }

  public static isUnixTimestamp(time: number): boolean {
    return ((String(time)).match(/0+$/gi) || [''])[0].length < 5;
  }

  public static toBastDate(timestamp: number, format?: string): string {
    if (!timestamp) { return '-'; }
    const date = DatepickerUtils.isUnixTimestamp(timestamp) ? new Date(timestamp * 1000) : new Date(timestamp);
    moment.locale('pl');
    return moment(date).format(format || 'DD.MM.YYYY');
  }

  public static toBastHour(timestamp: number, format?: string): string {
    if (!timestamp) { return '-'; }
    const date = DatepickerUtils.isUnixTimestamp(timestamp) ? new Date(timestamp * 1000) : new Date(timestamp);
    moment.locale('pl');
    return moment(date).format(format || 'LTS');
  }

  public static isTimestampArray(date?: any): boolean {
    if (!date) { return false; }
    if (date instanceof Array && date.length && typeof date[0] === 'number') { return true; }
    return false;
  }

  public static isSingleTimestamp(date?: any): boolean {
    if (!date) { return false; }
    if (typeof date === 'number' || typeof date === 'string') { return true; }
    return false;
  }

  public static isStringArray(date?: any): boolean {
    if (!date) { return false; }
    if (date instanceof Array && date.length && typeof date[0] === 'string') { return true; }
    return false;
  }

  public static timestampArrayToDateArray(date?: any): Date[] {
    const [from, to] = date;
    return [from ? new Date(from * 1000) : null, to ? new Date(to * 1000) : null];
  }

  public static timestampStringArrayToDateArray(date?: any): Date[] {
    const [from, to] = date;
    return [from ? new Date(Number(from) * 1000) : null, to && to !== 'null' ? new Date(Number(to) * 1000) : null];
  }

  public static isAfter(date1, date2): boolean {
    if (!date1 || !date2) { return false; }
    return date1.getTime() > date2.getTime();
  }

  public static isBefore(date1, date2): boolean {
    if (!date1 || !date2) { return true; }
    return date1.getTime() < date2.getTime();
  }

  public static copyDate(date?: Date): Date {
    if (!date) { return new Date(); }
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
  }
}
