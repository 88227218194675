import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BulkList} from '@shared/utils/bulk-operation';

@Component({
  selector: 'ie-table-change-column-order',
  templateUrl: './table-change-column-order.component.html',
  styleUrls: ['./table-change-column-order.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TableChangeColumnOrderComponent implements OnInit {

  @Input() columns: any;
  @Output() onorderchange: EventEmitter<any> = new EventEmitter();
  @Output() onclose: EventEmitter<any> = new EventEmitter();

  public orderChanged(e: any): void {
    this.columns = new BulkList(this.columns).move(e.previousIndex, e.currentIndex).toArray();
  }

  constructor() { }

  ngOnInit() {}

}
