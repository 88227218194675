import {from, Observable} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {map} from 'rxjs/operators';
import {URLUtils} from '../../../app/shared/utils/url-utils';
import {NonBlockingQueue} from '../../../app/shared/utils/non-blocking-queue';
import {SupplierIntegrationTask} from '../../../app/core/suppliers/model/supplier-integration-task';
import {APIService} from '../../../app/api/apiservice.service';

export enum STOCK_LIST_TYPE {
  FAILED = 'failed',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  CONFIRMED = 'confirmed',
  INPROGRESS = 'in_progress',
  WAITING = 'waiting'
};

export interface StockList {
  id?: number;
  supplier_id?: number;
  supplier_name?: number;
  supplier_integration_task_id?: number;
  supplier_manual_import_task_id?: number;
  name?: string;
  type?: string;
  extension?: string;
  size?: number;
  url?: string;
  execute_at?: number;
  finished_at?: number
  from_integration?: boolean;
  status?: string;
  report_url?: string;
};

export class StockListDomain {

  public get(status?: string, producer?: number, supplier?: number, dateFrom?: number, dateTo?: number): Promise<StockList[]> {
    const filters = {
      status,
      producer,
      supplier,
      date_from: dateFrom,
      date_to: dateTo,
      all: 1
    };

    return this.api.request(ENDPOINTS.getStockList + URLUtils.buildQueryUrl(filters), 'GET')
      .then(({data}) => data.map(sl => ({...sl, integration: sl.supplier_manual_import_task_id ? 'manualna' : (sl.supplier_integration_task_id ? 'automatyczna' : 'brak') }) ));
  }

  /**
   * Returns list of stock list for supplier
   * @param supplierId Supplier id
   * @returns Observable<PriceList[]>
   */
  public getBySupplierId(supplierId?: number): Observable<StockList[]> {
    return from(this.api.request(ENDPOINTS.getSupplierStockList(supplierId), 'GET'))
      .pipe(map(({data}) => data))
      .pipe(map(sl => sl.map(s => ({...s, integration: s.producer_manual_import_task_id ? 'manualna' : (s.producer_integration_task_id ? 'automatyczna' : 'brak') }) )));
  }

  /**
   * Creates stock list from selected material
   * @param supplierId Supplier id
   * @param materialId Material id
   * @param validFrom Unix timestamp from which stock list is valid
   * @returns Promise<StockList>
   */
  public create(
    supplierId: number,
    materialId: number,
    validFrom: number,
  ): Observable<any> {
    const stock_list = {
      supplier: supplierId,
      material: materialId,
      valid_from: validFrom,
    };

    return from(this.api.request(ENDPOINTS.createStockList, 'POST', { stock_list }));
  }

  /**
   * Updates stock list object
   * @param stockListId Supplier stock list id
   * @param supplierId Supplier id
   * @param materialId Material id with which stock list is updated
   * @param validFrom Unix timestamp that defines start of stock list validity
   */
  public update(
    stockListId: number,
    supplierId: number,
    materialId: number,
    validFrom: number
  ): Observable<any> {
    const stock_list = {
      supplier: supplierId,
      material: materialId,
      valid_from: validFrom
    };

    return from(this.api.request(ENDPOINTS.updateStockList(stockListId), 'POST', { stock_list }));
  }

  /**
   * Removes existing stock list
   * @param stockListId Stock list id
   */
  public delete(stockListId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteStockList(stockListId), 'DELETE');
  }

  /**
   * Deletes multiple stock lists. Deletion is guaranteed for passed arguments.
   * If one request fails queue continues to work.
   * @param stockLists Array of StockList objects
   * @returns Promise with array of deleted stock lists ids. Note that stock list that failed to be deleted
   * will not be included within that array.
   */
  public deleteBulk(stockLists: StockList[]): Promise<number[]> {
    return new Promise((resolve) => {
      new NonBlockingQueue(stockLists)
        .pop((stockList, queueResolve, queueReject) => {
          this.delete(stockList.id).then(() => queueResolve(stockList.id)).catch(() => queueReject(stockList));
        }, (ids) => resolve(ids));
    });
  }

  /**
   * Creates manual integration for stock list
   * @param stockListId
   * @param executeAt Unix timestamp that defines when to start processing
   */
  public createIntegrationForStockList(stockListId: number, executeAt: number): Observable<any> {
    const payload = {
      supplier_manual_import_task: {
        price_list: stockListId,
        task: { execute_at: executeAt }
      }
    };
    return from(this.api.request(ENDPOINTS.createStockListManualImportTask(stockListId), 'POST', payload));
  }

  /**
   * Sets status for integration both manual or automatic on selected stock list
   * @param stockList Supplier stock list object
   * @returns Observable<any>
   */
  public setTaskStatus(stockList: StockList, status: STOCK_LIST_TYPE): Promise<SupplierIntegrationTask> {
    const confirmManualIntegration = (taskId: number) => {
      const body = {
        supplier_manual_import_task: {
          task: {
            status,
          }
        }
      };
      return this.api.request(ENDPOINTS.updateSupplierManualTask(taskId), 'POST', body);
    };

    const confirmIntegration = (taskId: number) => {
      const body = {
        supplier_integration_task: {
          task: {
            status
          }
        }
      };
      return this.api.request(ENDPOINTS.updateSupplierTaskIntegration(taskId), 'POST', body);
    };

    return stockList.supplier_integration_task_id ?
      confirmIntegration(stockList.supplier_integration_task_id) :
      confirmManualIntegration(stockList.supplier_manual_import_task_id);
  }

  /**
   * Sets task status to 'confirmed' on selected stock list
   * @param stockList Supplier stock list
   */
  public confirm(stockList: StockList): Promise<SupplierIntegrationTask> {
    return this.setTaskStatus(stockList, STOCK_LIST_TYPE.CONFIRMED);
  }

  /**
   * Sets task status to 'failed' on selected stock list
   * @param stockList Supplier stock list
   */
  public suspendImport(stockList: StockList): Promise<SupplierIntegrationTask> {
    return this.setTaskStatus(stockList, STOCK_LIST_TYPE.FAILED);
  }

  constructor(
    private api: APIService,
  ) {}

}
