import {forkJoin, from, Observable, of, ReplaySubject, Subject, Subscription, BehaviorSubject} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {APIService} from '../../../app/api/apiservice.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {pick} from 'lodash-es';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/from';
import 'rxjs/add/operator/switchMap';
import {IOrderProduct, Order, OrderStatus, SupplierOrder} from './order';
import {ACTIVE_ORDER_KEY, IOrdersState} from './order-state';
import {IProductLotInterface} from '@bast/domain/product/product';
import {User, UserDomain} from '@bast/domain/user';
import {Injector} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';

export enum OrderStateSelector {
  Active = 'active',
  Orders ='orders'
}

export class OrderDomain {

  private _ordersState$: ReplaySubject<IOrdersState> = new ReplaySubject();
  private _getOrdersSubject$: BehaviorSubject<any> = new BehaviorSubject(null);
  private _getRecievedOrdersSubject$: BehaviorSubject<any> = new BehaviorSubject(null);
  private __ordersState: IOrdersState = { active: null, orders: [], newOrder: false };
  private __orderChanged: Subject<any> = new Subject();
  private _newOrderSubscription = new Subscription();

  /**
   * Returns state of order domain.
   * @param selector Selector used to prone state object
   */
  public state(selector?: string|string[], force?: boolean): Observable<IOrdersState> {
    if (!this.__ordersState.orders.length || force) { this.init(); }
    return this._ordersState$.asObservable().pipe(map(state => pick(state, selector ? [selector] : ['active', 'orders']) ));
  }

  /**
   * Returns order object by id
   * @param orderId
   * @param observeOrder If true order will be observable by cart drawer
   */
  public getById(orderId: number, observeOrder?: boolean) {
    if (observeOrder) {
      setTimeout(() => this.__orderChanged.next(), 0);
      return this.__orderChanged.asObservable().pipe(switchMap(() => this.fetch(orderId)));
    } else {
      return this.fetch(orderId);
    }
  }

  public getReceived(): Observable<any> {
    return this.getReceivedOrders$();
  }

  public getReceivedById(orderId: number): Observable<any> {
    return this.getReceivedOrderDetails$(orderId)
      .pipe(switchMap( () => this.getReceivedOrderProducts$(orderId),
        (order, products) => ({ order, products })))
      .pipe(map( result => {
        const order = new Order(result.order, false, this.userDomain);
        order.products = result.products;

        return order;
      }));
  }

  public activate(order: Order): Observable<SupplierOrder> {
    if (!order||!order.id) { return of(null); }
    if (this.__ordersState.active && order.id===this.__ordersState.active.id) { return of(this.__ordersState.active); }

    order.active = true;
    this.sessionStorage.store(ACTIVE_ORDER_KEY, order.id);
    this.__ordersState.orders.forEach(o => o.active = o.id===order.id);

    return this.fetch(order.id)
      .pipe(tap((o) => this.setState({ active: o, orders: this.__ordersState.orders })));
  }

  /**
   * Returns details of active order if toggled
   */
  public active(): Observable<SupplierOrder> {
    const activeOrderId = this.sessionStorage.retrieve(ACTIVE_ORDER_KEY);
    if (activeOrderId) {
      return this.fetch(activeOrderId, true);
    }

    return of(null);
  }

  public create(order): Observable<any> {
    return from(this.createOrder$(order))
      .pipe(switchMap(({id}) => this.fetch(id, false)))
      .pipe(tap(() => {
        this.getOrders$().subscribe(orders => this.setState({ orders }));
      }));
  }

  public update(orderId: number, update): Observable<any> {
    return this.updateOrder$(orderId, update)
      .pipe(tap(() => {
        if (this.__ordersState.active && orderId === this.__ordersState.active.id) {
          this.setState({ active: this.__ordersState.active.clone(update) });
        }

        this.init();
      }));
  }

  public updateReceived(orderId: number, update?: { status?, user?, comment?, flag? }): Observable<any> {
    return this.updateReceivedOrder$(orderId, update);
  }

  public delete(orderId: number): Promise<void> {
    return this.deleteOrder$(orderId)
      .then(() => {
        if (this.sessionStorage.retrieve(ACTIVE_ORDER_KEY) === orderId) { this.sessionStorage.clear(ACTIVE_ORDER_KEY); }

        const orders = this.__ordersState.orders.filter(({id}) => id !== orderId);
        const active = this.__ordersState.active && this.__ordersState.active.id === orderId ? null : this.__ordersState.active;
        this.setState({ active, orders });
      });
  }

  public addToOrder(lot: IProductLotInterface, order: SupplierOrder): Observable<any> {
    return this.activate(order)
      .pipe(switchMap( (activeOrder: SupplierOrder) => {
        const p = activeOrder.findProduct(lot.id);
        if (p) {
          const payload = { ...lot, quantity: lot.quantity + (p.quantity||0) };
          return this.updateProductInCart$(payload, p.id);
        } else {
          return this.addToCart$(lot, order);
        }
      }, (mapped_order, mapped_product) => ({ order: mapped_order, product: mapped_product }) ))
      .pipe(map( (result: any) => {
        const new_product: IOrderProduct = {
          id: result.product.id,
          product_name: lot.product_name,
          supplier_product_id: result.product.supplier_product,
          supplier_id: lot.supplier_id,
          supplier_name: lot.supplier_name,
          price: result.product.price,
          quantity: result.product.quantity
        };

        this.__ordersState.active.addProduct(new_product);
        this.setState({ active: this.__ordersState.active });
        this.orderChanged(this.__ordersState.active);
      }));
  }

  public updateProductQuantity(productId: number, quantity: number, add: boolean) {
    return this.updateProductQuantity$(productId, quantity, add)
      .pipe(tap(({ order }) => {
        if (this.__ordersState.active && order === this.__ordersState.active.id) {
          this.fetch(order, true).subscribe();
        }
      }));
  }

  public deleteProductFromOrder(product: IOrderProduct, orderId: number): Observable<void> {
    return this.deleteProductFromOrder$(product)
      .pipe(tap( () => {
        if (this.__ordersState.active && orderId === this.__ordersState.active.id) {
          this.__ordersState.active.deleteProduct(product);
          this._ordersState$.next(this.__ordersState);
        }
      }));
  }

  /**
   * Returns complete order object fetched from api
   * @param orderId
   * @param active Optional param which can be set if order should be set as active
   */
  public fetch(orderId, active?): Observable<SupplierOrder> {
    const order$ = this.getOrder$(orderId);
    const orderProducts$ = this.getOrderProducts$(orderId);
    return forkJoin({ order: order$, orderProducts: orderProducts$ })
      .pipe(map(({ order, orderProducts }) => {
        if (!order) { throw new Error(`Unable to fetch order of id: ${orderId}`); }
        const supplierOrder = new SupplierOrder({ ...order, products: orderProducts }, this.userDomain);
        if (active) { this.setState({ active: supplierOrder }); }

        return supplierOrder;
      }));
  }

  public getWarehouses(): Observable<{ data: Array<{ id: number, location_id: number, name: string }> }> {
    return this.getWarehouses$();
  }

  public send(orderId: number, warehouseId: number, comment: string): Observable<void> {
    return this.sendOrder$(orderId, warehouseId, comment)
      .pipe(tap(() => {
        if (this.__ordersState.active.id === orderId) {
          this.__ordersState.active = null;
          this.__ordersState.orders.map(o => {
            if (o.id === orderId) { o.status = OrderStatus.Sent; }
            o.active = false;
            return o;
          });
          this._ordersState$.next(this.__ordersState);
        }
      }));
  }

  public onchange(): Observable<any> { return this.__orderChanged.asObservable(); }

  private orderChanged(data?: any): void {
    this.__orderChanged.next(data);
  }

  /**
   * Observes received orders list with fixed
   * interval to determine if new order was created
   */

  private observeReceivedOrders (): void {
    this._newOrderSubscription = this.getBypassedReceivedOrders$()
      .pipe(filter(orders => {
        if (orders.some(order => order.status === 'new')) {
          return true;
        }
      }))
      .subscribe(
        res => {
          this.setState({ newOrder: true });
          this.setInterval();

        },
        err => {
          if (err.status !== 403) {
            this.setInterval();
          }
        }        
      );
  }

  private setInterval() {
    const interval = setInterval(() => {
      clearInterval(interval);
      this._newOrderSubscription.unsubscribe();
      this.observeReceivedOrders();
    }, 100000);
  }

  /**
   * Emits new order state to all observers
   * @param state partial valid state
   */
  private setState(state: IOrdersState): void {
    if (!state) { return; }
    if (state.active && typeof state.active !== 'object') { return; }
    this.__ordersState = {
      ...this.__ordersState,
      ...(state.active && { active: state.active }),
      ...(state.orders && { orders: state.orders }),
      ...(state.newOrder && { newOrder: state.newOrder })
    };
    this._ordersState$.next(this.__ordersState);
  }

  private getReceivedOrders$(): Observable<Order[]> {
    if (this._getRecievedOrdersSubject$.value) {
      return this._getRecievedOrdersSubject$.value;
    } else {
      return from(this.apiService.request(ENDPOINTS.getReceivedOrders, 'GET')).pipe(map(({data}) => data));
    }
  }

  private getBypassedReceivedOrders$(): Observable<Order[]> {
    const apikey = (window['angularInjector'] as Injector).get(LocalStorageService).retrieve('APIKEY');
    const header = new HttpHeaders({ Authorization : apikey });
    return this.apiService.http.get<{ data: Order[] }>(this.apiService.API_URL + ENDPOINTS.getReceivedOrders, { headers: header })
      .pipe(map(({ data }) => data));
  }

  private getReceivedOrderDetails$(orderId: number): Observable<Order> {
    return from(this.apiService.request<Order>(ENDPOINTS.getReceivedOrderDetails(orderId), 'GET'));
  }

  private getReceivedOrderProducts$(orderId: number): Observable<IOrderProduct[]> {
    return from(this.apiService.request(ENDPOINTS.getReceivedOrderProducts(orderId), 'GET')).pipe(map(({data}) => data))
  }

  private updateReceivedOrder$(orderId: number, update): Observable<any> {
    const {status, user, comment, flag} = update;
    const payload = {
      ...(status && { status }),
      ...(user && { user }),
      ...(comment && { comment: { content: comment} }),
      ...(flag && { custom_flag: flag }),
    };

    return from(this.apiService.request(ENDPOINTS.updateOrderStatus(orderId), 'POST', { supplier_order: payload } ));
  }

  private updateOrder$(orderId: number, update): Observable<any> {
    const {warehouse, user, comment, code} = update;
    const payload = {
      order: {
        ...(warehouse && { warehouse }),
        ...(comment && { comment: { content: comment } }),
        ...(user && { user }),
        ...(code && { code })
      }
    };

    return from(this.apiService.request(ENDPOINTS.updateOrder(orderId), 'POST', payload));
  }

  private getWarehouses$(): Observable<{ data: Array<{ id: number, location_id: number, name: string }> }> {
    return from(this.apiService.request(ENDPOINTS.getWarehouses, 'GET'))
      .pipe(map(({ data }) => data)) as Observable<{ data: Array<{ id: number, location_id: number, name: string }> }>;
  }

  private sendOrder$(orderId: number, warehouseId: number, comment: string): Observable<void> {
    return from(
      this.apiService.request(
        ENDPOINTS.sendUserOrder(orderId),
        'POST',
        { order: { warehouse: warehouseId, comment } }
      )
    ) as Observable<void>;
  }

  private getOrders$(): Observable<Array<Order>> {
    if (this._getOrdersSubject$.value) {
      return this._getOrdersSubject$.value;
    } else {
      return from(this.apiService.request(ENDPOINTS.getOrders, 'GET') )
      .pipe(map( ({data}) => data ))
      .pipe(catchError(() => []));
    }
  }

  private getOrder$(orderId: number): Observable<Order> {
    return from(this.apiService.request<Order>(ENDPOINTS.getOrder(orderId), 'GET' ));
  }

  private createOrder$(order: Order) {
    return this.apiService.request<Order>(ENDPOINTS.createOrder, 'POST', { order });
  }

  private deleteOrder$(orderId: number) {
    return this.apiService.request(ENDPOINTS.deleteOrder(orderId), 'DELETE');
  }

  private deleteProductFromOrder$(product: IOrderProduct): Observable<void> {
    return from(this.apiService.request(ENDPOINTS.deleteProductFromOrder(product.id), 'DELETE' )) as Observable<void>;
  }

  private addToCart$(lot: IProductLotInterface, order: Order) {
    const supplier_order_product = {
      supplier_product : lot.supplier_product_id ? lot.supplier_product_id : lot.id,
      quantity: lot.quantity,
      order: order.id
    };
    return this.apiService.request(ENDPOINTS.addProductToOrder(order.id), 'POST', { order_product: supplier_order_product });
  }

  private getOrderProducts$(orderId: number): Observable<IOrderProduct[]> {
    return from(this.apiService.request(ENDPOINTS.getOrderProducts(orderId), 'GET'))
      .pipe(map(({data}) => data))
      .pipe(catchError(() => of([]) ));
  }

  private updateProductQuantity$(productId: number, quantity: number, add: boolean): Observable<any> {
    return from(
      this.apiService.request(
        ENDPOINTS.updateProductInCart(productId),
        'POST',
        { order_product: { quantity, add }}
      )
    );
  }

  private updateProductInCart$(lot: IProductLotInterface, productOrderId: number) {
    const supplierOrderProduct = {
      quantity: lot.quantity
    };

    return this.apiService.request(
      ENDPOINTS.updateProductInCart(productOrderId),
      'POST',
      { order_product: supplierOrderProduct }
    );
  }

  public init(): void {
    const orders$ = this.getOrders$();
    const activeOrder$ = this.active();
    this._getOrdersSubject$.next(orders$)

    forkJoin({ orders: orders$, active: activeOrder$ })
      .subscribe(
        ({ orders, active }) => {
          this.__ordersState = { active, orders: orders.map(o => new Order(o, active && o.id===active.id, this.userDomain)) };
          this._ordersState$.next(this.__ordersState);
        });
    this.observeReceivedOrders();
  }

  constructor(
    private apiService: APIService,
    private sessionStorage: SessionStorageService,
    private userDomain: UserDomain
  ) {}
}
