import {BehaviorSubject, from, Observable} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {APIService} from '../../../app/api/apiservice.service';
import { UserDomain } from '../user';

export interface EtimCategory {
  id?: number;
  code?: string;
  name?: string;
  products?: EtimProduct[];
  expanded?: boolean;
}

export interface EtimProduct {
  id?: number;
  code?: string;
  name?: string
}

export interface EtimVersion {
  id?: number;
  name?: string;
  version?: number;
  current?: boolean;
}

export class EtimDomain {

  public etimVersions: BehaviorSubject<EtimVersion[]> = new BehaviorSubject([]);

  private _etimCategories: Promise<EtimCategory[]>;

  public categories(etimVersion: string): Promise<EtimCategory[]> {
    // return this._etimCategories || (this._etimCategories = this.apiService.request(ENDPOINTS.getEtimCategories + `?version=${etimVersion}`, 'GET').then(({data}) => data));
    return this.apiService.request(ENDPOINTS.getEtimCategories + `?version=${etimVersion}`, 'GET').then(({data}) => data);
  }

  public products(etimCategory: any, etimVersion: string): Promise<any> {
    return this.apiService.request(ENDPOINTS.getEtimProducts(etimCategory) + `?version=${etimVersion}`, 'GET').then(({data}) => Promise.resolve(data));
  }

  public features(etimProduct: number, etimVersion: string): Promise<any> {
    return this.apiService.request(ENDPOINTS.getEtimFeatures(etimProduct) + `?version=${etimVersion}`, 'GET').then(({data}) => data);
  }

  public featureValues(etimProduct: number, etimFeature: string, etimVersion: string): Promise<any> {
    const query = `${ENDPOINTS.getEtimValues}?etim_product=${etimProduct}&etim_feature=${etimFeature}&version=${etimVersion}`;
    return this.apiService.request(query, 'GET').then(({data}) => data);
  }

  public update(productId: number, etim: any): Observable<any> {
    const isValid = (value) => value !== null && value !== undefined;
    const filteredEtim = {
      ...etim,
      etim_features: etim.etim_features
        .filter(feature =>
          isValid(feature.value_from) || (isValid(feature.value_from) && isValid(feature.value_to))
        )
    };

    return from(this.apiService.request(
      ENDPOINTS.updateProductEtimFeatures(productId),
      'POST',
      { product: filteredEtim }
    ));
  }

  public fetchAvailableEtimVersions(): Promise<any> {
    return this.apiService.request(ENDPOINTS.getEtimVersions, 'GET').then(({data}) => Promise.resolve(data));
  }

  constructor(
    private apiService: APIService
  ) {
  }
}
