import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ComponentRef,
  ComponentFactoryResolver,
  OnDestroy,
  EventEmitter,
  Output,
  Type, ChangeDetectorRef, ViewContainerRef, Inject
} from '@angular/core';
import { DynamicAnchor } from '../../ie-dynamic-anchor/ie-dynamic-anchor';
import {KeyboardAware} from '@shared/keyboard-aware/keyboard-aware';
import {DOCUMENT} from '@angular/common';
import {IEDialogContentComponent} from '@material/ie-dialog/component/ie-dialog-content.component';
import {Router} from '@angular/router';

@Component({
  selector: 'ie-dialog',
  template: ``,
})
export class IEDialogComponent extends KeyboardAware implements OnInit, OnDestroy {

  @Input() component: Type<any>;
  @Input() data: any;
  @Input() warnBeforeClose: boolean;
  @Input() overflow = false;
  @Output() onclose: EventEmitter<void> = new EventEmitter();
  @ViewChild(DynamicAnchor, { static: true }) anchor: DynamicAnchor;

  private componentRef: ComponentRef<any>;
  private contentRef: ComponentRef<IEDialogContentComponent>;

  public openDialog<T>(data, component?: any, warnBeforeClose?: boolean): T {
    if (component) { this.component = component; }
    this.data = data;
    this.warnBeforeClose = warnBeforeClose;
    this.mountComponent();

    setTimeout(() => {
      this.toggleBodyScroll(true);
    }, 200);

    return this.componentRef.instance as T;
  }

  public closeDialog(): void {
    this.toggleBodyScroll(false);
    this.unmountComponent();
    this.unsubscribe();

    this.cd.markForCheck();
    this.cd.detectChanges();
    this.onclose.emit();
  }

  public preDestroy(): void {
    if (!this.warnBeforeClose) { this.closeDialog(); }
  }

  public onEscape(): void {
    this.closeDialog();
  }

  private mountComponent(): void {
    if (this.componentRef) { return; }

    // Inject content component into body
    const factory = this.componentFactory.resolveComponentFactory(IEDialogContentComponent);
    this.contentRef = this.viewContainerRef.createComponent(factory);
    this.document.querySelector('body').appendChild(this.contentRef.location.nativeElement);
    this.contentRef.instance.close = this.closeDialog.bind(this);
    this.contentRef.instance.destroy = this.preDestroy.bind(this);
    this.contentRef.instance.overflow = this.overflow;

    // Inject passed component into content
    const contentFactory = this.componentFactory.resolveComponentFactory(this.component);
    this.componentRef = this.contentRef.instance.anchor.viewContainerRef.createComponent(contentFactory);
    Object.assign(this.componentRef.instance, this.data);
    this.componentRef.instance.close = this.closeDialog.bind(this);
  }

  private unmountComponent(): void {
    if (!this.contentRef) { return; }
    this.contentRef.destroy();
    this.componentRef.destroy();
    this.contentRef = null;
    this.componentRef = null;
  }

  private toggleBodyScroll(opened?): void {
    if (opened) document.body.classList.add('--prevent-scroll');
    else document.body.classList.remove('--prevent-scroll');
  }

  private onRouteChange(): void {
      this.router.events.subscribe(() => this.closeDialog());
  }

  constructor(
    private componentFactory: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    @Inject(DOCUMENT) private document: Document,
    private viewContainerRef: ViewContainerRef,
    private router: Router
  ) {
    super();
    this.onRouteChange();
  }

  ngOnInit() {}

  ngOnDestroy() {
    document.body.classList.remove('--prevent-scroll');
  }

}
