import {APIService} from '../../../app/api/apiservice.service';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';

export interface Directory {
  id?: number
  name?: string
  parent?: number
  parent_id?: number;
  user_id?: number
  created_at? : number
  updated_at? : number
  files?: Files[]
  subdirectory?: Subdirectory[]
  dummy?: boolean
  extension? :string,
  public?: boolean,
}

export interface Files {
  id? : number
  name? : string
  type? : string
  extension? : string
  url? : string
  size? : number
  created_at? : number
  updated_at? : number
}

export interface Subdirectory {
  id?: number
  name?: string
  parent?: number
  user_id?: number
  created_at? : number
  updated_at? : number
}

export class FileRepositoryDomain {

  public getDirectories(): Promise<any> {
    return this.api.request(ENDPOINTS.getDirectories, 'GET').then(({data}) => data);
  }

  public createDirectory(directory: Directory): Promise<any> {
    return this.api.request(ENDPOINTS.createDirectory, 'POST', { directory });
  }

  public getDirectory(id: number | string): Promise<any> {
    return this.api.request(ENDPOINTS.getDirectory(id), 'GET').then(data => data);
  }

  public deleteDirectory(id: number): Promise<any> {
    return this.api.request(ENDPOINTS.deleteDirectory(id), 'DELETE').then(data => data);
  }

  public updateDirectory(directory: Directory) {
    return this.api.request(ENDPOINTS.updateDirectory(directory.id), 'POST', { directory });
  }

  public uploadFile(directoryId: number, file) {
    return this.api.request(ENDPOINTS.updateDirectory(directoryId), 'POST', { file });
  }

  constructor(private api: APIService) {}
}