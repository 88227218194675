import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrdersListComponent } from '../component/orders-list/orders-list.component';
import { OrderPreviewComponent } from '../component/order-preview/order-preview.component';
import { OrdersReceivedListComponent } from '../component/orders-received-list/orders-received-list.component';
import { OrdersReceivedPreviewComponent } from '../component/orders-received-preview/orders-received-preview.component';
import { OrderSuccessComponent } from '../component/order-success/order-success.component';

const routes: Routes = [
  { path: '', component: OrdersListComponent },
  { path: 'preview/:id', component: OrderPreviewComponent },
  { path: 'order-success', component: OrderSuccessComponent },
  { path: 'received', component: OrdersReceivedListComponent },
  { path: 'received/preview/:id', component: OrdersReceivedPreviewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrdersRoutingModule { }
