import {APIService} from '../../../app/api/apiservice.service';
import {from, Observable} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {map} from 'rxjs/operators';
import {SupplierWarehouse} from '../supplier-location';

export class WarehouseDomain {

  public get(): Observable<{ data: Array<{ id: number, location_id: number, name: string }> }> {
    return from(this.api.request(ENDPOINTS.getWarehouses, 'GET'))
      .pipe(map(({ data }) => data)) as Observable<{ data: Array<{ id: number, location_id: number, name: string }> }>;
  }

  public getByLocationId(locationId: number): Promise<any> {
    return this.api.request(ENDPOINTS.getWarehousesById(locationId), 'GET').then(({data}) => data);
  }

  public create(location: any, warehouse: any): Promise<SupplierWarehouse> {
    return this.api.request(
      ENDPOINTS.createWarehouse(location.id),
      'POST',
      { warehouse }
    );
  }

  public update(warehouse: any): Promise<SupplierWarehouse> {
    return this.api.request(
      ENDPOINTS.updateWarehouse(warehouse.id),
      'POST',
      { warehouse });
  }

  public delete(warehouseId: number): Promise<void> {
    return this.api.request(
      ENDPOINTS.deleteWarehouse(warehouseId),
      'DELETE'
    );
  }

  constructor(private api: APIService) {}
}
