import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IEBreadcrumbComponent } from "./ie-breadcrumb.component";
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [CommonModule, RouterModule],
    declarations: [IEBreadcrumbComponent],
    exports: [IEBreadcrumbComponent]
})
export class IEBreadCrumbModule {}