import {APIService} from '../../../app/api/apiservice.service';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {from, Observable} from 'rxjs';
import {Supplier} from '../supplier';

export class SupplierLocation {
  id?: number;
  supplier_id?: number;
  country?: number;
  country_name?: string;
  name?: string;
  city?: string;
  post_code?: string;
  street?: string;
  house_number?: string;
  flat_number?: string;
  phone?: string;
  email?: string;
  warehouses_count?: number;
  warehouses: SupplierWarehouse[];
  contacts: LocationContact[];

  public static toDto(obj): object {
    const output = { location: {} };
    const {name, ...address} = obj;
    Object.assign(output.location, {name: obj.name, address });

    return output;
  }

  constructor(props?: any) {
    if (props) { Object.assign(this, props); }
    else {
      // Set Poland as a default country
      this.country = 168;
    }
  }
}

export interface SupplierWarehouse {
  id?: number;
  name?: string;
}

export interface LocationContact {
  id?: number,
  first_name?: string,
  last_name?: string,
  phone?: string,
  email?: string,
  position?: string
}

export class SupplierLocationDomain {

  public get(supplierId: number): Promise<SupplierLocation[]> {
    return this.api.request(ENDPOINTS.getSupplierLocations(supplierId), 'GET').then(({ data }) => Promise.resolve(data));
  }

  public getById(locationId: number): Promise<Location> {
    return this.api.request(ENDPOINTS.getLocation(locationId), 'GET');
  }

  public delete(locationId): Observable<void> {
    return from(this.api.request(
      ENDPOINTS.deleteLocation(locationId),
      'DELETE'
    )) as Observable<void>;
  }

  public create(location: SupplierLocation, supplier: Supplier): Promise<SupplierLocation> {
    return this.api.request(ENDPOINTS.createLocation(supplier.id), 'POST', SupplierLocation.toDto(location));
  }

  public update(location: SupplierLocation): Promise<SupplierLocation> {
    return this.api.request(ENDPOINTS.updateLocation(location.id), 'POST', SupplierLocation.toDto(location));
  }

  public warehouses(locationId: number): Promise<any> {
    return this.api.request(ENDPOINTS.getWarehousesById(locationId), 'GET').then(({data}) => data);
  }

  public createWarehouse(location: any, warehouse: any): Promise<SupplierWarehouse> {
    return this.api.request(
      ENDPOINTS.createWarehouse(location.id),
      'POST',
      { warehouse }
    );
  }

  public updateWarehouse(warehouse: any): Promise<SupplierWarehouse> {
    return this.api.request(
      ENDPOINTS.updateWarehouse(warehouse.id),
      'POST',
      { warehouse });
  }

  public deleteWarehouse(warehouseId: number): Promise<any> {
    return this.api.request(
      ENDPOINTS.deleteWarehouse(warehouseId),
      'DELETE'
    );
  }

  public locationContact(locationId: number): Promise<any> {
    return this.api.request(ENDPOINTS.getLocationContacts(locationId), 'GET');
  }

  constructor(private api: APIService) {}
}
