import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IEInlineDropdownComponent } from "./ie-inline-dropdown.component";
import {IEIconModule} from '../ie-icon/ie-icon.module';

@NgModule({
  imports: [CommonModule, IEIconModule],
    declarations: [IEInlineDropdownComponent],
    exports: [IEInlineDropdownComponent]
})
export class IEInlineDropdownModule {}
