import { NgModule, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { CommonModule } from '@angular/common';
import { RootComponent } from './root.component';
import { RouterModule, UrlSerializer } from '@angular/router';
import { IEFlagModule } from '@material/ie-flag-notification/module/flag.module';
import { IEScrollToTopModule } from '@material/ie-scroll-to-top/ie-scroll-to-top.module';
import { IEReportBugModule } from '@material/ie-report-bug/ie-report-bug.module';
import { CustomUrlSerializer } from '@shared/utils/url-serializer';
import { BlanketModule } from '@shared/token-expiry-blanket/module/token-expiry-blanket.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    IEFlagModule,
    IEScrollToTopModule,
    IEReportBugModule,
    BlanketModule
  ],
  declarations: [
    RootComponent
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler()
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer }
  ]
})
export class RootModule { }
