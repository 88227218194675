import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {APIService} from '../../../app/api/apiservice.service';

export class Unit {
  id?: number;
  name?: string;
  unit?: string;
  rate?: any;
  supplier?: number;
  supplier_id?: number;
  derived_unit?: boolean;
  base_unit?: any;
  base_unit_id?: any;

  public static toDto(obj: Unit) {
    const { name, unit, rate, base_unit, supplier } = obj;

    return {
      name,
      unit,
      rate,
      base_unit,
      ...(supplier && { supplier })
    };
  }

  public isDerivedUnit?(): boolean {
    return this.derived_unit;
  }

  constructor(props?) {
    Object.assign(this, props);
    if (props) {
      if (props.supplier_id) { this.supplier = props.supplier_id; }
      if (props.base_unit_id) { this.base_unit = props.base_unit_id; this.derived_unit = true; }
    }
  }
}

export class UnitsDomain {
  public get(): Promise<Unit[]> {
    return this.api.request(ENDPOINTS.getUnits, 'GET').then(({data}) => data);
  }

  public getById(id: number): Promise<Unit> {
    return this.api.request(ENDPOINTS.getUnit(id), 'GET');
  }

  public update(unit: Unit) {
    return this.api.request(ENDPOINTS.updateUnit(unit.id), 'POST', { unit: Unit.toDto(unit) });
  }

  public create(unit: Unit) {
    return this.api.request(ENDPOINTS.createUnit, 'POST', { unit: Unit.toDto(unit) });
  }

  public delete(unitId: number): Promise<void> {
    return this.api.request(ENDPOINTS.deleteUnit(unitId), 'DELETE');
  }

  constructor(private api: APIService) {}
}
