import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEMenuComponent, IEMenuOption } from './ie-menu.component';
import {OverlayModule} from '@angular/cdk/overlay';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule
  ],
  declarations: [
    IEMenuComponent,
    IEMenuOption,
  ],
  exports: [
    IEMenuComponent,
    IEMenuOption
  ],
})
export class IEMenuModule { }
