import { Component, ViewChild } from '@angular/core';
import { IEDialogComponent } from '@material/ie-dialog/component/ie-dialog.component';
import { ContactFormComponent } from './contact-form.component';

@Component({
    selector: 'ie-contact-form-anchor',
    template: `
        <ie-dialog [overflow]="true"></ie-dialog>
    `,
})
export class ContactFormAnchorComponent {

    @ViewChild(IEDialogComponent, { static: true }) dialog: IEDialogComponent;

    public openContactForm(): void {
        this.dialog.openDialog(null, ContactFormComponent);
    }

    public closeContactForm(): void {
        this.dialog.closeDialog();
    }

    public constructor() {}
}
