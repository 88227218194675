import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from '../authentication/service/authentication.guard';
import {PublicNewsletterModule} from '../public-newsletter/module/public-newsletter.module';

const routes: Routes = [
  { path: 'authorization', loadChildren: () => import('../authentication/module/authentication.module').then(m => m.AuthenticationModule)},
  { path: 'app', loadChildren: () => import('../core/module/core.module').then(m => m.CoreModule), canActivate: [AuthenticationGuard] },
  { path: 'help', loadChildren: () => import('..//public-help/module/public-help.module').then(m => m.PublicHelpModule) },
  { path: 'newsletter', loadChildren: () => import('../public-newsletter/module/public-newsletter.module').then(m => PublicNewsletterModule) },
  { path: '**', redirectTo: 'app', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RoutingModule { }
