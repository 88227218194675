import { Component, OnInit, Input, ViewEncapsulation, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'ie-paginator',
  templateUrl: './ie-paginator.component.html',
  styleUrls: ['./ie-paginator.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IEPaginatorComponent implements OnInit, OnChanges {

  @Input() max: number;
  @Input() count: number;
  @Input() currentPage: number;
  @Input() startIndex = 1;
  @Input() disabled: boolean;
  @Output() change: EventEmitter<number> = new EventEmitter();
  public pages: number;

  public maxPagesCount(maxResults, resultsOnPage): number {
    return Math.ceil(maxResults / resultsOnPage);
  }

  public isLeftButtonActive(): boolean {
    return this.currentPage > this.startIndex;
  }

  public isRightButtonActive(): boolean {
    return (this.currentPage < this.pages);
  }

  public prevPage(): void {
    if (this.currentPage <= this.startIndex) return;
    this.currentPage -= 1;
    this.change.emit(this.currentPage);
  }

  public nextPage(): void {
    if ( this.currentPage >= this.pages) return;
    this.currentPage += 1;
    this.change.emit(this.currentPage);
  }

  public changePage(page): void {
    const pageNumber = parseInt(page, 10);
    if (isNaN(pageNumber)) { return }
    if (pageNumber > this.pages) {
      this.currentPage = this.pages;
    } else {
      this.currentPage = pageNumber;
    }
    this.change.emit(this.currentPage);
  }

  constructor() { }

  ngOnInit() {}

  ngOnChanges() {
    if (this.max && this.count) {
      this.pages = this.maxPagesCount(this.max, this.count);
    }
  }

}
