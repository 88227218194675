import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  NgZone,
  ElementRef, ViewContainerRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IEInputOutputAccessor } from '@shared/input-output-accessor/ie-input-output-accessor';
import {Overlay, OverlayRef} from '@angular/cdk/overlay';
import {skipUntil, takeUntil} from 'rxjs/operators';
import {Subject, timer} from 'rxjs';
import {TemplatePortal} from '@angular/cdk/portal';

@Component({
  selector: 'ie-flag-selector',
  templateUrl: './ie-flag-selector.component.html',
  styleUrls: ['./ie-flag-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IEFlagSelectorComponent),
      multi: true
    }
  ],
})
export class IEFlagSelectorComponent extends IEInputOutputAccessor {

  @Input() flag: number;
  @Input() flags: Array<string> = ['#F7A360', '#E57B74', '#B865C8', '#D4E3FC', '#58BA8A'];
  @ViewChild('flagSelectorContent') flagSelectorContent: TemplateRef<any>;
  @Output() onchange: EventEmitter<number> = new EventEmitter();

  public default = '#BCBCCB';
  private _visible: boolean;
  private _overlayRef: OverlayRef;
  private _destroyed = new Subject<void>();

  public openFlagSelector(): void {
    this._createOverlay();
  }

  public hideFlagSelector(): void {
    this._overlayRef.dispose();
    this._overlayRef = null;
    this._destroyed.next();
    this._visible = false;
  }

  public getCustomFlag(): string {
    if (this.fieldValue === null || this.fieldValue === 'undefined') { return this.default; }
    if (this.fieldValue > this.flags.length - 1) { return this.default; }
    return this.flags[this.fieldValue];
  }

  public selectFlag(index: number) {
    this.writeValue(index);
    this.onchange.emit(index);
    this.hideFlagSelector();
  }

  private _createOverlay(): OverlayRef {
    if (this._overlayRef) { return; }

    // Create connected position strategy that listens for scroll events to reposition.
    const strategy = this.overlay.position()
      .flexibleConnectedTo(this.el)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        }]
      ).withTransformOriginOn('.ie-inline-dropdown-container')

    strategy.positionChanges
      .pipe(takeUntil(this._destroyed))
      .pipe(skipUntil(timer(200)))
      .subscribe(change => {
        if (this._visible) { this.ngZone.run(() => this.hideFlagSelector()); }
      });

    this._overlayRef = this.overlay.create({
      positionStrategy: strategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({scrollThrottle: 0}),
      hasBackdrop: true,
      backdropClass: 'overlay-clear'
    });

    const portal = new TemplatePortal(this.flagSelectorContent, this.viewContainerRef);
    this._overlayRef.attach(portal);
    this._overlayRef.backdropClick().subscribe(() => this.hideFlagSelector());

    this._visible = true;
  }

  constructor(
    private overlay: Overlay,
    private ngZone: NgZone,
    private el: ElementRef,
    private viewContainerRef: ViewContainerRef,
  ) {
    super();
  }

}
