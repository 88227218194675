import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

export interface BreadcrumbPath {
  label: string;
  href: string;
  queryParams?: object;
}

export interface RepositoryPath {
  id: number;
  parent_id: number;
  name: string;
  parent_name: string;
}

@Component({
  selector: 'ie-breadcrumb',
  templateUrl: './ie-breadcrumb.component.html',
  styleUrls: ['./ie-breadcrumb.component.scss']
})
export class IEBreadcrumbComponent implements OnInit {

  @Input() breadcrumb: Array<BreadcrumbPath> = [];
  @Input() directory: RepositoryPath;
  @Input() absolute: boolean;
  @Input() withReload: boolean;
  @Input() disabled: boolean = false;
  @Output() changeRepositoryDirectory: EventEmitter<number> = new EventEmitter(); 

  constructor() { }

  ngOnInit() { }
}
