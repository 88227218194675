import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataExportFormat} from '@shared/ui-personalization/service/ui-personalization.service';

@Component({
  selector: 'ie-table-export-data',
  templateUrl: './table-export-data.component.html',
  styleUrls: ['./table-export-data.component.scss']
})
export class TableExportDataComponent implements OnInit {

  @Input() columns: any;
  @Output() onexport: EventEmitter<any> = new EventEmitter();
  @Output() onclose: EventEmitter<void> = new EventEmitter();

  public format: DataExportFormat = DataExportFormat.CSV;
  public pending: boolean;

  public setFormat(format: string): void {
    this.format = DataExportFormat[format];
  }

  public export(): void {
    const meta = {
      format: this.format,
      exportColumns: this.columns.filter(o => o.visible && !o.transient).map(o => o.property),
    };
    this.pending = true;
    this.onexport.emit(meta);
  }

  public selectAll(select?): void {
    select.value = !select.value;
    this.columns.map(c => c.visible = !!select.value);
  }

  public canBeExported(): boolean {
    return this.columns.some(c => c.visible);
  }

  constructor() { }

  ngOnInit() {
  }

}
