<ie-breadcrumb [breadcrumb]="breadcrumb"></ie-breadcrumb>
<div class='content-container'>

    <ng-container *loaderTemplate="pending">
      <ie-cdk-table
        *ngIf="!empty;else noOrders"
        [interface]="'orders-received-list'"
        [manageableColumns]="manageableColumns"
        [searchable]="true"
        [searchableFields]="['code']"
      >
        <table cdk-table [dataSource]="receivedOrders$">
          <ng-container cdkColumnDef="id">
            <th cdk-header-cell *cdkHeaderCellDef>#</th>
            <td cdk-cell *cdkCellDef="let row" style="width: 50px;min-width: 50px;">{{row.id}}</td>
          </ng-container>
          <ng-container cdkColumnDef="code">
            <th cdk-header-cell cdk-sortable-column="code" *cdkHeaderCellDef style="width: 300px;min-width: 300px;">nazwa</th>
            <td
              cdk-cell *cdkCellDef="let row"
              routerLink="/app/orders/received/preview/{{row.id}}"
              style="width: 300px;min-width: 300px;"
            >
              <a
                class='--link'
                routerLink="/app/orders/received/preview/{{row.id}}"
              >{{row.code}}</a>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="created_at">
            <th cdk-header-cell cdk-sortable-column="created_at" *cdkHeaderCellDef>utworzono</th>
            <td
              cdk-cell *cdkCellDef="let row"
              [class.order-new]="row.status === 'new'"
            >{{row.created_at | date}}</td>
          </ng-container>
          <ng-container cdkColumnDef="price">
            <th cdk-header-cell cdk-sortable-column="price" *cdkHeaderCellDef>netto</th>
            <td
              cdk-cell *cdkCellDef="let row"
              [class.order-new]="row.status === 'new'"
            >{{row.price|price}} zł</td>
          </ng-container>
          <ng-container cdkColumnDef="status">
            <th cdk-header-cell cdk-sortable-column="status" *cdkHeaderCellDef>status</th>
            <td cdk-cell *cdkCellDef="let row" style="width: 100px;min-width: 100px;">
              <ie-inline-dropdown
                [bindValue]="'label'"
                [bindKey]="'status'"
                [options]="availableOrderStatus"
                [(ngModel)]="row.status"
                (onchange)="changeOrderStatus(row, $event)"
              ></ie-inline-dropdown>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="user">
            <th cdk-header-cell cdk-sortable-column="user_first_name" *cdkHeaderCellDef>opiekun</th>
            <td cdk-cell *cdkCellDef="let row" [class.order-new]="row.status === 'new'" style="min-width: 250px; white-space: nowrap; max-width: fit-content">
              {{row.user | user | async}}
              <ie-icon-button
                *ngIf="myself.id !== row.user"
                (click)="takeOrder(row)"
                style="display: inline-block; margin-left: 10px"
                [icon]="'takeover'"
                [label]="'przejmij'"
              ></ie-icon-button>
            </td>
          </ng-container>
          <ng-container cdkColumnDef="manage">
            <th cdk-header-cell *cdkHeaderCellDef></th>
            <td cdk-cell align="right" *cdkCellDef="let row">
              <ie-icon
                *vaultlocal="'GET_SUPPLIER_ORDER'|vaultprivilege"
                routerLink="/app/orders/received/preview/{{row.id}}"
                [icon]="'preview'"
                [hover]="true"
              ></ie-icon>
            </td>
          </ng-container>

          <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
          <tr cdk-row *cdkRowDef="let row;columns:displayedColumns"></tr>
        </table>
      </ie-cdk-table>

      <ng-template #noOrders>
          <ie-empty-placeholder
            [header]="'Lista zamówień jest pusta'"
            [placeholder]="'Na tej liście pojawią się wszystkie zamówienia Twojego sprzedawcy'"
            [image]="NO_ORDERS"
            [height]="'calc(100vh - 8rem)'"
          ></ie-empty-placeholder>
      </ng-template>
    </ng-container>

</div>
