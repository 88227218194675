import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER, MatTooltip, TooltipComponent} from './ie-tooltip.directive';
import {OverlayModule} from '@angular/cdk/overlay';



@NgModule({
  declarations: [MatTooltip, TooltipComponent],
  entryComponents: [TooltipComponent],
  exports: [MatTooltip, TooltipComponent],
  imports: [
    CommonModule,
    OverlayModule,
  ],
  providers: [MAT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER]
})
export class IETooltipModule { }
