import {Component, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation} from '@angular/core';
import {FlagService} from '@material/ie-flag-notification/service/flag-service';
import {OrderProductTile} from '../order-product-tile/order-product-tile';
import {Bast} from '@bast';
import {OrderSupplierProduct} from '@bast/domain/order/order';

@Component({
  selector: `ie-order-supplier-tile`,
  templateUrl: `./order-supplier-tile.component.html`,
  styleUrls: [`./order-supplier-tile.component.scss`],
  encapsulation: ViewEncapsulation.None
})
export class OrderSupplierTileComponent extends OrderProductTile implements OnDestroy {

  @Input() index = 0;
  @Input() disabled = false;
  @Input() product: OrderSupplierProduct;
  @Output() quantityChanged: EventEmitter<OrderSupplierProduct> = new EventEmitter();
  @Output() productDelete: EventEmitter<void> = new EventEmitter();

  constructor(
    protected bast: Bast,
    protected flagService: FlagService,
  ) {
    super(flagService, bast);
  }

  ngOnDestroy(): void {
    if (this._quantitySubscription) { this._quantitySubscription.unsubscribe(); }
  }

}
