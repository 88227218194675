import {ARTICLE_TYPE_MAP, PRODUCER_ARTICLES_TYPE} from '../../../app/core/producers/model/producer-article-type-map';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {HttpHeaders} from '@angular/common/http';
import {NonBlockingQueue} from '../../../app/shared/utils/non-blocking-queue';
import {APIService} from '../../../app/api/apiservice.service';
import {LocalStorageService} from 'ngx-webstorage';
import {ProducerArticleAttachment} from './producer-article-attachments';
import {ProducerArticle} from './producer-article';
import {Material} from '@bast/domain/material';

export default class {

  public get(type: PRODUCER_ARTICLES_TYPE, producerId: number): Promise<ProducerArticle> {
    return this.apiService.request(ARTICLE_TYPE_MAP[type].get(producerId), 'GET');
  }

  public update(article: ProducerArticle): Promise<ProducerArticle> {
    if (ProducerArticle.isPromotion(article.type)) {
      return article.id ?
        this.updatePromotion(article).then(res => new ProducerArticle({ ...article, ...res, type: article.type })) :
        this.createPromotion(article).then(res => new ProducerArticle({ ...article,  ...res, type: article.type }));
    }

    return this.apiService.request(
      ARTICLE_TYPE_MAP[article.type].update(article.producer_id),
      'POST',
      { article: ProducerArticle.toDto(article) }
    ).then((res: any) => new ProducerArticle({ ...article, ...res, type: article.type }));
  }

  public createPromotion(article: ProducerArticle): Promise<ProducerArticle> {
    return this.apiService.request(
      ENDPOINTS.createProducerPromotion(article.producer_id),
      'POST',
      { promotion_article: { article: ProducerArticle.toDto(article) }});
  }

  public updatePromotion(article: ProducerArticle): Promise<ProducerArticle> {
    return this.apiService.request(
      ENDPOINTS.updateProducerPromotion(article.id),
      'POST',
      { promotion_article: { article: ProducerArticle.toDto(article) }}
    );
  }

  public deletePromotion(article: ProducerArticle): Promise<ProducerArticle> {
    return this.apiService.request(
      ENDPOINTS.deleteProducerPromotion(article.id),
      'DELETE'
    );
  }

  public createAttachment(payload: { file: File, custom_fields: any }): Promise<Material> {
    const formData = new FormData();
    formData.append('attachment[material][file]', payload.file, payload.file.name);
    Object.keys(payload.custom_fields).forEach(key => {
      formData.append(`attachment[custom_fields][${key}]`, payload.custom_fields[key]);
    });
    const header = new HttpHeaders({ 'Authorization' : this.localStorage.retrieve('APIKEY') });
    return this.apiService.request(ENDPOINTS.createAttachment, 'POST', formData, header, true);
  }

  public updateAttachment(file: any): Promise<Material | void> {
    if (!file.id) { return Promise.reject(null); }
    const formData = new FormData();
    Object.keys(file.custom_fields).forEach(key => {
      formData.append(`attachment[custom_fields][${key}]`, file.custom_fields[key]);
    });
    const header = new HttpHeaders({ 'Authorization' : this.localStorage.retrieve('APIKEY') });
    return this.apiService.request(ENDPOINTS.updateAttachment(file.id), 'POST', formData, header, true);
  }

  public updateAttachmentBulk(files): Promise<ProducerArticleAttachment[]> {
    return new Promise(
      (resolve) => {
        new NonBlockingQueue(files)
          .pop((attachment, queueResolve) => {
              this.updateAttachment(ProducerArticleAttachment.toDto(attachment))
                .then(() => queueResolve(attachment));
            },
            (updatedAttachments) => resolve(updatedAttachments))
      });
  }

  public deleteAttachment(id: number): Promise<any> {
    return this.apiService.request(ENDPOINTS.deleteAttachment(id), 'DELETE');
  }

  public deleteAttachments(ids: number[]): Promise<number[]> {
    return new Promise((resolve) => {
      new NonBlockingQueue(ids)
        .pop((id, queueResolve, queueReject) => {
          this.deleteAttachment(id as any)
            .then(() => queueResolve(id))
            .catch(() => queueReject(id));
        }, (deletedMaterialsIds) => {
          resolve(deletedMaterialsIds);
        });
    });
  }

  public attachments(): Promise<Array<Material>> {
    return this.apiService.request(ENDPOINTS.getAttachments, 'GET').then(({data}) => data);
  }

  public attachmentsForArticle(articleId: number, type?: string): Promise<Material[]> {
    if (ProducerArticle.isPromotion(type)) { return this.attachmentsForPromotion(articleId); }
    return this.apiService.request(ENDPOINTS.getAttachmentsForArticle(articleId), 'GET').then(({data}) => Promise.resolve(data));
  }

  public attachmentsForPromotion(articleId: number): Promise<Material[]> {
    return this.apiService.request(ENDPOINTS.getAttachmentsForPromotionArticle(articleId), 'GET').then(({data}) => Promise.resolve(data));
  }

  public promotions(producerId: number): Promise<{ data: ProducerArticle[] }> {
    return this.apiService.request(ENDPOINTS.getProducerPromotions(producerId), 'GET');
  }

  public promotionById(promotionArticleId: number): Promise<ProducerArticle> {
    return this.apiService.request(ENDPOINTS.getProducerPromotion(promotionArticleId), 'GET');
  }

  constructor(
    private apiService: APIService,
    private localStorage: LocalStorageService
  ) { }
}
