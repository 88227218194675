import { LocalStorageService } from 'ngx-webstorage';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';
import { APP_CONFIGURATION } from '../configuration/APP_CONFIGURATION';
import { FlagService } from '@material/ie-flag-notification/service/flag-service';
import { confetti } from 'dom-confetti';
import {environment} from '../../environments/environment';
import {Observable, TimeoutError} from 'rxjs';
import {tap, timeout} from 'rxjs/operators';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/operator/catch';
import {Router} from '@angular/router';

@Injectable()
export class HttpBackendInterceptor implements HttpInterceptor {

  /**
   * Adds authorization header to all HTTP requests if APIKey is
   * present within session storage
   * @param {HttpRequest<any>} req HTTP request
   * @returns {HttpRequest<any>}
   */
  addAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    if (this.localStorage.retrieve('APIKEY')) {
      req = req.clone( {
        setHeaders: {
          Authorization: this.localStorage.retrieve('APIKEY')
        }
      });
    }
    return req;
  }

  /**
   * Intercepts all error HTTP codes timeouts and connection errors
   * @param {HttpRequest<any>} req HTTP Request
   * @param {HttpHandler<any>} next HTTP Handler
   * @returns {Observable<HttpEvent<any>>}
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Add authorization header
    req = this.addAuthorizationHeader(req);

    return next.handle(req)
      .pipe(timeout(APP_CONFIGURATION.request_timeout))
      .pipe(tap((event: HttpEvent<any>) => {},
    (error: any) => {
      if (error instanceof TimeoutError) {
        this.injector.get(FlagService).publishWarningFlag(
          'Przekroczono czas oczekiwania na odpowiedź',
          'Załaduj ponownie stronę'
        );
      } else if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 400 : break;
          case 401 : {
            if (error.url.indexOf('logout') === -1 && error.url.indexOf('login') === -1 && !this.router.url.includes('authorization')) {
              this.setReferer();
              this.injector.get(FlagService).publishBlanket();
            }
            break;
          }
          case 403 : break;
          case 500 : {
            this.celebrate500Response();
            this.injector.get(FlagService).publishErrorFlag(
              'Błąd 500',
              `Wystąpił błąd serwera. Skontaktuj się z administratorem.<br/>
               <a href="mailto:kontakt@bastie24.pl>Zaraportuj błąd na kontakt@bast.ie24.pl</a>`
            );
            break;
          }
          case 502: {
            this.celebrate500Response();
            break;
          }
        }
      }
    }));
  }

  protected celebrate500Response(): void {
    if (environment.environment === 'prod') return;
    const el = document.getElementById('confetti-trigger');
    confetti(el, { height: '10px' as any, spread: 45, angle: 60, startVelocity: 120, elementCount: 120, duration: 6000 });
    const reportBugButton = document.getElementById('report-bug-button');
    reportBugButton.classList.add('bounce');
    setTimeout(() => {
      reportBugButton.classList.remove('bounce');
    }, 5000)
  }

  protected setReferer(): void {
    this.localStorage.store('referer', window.location.pathname + window.location.search);
  }

  constructor(private injector: Injector, private localStorage: LocalStorageService, private router: Router) {}
}
