import {from, Observable, of} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {catchError, flatMap, map, switchMap} from 'rxjs/operators';
import {APIService} from '../../../app/api/apiservice.service';
import {Injector} from '@angular/core';
import {StorageService} from '../../../app/shared/storage-service/storage.service';
import {Product} from './product';
import {ProductChangelog} from '@bast/domain/product';

export class ProductDomain {

  public get(productId: number, inStock?: boolean, omitFields?: string[]): Observable<Product> {
    omitFields = omitFields || [];
    return this.getById(productId)
      .pipe(flatMap(p => {
        const product = new Product(p, (window['angularInjector'] as Injector).get(StorageService));
        return of(product);
      }))
      .pipe(switchMap(() => omitFields.includes('supplier_availability') ? of(null) : this.availability(productId, inStock),
        (outer, inner) => ({ product: outer, availability: inner })
      ))
      .pipe(flatMap((res: any) => {
        const { product, availability } = res;
        if (availability) { product.buildAvailability(availability); }

        return of(product);
      }))
      .pipe(switchMap(() => omitFields.includes('etim_features') ? of(null) : this.etimFeatures(productId).pipe(catchError(_ => of(null))),
        (outer, inner) => ({ product: outer, features: inner })
      ))
      .pipe(flatMap((res: any) => {
        const { product, features } = res;
        product.etim_features = features;
        return of(product);
      }));
  }

  public update(product: Product): Observable<any> {
    return from(this.api.request(ENDPOINTS.updateProduct(product.id), 'POST', Product.toDto(product)));
  }

  public deleteImage(product: Product, imageId: number): Observable<any> {
    return from(this.api.request(ENDPOINTS.deleteProductImage(product.id, imageId), 'DELETE'))
  }

  public availability(productId, inStock?: boolean): Observable<any> {
    const inStockFilter = inStock ? '?in_stock=1' : '' ;
    return from(this.api.request(ENDPOINTS.getSupplierProducts(productId) + inStockFilter, 'GET'))
      .pipe(map(({ data }) => data))
      .pipe(catchError(_ => of(null)));
  }

  public etimFeatures(productId): Observable<any> {
    return from(this.api.request(ENDPOINTS.getProductETIMFeatures(productId), 'GET'))
      .pipe(map(({data}) => data))
      .pipe(catchError((e) => {
        if (e.status === 403) { return of(null); }
        if (e.status === 404) { return of([]); }

        return of([]);
      }));
  }

  public files(productId): Observable<any[]> {
    return from(this.api.request(ENDPOINTS.getProductDownloadFiles(productId), 'GET'))
      .pipe(map(({ data }) => data));
  }

  public changelog(productId): Promise<ProductChangelog[]> {
    return this.api.request(ENDPOINTS.getProductChangelog(productId), 'GET')
      .then(({data}) => data);
  }

  protected getById(id: any): Observable<any> {
    return from(this.api.request(ENDPOINTS.getProduct(id), 'GET'));
  }

  constructor(private api: APIService) {}
}
