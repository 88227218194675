import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IEScrollToTopComponent } from "./ie-scroll-to-top.component";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        IEScrollToTopComponent
    ],
    exports: [
        IEScrollToTopComponent
    ]
})
export class IEScrollToTopModule {}