import {debounceTime, distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {EventEmitter} from '@angular/core';
import {pick} from 'lodash-es';

export class TableFilter {

  public phrase: string;
  public input: any[];
  public next$: Subject<any>;
  public onclear: EventEmitter<void>;

  private _searchMap: object;
  private _searchableFields: string[] = [];

  public subscribeToTableFilter(): Observable<string[]> {
    return this.next$
      .pipe(filter(e => e.keyCode !== 18 && e.keyCOde !== 17 && e.keyCode !== 32))
      .pipe(map((data: any) => data.target.value))
      .pipe( tap( _ => {
        this.phrase = _;

        if (_.length < 1) {
          this.onclear.emit();
          this.phrase = null;
          return of(null);
        }
      }))
      .pipe(filter(_ => _))
      .pipe(distinctUntilChanged())
      .pipe(debounceTime(200))
      .pipe(map(phrase => {
        if (!phrase) { return; }
        const matchedIndexes = Object.keys(this._searchMap).filter((key) => {
          return this._searchMap[key].indexOf(phrase.toLowerCase()) > -1;
        });

        return matchedIndexes;
      }));
  }

  public clear(): void {
    this.phrase = null;
  }

  public resetSearchMap(data: any[]): void {
    this.input = data;
    this.buildSearchMap();
  }

  public reindex(data: any[]): void {
    this.input = [...data];
    this.filterSearchableFields();
    this.buildSearchMap();
  }

  protected filterSearchableFields(): void {
    this.input = this.input.map(row => ({ ...pick(row, this._searchableFields) } ));
  }

  protected buildSearchMap(): void {
    const searchMap = {};
    this.input.forEach((row, index) => {
      const merge = Object.values(row).join('').toLowerCase();
      Object.defineProperty(searchMap, index, { value: merge, enumerable: true });
    });

    this._searchMap = searchMap;
  }

  constructor(data: any[] = [], searchableFields = []) {
    this.input = [...data];
    this.next$ = new Subject<any>();
    this.onclear = new EventEmitter<void>();
    this._searchableFields = searchableFields;
    this.filterSearchableFields();
    this.buildSearchMap();
  }
}
