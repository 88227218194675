<ie-breadcrumb [breadcrumb]="breadcrumb"></ie-breadcrumb>

<ng-container *loaderTemplate="pending">
    <div class='content-container fly-in' *ngIf="order">

        <div class='content-header' [attr.order-active]="order.status!=='sent'?true:null">
            <ie-icon
              routerLink="/app/orders"
              style="margin-right: 15px; transform: rotate(180deg);"
              [icon]="'input_arrow'"
              [hover]="true"
              [title]="'Wróć'"
            ></ie-icon>
            <div class="editable-order-name">
              <h1
                #orderName
                [attr.contenteditable]="orderNameEditable?true:null"
                (blur)="changeOrderName($event)"
              >{{window.innerWidth<500?'':'Zamówienie: '}}{{order.code}}</h1>
              <ie-icon-button
                *ngIf="!orderNameEditable"
                [icon]="'edit'"
                [label]="'zmień nazwę'"
                (click)="makeOrderNameEditable(orderName)"
              ></ie-icon-button>
            </div>
            <div class='--empty-grow'></div>
            <div class="order-sort-options">
              <p>data utworzenia: {{order.params.created_at | date}}</p>
              <span style="margin-right: 5px;">sortuj</span>
              <ie-inline-dropdown
                [placeholder]="'Sortuj'"
                [options]="sortOptions"
                (onchange)="sortBy($event)"
                [(ngModel)]="order.sortBy"
              ></ie-inline-dropdown>
            </div>
        </div>

        <div class="order-container-wrapper">
          <div class="order-product-list-container">

            <!-- Products list -->
            <ng-container *ngIf="order.suppliers.length > 0;else noSuppliers">
              <ng-container [ngSwitch]="order.sortBy">

                <!-- Sort by supplier -->
                <ng-container *ngSwitchCase="1">
                  <ie-extension-panel
                      *ngFor="let supplier_order of order.suppliers;let i=index"
                      class='order-extension-panel'
                      [opened]="i===0"
                  >
                      <div
                          class='supplier-name'
                          panel-header
                      >{{supplier_order.supplier_alias || supplier_order.supplier_name}}</div>
                      <div style="width: 100%" panel-content>
                          <ul>
                              <li
                                  *ngFor="let product of supplier_order.supplier_products;let i=index"
                                  [attr.index]="i+1"
                              >
                                <ie-order-product-tile
                                  [index]="i"
                                  [disabled]="order.status==='sent'||!order.is_manageable"
                                  [product]="product"
                                  (quantityChanged)="recalculatePrices()"
                                  (productDelete)="deleteProduct(product)"
                                ></ie-order-product-tile>
                              </li>
                          </ul>

                          <div class='supplier-order-summary'>
                              <div>
                                  <label style="white-space: nowrap"><b>razem do zapłaty netto: </b>{{order.getTotalSupplierCost(supplier_order.supplier_id) | price}} zł</label>
                              </div>
                          </div>
                      </div>
                  </ie-extension-panel>
                </ng-container>

                <!-- Sort by product -->
                <ng-container *ngSwitchCase="2">
                  <ie-extension-panel
                      *ngFor="let supplier_product of order.products;let i=index"
                      class='order-extension-panel'
                      [opened]="i===0"
                  >
                      <div
                          class='supplier-name'
                          panel-header
                      >{{supplier_product.product_name}}</div>
                      <div style="width: 100%" panel-content>
                          <ul>
                              <li
                                  *ngFor="let product of supplier_product.suppliers;let i=index"
                                  [attr.index]="i+1"
                              >
                                <ie-order-supplier-tile
                                  [index]="i"
                                  [disabled]="order.status==='sent'||!order.is_manageable"
                                  [product]="product"
                                  (quantityChanged)="recalculatePrices()"
                                  (productDelete)="deleteProduct(product)"
                                ></ie-order-supplier-tile>
                              </li>
                          </ul>

                          <div class='supplier-order-summary'>
                              <div>
                                  <label style="white-space: nowrap"><b>razem do zapłaty netto: </b>{{order.getTotalProductCost(supplier_product.product_id) | price}} zł</label>
                              </div>
                          </div>
                      </div>
                  </ie-extension-panel>
                </ng-container>


               </ng-container>
            </ng-container>


            <div class="order-summary-bottom">

              <!-- Comments -->
              <ng-container *ngIf="order.status!=='sent'">
                <div class="order-manage-comment">
                  <label class="optional-comment-label">komentarz zbiorczy (opcjonalnie)</label>
                  <div class="comment-editable-wrapper">
                    <div
                        #commentInput
                        contentEditable
                        class="order-editable"
                        (click)="focusCommentArea(commentInput)"
                        (blur)="appendOrderComment(commentInput)"
                    ></div>
                  </div>

                  <div *ngIf="order.comments.length > 0" class="comments-list">
                    <h4>komentarze ({{order.comments.length}})</h4>
                    <div *ngFor="let comment of order.comments" class="comment-entry">
                      <div class="comment-entry-header"><b>{{comment.user | user | async}}</b><span>{{comment.date | date}}</span></div>
                      <p>{{comment.content}}</p>
                    </div>
                  </div>
                </div>
              </ng-container>

              <!-- Order summary -->
              <div
                *ngIf="order.status!=='sent'"
                class="summary-wrapper-container"
              >
                <div class="summary-wrapper" *ngIf="order.user===account.id;else notAnOwner">
                    <div
                        class='order-summary'
                        *ngIf="order.status==='open'"
                    >
                        <h3>podsumowanie</h3>
                        <table>
                            <tr class='price-summary'>
                                <td>{{order.price|price}} zł netto</td>
                            </tr>
                            <tr class='price-summary'>
                              <td>{{order.gross_price|price}} zł brutto</td>
                            </tr>
                        </table>
                        <ng-container *ngIf="warehouses?.length;else noWarehouses">
                          <!-- <label>wybierz magazyn dostawy</label> -->
                          <ie-multiselect
                            style="margin: 5px 0; display: block"
                            matTooltip="{{order.params?.products?.length===0?'Brak produktów w zamówieniu. Dodaj produkty, aby złożyc zamowienie':''}}"
                            [matTooltipShowDelay]="400"
                            [hidePlaceholder]="true"
                            [placeholder]="'wybierz magazyn do dostawy'"
                            [label]="'magazyn do dostawy'"
                            [disabled]="order.params?.products?.length===0"
                            [(ngModel)]="warehouse"
                          >
                            <ie-multiselect-option
                              *ngFor="let warehouse of warehouses"
                              [option]="{ key: warehouse.id, value: warehouse.name }"
                            >{{warehouse.name}}</ie-multiselect-option>
                          </ie-multiselect>
                        </ng-container>

                        <ng-template #noWarehouses>
                          <div class="no-warehouses-wrapper">
                            <label>nie posiadasz jeszcze magazynu!</label>
                            <ie-icon-button
                              [icon]="'add'"
                              [label]="'Dodaj pierwszy magazyn'"
                              (click)="createFirstWarehouse()"
                            ></ie-icon-button>
                          </div>
                        </ng-template>
                    </div>
                </div>

                <div 
                  class="order-buttons-container"
                  *ngIf="order?.id">
                    <ie-button
                      *vaultlocal="'SEND_ORDER'|vaultprivilege"
                      matTooltip="{{!warehouse ? 'Aby złożyć zamówienie wybierz magazyn docelowy' : ''}}"
                      matTooltipShowDelay="400"
                      [active]="warehouse"
                      [text]="'Złóż zamówienie'"
                      (click)="sendOrder()"
                    ></ie-button>

                    <ie-icon-button
                      *ngIf="!order.active"
                      [icon]="'activate'"
                      [label]="'aktywuj zamówienie'"
                      (click)="activateOrder()"
                    ></ie-icon-button>
                </div>
              </div>

              <ng-template #notAnOwner>
                <div
                  ie-on-mount
                  class='summary-wrapper'
                  (onmount)="fetchUsers()"
                >
                  <div class='order-summary'>
                    <label>nie jesteś obecnie opiekunem danego zamówienia</label>
                    <label style="font-weight: 400; display: block; margin-top: 10px">zmień opiekuna zamówienia</label>
                    <ie-multiselect
                      style="margin: 5px 0; display: block"
                      [hidePlaceholder]="true"
                      [placeholder]="'Wybierz opiekuna'"
                      (changed)="changeOrderOwner()"
                      [(ngModel)]="newOwner"
                    >
                      <ie-multiselect-option
                        *ngFor="let user of users"
                        [option]="{ key: user.id, value: user.email }"
                      >
                        <div class='users-multiselect-option'>
                          <span>{{user.first_name}} {{user.last_name}}</span>
                          <span>{{user.email}}</span>
                        </div>
                      </ie-multiselect-option>
                    </ie-multiselect>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>

          <ng-template #noSuppliers>
            <ie-empty-placeholder
              [header]="'brak produktów w zamowieniu'"
              [placeholder]="'wyszukaj produkty i skompletuj zamwówienie'"
              [image]="NO_PRODUCTS_PLACEHOLDER.image"
              [action]="NO_PRODUCTS_PLACEHOLDER.action"
            ></ie-empty-placeholder>
          </ng-template>
        </div>

    </div>
</ng-container>
<ie-warn-dialog></ie-warn-dialog>
