import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {LocalStorageService} from 'ngx-webstorage';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authorizationService.isAuthorized()) { return true; } else {
      this.localStorage.store('referer', window.location.pathname  + window.location.search);
      this.router.navigateByUrl('/authorization/login');
    }
  }

  constructor(private authorizationService: AuthenticationService, private router: Router, private localStorage: LocalStorageService) {}
}
