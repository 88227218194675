import {InterfaceElement} from '@shared/ui-personalization/model/InterfaceElement';
import {TableInterfaceOptions} from '@shared/ui-personalization/model/TableInterfaceOptions';
import {CDKColumnDefinition} from './ie-cdk-table.component';

export class CdkManageableTable {

  public id: number;
  public name: string;
  public options: TableInterfaceOptions;

  public static toDto(columns: CDKColumnDefinition[]): TableInterfaceOptions {
    const persistableColumns = columns
      .filter(c => !c.transient && c.visible)
      .map(c => c.property);
    const orderedColumns = columns
      .filter(c => !c.transient)
      .map(c => c.property);
    return {
      columnsVisible: persistableColumns,
      order: orderedColumns,
    };
  }

  constructor(payload: InterfaceElement) {
    Object.assign(this, payload);
  }
}
