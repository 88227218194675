import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IEInputOutputAccessor } from '@shared/input-output-accessor/ie-input-output-accessor';

@Component({
  selector: 'ie-radiobutton',
  template: `
    <label
      role="radio"
      class='ie-radiobutton'
      [attr.tabindex]="fieldValue===true ? -1 : 0"
      [attr.aria-checked]="fieldValue || null"
      [attr.disabled]="disabled ? true : null"
      (keydown.enter)="writeValue(value)"
    >
      <input
        type="radio"
        name="{{name}}"
        [value]="value"
        [attr.value]="value"
        [attr.checked]="fieldValue===value ? true : null"
        [(ngModel)]="fieldValue"
        (click)="onChange()"
      >
      <span class='radiobutton'></span>
      {{label}}
    </label>
  `,
  styleUrls: ['./ie-radiobutton.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IERadiobuttonComponent),
      multi: true
    }
  ]
})
export class IERadiobuttonComponent extends IEInputOutputAccessor implements OnInit {

  @Input() value: string;
  @Input() label: string;
  @Input() name: string;
  @Input() checked;
  @Input() disabled: boolean;
  @Output() onchange: EventEmitter<any> = new EventEmitter();

  constructor() {
    super();
  }

  public onChange(): void {
    if (this.disabled) { return; }
    this.onchange.emit(this.value);
  }

  ngOnInit() {}

}
