import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Subscriber } from 'rxjs/Subscriber';
import { LocalStorageService } from 'ngx-webstorage';
 
@Injectable()
export class SecuredImgService {
    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService
    ) {
    }
 
    get(url: string): Observable<any> {
        return new Observable((observer: Subscriber<any>) => {
            let objectUrl: string = null;
 
            this.http
                .get(url, {
                    headers: {'authorization': this.localStorage.retrieve('APIKEY')},
                    responseType: 'blob'
                })
                .subscribe(m => {
                    objectUrl = URL.createObjectURL(m);
                    observer.next(objectUrl);
                });
 
            return () => {
                if (objectUrl) {
                    URL.revokeObjectURL(objectUrl);
                    objectUrl = null;
                }
            };
        });
    }
}