import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IERadiobuttonComponent } from './ie-radiobutton.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [IERadiobuttonComponent],
    exports: [IERadiobuttonComponent]
})
export class IERadiobuttonModule {}