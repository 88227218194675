import {environment} from '../../environments/environment';

export const DURSZLAK_ENDPOINTS = {
  getInterfaceElementForUser: (VAULT_URL, userId, name) => `${VAULT_URL}/interface-element/name/${name}?user=${userId}`,
  createInterfaceElement: (VAULT_URL) => `${VAULT_URL}/interface-element`,
  updateInterfaceElement: (VAULT_URL, interfaceElementId) => `${VAULT_URL}/interface-element/${interfaceElementId}`,
  exportInterfaceElementBoundData: (VAULT_URL, interfaceElementId, format, columns: string[]) => {
    const columnsQuery = columns.reduce((acc, next) => `${acc}&columns[]=${next}`, '&');
    return `${VAULT_URL}/interface-element/${interfaceElementId}/export?format=${format}${columnsQuery}`;
  },
}
