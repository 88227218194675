import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'price'
})
export class PricePipe implements PipeTransform {

    transform(value: number, ...args: any[]) {
        if (!value) { return '-'; }
        const value_string = value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace(/\./, ',');
        return value_string;
    }
}
