import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEEmptyPlaceholderComponent } from './ie-empty-placeholder.component';
import { IEButtonModule } from '../ie-button/ie-button.module';
import {VaultSharedModule} from '../../authorization/module/vault.module';
import {SharedModule} from '@shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    IEButtonModule,
    VaultSharedModule,
    SharedModule
  ],
  declarations: [
    IEEmptyPlaceholderComponent
  ],
  exports: [
    IEEmptyPlaceholderComponent
  ]
})
export class IEEmptyPlaceholderModule { }
