import {ARTICLE_TYPE_MAP, PRODUCER_ARTICLES_TYPE} from '../../../app/core/producers/model/producer-article-type-map';
import {DatepickerRange} from '../../../app/ie-material/ie-datepicker/utils/datepicker-range';
import {DatepickerMode} from '../../../app/ie-material/ie-datepicker/utils/datepicker-mode';
import {DatePipe} from '../../../app/shared/pipes/date.pipe';

export class ProducerArticle {
  id: number;
  type: PRODUCER_ARTICLES_TYPE;
  name: string;
  content: string;
  custom_fields: any;
  valid_from: number;
  valid_to: number;
  update_at: number;
  producer_id: number;
  article_id: number;
  valid: any;
  attachments: Array<any>;
  datepickerOptions?: any = {
    label: 'Termin obowiązywania',
    ranges: [DatepickerRange.DAY, DatepickerRange.MONTH, DatepickerRange.YEAR],
    range: DatepickerRange.DAY,
    mode: DatepickerMode.RANGE,
  };
  label: string;
  dateRangeLabel: string;
  privilege: string | string[];

  public static toDto(obj: ProducerArticle): ProducerArticle {
    const output = { ...obj, valid_from: undefined, valid_to: undefined };
    if (obj.valid) {
      const [from, to] = obj.valid;
      Object.assign(output, from && { valid_from: from });
      Object.assign(output, to && { valid_to: to });
    }

    delete output['datepickerOptions'];
    delete output['valid'];

    return output as ProducerArticle;
  }

  public static isPromotion(type: string): boolean {
    return type === PRODUCER_ARTICLES_TYPE.PROMOTION;
  }

  public static isAgreement(type: string): boolean {
    return type === PRODUCER_ARTICLES_TYPE.AGREEMENT;
  }

  public static isSale(type: string): boolean {
    return type === PRODUCER_ARTICLES_TYPE.SALE;
  }

  public static isDiscount(type: string): boolean {
    return type === PRODUCER_ARTICLES_TYPE.DISCOUNT;
  }

  public getArticleId(): number {
    return this.type === PRODUCER_ARTICLES_TYPE.PROMOTION ? this.article_id : this.id;
  }

  public isAgreementArticle(): boolean {
    return ProducerArticle.isAgreement(this.type);
  }

  protected setDatepickerOptions?(type: string) {
    if (ProducerArticle.isSale(type)) {
      this.datepickerOptions = {
        label: 'Ważność za rok',
        ranges: [DatepickerRange.YEAR],
        range: DatepickerRange.YEAR,
        mode: DatepickerMode.SINGLE
      };
    }
  }

  protected setArticleTitleLabel(): void {
    if (!!!this.valid_from && !!!this.valid_to) { return; }
    if (this.type === PRODUCER_ARTICLES_TYPE.SALE) {
      this.dateRangeLabel = `<span class="--semibold">Ważne za rok</span> ${new DatePipe().transform(this.valid_from, 'YYYY')}`;
    } else {
      if (!this.valid_from) { this.dateRangeLabel = ''; return;  }
      this.dateRangeLabel = `<span class='--semibold'>Termin obowiązywania warunków</span> od ${new DatePipe().transform(this.valid_from)}  ${this.valid_to ? `${this.valid_to ? ' do ' : ''} ${new DatePipe().transform(this.valid_to)}` : ''}`;
    }
  }

  protected setLabel(): void {
    this.label = ARTICLE_TYPE_MAP[this.type].label;
  }

  protected setPrivilege(): void {
    this.privilege = ARTICLE_TYPE_MAP[this.type].privilegeUpdate;
  }

  constructor(props?: any) {
    this.id = null;
    this.type = PRODUCER_ARTICLES_TYPE.AGREEMENT;
    this.name = '';
    this.content = '';
    this.valid_from = null;
    this.valid_to = null;
    if (props) {
      Object.assign(this, props);
      this.setDatepickerOptions(props.type);
      this.setPrivilege();
      this.setArticleTitleLabel();
      this.setLabel();
      if (props.valid_from) { this.valid = [props.valid_from]; }
      if (props.valid_to) { this.valid = [...this.valid, props.valid_to]; }
    }
  }
}
