import {
  Directive,
  ElementRef, Host,
  HostListener,
  Input,
  OnInit,
  Renderer2
} from '@angular/core';
import {IECdkTableComponent} from '../ie-cdk-table.component';

@Directive({
  selector: '[ieDragScroller]'
})
export class DragScrollerDirective implements OnInit {

  @Input() sticky: boolean;

  protected __active: boolean;
  protected _initialX = 0;
  protected _currentX = 0;

  @HostListener('mousedown', ['$event'])
  dragStart(event) {
    if (!this.isScrollable()) { return; }
    this.__active = true;
    this._initialX = event.clientX;
    this.renderer.setStyle(this.el.nativeElement, 'user-select', 'none');
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'grab');
  }

  @HostListener('mouseup', ['$event'])
  dragEnd(event) {
    if (!this.isScrollable()) { return; }
    this.__active = false;
    this.renderer.setStyle(this.el.nativeElement, 'user-select', 'all');
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'initial');
  }

  @HostListener('mousemove', ['$event'])
  drag(event) {
    if (this.__active) {
      this._currentX = (this._initialX -  event.clientX) / 12;
      const clientX = (event as MouseEvent).clientX;
      this.el.nativeElement.scrollLeft = this.el.nativeElement.scrollLeft - clientX + this._initialX;
      this._initialX = clientX;
      event.preventDefault();
    }
    event.stopPropagation();
  }

  protected setContainerHeight(): void {
    if (!this.sticky) { return; }
    setTimeout(() => {
      const rect: DOMRect = document.querySelector('.cdk-header-row').getBoundingClientRect();
      this.renderer.setStyle(this.el.nativeElement, 'max-height', `calc(100vh - ${rect.top + 40}px)`);
    }, 200);
  }

  protected isScrollable(): boolean {
    return this.el.nativeElement.scrollWidth > this.el.nativeElement.clientWidth;
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private parent: IECdkTableComponent
  ) {
    this.renderer.setStyle(this.el.nativeElement, 'width', '100%');
    this.renderer.setStyle(this.el.nativeElement, 'overflow', 'auto');
  }

  ngOnInit(): void {
    this.setContainerHeight();
  }
}
