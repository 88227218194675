<!-- BAST-768 -->
<!-- [attr.placeholder]="placeholder" -->

<div
  #inputContainer
  class='ie-input'
  [class.range]="type === 'range'"
  [class.focus]="type === 'range' && focused"
  [ngClass]="error ? 'input-error' : ''"
  [attr.floating]="floatingPlaceholder ? true : null"
  [style.height]="height"
>
  <label
    #placeholderEl
    *ngIf="showPlaceholder()"
    class='input-label'
    [attr.disabled]="disabled ? true : null"
  >{{label ? label : placeholder}}</label>
  <input
    #inputField
    *ngIf="!multiline && type !== 'range'"
    tabindex="0"
    [attr.disabled]="disabled ? true : null"
    [(ngModel)]="fieldValue"
    name="{{name}}"
    type="{{type}}"
    (blur)="blur()"
    (focus)="focus()"
    (input)="onchange.emit(fieldValue)"
  />
  <textarea
    #inputField
    *ngIf="multiline"
    tabindex="0"
    [attr.disabled]="disabled ? true : null"
    [(ngModel)]="fieldValue"
    name="{{name}}"
    (blur)="blur()"
    (focus)="focus()"
  ></textarea>
  <ng-container *ngIf="type === 'range'">
    <div class="range-input">
      <input
        #inputField
        tabindex="0"
        [attr.placeholder]="placeholder"
        [attr.disabled]="disabled ? true : null"
        [(ngModel)]="fieldValue.value_from"
        name="{{name}}"
        (blur)="blur()"
        (focus)="focus()"
        (input)="onchange.emit(fieldValue)"
      />
      <ie-icon
        [icon]="'minus'"
        [size]="13"
      ></ie-icon>
      <input
        #inputField
        tabindex="0"
        [attr.placeholder]="placeholder"
        [attr.disabled]="disabled ? true : null"
        [(ngModel)]="fieldValue.value_to"
        name="{{name}}"
        (blur)="blur()"
        (focus)="focus()"
        (input)="onchange.emit(fieldValue)"
      />
      <span style="margin-left: auto; margin-right: 10px;">{{unit}}</span>
    </div>
  </ng-container>
  <span
    *ngIf="error"
    class="error-span"
  >{{error}}</span>
</div>
