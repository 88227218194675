import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from '../component/contact-form/contact-form.component';
import { ContactFormAnchorComponent } from '../component/contact-form/contact-form-anchor';
import { IEDialogModule } from '@material/ie-dialog/module/ie-dialog.module';
import { IEMultiselectModule } from '@material/ie-multiselect/ie-multiselect.module';
import { IEInputModule } from '@material/ie-input/ie-input.module';
import { FormsModule } from '@angular/forms';
import { IEIconButtonModule } from '@material/ie-icon-button/ie-icon-button.module';
import { IERadiobuttonModule } from '@material/ie-radiobutton/ie-radiobutton.module';
import { IEButtonModule } from '@material/ie-button/ie-button.module';
import {IEIconModule} from '@material/ie-icon/ie-icon.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IEDialogModule,
    IEMultiselectModule,
    IEInputModule,
    IEIconButtonModule,
    IERadiobuttonModule,
    IEButtonModule,
    IEIconModule
  ],
  declarations: [
    ContactFormComponent,
    ContactFormAnchorComponent
  ],
  exports: [
    ContactFormComponent,
    ContactFormAnchorComponent
  ],
  entryComponents: [
    ContactFormComponent
  ]
})
export class ContactModule {}
