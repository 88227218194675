import { Pipe, PipeTransform } from '@angular/core';
import {DatepickerUtils} from '@material/ie-datepicker/utils/DatepickerUtils';

@Pipe({
  name: 'date',
  pure: true
})
export class DatePipe implements PipeTransform {

  transform(value: number, args?: any): any {
    return value ? DatepickerUtils.toBastDate(value, args) : '-';
  }

}
