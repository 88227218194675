import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {APIService} from '../../../app/api/apiservice.service';

export class Role {

  id?: number;
  parent?: number;
  name?: string;
  description?: string;
  short_description?: string;
  _parentPrivileges?: Array<any> = [];
  _privileges?: Array<any> = [];

  get privileges() {
    return this._privileges;
  }

  set privileges(privileges: Array<any>) {
    this._privileges = privileges;
  }

  get parentPrivileges() {
    return this._parentPrivileges;
  }

  set parentPrivileges(parentPrivileges) {
    this._parentPrivileges = parentPrivileges;
    this.mapSelectedPrivileges(parentPrivileges);
  }

  public static toDto(role: Role) {
    const privileges = role.privileges
      .filter(({ selected }) => !!selected)
      .map(({ id }) => id);
    return {
      role: {
        ...role,
        privileges
      }
    };
  }

  private mapSelectedPrivileges(parentPrivileges): void {
    const privilegesIds = this.privileges.map(({ id }) => id);
    const mappedPrivieleges = parentPrivileges.reduce((acc, next) => {
      return privilegesIds.includes(next.id) ? [...acc, { ...next, selected: true }] : [...acc, next];
    }, []);

    this._privileges = mappedPrivieleges;
  }

  constructor(params?) {
    Object.assign(this, params);
  }
}

export class Privilege {
  id?: number;
  name?: string;
  description?: string;

  public static toDto(obj) {
    const {description} = obj;
    return {
      privilege: { description }
    };
  }
}

export class RoleDomain {

  public get(): Promise<any> {
    return this.api.request(ENDPOINTS.getRoles, 'GET').then(({ data }) => data);
  }

  public getById(roleId: number): Promise<any> {
    return this.api.request(ENDPOINTS.getSingleRole(roleId), 'GET');
  }

  public privileges(roleId: number): Promise<Privilege[]> {
    return this.api.request(ENDPOINTS.getRolePrivileges(roleId), 'GET').then(({data}) => Promise.resolve(data));
  }

  public allPrivileges(): Promise<Privilege[]> {
    return this.api.request(ENDPOINTS.getPrivileges, 'GET').then(({data}) => Promise.resolve(data));
  }

  public update(role: Role): Promise<any> {
    return this.api.request(ENDPOINTS.updateRole(role.id), 'POST', Role.toDto(role));
  }

  public create(payload: Role): Promise<any> {
    return this.api.request(ENDPOINTS.createRole, 'POST', Role.toDto(payload));
  }

  public delete(roleId: number): Promise<any> {
    return this.api.request(ENDPOINTS.deleteRole(roleId), 'DELETE');
  }

  public propagate(sourceRoleId: number, privileges: Array<number>, all?: boolean): Promise<any> {
    return this.api.request(ENDPOINTS.populatePrivileges(sourceRoleId), 'POST', { role: { privileges, all } });
  }

  public copy(sourceRoleId: number, privileges: Array<number>, targetRolesIds: Array<number>): Promise<any> {
    return this.api.request(ENDPOINTS.copyPrivileges(sourceRoleId), 'POST', {
      role: { privileges, target_roles: targetRolesIds }
    });
  }

  public updatePrivilege(privilege: Privilege): Promise<any> {
    return this.api.request(ENDPOINTS.updatePrivilege(privilege.id), 'POST', Privilege.toDto(privilege))
  }

  constructor(private api: APIService) {}

}
