import {HttpHeaders} from '@angular/common/http';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {APIService} from '../../../app/api/apiservice.service';
import {LocalStorageService} from 'ngx-webstorage';
import {Observable} from 'rxjs';

export class Material {
  id?: number;
  url?: string;
  name?: string;
  type?: string;
  size?: number;
  extension?: string;
  created_at?: number;
  updated_at?: number;
  custom_fields?: any;
}

export class MaterialDomain {

  create(file: File, directory?: number): Promise<Material> {
    const formData = new FormData();
    formData.append('material[file]', file, file.name);
    if (directory) {formData.append('material[directory]', directory.toString())}
    const header = new HttpHeaders({ Authorization : this.localStorage.retrieve('APIKEY') });
    return this.api.request(ENDPOINTS.createMaterial, 'POST', formData, header, true);
  }

  createForObject(file: File, url?: string, objectId?: number): Promise<Material> {
    const formData = new FormData();
    formData.append('image[file]', file, file.name);
    const header = new HttpHeaders({ Authorization: this.localStorage.retrieve('APIKEY') });
    const requestUrl = objectId ? (url + objectId) : (url||ENDPOINTS.createMaterial);
    return this.api.request(requestUrl, 'POST', formData, header, true);
  }

  update(materialId: number, file: File): Promise<Material> {
    const formData = new FormData();
    formData.append('material[file]', file, file.name);
    const header = new HttpHeaders({ Authorization : this.localStorage.retrieve('APIKEY') });
    return this.api.request(ENDPOINTS.updateMaterial(materialId), 'POST', formData, header, true );
  }

  delete(materialId: number): Promise<void> {
    return this.api.request(ENDPOINTS.removeMaterial(materialId), 'DELETE');
  }

  download(fileUrl): Observable<any> {
    return this.api.downloadFile(fileUrl);
  }

  constructor(
    private api: APIService,
    private localStorage: LocalStorageService
  ) {}

}
