export class ProducerLocation {
  id?: number;
  producer_id?: number;
  country?: number;
  country_name?: string;
  name?: string;
  city?: string;
  post_code?: string;
  street?: string;
  house_number?: number;
  flat_number?: number;
  phone?: string;
  email?: string;

  public static toDto(obj: ProducerLocation) {
    const output = { location: {} };
    const {name, ...address} = obj;
    Object.assign(output.location, {name: obj.name, address });

    return output;
  }

  constructor(props?: any) {
    if (props) { Object.assign(this, props); }
    else {
      // Set Poland as a default country
      this.country = 168;
    }
  }
}
