import {APIService} from '../../../app/api/apiservice.service';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';

export class Help {
  id?: number;
  public?: boolean;
  active?: boolean;
  name?: string;
  category?: string;
  content?: string;
}

export class HelpDomain {

  public get(): Promise<Help[]> {
    return this.api.request(ENDPOINTS.getHelpList, 'GET').then(({data}) => data);
  }

  public getById(id: number): Promise<Help> {
    return this.api.request(ENDPOINTS.getHelp(id), 'GET');
  }

  public getPublic(): Promise<Help[]> {
    return this.api.request(ENDPOINTS.getPublicHelpList, 'GET').then(({data}) => Promise.resolve(data));
  }

  public getPublicById(id: number): Promise<Help> {
    return this.api.request(ENDPOINTS.getPublicHelp(id), 'GET');
  }

  public create(help: Help) {
    return this.api.request(ENDPOINTS.createHelp, 'POST', { help });
  }
  public update(help: Help) {
    return this.api.request(ENDPOINTS.updateHelp(help.id), 'POST', { help });
  }
  public delete(id: number) {
    return this.api.request(ENDPOINTS.deleteHelp(id), 'DELETE' );
  }

  constructor(private api: APIService) {}
}
