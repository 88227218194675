import { Component, OnInit } from '@angular/core';
import { FlagService } from '@material/ie-flag-notification/service/flag-service';
import { Title } from '@angular/platform-browser';
import { PAGE_TITLES } from '../../../../configuration/PAGE_TITLES';
import { ORDER_STATUS_MAP } from '../../../../configuration/translations/ORDER_STATUS_MAP';
import {IE_EMPTY_PLACEHOLDER} from '@material/ie-empty-placeholder/ie-empty-placeholder.component';
import {ReplaySubject, Subject} from 'rxjs';
import {CDKColumnDefinition} from '@material/ie-cdk-table/ie-cdk-table.component';
import {Bast} from '@bast';
import {Order} from '@bast/domain/order/order';
import {User} from '@bast/domain/user';

@Component({
  selector: 'ie-orders-received-list',
  templateUrl: './orders-received-list.component.html',
  styleUrls: ['./orders-received-list.component.scss'],
})
export class OrdersReceivedListComponent implements OnInit {

  public pending = true;
  public empty: boolean;
  public breadcrumb = [
    { href: '/orders/received', label: 'Zamówienia otrzymane' }
  ];
  public receivedOrders$: Subject<Order[]> = new ReplaySubject();
  public displayedColumns = ['id', 'code', 'price', 'created_at', 'user', 'status', 'manage'];
  public manageableColumns: CDKColumnDefinition[] = [
    { property: 'id', name: '#', visible: false },
    { property: 'code', name: 'Nazwa zamówienia' },
    { property: 'created_at', name: 'Utworzono' },
    { property: 'price', name: 'Wartość netto' },
    { property: 'status', name: 'Status' },
    { property: 'user', name: 'Opiekun' },
    { property: 'manage', transient: true },
  ];
  public users: Array<any> = [];
  public myself: User;
  public availableOrderStatus = [
    { status: 'new', label: ORDER_STATUS_MAP.new },
    { status: 'in_progress', label: ORDER_STATUS_MAP.in_progress },
    { status: 'canceled', label: ORDER_STATUS_MAP.canceled },
    { status: 'closed', label: ORDER_STATUS_MAP.closed }
  ];
  public NO_ORDERS = IE_EMPTY_PLACEHOLDER.NO_ORDERS;

  public changeOrderStatus(order: any, status: string) {
    this.bast.order.updateReceived(order.id, { status })
      .subscribe(
        () => {},
        () => this.flagService.publishErrorFlag(
          'Błąd',
          'Nie udało się zmienić statusu zamówienia'
        )
    );
  }

  public takeOrder(order) {
     this.bast.order.updateReceived(order.id, { user: this.myself.id })
    .subscribe(
      () => {},
      () => this.flagService.publishErrorFlag(
        'Błąd',
        'Nie udało się zmienić opiekuna zamówienia'
      ),
      () => this.fetchReceivedOrders()
    );
  }

  public changeOrderCustomFlag(order, flagIndex: number) {
    this.bast.order.updateReceived(order.id, { flag: flagIndex })
      .subscribe(
        () => {},
        () => this.flagService.publishErrorFlag(
          'Błąd',
          'Nie udało się zmienić flagi zamówienia'
        )
      );
  }

  private fetchReceivedOrders(): void {
    this.pending = true;
    this.title.setTitle(PAGE_TITLES.ordersReceived);
    this.bast.order.getReceived()
      .subscribe(
        res => {
          this.receivedOrders$.next(res);
          this.pending = false;
        },
        () => {
          this.empty = true;
          this.pending = false;
        }
      );
  }

  private fetchUsers(): void {
    this.bast.user.get()
      .subscribe(res => {
        this.users = res;
      });

    this.bast.user.myself().then(user => this.myself = user);
  }

  constructor(
    public bast: Bast,
    private flagService: FlagService,
    private title: Title
  ) {
    this.fetchReceivedOrders();
    this.fetchUsers();
  }

  ngOnInit() {
  }

}

