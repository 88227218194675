
<ie-contact-form-anchor></ie-contact-form-anchor>
<ng-container *ngIf="show && !router.url.includes('authorization')">
  <div
    *vaultlocal="'SEND_CONTACT_FORM'|vaultprivilege"
    (click)="openContactForm()"
    class="report-bug-wrapper"
    id="report-bug-button"
  >
      <ie-icon
      [icon]="'bug_report'"
      [size]="57"
      matTooltip="Zgłoś błąd"
    ></ie-icon>
  </div>
</ng-container>
