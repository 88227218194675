import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

export class CalendarDomain {

  public get(): Observable<any> {
    return window['angularInjector'].get(HttpClient).get(this.config.icalProxyLink, {responseType: 'text'} );
  }

  constructor(private config) {}
}
