import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEOnMountDirective } from './ie-on-mount.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    IEOnMountDirective
  ],
  exports: [
    IEOnMountDirective
  ]
})
export class IEOnMountModule { }
