import {from, Observable, ReplaySubject, Subject} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {map, tap} from 'rxjs/operators';
import {Tag} from '../../../app/ie-material/ie-tag/Tag';
import {Producer} from '../../../app/core/producers/model/producer';
import {APIService} from '../../../app/api/apiservice.service';
import {Supplier} from '../supplier';
import {Product} from '@bast/domain/product';

function checkIfFalsy(value, filterName) {
  return (value !== null && value !== undefined) ? (`${filterName}=${value}`) : null;
}

export class SearchDomain {

  private _phrase: Subject<string> = new Subject();
  private _onProductsLoaded$: ReplaySubject<{ total, page, pages, length }> = new ReplaySubject();
  public _liveSearchPhrase: Subject<string> = new Subject();

  public setPhrase(phrase: string) {
    this._phrase.next(phrase);
  }

  public getPhrase(): Observable<string> {
    return this._phrase.asObservable();
  }

  public searchByPhrase(
    query: string,
    page?: number,
    filters?: any,
    etim?: any,
    order?: string,
    orderBy?: string
  ): Observable<any> {
    this.setPhrase(query);
    const searchQuery = ENDPOINTS.getProducts + '?' + this.buildQuery(query) +
      '&page=' + (page || 1) + this.buildFilters(filters) + this.buildEtimFilters(etim) + this.buildOrder(order, orderBy);
    return from(this.api.request(
      searchQuery,
      'GET'
    )).pipe(tap(_ => {
      this._onProductsLoaded$.next({ total: _.total, length: _.data.length, page, pages: _.pages });
    }));
  }

  public searchFilters(
    query?: string,
    page?: number,
    filters?: any,
    order?: string,
    orderBy?: string
  ): Observable<any> {
    const searchQuery = ENDPOINTS.getFilters + '?' + this.buildQuery(query) +
    '&page=' + (page || 1) + this.buildFilters(filters) + this.buildOrder(order, orderBy);
    return from(this.api.request(
      searchQuery,
      'GET'
    ))
    .pipe(map(({ data }) => data));
  }

  public serarchProducerProducts(
    phrase: string,
    producerId: number,
    page?: number,
    filters?: any,
    order?: string,
    orderBy?: string
  ): Observable<any> {
    this.setPhrase(phrase);
    const query = ENDPOINTS.getProducts
      + '?' + this.getQueries(page, phrase, producerId)
      + this.buildFilters(filters) + this.buildOrder(order, orderBy);

    return from(this.api.request(query, 'GET'));
  }

  public searchSelfProductsByPhrase(
    phrase: string,
    page?: number,
    filters?: any,
    order?: string,
    orderBy?: string
  ): Observable<any> {  
    const pageQurery = (page !== null && page !== undefined) ? (`page=` + page) : '';
    const phraseQuery = (phrase !== null && phrase !== undefined) ? ('q=' + phrase) : '';
    const queries = [phraseQuery, pageQurery];
    const query = ENDPOINTS.getProducts + '?' + queries.join('&')
      + this.buildFilters(filters)
      + this.buildOrder(order, orderBy);
    return from(this.api.request(query, 'GET'));
  }

  public searchTags(query: string): Observable<Tag[]> {
    return from(this.api.request( ENDPOINTS.searchTags + '?q=' + query, 'GET'))
      .pipe(map(({data}) => data));
  }

  public getProductsByTag(id: any): Observable<Product[]> {
    return from(this.api.request(ENDPOINTS.getProductsByTag(id), 'GET'))
      .pipe(map(({ data }) => data));
  }

  public getSuppliersByTag(id: any): Observable<Supplier[]> {
    return from(this.api.request(ENDPOINTS.getSuppliersByTag(id), 'GET'))
      .pipe(map(({ data }) => data));
  }

  public getProducersByTag(id: any): Observable<Producer[]> {
    return from(this.api.request(ENDPOINTS.getProducersByTag(id), 'GET'))
      .pipe(map(({ data }) => data));
  }

  public onProductsLoaded(): Observable<{ total, length, page, pages }> {
    return this._onProductsLoaded$.asObservable();
  }

  private getQueries(page, phrase, producer?: number): string {
    const queries = [
      checkIfFalsy(page, 'page'),
      checkIfFalsy(phrase, 'q'),
      checkIfFalsy(producer, 'producer')
    ];

    return queries.filter(t => t).join('&');
  }

  private buildEtimFilters(etim) {
    if (!etim) return '';
    let f = '&';
    etim.forEach(e => {
      f = f+'filters[etim_features]'+e+'&';
    });
    return f.replace(/\&$/gi, '');
  }

  private buildFilters(filters) {
    const filter = filters ? filters : {};
    if (!filter) return '';

    let f = '&';
    // tslint:disable-next-line:forin
    for (const prop in filter) {
      if (!filter[prop]) { continue; }

      if (filter[prop] instanceof Array) {
        const arrayFilter = filter[prop]
          .reduce((acc, next) => {
            return acc += `filters[${prop}][]=${next}&`;
          }, '');

        f += arrayFilter;
      } else {
        // Chujowy fix
        const value = (filter[prop] === 'true' || filter[prop] === 'false') ?
          this.toNumber(filter[prop]) :
          filter[prop];

        f += `filters[${prop}]=${value}&`;
      }
    }

    return f.replace(/\&$/gi, '');
  }

  private toNumber(val): number {
    return val === 'true' ? 1 : 0;
  }

  private buildOrder(order: string, orderBy: string): string {
    if (!order) { return ''; }
    if (order && !orderBy) { return ''; }
    return `&order=${order}&order_by=${orderBy}`;
  }

  private buildQuery(query: string) {
    if (!query) return '';
    return `q=${query}`;
  }



  constructor(
    private api: APIService,
  ) { }
}
