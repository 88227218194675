import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicNewsletterRoutingModule } from './public-newsletter-routing.module';
import {PublicNewsletterComponent} from '../component/public-newsletter.component';
import {IEIconModule} from '@material/ie-icon/ie-icon.module';
import {SharedModule} from '@shared/shared.module';
import {IEEmptyPlaceholderModule} from '@material/ie-empty-placeholder/ie-empty-placeholder.module';


@NgModule({
  declarations: [PublicNewsletterComponent],
  imports: [
    CommonModule,
    PublicNewsletterRoutingModule,
    IEIconModule,
    SharedModule,
    IEEmptyPlaceholderModule
  ]
})
export class PublicNewsletterModule { }
