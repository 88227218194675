import {Injectable} from '@angular/core';
import {IEMultiselectComponent} from './ie-multiselect.component';

// Service used to prevent circular dependency between components
@Injectable()
export class MultiselectBindService {

  private _multiselect: IEMultiselectComponent;

  public getMultiselect(): IEMultiselectComponent {
    return this._multiselect;
  }

  public setMultiselect(multiselect: IEMultiselectComponent): void {
    this._multiselect = multiselect;
  }
}
