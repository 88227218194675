import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEQuantityInputComponent } from './ie-quantity-input.component';
import {IEIconModule} from '@material/ie-icon/ie-icon.module';
import {FormsModule} from '@angular/forms';



@NgModule({
  declarations: [IEQuantityInputComponent],
  exports: [
    IEQuantityInputComponent
  ],
  imports: [
    CommonModule,
    IEIconModule,
    FormsModule
  ]
})
export class IEQuantityInputModule { }
