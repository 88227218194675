import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {VaultLocalDirective} from '../directive/vault-local.directive';
import {VaultPrivilegePipe} from '../pipe/vault-privilege.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [VaultLocalDirective, VaultPrivilegePipe],
  exports: [VaultLocalDirective, VaultPrivilegePipe],
})
export class VaultSharedModule {}
