export class ObjectUtils<T> {
  private __object: any;

  /**
   * Factory method that returns ObjectUtils object of given type
   * @param obj
   * @returns ObjectUtils object
   */
  public static forObject<T>(obj?): ObjectUtils<T> {
    return new ObjectUtils(obj);
  }

  /**
   * Returns file name from file url.
   * @param url
   */
  public static readFilenameFromUrl(url: string) {
    return (url.match(/([^\/]+).\w+$/gi) || [])[0];
  }

  /**
   * Removes property from object if value for this property is an empty array
   */
  public stripEmptyArrays(): ObjectUtils<T> {
    if (!this.__object) { return this; }
    const output = {};
    Object.keys(this.__object).forEach(key => {
      if (this.__object[key] instanceof Array && !this.__object[key].length) { return; }
      Object.assign(output, { [key]: this.__object[key] });
    });

    this.__object = output;
    return this;
  }

  /**
   * Removes property from object if value for this property
   * is an empty string or stringified null
   */
  public stripEmptyStringParams(): ObjectUtils<T> {
    if (!this.__object) { return this; }
    const output = {};
    Object.keys(this.__object).forEach(key => {
      if (typeof this.__object[key] === 'string' && (this.__object[key] === 'null' || this.__object[key] === '')) { Object.assign(output, { [key]: null }); return; }
      Object.assign(output, { [key]: this.__object[key] });
    });

    this.__object = output;
    return this;
  }

  /**
   * Finds element in a array by key and replaces it in a existing array
   * @param replaceWith
   * @param key
   *
   * @returns array with replaced element if one was found. If nothing matched existing array with appended element
   * is returned
   */
  public findAndReplace(replaceWith: T, key?: string): ObjectUtils<T> {
    const idKey = key || 'id';
    const index = this.__object.findIndex(o => o[idKey] === replaceWith[idKey]);
    if (!(this.__object instanceof Array)) { return this; }
    if (!replaceWith.hasOwnProperty(idKey)) { this.__object = [...this.__object, replaceWith]; return this; }
    if (index === -1) { this.__object = [...this.__object, replaceWith]; return this; }

    const object = this.__object[index];
    this.__object[index] = {...object, ...replaceWith};
    return this;
  }

  public toObject(): T {
    return this.__object;
  }

  constructor(obj) { this.__object = obj; }
}
