<div class="change-column-container">
  <h3>Zarządzaj kolumnami</h3>
  <p>Zaznacz, które kolumny chcesz wyświetlić.<br/>Kliknij i przeciągnij, aby ustawić odpowiednią kolejność</p>
  <label class="columns-label">Kolumny</label>
  <ul class="dialog-form-container" cdkDropList (cdkDropListDropped)="orderChanged($event)">
    <li
      *ngFor="let column of columns"
      class="draggable-row"
      cdkDrag
    >
      <ng-container *ngIf="!column.transient">
        <div class="draggable-wrapper">
          <ie-checkbox [(ngModel)]="column.visible"></ie-checkbox>
          <span>{{column.name}}</span>
          <ie-icon
            cdkDragHandle
            class="change-order-icon"
            [icon]="'drag'"
          ></ie-icon>
        </div>
      </ng-container>
    </li>
  </ul>

  <div class="dialog-form-buttons" style="box-shadow: none;">
    <button class="bar-cancel-button" (click)="onclose.emit()">anuluj</button>
    <button class="bar-accept-button" (click)="onorderchange.emit(columns)">zapisz</button>
  </div>
</div>
