<div class="order-product-tile" [class.withdrawn]="!product.product_id">
  <div class="product-index">{{index+1}}</div>

  <div class="product-photo-wrapper">
    <div
      class="product-photo"
      [attr.inaccessibile]="!product.product_id?true:null"
      [style.background-image]="'url(' + product.product_image + ')'"
    ></div>
  </div>

  <div class="product-details-wrapper">

    <div class="product-name-wrapper">
      <a *ngIf="product.product_id;else simpleProductName" class="--link" routerLink="/app/product/{{product.product_id}}">{{product.product_name}}</a>
      <ng-template #simpleProductName>
        <span [attr.inaccessibile]="!product.product_id?true:null">{{product.product_name}}</span>
      </ng-template>
    </div>

    <div class="product-info-wrapper">
      <div class="product-tile-col product-name-col">
        <span class="producer-name">producent: <b>{{product.producer_alias || product.producer_name}}</b></span>
        <span class="product-code">kod produktu: <b>{{product.product_code}}</b></span>
      </div>

      <div class="product-tile-col">
        <span class="product-unit-netto-price">netto: <b>{{product.price|price}} zł</b></span>
        <span class="unit">jednostka: <b>{{product.unit}}</b></span>
        <span class="lot-number">nr partii: <b>{{product.lot||'-'}}</b></span>
      </div>

      <div class="product-tile-col">
        <span class="stock">
          dostępność: <b>{{product.stock||'-'}} szt.</b>
          <ie-icon
            *ngIf="product.infinity_stock"
            matTooltip="Produkt może być zamówiony w dowolnej ilości u tego sprzedawcy"
            style="display: inline-block;vertical-align: text-top;margin-left:5px"
            [icon]="'infinity_stock'"
          ></ie-icon>
        </span>
        <span class="package">opakowanie: <b>{{product.package_quantity||'-'}} szt.</b></span>
      </div>

      <div class="product-tile-col product-manage-col">
        <div class="product-manage-price">
          <div
            class="quantity-input-container"
            [attr.locked]="locked?true:null"
          >
            <div
              class="quantity-input-wrapper"
              matTooltip="{{disabled?'Edycja zamówienia została zablokowana. Zmień opiekuna zamówienia, aby zarządzać produktami.':''}}"
              [matTooltipShowDelay]="700"
            >
              <ie-quantity-input
                [name]="product.product_name"
                [pending]="locked"
                [disabled]="disabled"
                [max]="product.infinity_stock?INFINITY:product.stock"
                (onchange)="quantityChange($event)"
                [(ngModel)]="product.quantity"
              ></ie-quantity-input>
            </div>
          </div>

          <div class="product-price-details">
            <span class="order-product-netto-price">{{product.price_total|price}} zł</span>
            <span class="order-product-gross-price">({{product.gross_price_total|price}} zł brutto)</span>
            <span class="tax-value">(VAT {{product.tax}} %)</span>
          </div>
        </div>
      </div>

      <div
        *ngIf="!disabled"
        class="product-tile-col delete-product-col"
      >
        <ie-icon
          [icon]="'delete'"
          [size]="17"
          [hover]="true"
          (click)="productDelete.emit()"
        ></ie-icon>
      </div>
    </div>

  </div>

</div>
