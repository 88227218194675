import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {from, Subscription} from 'rxjs';
import { FlagService } from '@material/ie-flag-notification/service/flag-service';
import { Title } from '@angular/platform-browser';
import { PAGE_TITLES } from '../../../../configuration/PAGE_TITLES';
import { ORDER_STATUS_MAP } from '../../../../configuration/translations/ORDER_STATUS_MAP';
import {DomainComparator} from '@shared/utils/domain-comparator';
import {Bast} from '@bast';
import {Order} from '@bast/domain/order/order';
import {User} from '@bast/domain/user';

@Component({
  selector: 'ie-orders-received-preview',
  templateUrl: './orders-received-preview.component.html',
  styleUrls: ['./orders-received-preview.component.scss']
})
export class OrdersReceivedPreviewComponent implements OnDestroy {

  public pending = true;
  public breadcrumb = [{ href: '/orders/received', label: 'Zamówienia otrzymane' }];
  public order: Order;
  public comparator: DomainComparator<Order>;
  public users: Array<any> = [];
  public newComment: string;
  public availableOrderStatus = [
    { status: 'new', label: ORDER_STATUS_MAP.new },
    { status: 'in_progress', label: ORDER_STATUS_MAP.in_progress },
    { status: 'canceled', label: ORDER_STATUS_MAP.canceled },
    { status: 'closed', label: ORDER_STATUS_MAP.closed }
  ];
  public commentInputFocused = false;
  public myself: User;

  private _routeSubscription: Subscription;
  private _orderDetailsSubscription: Subscription;
  private _usersListSubscription = new Subscription();

  public commentOrder(): void {
    this.bast.order.updateReceived(this.order.id, { comment: this.order.comment })
      .subscribe(
        () => {
          this.fetchOrderDetails(this.order.id);
          this.newComment = null;
        },
        () => {
          this.flagService.publishErrorFlag(
            'Błąd',
            'Nie udało się skomentować zamówienia'
          );
        }
      );
  }

  public takeOrder(): void {
    this.bast.order.updateReceived(this.order.id, { user: this.myself.id })
      .subscribe(
        () => this.fetchOrderDetails(this.order.id),
        () => this.flagService.publishErrorFlag(
          'Błąd',
          'Nie udało się zmienić opiekuna zamówienia'
        )
      );
  }

  public changeOrderStatus(status: any): void {
    this.bast.order.updateReceived(this.order.id, { status })
      .subscribe(
        () => this.fetchOrderDetails(this.order.id),
        () => this.flagService.publishErrorFlag(
          'Błąd',
          'Nie udało się zmienić statusu zamówienia'
        )
      );
  }

  public changeOrderCustomFlag(flagIndex: number): void {
    this.bast.order.updateReceived(this.order.id, { flag: flagIndex })
      .subscribe(
        () => this.fetchOrderDetails(this.order.id),
        () => this.flagService.publishErrorFlag(
          'Błąd',
          'Nie udało się zmienić flagi zamówienia'
        )
      );
  }

  public updateOrder(): void {
    if (this.comparator.hasChanged()) {
      this.bast.order.updateReceived(this.order.id, { user: this.comparator.getCurrent().user_id, status: this.comparator.getCurrent().status })
        .subscribe(() => {
          this.comparator.squash();
        });
    }
  }

  public focusCommentArea(el): void {
    this.commentInputFocused = true;
    setTimeout(() => el.focus(), 50);
  }

  public appendOrderComment(el) {
    const comment = el.innerText;
    if (!comment.length) { return; }
    this.bast.order.updateReceived(this.order.id, { comment })
      .subscribe(async (res: any) => {
          const user = await this.bast.user.myself();
          this.order.comments = [...this.order.comments, { user: user.id || null, date: Math.floor(Date.now() / 1000), content: comment }];
          this.commentInputFocused = false;
          el.innerText = '';
        },
        () => {
          this.flagService.publishWarningFlag(
            'Błąd',
            'Nie udało się dodać komentarza do zamówienia'
          );
        });
  }

  private setBreadcrumb(order: any): void {
    if (!order) { return; }
    if (this.breadcrumb.length === 2) { return; }
    this.breadcrumb = [
      ...this.breadcrumb,
      { href: `/orders/received/preview/${order.id}`, label: order.code }
    ];
  }

  private fetchOrderDetails(id: number): void {
    this._orderDetailsSubscription = this.bast.order.getReceivedById(id)
      .subscribe( order => {
        this.order = order;
        this.comparator = new DomainComparator<Order>(order);
        this.title.setTitle(PAGE_TITLES.orderPreview(this.order.code));
        this.setBreadcrumb(this.order);
        this.pending = false;
      });
  }

  private fetchUsers(): void {
    this._usersListSubscription.add(this.bast.user.get()
      .subscribe(res => {
        this.users = res;
      }));

    this._usersListSubscription.add(from(this.bast.user.myself()).subscribe(myself => this.myself = myself));
  }

  constructor(
    public bast: Bast,
    private flagService: FlagService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private title: Title
  ) {
    this._routeSubscription = this.activeRoute.params.subscribe(
      params => {
        const { id } = params;
        if (id) {
          this.fetchUsers();
          this.fetchOrderDetails(id);
        } else {
          this.router.navigateByUrl('/app/orders/received');
        }
      }
    );
  }

  ngOnDestroy() {
    if (this._routeSubscription) { this._routeSubscription.unsubscribe(); }
    if (this._usersListSubscription) { this._usersListSubscription.unsubscribe(); }
    if (this._orderDetailsSubscription) { this._orderDetailsSubscription.unsubscribe();  }
  }

}
