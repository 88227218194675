export const PAGE_TITLES = {
    home: 'BAST - Inter-Elektro',
    createUser: 'Nowy użytkownik - BAST',
    editUser: (username) => `${username} - BAST`,
    userList: 'Użytkownicy - BAST',
    createProducer: 'Nowy producent - BAST',
    editProducer: (producer) => `${producer} - BAST`,
    producersList: 'Producenci - BAST',
    editSupplier: (supplier) => `${supplier} - BAST`,
    suppliersList: `Sprzedawcy - BAST`,
    createSupplier: `Nowy sprzedawca - BAST`,
    orders: 'Moje zamówienia - BAST',
    ordersReceived: 'Zamówienia odebrane - BAST',
    orderPreview: (orderCode) => `Szczegóły zamówienia - ${orderCode} - BAST`,
    settings: 'Ustawienia - BAST',
    productPreview: (productName) => `${productName} - BAST`,
    searchResults: (phrase) => `${phrase} - wyszukiwanie - BAST`,
    help: 'Pomoc - BAST',
    resetPassword: 'Resetuj hasło',
    smsConfirm: 'Potwierdzenie logowania - BAST',
    activateAccount: 'Aktywacja nowego konta - BAST',
    integrations: 'Cenniki - BAST',
    calendar: 'Kalendarz - BAST'
};
