import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ContactFormAnchorComponent } from 'src/app/core/contact/component/contact-form/contact-form-anchor';
import { Router } from '@angular/router';

@Component({
  selector: 'ie-report-bug',
  templateUrl: './ie-report-bug.component.html',
  styleUrls: ['./ie-report-bug.component.scss']
})
export class IEReportBugComponent implements OnInit, OnDestroy {

  @ViewChild(ContactFormAnchorComponent, { static: true }) contactForm: ContactFormAnchorComponent;
  public show: boolean;

  constructor(public router: Router) {
  }

  public openContactForm(): void {
    this.contactForm.openContactForm();
  }

  ngOnInit() {
    setTimeout(() => {
      this.show = true;
    },500)
  }

  ngOnDestroy() {
  }

}
