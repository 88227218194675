import {Component, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import {BehaviorSubject} from 'rxjs';
import { PAGE_TITLES } from '../../../../configuration/PAGE_TITLES';
import {IE_EMPTY_PLACEHOLDER} from '@material/ie-empty-placeholder/ie-empty-placeholder.component';
import {IEWarnDialogComponent} from '@material/ie-warn-dialog/ie-warn-dialog.component';
import {Bast} from '@bast';
import {Order} from '@bast/domain/order/order';

@Component({
  selector: 'ie-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {

  @ViewChild(IEWarnDialogComponent) warnDialog: IEWarnDialogComponent;
  public orders$: BehaviorSubject<Order[]> = new BehaviorSubject<Order[]>([]);
  public activeOrder: Order;
  public disableRadioButtons: boolean = true;
  public pending = true;
  public displayedColumns = ['active', 'status', 'code', 'user', 'price', 'manage'];
  public manageableColumns = [
    // { property: 'id', name: '#', visible: false },
    { property: 'active', name: 'Aktywne zam.'},
    { property: 'status', name: 'Status zamówienia' },
    { property: 'user', name: 'Opiekun' },
    { property: 'code', name: 'Nazwa' },
    { property: 'price', name: 'Cena netto' },
    { property: 'manage', transient: true }
  ];
  public breadcrumb = [
    { href: '/orders', label: 'Zamówienia zlecone'}
  ];
  public NO_ORDERS = IE_EMPTY_PLACEHOLDER.NO_ORDERS;

  public previewOrder(order: Order): void {
    this.router.navigateByUrl('/app/orders/preview/' + order.id);
  }

  public activateOrder(order: Order): void {
    this.disableRadioButtons = true;
    this.pending = true;
    this.bast.order.activate(order).subscribe(() => order.active = true);
  }

  public deleteOrder(order): void {
    this.warnDialog.open(`warn`, `Usuwanie zamówienia`, `Zamówienie zostanie usunięte. Tej operacji nie można cofnać`, 'usuń')
      .onAccept()
      .subscribe(() => {
        this.warnDialog.setPending(true);
        this.bast.order.delete(order.id).then(() => this.warnDialog.close());
        this.initialize();
      });
  }

  private initialize(): void {
    this.title.setTitle(PAGE_TITLES.orders);
    this.pending = true;
    this.bast.order.state()
      .subscribe(({orders, active}) => {
        this.orders$.next(orders);
        this.activeOrder = active;
        this.disableRadioButtons = false;
        this.pending = false;
      });
  }

  constructor(
    public bast: Bast,
    private router: Router,
    private title: Title,
  ) {
    this.initialize();
  }

  ngOnInit() {}

}
