import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'safecontent'
})
export class SafeContentPipe implements PipeTransform {

  constructor(protected _sanitizer: DomSanitizer) {}

  transform(value: string, args?: any): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }

}
