import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular'

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { version } from '../package.json'

import 'hammerjs';

if (environment.production) {
  enableProdMode();
  Sentry.init({
    dsn: 'https://fc1acfda88784f458b3182c9c0677aae@o284200.ingest.sentry.io/1513429',
    release: `${version}`
  })
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then((module) => {
    window['angularInjector'] = module.injector;
  })
  .catch(err => console.error(err));
