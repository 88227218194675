import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IEOverlayFocusTrapDirective} from './ie-overlay-focus-trap.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    IEOverlayFocusTrapDirective
  ],
  exports: [
    IEOverlayFocusTrapDirective
  ]
})
export class IEOverlayFocusTrapModule { }
