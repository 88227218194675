export class URLUtils {

  /**
   * Returns query url segment based on filter object
   * Appends query param only if not null
   * @param filters Filter map object
   */
  public static buildQueryUrl(filters: object): string {
    const queryParams = Object.keys(filters).reduce((acc, next) => {
      if (!filters[next]) { return acc; }
      return `${acc}&${next}=${filters[next]}`;
    }, ``);

    return queryParams.length ? (`?` + queryParams) : '';
  }
}
