import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEPaginatorComponent } from './ie-paginator.component';
import { IEIconModule } from '@material/ie-icon/ie-icon.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    IEIconModule,
    FormsModule
  ],
  declarations: [
    IEPaginatorComponent
  ],
  exports: [
    IEPaginatorComponent
  ]
})
export class IEPaginatorModule { }
