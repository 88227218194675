<div class="order-product-tile">
  <div class="product-index">{{index+1}}</div>

  <div class="product-details-wrapper" style="margin-top: 3px">

    <div class="product-name-wrapper"><a class="--link" routerLink="/app/product/{{product.product_id}}">{{product.product_name}}</a></div>
    <div class="product-info-wrapper">
      <div class="product-tile-col">
        <span class="product-name">{{product.supplier_alias || product.supplier_name}}</span>
        <span class="producer-name">Telefon: <b>{{product.supplier_phone}}</b></span>
        <span class="product-code">E-mail: <b>{{product.supplier_email}}</b></span>
        <span class="product-code">NIP: <b>{{product.supplier_tax_number}}</b></span>
      </div>

      <div class="product-tile-col col-shifted">
        <span class="lot-number">Nr partii: <b>{{product.lot||'-'}}</b></span>
        <span class="product-unit-netto-price">Netto: <b>{{product.price|price}} zł</b></span>
        <span class="unit">Jednostka: <b>{{product.unit}}</b></span>
      </div>

      <div class="product-tile-col col-shifted">
        <div class="product-manage-price">
          <div class="quantity-input-container">
            <div
              class="quantity-input-wrapper"
              matTooltip="{{disabled?'Edycja zamówienia została zablokowana. Zmień opiekuna zamówienia, aby zarządzać produktami.':''}}"
            >
              <ie-quantity-input
                [name]="product.product_name"
                [pending]="locked"
                [disabled]="disabled"
                [max]="product.infinity_stock?INFINITY:product.stock"
                (onchange)="quantityChange($event)"
                [(ngModel)]="product.quantity"
              ></ie-quantity-input>
            </div>
          </div>

          <div class="product-price-details">
            <span class="order-product-netto-price">{{product.price_total|price}} zł</span>
            <span class="order-product-gross-price">({{product.gross_price_total|price}} zł brutto)</span>
            <span class="tax-value">(VAT {{product.tax}} %)</span>
          </div>
        </div>
      </div>


      <div
        *ngIf="!disabled"
        class="product-tile-col col-shifted delete-product-col"
      >
        <ie-icon
          [icon]="'delete'"
          [size]="17"
          [hover]="true"
          (click)="productDelete.emit()"
        ></ie-icon>
      </div>
    </div>
  </div>

</div>
