<div
  class='searchbar-container'
  (clickOutside)="closeSearchbox()"
  [attr.opened]="keepOpen === true || searchBoxOpened ? true : false"
  [attr.error]="error ? error : null"
>
  <input
    #input
    [attr.opened]="searchBoxOpened"
    [attr.placeholder]="placeholder"
    (focus)="searchBoxOpened=true"
    (keydown.enter)="onenter.emit($event)"
    (keyup)="onKeyUp($event)"
    (paste)="onPaste($event)"
    [(ngModel)]="phrase"
  />
  <div class="searchbar-buttons">
    <button>
      <ie-icon
        *ngIf="phrase&&!!phrase.length"
        class="clear-button"
        [icon]="'clear'"
        [pointer]="true"
        [size]="10"
        (click)="clear()"
      ></ie-icon>
    </button>
    <button>
      <ie-icon
        class="search-button"
        [icon]="'search_big'"
        [pointer]="true"
        [size]="14"
        (click)="search()"
      ></ie-icon>
    </button>
  </div>
</div>
