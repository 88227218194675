import { NgModule, Directive, ElementRef, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

@Directive(
  { selector: '[autofocus]' }
)
export class AutofocusDirective implements OnInit {
  private focus = true;
  constructor(private el: ElementRef) {}
  ngOnInit() {
    if (this.focus) {
        window.setTimeout(() => {
            this.el.nativeElement.focus();
        });
    }
  }
  @Input() set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }
}

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [AutofocusDirective],
  exports: [AutofocusDirective]
})
export class IEAutofocusModule { }