import {from, Observable} from 'rxjs';
import {ENDPOINTS} from '../../../app/configuration/ENDPOINTS';
import {map} from 'rxjs/operators';
import {APIService} from '../../../app/api/apiservice.service';

export interface ProducerBrand {
  id: number;
  producer_id?: number;
  producer?: number;
  name?: string;
  description?: string;
  image?: number;
  image_id?: number;
  image_url?: string;
}

export class BrandDomain {

  public get(producerId: number): Observable<ProducerBrand[]> {
    return from(this.apiService.request(ENDPOINTS.getBrands(producerId), 'GET')).pipe(map(({data}) => data));
  }

  public getSupplier(): Promise<ProducerBrand[]> {
    return this.apiService.request(ENDPOINTS.getBrands(), 'GET').then(({data}) => Promise.resolve(data));
  }

  public getById(brandId: number): Observable<{ brands: Array<ProducerBrand> }> {
    return from(this.apiService.request(ENDPOINTS.getBrand(brandId), 'GET')) as Observable<{ brands: Array<ProducerBrand> }>;
  }

  public create(brand: any): Observable<ProducerBrand> {
    return from(this.apiService.request(ENDPOINTS.createBrand, 'POST', { brand })) as Observable<ProducerBrand>;
  }

  public update(brand: any): Observable<ProducerBrand> {
    return from(this.apiService.request<ProducerBrand>(
      ENDPOINTS.updateBrand(brand.id),
      'POST',
      { brand }
    ));
  }

  public delete(brandId: number): Observable<any> {
    return from(this.apiService.request<void>(
      ENDPOINTS.deleteBrand(brandId),
      'DELETE'
    ));
  }

  constructor(
    private apiService: APIService,
  ) {}
}
