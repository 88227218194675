<div
  class='ie-multiselect-options-container'
  (clickOutside)="close()"
>
  <div class='ie-multiselect-search-wrapper'>
    <ie-icon
      class="close-icon-button"
      [icon]="'input_arrow'" style="transform: rotate(180deg)"
      [size]="20"
      [hover]="true"
      (click)="close()"
    ></ie-icon>
    <input
      id="ieselectinput"
      class='ie-multiselect-search-input'
      autocomplete="off"
      autocapitalize="none"
      autocorrect="off"
      tabindex="0"
      placeholder="Wyszukaj"
      [(ngModel)]="parent.getMultiselect().filter"
    />
    <ie-icon
      class="ie-multiselect-search-icon"
      [icon]="'search'"
      [size]="14"
    ></ie-icon>
  </div>
  <div
    class='ie-multiselect-option-wrapper'
    [style.max-height.px]="height"
  >
    <div
      *ngIf="parent.getMultiselect().multiple && parent.getMultiselect().showSelectAll"
      class='ie-multiselect-option select-all'
      (click)="toggleAll()"
    >{{someSelected() ? 'Odznacz' : 'Zaznacz'}} wszystkie
    </div>
    <div
      *ngIf="!parent.getMultiselect().options.length"
      class='ie-multiselect-option ie-disabled-option'
    >Brak opcji
    </div>
    <div id="options-container">
      <ng-content></ng-content>
    </div>
  </div>
</div>
