import {Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Bast} from '@bast';

@Directive({
  selector: '[vaultlocal]'
})
export class VaultLocalDirective {

  private _privileges: string[];
  private _else: TemplateRef<any>;
  private _context: object;
  private _viewRef: EmbeddedViewRef<any>;

  /**
   * Sets privileges list which is unparsed array of strings,
   * array of string array or single string
   * @param privileges
   */
  @Input() set vaultlocal(privileges: string[]) {
    if (!privileges) { return; }
    this._privileges = privileges;
    this._updateView();
  }

  /**
   * If set and user does not have appropiate privileges directive will mount
   * this template ref
   * @param elzz
   */
  @Input() set vaultlocalElse(elzz: TemplateRef<any>) {
    this._else = elzz;
    this._updateView();
  }

  /**
   * If set privilege will check against passed context.
   * Context should be a partial object of account object from /account
   * @param context
   */
  @Input() set vaultlocalContext(context: object) {
    this._context = context;
    if (context) { this._updateView(); }
  }

  protected mountElse(): void {
    if (!this._else) { return; }
    if (!this._viewRef) {
      this._viewRef = this.viewContainer.createEmbeddedView(this._else);
    }
  }

  protected _updateView() {
    // if (this._viewRef) { return; }
    this.bast.authorization[this._context ? 'checkWithinContext' : 'check']
      .call(this.bast.authorization, this._privileges, this._context)
      .subscribe(access => {
        if (access) {

          this.viewContainer.clear();
          this._viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this._viewRef = null;
          this.viewContainer.clear();
          this.mountElse();
        }
      });
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private bast: Bast,
  ) { }

}
