import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {IEMultiselectComponent} from '@material/ie-multiselect/ie-multiselect.component';
import {IEInputComponent} from '@material/ie-input/ie-input.component';
import {ErrorResponse} from '@shared/input-output-accessor/Validator';
import {Bast} from '@bast';

@Component({
  selector: 'ie-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactFormComponent implements OnInit {

  @ViewChild(IEMultiselectComponent, { static: true }) multiselect: IEMultiselectComponent;
  @ViewChild(IEInputComponent, { static: true }) input: IEInputComponent;

  public contactForm: any = {
    subject: null,
    content: null,
    files: [],
    contactAggreement: false,
    referer: null
  };
  public attachmentError: boolean;
  public pending: boolean;
  public taskKey: string; 

  public SUBJECTS = [
    { key: 'bast_suggestion', value: 'Sugestia' },
    { key: 'bast_front_error', value: 'Błąd w Systemie BAST' },
    { key: 'bast_backend_error', value: 'Błąd w API BAST' },
    { key: 'bast_price_list_error', value: 'Błąd w cenniku' },
    { key: 'bast_import_error', value: 'Błąd w imporcie BAST' },
    { key: 'bast_privilege_error', value: 'Problem z uprawnieniami' }
  ];
  public sent: boolean;

  public handleFileInput(files: FileList): void {
    this.contactForm.files = [...this.contactForm.files, ...Array.from(files)];
  }

  public deleteFile(index: number): void {
    this.attachmentError = false;
    this.contactForm.files.splice(index, 1);
  }

  public sendContactForm(): void {
    this.pending = true;
    this.bast.contact.send(this.contactForm)
      .then(res => {
        this.sent = true;
        this.taskKey = res.key;
      })
      .catch(e => {
        this.validate(e.error);
        if (e.error.errors) { this.attachmentError = true; }
      });
  }

  private validate(error: ErrorResponse): void {
    this.multiselect.validate(error);
    this.input.validate(error);
  }

  constructor(
    private bast: Bast,
  ) { }

  ngOnInit() {
    this.contactForm.referer = window.location.href;
  }

}
