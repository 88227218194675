import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IEInputComponent } from './ie-input.component';
import { FormsModule } from '@angular/forms';
import { IEIconModule } from '@material/ie-icon/ie-icon.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IEIconModule
  ],
  declarations: [IEInputComponent],
  exports: [IEInputComponent]
})
export class IEInputModule { }
