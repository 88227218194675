<div 
  class='extension-panel' 
  [attr.disabled]="disabled ? true : null" 
  [attr.locked]="locked ? true : null" 
  [attr.opened]="opened"
>
  <div 
    class='extension-panel-header' 
    (click)="toggleExtensionPanel($event)"
  >
    <ie-icon
      class="expand-icon"
      [icon]="icon"
      [size]="16"
    ></ie-icon>
    <ng-content select="[panel-header]"></ng-content>
  </div>
  <div class='extension-panel-content'>
    <ng-content select="[panel-content]"></ng-content>
  </div>
</div>
